import http from "../http-common";
/* eslint-disable */
class PibHospitalityTeamService {
  hospitalityTeamList(page, itemPerPage, keyword, id) {
    return http.get(
      "/pib/hospitality-team/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.hospitality_id)
      bodyFormData.append("hospitality_id", data.hospitality_id);
    if (data.nama) bodyFormData.append("nama", data.nama);

    if (data.selectedOlahraga && data.selectedOlahraga != undefined) {
      bodyFormData.append("olahraga", data.selectedOlahraga.id);
    }

    return http.post("/pib/hospitality-team", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.hospitality_id)
      bodyFormData.append("hospitality_id", data.hospitality_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.selectedOlahraga && data.selectedOlahraga != undefined) {
      bodyFormData.append("olahraga", data.selectedOlahraga.id);
    }

    return http.put(`/pib/hospitality-team/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/pib/hospitality-team/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PibHospitalityTeamService();
