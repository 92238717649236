import http from "../http-common";
/* eslint-disable */
class PesertaEventService {
  pesertaEventList(page, itemPerPage, keyword, id) {
    return http.get(
      "/event/peserta/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.whatsapp) bodyFormData.append("whatsapp", data.whatsapp);
    if (data.email) bodyFormData.append("email", data.email);
    if (data.tempat_lahir)
      bodyFormData.append("tempat_lahir", data.tempat_lahir);
    if (data.tanggal_lahir)
      bodyFormData.append("tanggal_lahir", data.tanggal_lahir);
    if (data.alamat) bodyFormData.append("alamat", data.alamat);

    if (data.file_ktp != null) bodyFormData.append("foto_ktp", data.file_ktp);
    if (data.file_diri != null)
      bodyFormData.append("foto_diri", data.file_diri);

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    if (data.selectedKecamatan && data.selectedKecamatan != undefined) {
      bodyFormData.append("kecamatan_id", data.selectedKecamatan.id);
    }

    if (data.selectedKelurahan && data.selectedKelurahan != undefined) {
      bodyFormData.append("kelurahan_id", data.selectedKelurahan.id);
    }

    return http.put(`/event/peserta/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  send(id) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.post("/event/peserta-broadcast", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  batalkanKepesertaan(id) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.post("/event/batal-peserta", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadPesertaReport(search, id) {
    return http.get(
      "/report/peserta-event/download?keyword=" + search + "&event_id=" + id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  downloadPesertaReportAll(search) {
    return http.get("/report/peserta-event-all/download?keyword=" + search, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PesertaEventService();
