import http from "../../http-common"

class PenyelenggaraSkpKemkesService {
  penyelenggaraList(page, itemPerPage, keyword) {
    return http.get(
      "/penyelenggara-skp-kegiatan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  penyelenggaraListAll() {
    return http.get("/penyelenggara-skp-kegiatan/list/all", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  getPenyelenggara(id) {
    return http.get(`/penyelenggara-skp-kegiatan/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  create(data) {
    var bodyFormData = new FormData()
    if (data.nama) bodyFormData.append("nama", data.nama)
    if (data.email) bodyFormData.append("email", data.email)
    if (data.nama_penanggung_jawab)
      bodyFormData.append("nama_penanggung_jawab", data.nama_penanggung_jawab)
    if (data.whatsapp_penanggung_jawab)
      bodyFormData.append(
        "whatsapp_penanggung_jawab",
        data.whatsapp_penanggung_jawab
      )
    if (data.nama_pic) bodyFormData.append("nama_pic", data.nama_pic)
    if (data.whatsapp_pic)
      bodyFormData.append("whatsapp_pic", data.whatsapp_pic)
    if (data.alamat) bodyFormData.append("alamat", data.alamat)

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id)
    }
    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id)
    }

    return http.post("/penyelenggara-skp-kegiatan", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  update(id, data) {
    var bodyFormData = new FormData()

    if (data.nama) bodyFormData.append("nama", data.nama)
    if (data.email) bodyFormData.append("email", data.email)
    if (data.nama_penanggung_jawab)
      bodyFormData.append("nama_penanggung_jawab", data.nama_penanggung_jawab)
    if (data.whatsapp_penanggung_jawab)
      bodyFormData.append(
        "whatsapp_penanggung_jawab",
        data.whatsapp_penanggung_jawab
      )
    if (data.nama_pic) bodyFormData.append("nama_pic", data.nama_pic)
    if (data.whatsapp_pic)
      bodyFormData.append("whatsapp_pic", data.whatsapp_pic)
    if (data.alamat) bodyFormData.append("alamat", data.alamat)

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id)
    }
    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id)
    }

    return http.put(`/penyelenggara-skp-kegiatan/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  delete(id) {
    return http.delete(`/penyelenggara-skp-kegiatan/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }
}

export default new PenyelenggaraSkpKemkesService()
