<template>
  <div>
    <v-card>
      <v-card-title>
        Modul <br />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Modul"
          single-line
          hide-details
          v-on:keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="mb-2" @click="openTambah">
          Tambah
        </v-btn>
        <v-dialog v-model="dialog" persistent>
          <v-card>
            <v-card-title>
              <span class="text-h5">Modul</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6" class="pr-4">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="fotoKey"
                            :src="currentData.FotoUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImage"
                        accept="image/*"
                        v-model="currentData.file_foto"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Judul"
                            dense
                            value=""
                            rows="3"
                            v-model="currentData.title"
                            :rules="currentData.kegiatanTitleRules"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Deskripsi"
                            dense
                            value=""
                            v-model="currentData.description"
                            :rules="currentData.kegiatanDescRules"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Informasi"
                            dense
                            value=""
                            v-model="currentData.informasi"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h5>Konten</h5>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="4" md="4">
                      <v-textarea
                        outlined
                        name="input-7-4"
                        label="Prasyarat Khusus"
                        dense
                        value=""
                        v-model="currentData.prasyarat_khusus"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="No SKP"
                            outlined
                            dense
                            v-model="currentData.nomor_skp"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- <v-col cols="12" sm="4" md="4">
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Tanggal"
                            v-model="currentData.tanggal"
                            type="date"
                            dense
                            :rules="currentData.tanggalRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                            label="Jam Awal"
                            v-model="currentData.jam_awal"
                            type="time"
                            dense
                            :rules="currentData.jamAwalRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense
                        ><v-col cols="12">
                          <v-text-field
                            label="Jam Akhir"
                            v-model="currentData.jam_akhir"
                            type="time"
                            dense
                            :rules="currentData.jamAkhirRules"
                          ></v-text-field> </v-col
                      ></v-row>
                    </v-col> -->
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="SKP Narasumber"
                        outlined
                        dense
                        v-model="currentData.skp_narasumber"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="SKP Moderator"
                        outlined
                        dense
                        v-model="currentData.skp_moderator"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="SKP Panitia"
                        outlined
                        dense
                        v-model="currentData.skp_panitia"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        label="SKP Peserta"
                        outlined
                        dense
                        v-model="currentData.skp_peserta"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
        <template v-slot:[`item.description`]="{ item }">
          {{ truncate(item.description) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-4">
      <PelatihanCategoryComponent
        v-if="selected != undefined && selected.length > 0"
        :id="selected[0].id"
        ref="mychild"
        :key="childKey"
        :kegiatan_id="id"
      />
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import PelatihanService from "../../services/PelatihanService"
import * as moment from "moment"
import CommonService from "../../services/CommonService"

import PelatihanCategoryComponent from "./PelatihanCategory.vue"

export default {
  props: {
    id: String,
  },
  components: {
    PelatihanCategoryComponent,
  },
  data: () => ({
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "title", sortable: false },
      { text: "Deskripsi", value: "description", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    currentData: {
      kegiatan_id: "",
      id: null,
      FotoUrl: "",
      file_foto: null,
      title: "",
      description: "",
      informasi: "",
      prasyarat_khusus: "",
      skp_narasumber: "",
      skp_moderator: "",
      skp_panitia: "",
      skp_peserta: "",
      nomor_skp: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],

      tanggal: "",
      jam_awal: "",
      jam_akhir: "",
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      jamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      jamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
    },
    fotoKey: 0,

    layananList: [],
    currentLayanan: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    truncate: function (input) {
      if (input && input.length > 10) {
        return input.substring(0, 5) + "..."
      }
      return input
    },
    loadImage: function (data) {
      console.log("loadImage:", data)
      if (data) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result
          this.fotoKey++
        }
        reader.readAsDataURL(data)
      } else {
        this.currentData.FotoUrl = ""
        this.currentData.file_foto = null
      }
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY")
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options

      this.loading = true
      PelatihanService.pelatihanList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data
          this.totalData = res.data.total
          this.loading = false
          this.isFirst = true
        })
        .catch((err) => {
          this.loading = false
          this.isFirst = true
          console.log(err)
        })
    },

    openTambah() {
      this.currentData.id = null
      this.currentData.FotoUrl = ""
      this.currentData.file_foto = null
      this.currentData.title = ""
      this.currentData.description = ""
      this.currentData.informasi = ""
      this.currentData.prasyarat_khusus = ""
      this.currentData.skp_narasumber = ""
      this.currentData.skp_moderator = ""
      this.currentData.skp_panitia = ""
      this.currentData.skp_peserta = ""
      this.currentData.nomor_skp = ""
      this.currentData.tanggal = ""
      this.currentData.jam_awal = ""
      this.currentData.jam_akhir = ""
      this.dialog = true
      this.isEditable = 1
    },

    editItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.dialog = true
      console.log(this.editedItem)
      this.currentData = this.editedItem
      this.currentData.tanggal = moment(this.editedItem.tanggal).format(
        "YYYY-MM-DD"
      )
      this.currentData.FotoUrl = this.editedItem.photo

      this.isEditable = 1
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.currentData.id == null) {
        this.currentData.kegiatan_id = this.id
        PelatihanService.create(this.currentData)
          .then((res) => {
            console.log(res)
            this.dialog = false

            CommonService.showSuccessToast("Tambah Modul berhasil")

            this.getDataFromApi()
          })
          .catch((err) => {
            console.log(err)

            CommonService.showSuccessToast("Tambah Modul gagal. Coba lagi")
          })
      } else {
        this.currentData.kegiatan_id = this.id

        PelatihanService.update(this.currentData)
          .then((res) => {
            this.dialog = false

            console.log(res)

            CommonService.showSuccessToast("Update Modul berhasil")

            this.getDataFromApi()
          })
          .catch((err) => {
            console.log(err)

            CommonService.showSuccessToast("Update Modul gagal. Coba lagi")
          })
      }
    },

    deleteItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.closeDelete()

      PelatihanService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res)
          CommonService.showSuccessToast("Hapus kegiatan berhasil")

          this.getDataFromApi()
        })
        .catch((err) => {
          console.log(err)
          CommonService.showSuccessToast("Hapus kegiatan gagal. Coba lagi")
        })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
