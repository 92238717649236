<template>
  <div>
    <v-card>
      <v-card-title>
        Galeri Foto
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="mb-2" @click="doAdd"> Tambah </v-btn>

        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Galeri Foto</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-file-input
                      @change="loadImage"
                      accept="image/*"
                      v-model="currentData.foto_url"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-responsive :aspect-ratio="16 / 9">
                      <v-img
                        :key="currentData.fotoKey"
                        :src="currentData.FotoUrl"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:[`item.foto_url`]="{ item }">
          <v-img :src="item.foto_url" contain height="100" width="200"></v-img>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import GaleriFotoService from "../../services/web_content/GaleriFotoService";
import CommonService from "../../services/CommonService";

export default {
  props: {
    id: Number,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Galeri Foto",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Foto", value: "foto_url", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      foto_url: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      GaleriFotoService.galeriFotoList(page, itemsPerPage, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.foto_url = null;
      this.currentData.FotoUrl = null;

      console.log("do add");
    },

    doSave: async function () {
      if (this.currentData.id != null) {
        this.currentData.galeri_id = this.id;
        GaleriFotoService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update Foto berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update foto gagal. Coba lagi");
          });
      } else {
        this.currentData.galeri_id = this.id;
        GaleriFotoService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah foto berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah foto gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.FotoUrl = this.editedItem.foto_url;
      this.currentData.id = this.editedItem.id;
      this.currentData.fotoKey++;

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      GaleriFotoService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus foto berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus foto gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function (file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },
  },
};
</script>
