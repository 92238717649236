import http from "../../http-common";
/* eslint-disable */
class InstagramService {
  getInstagram() {
    return http.get("/instagram", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("link_instagram", data.link_instagram);

    if (data.poster_url != null) bodyFormData.append("photo", data.poster_url);

    return http.post("/instagram", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("link_instagram", data.link_instagram);
    if (data.poster_url != null) bodyFormData.append("photo", data.poster_url);

    return http.put(`/instagram/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new InstagramService();
