<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title>
          Pelatihan
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img
                    :key="fotoKey"
                    :src="currentData.FotoUrl"
                    contain
                  ></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                @change="loadImage"
                accept="image/*"
                v-model="currentData.file_foto"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Judul"
                    dense
                    value=""
                    rows="3"
                    v-model="currentData.kegiatan_title"
                    :rules="currentData.kegiatanTitleRules"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Deskripsi"
                    dense
                    value=""
                    v-model="currentData.kegiatan_desc"
                    :rules="currentData.kegiatanDescRules"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Informasi"
                    dense
                    value=""
                    v-model="currentData.informasi"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h5>
                Konten
              </h5>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-textarea
                outlined
                name="input-7-4"
                label="Prasyarat Khusus"
                dense
                value=""
                v-model="currentData.prasyarat_khusus"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Harga"
                    outlined
                    dense
                    v-model="currentData.harga"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="No SKP"
                    outlined
                    dense
                    v-model="currentData.nomor_skp"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Tanggal Awal"
                    v-model="currentData.tanggal_awal"
                    type="date"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Tanggal Akhir"
                    v-model="currentData.tanggal_akhir"
                    type="date"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                label="SKP Narasumber"
                outlined
                dense
                v-model="currentData.skp_narasumber"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                label="SKP Moderator"
                outlined
                dense
                v-model="currentData.skp_moderator"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                label="SKP Panitia"
                outlined
                dense
                v-model="currentData.skp_panitia"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                label="SKP Peserta"
                outlined
                dense
                v-model="currentData.skp_peserta"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Penyelenggara"
                outlined
                dense
                v-model="currentData.penyelenggara"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Kategori"
                clearable
                dense
                outlined
                v-model="currentData.selectedKategori"
                :items="items_kategori"
                item-text="value"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px;">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import KegiatanService from "../services/KegiatanService";
import MasterService from "../services/MasterService";

export default {
  name: "KegiatanAdd",
  components: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Pelatihan",
        disabled: false,
        exact: true,
        to: { name: "Pelatihan" },
      },
      { text: "Tambah Pelatihan", disabled: true },
    ],
    valid: true,
    currentData: {
      FotoUrl: "",
      file_foto: null,
      kegiatan_id: "",
      kegiatan_title: "",
      kegiatan_desc: "",
      informasi: "",
      prasyarat_khusus: "",
      harga: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      jenis_kegiatan: "",
      skp_narasumber: "",
      skp_moderator: "",
      skp_panitia: "",
      skp_peserta: "",
      nomor_skp: "",
      penyelenggara: "",
      kategori: "",
      selectedKategori: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
    items_kategori: [],
    fotoKey: 0,
  }),
  mounted: async function() {
    this.getListKategoriKegiatan();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  methods: {
    loadImage: function(data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function() {
      this.$router.go(-1);
    },

    doSubmit: async function() {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      KegiatanService.create(this.currentData, this.currentData.file_foto)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Pelatihan berhasil");

          this.$router
            .push("/kegiatan-edit/" + res.data.kegiatan.kegiatan_id)
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Tambah Pelatihan gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped></style>
