<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-tabs v-model="tab">
          <v-tab>Instruktur</v-tab>
          <v-tab>Role</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <v-card-title>
                Instruktur PIB
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Cari nama"
                  single-line
                  hide-details
                  v-on:keyup.enter="getDataFromApi"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="openTambah">
                  Tambah
                </v-btn>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Apa anda akan menghapus data ini?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Tidak</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >Ya</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialog" persistent max-width="800px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Tambah Pembicara</span>
                    </v-card-title>
                    <v-card-text>
                      <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="pl-4 pr-4"
                      >
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-progress-linear
                                indeterminate
                                v-if="showProgressUpload"
                                color="amber"
                                height="25"
                              >
                              </v-progress-linear>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                label="Nama PIB"
                                outlined
                                dense
                                v-model="currentData.pib"
                                required
                                :rules="currentData.pibRules"
                                @click="openPibModal"
                              ></v-text-field>

                              <v-text-field
                                label="Nama"
                                outlined
                                dense
                                v-model="currentData.nama"
                                required
                                :rules="currentData.namaRules"
                              ></v-text-field>

                              <v-text-field
                                label="Email"
                                outlined
                                dense
                                v-model="currentData.email"
                                required
                                :rules="currentData.emailRules"
                              ></v-text-field>

                              <v-text-field
                                label="No Whatsapp"
                                outlined
                                dense
                                v-model="currentData.whatsapp"
                                required
                                :rules="currentData.whatsappRules"
                              ></v-text-field>

                              <v-text-field
                                label="No NPA"
                                outlined
                                dense
                                v-model="currentData.no_npa"
                                required
                              ></v-text-field>

                              <v-text-field
                                label="Institusi"
                                outlined
                                dense
                                v-model="currentData.institusi"
                                required
                              ></v-text-field>

                              <!-- <v-combobox
                                label="Role"
                                clearable
                                dense
                                outlined
                                v-model="currentData.selectedRole"
                                :items="items_role"
                                item-text="nama"
                                :rules="currentData.roleRules"
                              ></v-combobox> -->
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog = false">
                        Close
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="doSave">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="list_data"
                :options.sync="options"
                :server-items-length="totalData"
                :loading="loading"
                class="elevation-1"
                v-model="selected"
                :single-select="true"
                show-select
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="d-flex flex-row">
                    <v-icon medium class="mr-3" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon medium @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </div>
                </template>
              </v-data-table>
            </v-card>

            <v-card class="mt-4">
              <PibPembicaraEventComponent
                v-if="selected != undefined && selected.length > 0"
                :id="selected[0].id"
                ref="mychild"
                :key="childKey"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <PibInstrukturRoleComponent />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <PibSelectComponent
      @pilihPib="pilihPib"
      ref="modalPib"
      v-model="dialogPib"
    />

    <AnggotaPerdatinSelectComponent
      @pilihAnggota="pilihAnggota"
      ref="modalAnggota"
      v-model="dialogAnggota"
    />
  </v-main>
</template>

<script>
import PibPembicaraService from "../../services/PibPembicaraService";
import CommonService from "../../services/CommonService";
import PibSelectComponent from "../../components/PibSelect.vue";
import AnggotaPerdatinSelectComponent from "../../components/AnggotaPerdatinSelect.vue";
import PibInstrukturRoleComponent from "../../views/pib/PibInstrukturRole.vue";
import PibPembicaraEventComponent from "../../views/pib/PibPembicaraEvent.vue";
import InvoicePibService from "../../services/InvoicePibService";
import PibRoleService from "../../services/PibRoleService";

export default {
  components: {
    PibSelectComponent,
    AnggotaPerdatinSelectComponent,
    PibInstrukturRoleComponent,
    PibPembicaraEventComponent,
  },
  data: () => ({
    tab: "",
    dialogAnggota: false,
    showProgressUpload: false,
    childKey: 0,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Instruktur",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No NPA", value: "no_npa", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "No Whatsapp", value: "whatsapp", sortable: false },
      { text: "PIB", value: "pib_nama", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      page: 1,
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    dialogPib: false,

    currentData: {
      id: null,
      pib: "",
      pib_id: "",
      selectedKategori: "",
      selectedItem: "",
      selectedRole: "",
      pembicara: "",
      user_id: "",
      pibRules: [(v) => !!v || "PIB harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
      itemRules: [(v) => !!v || "Item harus diisi"],
      pembicaraRules: [(v) => !!v || "Pembicara harus diisi"],
      roleRules: [(v) => !!v || "Role harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      whatsappRules: [(v) => !!v || "No Whatsapp harus diisi"],
    },

    items_jabatan: [],
    items_divisi: [],

    items_kategori: [
      {
        id: 1,
        name: "Simposium",
      },
      {
        id: 2,
        name: "Workshop",
      },
    ],
    items_item: [],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getPibRole();
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PibPembicaraService.pembicaraList(page, itemsPerPage, this.search, 1)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      // this.currentData = this.editedItem;
      this.currentData.id = this.editedItem.id;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.email = this.editedItem.email;
      this.currentData.whatsapp = this.editedItem.whatsapp;
      this.currentData.no_npa = this.editedItem.no_npa;
      this.currentData.institusi = this.editedItem.institusi;
      this.currentData.pib_id = this.editedItem.pib_id;
      this.currentData.pib = this.editedItem.pib_nama;

      this.isEditable = 1;
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PibPembicaraService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus Pembicara berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus Pembicara gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openTambah() {
      this.getPibRole();

      this.currentData.id = null;
      this.currentData.pib = "";
      this.currentData.selectedKategori = null;
      this.currentData.selectedItem = null;
      this.currentData.selectedRole = null;
      this.currentData.pib_id = "";
      this.currentData.user_id = "";
      this.currentData.nama = "";
      this.currentData.email = "";
      this.currentData.no_npa = "";
      this.currentData.institusi = "";
      this.currentData.whatsapp = "";
      this.dialog = true;
    },

    openPibModal: async function () {
      this.$refs.modalPib.getDataFromApi();
      this.dialogPib = true;
    },

    openPembicaraModal: async function () {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihPib(item) {
      this.dialogPib = false;
      console.log(item);
      this.currentData.pib = item.nama;
      this.currentData.pib_id = item.id;
    },

    getDataByKategori() {
      if (this.currentData.selectedKategori)
        this.getKategoriItem(this.currentData.selectedKategori.id);
      else this.getKategoriItem("");

      this.currentData.selectedItem = null;
    },

    getKategoriItem(kategori) {
      InvoicePibService.getKategoriItemByPib(kategori, this.currentData.pib_id)
        .then((res) => {
          this.items_item = res.data.list_data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    getPibRole() {
      PibRoleService.roleListAll()
        .then((res) => {
          this.items_role = res.data.list_data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    pilihAnggota(item) {
      console.log(item);

      this.dialogAnggota = false;
      console.log(item);
      this.currentData.pembicara = item.nama;
      this.currentData.user_id = item.id;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.showProgressUpload = true;
      if (this.currentData.id == null) {
        PibPembicaraService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            this.showProgressUpload = false;

            CommonService.showSuccessToast("Tambah Pembicara berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            this.showProgressUpload = false;
            console.log(err);

            CommonService.showErrorToast("Tambah Pembicara gagal. Coba lagi");
          });
      } else {
        PibPembicaraService.update(this.currentData)
          .then((res) => {
            this.showProgressUpload = false;
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update Pembicara berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            this.showProgressUpload = false;
            console.log(err);

            CommonService.showErrorToast("Update Pembicara gagal. Coba lagi");
          });
      }
    },
  },
};
</script>
