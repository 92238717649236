<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card>
      <v-card-title>
        Sertifikat <br />

        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
        <template v-slot:[`item.nama`]="{ item }">
          <v-row>
            {{ item.nama }}
          </v-row>
          <v-row v-if="item.category == 2"
            >Tanggal : {{ getCurrentDate(item.tanggal_awal) }}</v-row
          >
          <v-row v-if="item.category == 3"
            >Qty : {{ item.qty }} Pax.
            <div v-if="item.tanggal_hospitality">
              Tanggal Wisata : {{ getCurrentDate(item.tanggal_hospitality) }}
            </div></v-row
          >
          <v-row v-if="item.category == 4"
            >Check In : {{ getCurrentDate(item.tanggal_check_in) }}. Check Out :
            {{ getCurrentDate(item.tanggal_check_out) }}</v-row
          >
        </template>
        <template v-slot:[`item.harga`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.harga * item.qty) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="item.sertifikat_template"
            medium
            class=""
            @click="getDownloadSertifikat(item)"
          >
            mdi-download
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import InvoiceItemPibService from "../../services/InvoiceItemPibService";
import InvoicePibService from "../../services/InvoicePibService";
import * as moment from "moment";
import GaransiSponsorInvoiceItemPibService from "../../services/GaransiSponsorInvoiceItemPibService";
import CommonService from "../../services/CommonService";
import SertifikatService from "../../services/SertifikatService";
export default {
  props: {
    id: Number,
    invoice_id: String,
  },
  components: {},
  data: () => ({
    isProgressBarShow: false,
    childKey: 0,
    isEditable: 0,
    valid: true,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Kategori", value: "category_nama", sortable: false },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Harga", value: "harga", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      pelatihan_id: "",
      id: null,
      title: "",
      urutan: "",
      selectedItem: "",
      selectedKategori: "",
      jumlah_pax: "",
      tanggal_check_in: "",
      tanggal_check_out: "",
      tanggal_hospitality: "",
      titleRules: [(v) => !!v || "Judul harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      tanggalCheckInRules: [(v) => !!v || "Tanggal Check In harus diisi"],
      tanggalCheckOutRules: [(v) => !!v || "Tanggal Check Out harus diisi"],
      tanggalHospitalityRules: [(v) => !!v || "Tanggal Wisata harus diisi"],
      jumlahPaxRules: [(v) => !!v || "Jumlah Pax harus diisi"],
    },

    items_kategori: [
      {
        id: 1,
        name: "Simposium",
      },
      {
        id: 2,
        name: "Workshop",
      },
      {
        id: 3,
        name: "Paket Wisata",
      },
      {
        id: 4,
        name: "Hotel",
      },
      {
        id: 5,
        name: "Hybrid Simposium",
      },
    ],
    items_item: [],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      InvoiceItemPibService.invoiceItemByUserIdList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    openTambah() {
      this.currentData.id = null;
      this.dialog = true;
      this.currentData.selectedKategori = null;
      this.currentData.selectedItem = null;
      this.currentData.tanggal_check_in = null;
      this.currentData.tanggal_check_out = null;
      this.currentData.jumlah_pax = null;
      this.isEditable = 1;
    },

    getDataByKategori() {
      if (this.currentData.selectedKategori)
        this.getKategoriItem(this.currentData.selectedKategori.id);
      else this.getKategoriItem("");

      this.currentData.selectedItem = null;
    },

    getKategoriItem(kategori) {
      InvoicePibService.getKategoriItem(kategori)
        .then((res) => {
          this.items_item = res.data.list_data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.invoice_id = this.id;
        GaransiSponsorInvoiceItemPibService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Event Item berhasil");

            this.getDataFromApi();
            this.$emit("reloadInvoice");
          })
          .catch((err) => {
            console.log(err);

            if (err.response?.data?.code == 1) {
              CommonService.showErrorToast(err.response?.data?.message);
            } else {
              CommonService.showErrorToast(
                "Tambah Event Item gagal. Coba lagi"
              );
            }
          });
      } else {
        this.currentData.pib_id = this.id;

        GaransiSponsorInvoiceItemPibService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update Event Item berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Update Event Item gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    deleteItemConfirm() {
      this.closeDelete();

      GaransiSponsorInvoiceItemPibService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus item berhasil");

          this.getDataFromApi();
          this.$emit("reloadInvoice");
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Hapus item gagal. Coba lagi");
        });
    },

    getDownloadSertifikat(item) {
      this.isProgressBarShow = true;
      SertifikatService.downloadSertifikatPibPeserta(item.id)
        .then((res) => {
          this.isProgressBarShow = false;
          console.log(res.data.file);
          window.open(res.data.file, "_blank");
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          console.log(err);
          CommonService.showErrorToast("Download sertifikat gagal. Coba lagi");
        });
    },
  },
};
</script>
