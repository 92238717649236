import http from "../http-common"
/* eslint-disable */
class SKPService {
  skpKegiatanList(
    page,
    itemPerPage,
    keyword,
    tipePenyelenggara,
    cabangId,
    keseminatanId,
    penyelenggaraId
  ) {
    return http.get(
      "/skp-kegiatan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&tipe_penyelenggara=" +
        tipePenyelenggara +
        "&cabang_id=" +
        cabangId +
        "&keseminatan_id=" +
        keseminatanId +
        "&penyelenggara_id=" +
        penyelenggaraId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  skpKegiatanFasilitatorList(page, itemPerPage, id) {
    return http.get(
      "/skp-kegiatan/fasilitator-all/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&skp_kegiatan_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  getSkpKegiatan(id) {
    return http.get(`/skp-kegiatan/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  create(
    data,
    file_dokumen_kak,
    file_dokumen_materi,
    list_data_fasilitator,
    list_array_materi
  ) {
    var bodyFormData = new FormData()
    if (data.nama_kegiatan)
      bodyFormData.append("nama_kegiatan", data.nama_kegiatan)
    if (data.selectedJenisKegiatan && data.selectedJenisKegiatan != undefined) {
      bodyFormData.append("jenis_kegiatan", data.selectedJenisKegiatan.code)
    }
    if (
      data.selectedKategoriKegiatan &&
      data.selectedKategoriKegiatan != undefined
    ) {
      bodyFormData.append(
        "kategori_kegiatan",
        data.selectedKategoriKegiatan.code
      )
    }
    if (
      data.selectedTipePenyelenggara &&
      data.selectedTipePenyelenggara != undefined
    ) {
      bodyFormData.append(
        "tipe_penyelenggara",
        data.selectedTipePenyelenggara.code
      )
    }
    if (data.selectedPenyelenggara && data.selectedPenyelenggara != undefined) {
      bodyFormData.append("penyelenggara_id", data.selectedPenyelenggara.id)
    }
    if (data.selectedKeseminatan && data.selectedKeseminatan != undefined) {
      bodyFormData.append("keseminatan_id", data.selectedKeseminatan.id)
    }

    if (data.selectedCakupan && data.selectedCakupan != undefined) {
      bodyFormData.append("cakupan", data.selectedCakupan.code)
    }
    if (data.sasaran_profesi)
      bodyFormData.append("sasaran_profesi", data.sasaran_profesi)
    if (data.biaya_pelatihan)
      bodyFormData.append("biaya_pelatihan", data.biaya_pelatihan)
    if (data.moderator)
      bodyFormData.append("moderator", data.moderator)
    if (data.jumlah_jep) bodyFormData.append("jumlah_jep", data.jumlah_jep)
    if (data.nama_pic) bodyFormData.append("nama_pic", data.nama_pic)
    if (data.whatsapp_pic)
      bodyFormData.append("whatsapp_pic", data.whatsapp_pic)
    if (data.tanggal_kegiatan_terdekat)
      bodyFormData.append(
        "tanggal_kegiatan_terdekat",
        data.tanggal_kegiatan_terdekat
      )
    if (data.skp) bodyFormData.append("skp", data.skp)
    if (data.selectedCabang && data.selectedCabang != undefined) {
      bodyFormData.append("cabang_id", data.selectedCabang.id)
    }
    if (data.selectedFasilitator && data.selectedFasilitator != undefined) {
      bodyFormData.append("fasilitator_id", data.selectedFasilitator.user_id)
    }
    if (
      data.selectedPengendaliPelatihan &&
      data.selectedPengendaliPelatihan != undefined
    ) {
      bodyFormData.append(
        "pengendali_pelatihan_id",
        data.selectedPengendaliPelatihan.id
      )
    }
    if (data.alamat) bodyFormData.append("alamat", data.alamat)
    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id)
    }
    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id)
    }
    if (data.nama_penanggung_jawab)
      bodyFormData.append("nama_penanggung_jawab", data.nama_penanggung_jawab)
    if (data.whatsapp_penanggung_jawab)
      bodyFormData.append(
        "whatsapp_penanggung_jawab",
        data.whatsapp_penanggung_jawab
      )
    // if (data.judul_materi)
    //   bodyFormData.append("judul_materi", data.judul_materi)

    if (data.catatan_reject)
      bodyFormData.append("catatan_reject", data.catatan_reject)

    if (file_dokumen_kak != null)
      bodyFormData.append("dokumen_kak", file_dokumen_kak)

    // if (file_dokumen_materi != null)
    //   bodyFormData.append("dokumen_materi", file_dokumen_materi)

    if (list_data_fasilitator && list_data_fasilitator.length > 0) {
      bodyFormData.append(
        "list_fasilitator",
        JSON.stringify(list_data_fasilitator)
      )

      for (let i = 0; i < list_array_materi.length; i++) {
        bodyFormData.append(
          "list_materi[]",
          list_array_materi[i],
          list_array_materi[i].name
        )
      }
    }

    return http.post("/skp-kegiatan", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  update(
    id,
    data,
    file_dokumen_kak,
    file_dokumen_materi,
    list_data_fasilitator
  ) {
    var bodyFormData = new FormData()

    if (data.nama_kegiatan)
      bodyFormData.append("nama_kegiatan", data.nama_kegiatan)
    if (data.selectedJenisKegiatan && data.selectedJenisKegiatan != undefined) {
      bodyFormData.append("jenis_kegiatan", data.selectedJenisKegiatan.code)
    }
    if (
      data.selectedKategoriKegiatan &&
      data.selectedKategoriKegiatan != undefined
    ) {
      bodyFormData.append(
        "kategori_kegiatan",
        data.selectedKategoriKegiatan.code
      )
    }
    if (
      data.selectedTipePenyelenggara &&
      data.selectedTipePenyelenggara != undefined
    ) {
      bodyFormData.append(
        "tipe_penyelenggara",
        data.selectedTipePenyelenggara.code
      )
    }
    if (data.selectedPenyelenggara && data.selectedPenyelenggara != undefined) {
      bodyFormData.append("penyelenggara_id", data.selectedPenyelenggara.id)
    }
    if (data.selectedKeseminatan && data.selectedKeseminatan != undefined) {
      bodyFormData.append("keseminatan_id", data.selectedKeseminatan.id)
    }

    if (data.selectedCakupan && data.selectedCakupan != undefined) {
      bodyFormData.append("cakupan", data.selectedCakupan.code)
    }
    if (data.sasaran_profesi)
      bodyFormData.append("sasaran_profesi", data.sasaran_profesi)
    if (data.biaya_pelatihan)
      bodyFormData.append("biaya_pelatihan", data.biaya_pelatihan)
    if (data.moderator)
      bodyFormData.append("moderator", data.moderator)
    if (data.jumlah_jep) bodyFormData.append("jumlah_jep", data.jumlah_jep)
    if (data.nama_pic) bodyFormData.append("nama_pic", data.nama_pic)
    if (data.whatsapp_pic)
      bodyFormData.append("whatsapp_pic", data.whatsapp_pic)
    if (data.tanggal_kegiatan_terdekat)
      bodyFormData.append(
        "tanggal_kegiatan_terdekat",
        data.tanggal_kegiatan_terdekat
      )
    if (data.skp) bodyFormData.append("skp", data.skp)
    if (data.selectedCabang && data.selectedCabang != undefined) {
      bodyFormData.append("cabang_id", data.selectedCabang.id)
    }
    if (data.selectedFasilitator && data.selectedFasilitator != undefined) {
      bodyFormData.append("fasilitator_id", data.selectedFasilitator.user_id)
    }
    if (
      data.selectedPengendaliPelatihan &&
      data.selectedPengendaliPelatihan != undefined
    ) {
      bodyFormData.append(
        "pengendali_pelatihan_id",
        data.selectedPengendaliPelatihan.id
      )
    }
    if (data.alamat) bodyFormData.append("alamat", data.alamat)
    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id)
    }
    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id)
    }
    if (data.nama_penanggung_jawab)
      bodyFormData.append("nama_penanggung_jawab", data.nama_penanggung_jawab)
    if (data.whatsapp_penanggung_jawab)
      bodyFormData.append(
        "whatsapp_penanggung_jawab",
        data.whatsapp_penanggung_jawab
      )
    // if (data.judul_materi)
    //   bodyFormData.append("judul_materi", data.judul_materi)

    if (data.catatan_reject)
      bodyFormData.append("catatan_reject", data.catatan_reject)

    if (file_dokumen_kak != null)
      bodyFormData.append("dokumen_kak", file_dokumen_kak)

    // if (file_dokumen_materi != null)
    //   bodyFormData.append("dokumen_materi", file_dokumen_materi)

    return http.put(`/skp-kegiatan/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  tambahFasilitator(id, userId, judulMateri, dokumen_materi) {
    var bodyFormData = new FormData()

    if (id) bodyFormData.append("skp_kegiatan_id", id)
    if (userId) bodyFormData.append("user_id", userId)
    if (judulMateri) bodyFormData.append("judul_materi", judulMateri)

    if (dokumen_materi != null)
      bodyFormData.append("dokumen_materi", dokumen_materi)

    return http.post(`/skp-kegiatan/fasilitator-new`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  delete(id) {
    return http.delete(`/skp-kegiatan/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  deleteFasilitator(id) {
    return http.delete(`/skp-kegiatan/fasilitator-new/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  approvalSkpKegiatan(id, status) {
    var bodyFormData = new FormData()
    bodyFormData.append("status", status) // 2=Disetujui, 3=Reject

    return http.put(`/skp-kegiatan/approval/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  rejectSkpKegiatan(id, catatanReject) {
    var bodyFormData = new FormData()
    bodyFormData.append("catatan_reject", catatanReject) // 2=Disetujui, 3=Reject

    return http.put(`/skp-kegiatan/reject/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  propinsiList() {
    return http.get("/propinsi/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  kabupatenList(propinsiId) {
    return http.get("/kabupaten/list?propinsi_id=" + propinsiId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }
}

export default new SKPService()
