import http from "../http-common";
/* eslint-disable */
class FasyankesService {
  fasyankesList(page, itemPerPage, keyword, propinsi_id, kabupaten_id, type) {
    var url =
      "/fasyankes/list?page=" +
      page +
      "&item_per_page=" +
      itemPerPage +
      "&keyword=" +
      keyword;

    if (propinsi_id) {
      url += "&propinsi_id=" + propinsi_id;
    }

    if (kabupaten_id) {
      url += "&kabupaten_id=" + kabupaten_id;
    }

    if (type) {
      url += "&type=" + type;
    }

    return http.get(url, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  calonFasyankesList(page, itemPerPage, keyword) {
    return http.get(
      "/calon-fasyankes/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getFasyankes(id) {
    return http.get(`/fasyankes/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  getFasyankesByUser(id) {
    return http.get(`/fasyankes/user/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, photo) {
    var bodyFormData = new FormData();
    if (data.no_anggota) bodyFormData.append("no_anggota", data.no_anggota);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.alamat) bodyFormData.append("alamat", data.alamat);
    if (data.telp) bodyFormData.append("telp", data.telp);

    if (data.jenis_user && data.jenis_user != undefined) {
      bodyFormData.append("type", data.jenis_user.code);
    }

    if (data.selectedJenisRS && data.selectedJenisRS != undefined) {
      bodyFormData.append("jenis_rs", data.selectedJenisRS.code);
    }

    if (data.selectedKelasRS && data.selectedKelasRS != undefined) {
      bodyFormData.append("kelas_rs", data.selectedKelasRS.code);
    }

    if (
      data.selectedStatusRSSyariah &&
      data.selectedStatusRSSyariah != undefined
    ) {
      bodyFormData.append(
        "status_rs_syariah",
        data.selectedStatusRSSyariah.code
      );
    }

    if (data.selectedStatusBlu && data.selectedStatusBlu != undefined) {
      bodyFormData.append("status_blu", data.selectedStatusBlu.code);
    }

    if (data.selectedKepemilikanRS && data.selectedKepemilikanRS != undefined) {
      bodyFormData.append("kepemilikan", data.selectedKepemilikanRS.code);
    }

    if (data.direktur) bodyFormData.append("direktur", data.direktur);
    if (data.luas_tanah) bodyFormData.append("luas_tanah", data.luas_tanah);
    if (data.luas_bangunan)
      bodyFormData.append("luas_bangunan", data.luas_bangunan);
    if (data.link_google_map)
      bodyFormData.append("link_google_map", data.link_google_map);
    if (data.website) bodyFormData.append("website", data.website);

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/fasyankes", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    if (data.no_anggota) bodyFormData.append("no_anggota", data.no_anggota);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.alamat) bodyFormData.append("alamat", data.alamat);
    if (data.telp) bodyFormData.append("telp", data.telp);

    if (data.jenis_user && data.jenis_user != undefined) {
      bodyFormData.append("type", data.jenis_user.code);
    }

    if (data.selectedJenisRS && data.selectedJenisRS != undefined) {
      bodyFormData.append("jenis_rs", data.selectedJenisRS.code);
    }

    if (data.selectedKelasRS && data.selectedKelasRS != undefined) {
      bodyFormData.append("kelas_rs", data.selectedKelasRS.code);
    }

    if (
      data.selectedStatusRSSyariah &&
      data.selectedStatusRSSyariah != undefined
    ) {
      bodyFormData.append(
        "status_rs_syariah",
        data.selectedStatusRSSyariah.code
      );
    }

    if (data.selectedStatusBlu && data.selectedStatusBlu != undefined) {
      bodyFormData.append("status_blu", data.selectedStatusBlu.code);
    }

    if (data.selectedKepemilikanRS && data.selectedKepemilikanRS != undefined) {
      bodyFormData.append("kepemilikan", data.selectedKepemilikanRS.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    if (data.direktur) bodyFormData.append("direktur", data.direktur);
    if (data.luas_tanah) bodyFormData.append("luas_tanah", data.luas_tanah);
    if (data.luas_bangunan)
      bodyFormData.append("luas_bangunan", data.luas_bangunan);
    if (data.link_google_map)
      bodyFormData.append("link_google_map", data.link_google_map);
    if (data.website) bodyFormData.append("website", data.website);

    if (photo != null) bodyFormData.append("photo", photo);

    return http.put(`/fasyankes/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/fasyankes/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  verifikasiAnggota(data) {
    return http.put(`/fasyankes/verifikasi/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  fasyankesPengurusList(page, itemPerPage, keyword, id) {
    return http.get(
      "/fasyankes/pengurus/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&fasyankes_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  fasyankesNonPengurusList(page, itemPerPage, keyword, id) {
    return http.get(
      "/fasyankes/non-pengurus/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&fasyankes_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  createPengurus(data) {
    return http.post("/fasyankes/pengurus", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  deletePengurus(id) {
    return http.delete(`/fasyankes/pengurus/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new FasyankesService();
