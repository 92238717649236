<template>
  <div>
    <v-card
      ><v-tabs v-model="tab">
        <v-tab>Acara</v-tab>
        <v-tab>Materi Pdf</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <SimposiumListDetailComponent :id="id" />
        </v-tab-item>
        <v-tab-item> <SimposiumMateriComponent :id="id" /></v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import SimposiumListDetailComponent from "./SimposiumListDetail.vue";
import SimposiumMateriComponent from "./SimposiumMateri.vue";

export default {
  props: {
    id: Number,
    workshop_id: String,
    harga: Number,
  },
  components: {
    SimposiumListDetailComponent,
    SimposiumMateriComponent,
  },
  data: () => ({
    tab: null,
    childKey: 0,
    isEditable: 0,
    valid: true,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "judul", sortable: false },
      { text: "Deskripsi", value: "deskripsi", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      pelatihan_id: "",
      id: null,
      judul: "",
      deskripsi: "",
      judulRules: [(v) => !!v || "Judul harus diisi"],
      deskripsiRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {},
};
</script>
