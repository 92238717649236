<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Keseminatan </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img :key="fotoKey" :src="userData.FotoUrl" contain></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                @change="loadImage"
                accept="image/*"
                v-model="userData.file_foto"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Nama"
                    outlined
                    dense
                    v-model="userData.nama"
                    :rules="userData.namaRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h5>Tentang Keseminatan</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="10" md="10" class="">
              <vue-editor
                v-model="userData.konten"
                useCustomImageHandler
                @image-added="handleImageAdded"
              />
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <!-- Modal -->
    </v-container>
  </v-main>
</template>

<script>
import KeseminatanService from "../../services/web_content/KeseminatanService";
import CommonService from "../../services/CommonService";
import { VueEditor } from "vue2-editor";
import EventService from "../../services/EventService";
import { uuid } from "vue-uuid";

export default {
  name: "KeseminatanAdd",
  components: { VueEditor },
  data: () => ({
    komunitas_key: 0,
    breadcrumbs: [
      {
        text: "Data Keseminatan",
        disabled: false,
        exact: true,
        to: { name: "Keseminatan" },
      },
      { text: "Tambah Keseminatan", disabled: true },
    ],
    valid: true,
    userData: {
      uuid: uuid.v4(),
      konten: "",
      FotoUrl: "",
      file_foto: null,
      nama: "",
      items_hide: [
        {
          text: 1,
          value: "Published",
        },
        {
          text: 0,
          value: "Unpublished",
        },
      ],
      selectedHide: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      phoneRules: [(v) => !!v || "No Telepon harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      noAnggotaRules: [(v) => !!v || "Email harus diisi"],
    },
    items_propinsi: [],
    items_kabupaten: [],
    result: {},
    fotoKey: 0,
    searchNakes: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Kategori", value: "category_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },
  }),
  mounted: async function () {},
  computed: {
    imageSrc() {
      return this.userData.FotoUrl;
    },
  },
  watch: {},
  methods: {
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      KeseminatanService.create(this.userData, this.userData.file_foto)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah keseminatan berhasil");

          this.$router
            .push("/keseminatan-edit/" + res.data.keseminatan.id)
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Tambah keseminatan gagal. Coba lagi");
        });
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      //1 = kategori event
      EventService.uploadFotoKonten(this.userData.uuid, file, 3)
        .then((res) => {
          console.log(res);

          Editor.insertEmbed(cursorLocation, "image", res.data.event.foto_url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Gagal menambah foto. Coba lagi");
        });
    },
  },
};
</script>

<style scoped>
</style>
