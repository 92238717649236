<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-progress-linear
        v-if="isProgressBarShow"
        indeterminate
        color="green"
      ></v-progress-linear>

      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Serkom
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <!-- <router-link
            to="/kegiatan-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="primary" dark class="mb-2"> Tambah </v-btn>
          </router-link> -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          v-model="selected"
          :single-select="true"
          show-select
        >
          <template v-slot:[`item.tanggal`]="{ item }">
            {{ getCurrentDate(item.tanggal) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip v-if="item.status" :color="getColor(item.status)" dark>
              {{ getStatus(item.status) }}
            </v-chip>
          </template>
          <template v-slot:[`item.harga`]="{ item }">
            {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
          </template>
          <template v-slot:[`item.download_serkom`]="{ item }">
            <v-btn
              v-if="item.status == 4"
              color="green"
              dark
              class=""
              @click="downloadSerkom(item.id)"
            >
              Download</v-btn
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon> -->
            <div class="d-flex flex-row">
              <v-icon
                v-if="
                  item.status == 1 && (item.is_approver == 1 || getRoleId == 1)
                "
                medium
                class="mr-3"
                @click="approveItem(item)"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-if="
                  item.status == 1 && (item.is_approver == 1 || getRoleId == 1)
                "
                medium
                class="mr-3"
                @click="rejectItem(item)"
              >
                mdi-close
              </v-icon>

              <v-icon v-if="item.status != 4" medium @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>

      <v-dialog v-model="dialogApprove" max-width="600px">
        <v-card>
          <v-card-title class="text-h5"
            >Apa anda akan mengapprove serkom anggota ini?</v-card-title
          >
          <v-card-actions>
            <v-container>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeApprove"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="approveItemConfirm"
                  >Ya</v-btn
                >
              </v-row>
            </v-container>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card class="mt-4">
        <SerkomInvoiceComponent
          v-if="selected != undefined && selected.length > 0"
          :id="selected[0].id"
          :userId="selected[0].user_id"
          ref="mychild"
          :key="childKey"
        />
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import SerkomService from "../services/SerkomService";
import * as moment from "moment";

import SerkomInvoiceComponent from "./SerkomInvoice.vue";
import store from "../store/index";
import CommonService from "../services/CommonService";

export default {
  components: {
    SerkomInvoiceComponent,
  },
  data: () => ({
    isProgressBarShow: false,
    dialogApprove: false,
    dialogReject: false,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Serkom",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false },
      { text: "Tanggal", value: "tanggal", sortable: false },
      { text: "Jenis", value: "serkom_jenis_value", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Download Serkom", value: "download_serkom", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getRolesId() {
      return store.getters.getUser.roles_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
    isCabangPengurus() {
      if (store.getters.getUser.cabang_pengurus_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getColor(id) {
      if (id == 1) {
        return "red";
      } else if (id == 2) {
        return "orange";
      } else if (id == 3) {
        return "green";
      } else if (id == 4) {
        return "blue";
      } else if (id == 5) {
        return "red";
      } else if (id == 6) {
        return "red";
      }
    },

    getStatus(id) {
      if (id == 1) {
        return "Menunggu Verifikasi Admin Kati";
      } else if (id == 2) {
        return "Menunggu Pembayaran Anggota";
      } else if (id == 3) {
        return "Menunggu Pembayaran Anggota";
      } else if (id == 4) {
        return "Terverifikasi";
      } else if (id == 5) {
        return "Rejected";
      } else if (id == 6) {
        return "Canceled";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      SerkomService.serkomList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
          console.log(this.list_data);
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/kegiatan-edit/" + item.kegiatan_id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      SerkomService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Hapus Serkom berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.selected = [];

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus Serkom gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItem(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      SerkomService.approve(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve Serkom berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve Serkom gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    rejectItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReject = true;
    },

    closeReject() {
      this.dialogReject = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    rejectItemConfirm() {
      SerkomService.reject(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeReject();

          console.log(res);
          this.$toast.success("Reject Serkom berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Reject Serkom gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    downloadSerkom(serkomId) {
      console.log(serkomId);

      this.isProgressBarShow = true;

      SerkomService.downloadSerkom(serkomId)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download sertifikat. Coba lagi");
          console.log(err);
        });
    },
  },
};
</script>
