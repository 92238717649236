<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card
      ><v-tabs v-model="tab">
        <v-tab>EPoster</v-tab>
        <v-tab>EPoster Peserta</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card class="pl-4 pr-4 pt-4 pb-4">
            <v-card-title> EPoster </v-card-title>
            <v-fab-transition>
              <v-btn
                class="mt-8 mr-4"
                v-if="isEditable == 0"
                @click="changeEditable"
                color="pink"
                dark
                absolute
                top
                right
                fab
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-fab-transition>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="currentData.fotoKey"
                          :src="currentData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImage"
                      accept="image/*"
                      v-model="currentData.file_foto"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto EPoster"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Judul"
                          dense
                          rows="3"
                          v-model="currentData.judul"
                          :rules="currentData.eposterJudulRules"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Deskripsi"
                          dense
                          value=""
                          v-model="currentData.deskripsi"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              label="Tanggal Awal"
                              v-model="currentData.tanggal_awal"
                              type="date"
                              dense
                              :readonly="isEditable == 0"
                              :rules="currentData.tanggalAwalRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              label="Tanggal Akhir"
                              v-model="currentData.tanggal_akhir"
                              type="date"
                              dense
                              :readonly="isEditable == 0"
                              :rules="currentData.tanggalAkhirRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Kontak Person"
                          outlined
                          dense
                          v-model="currentData.cp"
                          required
                          :readonly="isEditable == 0"
                          :rules="currentData.eposterCpRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      @click="doBatal"
                      style="margin-right: 10px"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item> <InasiaEPosterPesertaComponent :id="id" /></v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import CommonService from "../../services/CommonService";
import InasiaEPosterService from "../../services/inasia/InasiaEPosterService";

import InasiaEPosterPesertaComponent from "./InasiaEPosterPeserta.vue";

import * as moment from "moment";
import store from "../../store/index";

export default {
  props: {
    id: String,
  },
  components: { InasiaEPosterPesertaComponent },
  data: () => ({
    isProgressBarShow: false,
    tab: null,
    isEditable: 0,
    childKey: 0,
    dialogUpdate: false,
    valid: true,

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      file_foto: null,
      FotoUrl: "",
      judul: "",
      deskripsi: "",
      cp: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      fotoKey: 0,
      eposterPhotoRules: [(v) => !!v || "Foto harus diisi"],
      eposterJudulRules: [(v) => !!v || "Judul harus diisi"],
      tanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      tanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      eposterCpRules: [(v) => !!v || "Kontak Person harus diisi"],
    },
  }),
  mounted() {
    this.getEPoster();
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    getEPoster() {
      InasiaEPosterService.eposter(this.id)
        .then((res) => {
          if (res.data.eposter) {
            this.currentData.id = res.data.eposter.id;
            this.currentData.judul = res.data.eposter.judul;
            this.currentData.deskripsi = res.data.eposter.deskripsi;

            if (res.data.eposter.tanggal_awal)
              this.currentData.tanggal_awal = moment(
                res.data.eposter.tanggal_awal
              ).format("YYYY-MM-DD");

            if (res.data.eposter.tanggal_akhir)
              this.currentData.tanggal_akhir = moment(
                res.data.eposter.tanggal_akhir
              ).format("YYYY-MM-DD");

            this.currentData.cp = res.data.eposter.cp;

            if (res.data.eposter.photo)
              this.currentData.FotoUrl = res.data.eposter.photo;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.isEditable = 0;
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.inasia_id = this.id;

        InasiaEPosterService.create(this.currentData)
          .then((res) => {
            console.log(res);
            CommonService.showSuccessToast("Edit E-Poster berhasil");

            this.isEditable = 0;
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Edit E-Poster gagal. Coba lagi");
          });
      } else {
        this.currentData.inasia_id = this.id;

        InasiaEPosterService.update(this.currentData)
          .then((res) => {
            console.log(res);
            CommonService.showSuccessToast("Edit E-Poster berhasil");

            this.isEditable = 0;
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Edit E-Poster gagal. Coba lagi");
          });
      }
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
