<template>
  <v-dialog v-model="show" max-width="800px">
    <v-card>
      <v-card-title>
        Anggota
        <v-spacer></v-spacer>
      </v-card-title>

      <v-row class="mx-4 my-4">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            outlined
            dense
            hide-details
            v-on:keyup.enter="getDataFromApiSelection"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-combobox
            label="Cabang Perdatin"
            clearable
            dense
            outlined
            v-model="selectedPropinsi"
            :items="items_propinsi"
            item-text="name"
            @change="changePropinsi"
          ></v-combobox>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="ma-2" color="primary" dark @click.stop="pilih(item)">
            Pilih
            <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="show = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NakesService from "../services/NakesService";

export default {
  name: "AnggotaSelect",
  props: {
    value: Boolean,
    id: String,
    propinsiId: String,
  },
  data: () => ({
    dialogCustomer: false,
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No NPA", value: "no_npa", sortable: false },
      { text: "Cabang", value: "propinsi_nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    totalData: 0,
    list_data: [],
    loading: true,
    selectedPropinsi: "",
    items_propinsi: "",
    options: {
      page: 1,
      itemsPerPage: 5,
    },
  }),
  mounted() {
    console.log(this.propinsiId);
    this.getListPropinsi();
    this.getDataFromApiSelection();
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApiSelection();
      },
      deep: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;

          if (this.propinsiId) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == this.propinsiId) {
                this.selectedPropinsi = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi(propinsiIdParam) {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var propinsiId = "";

      if (propinsiIdParam) propinsiId = propinsiIdParam;

      if (propinsiIdParam) {
        this.items_propinsi.forEach((entry) => {
          if (entry.id == propinsiIdParam) {
            this.selectedPropinsi = entry;
          }
        });
      }

      NakesService.nakesListPengurusCabang(
        page,
        itemsPerPage,
        this.search,
        propinsiId
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    getDataFromApiSelection() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var propinsiId = "";

      if (this.selectedPropinsi) propinsiId = this.selectedPropinsi.id;

      NakesService.nakesList(page, itemsPerPage, this.search, propinsiId)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    pilih: function (item) {
      this.$emit("pilihAnggota", item);

      this.show = false;
    },

    async changePropinsi() {
      this.getDataFromApiSelection();
    },
  },
};
</script>

<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
