import http from "../../http-common";
/* eslint-disable */
class AdRtService {
  adrt() {
    return http.get("/adrt", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();

    if (data.file_pdf_val != null)
      bodyFormData.append("file", data.file_pdf_val);

    return http.post("/adrt", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.file_pdf_val != null)
      bodyFormData.append("file", data.file_pdf_val);

    return http.put(`/adrt/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new AdRtService();
