<template>
  <div>
    <v-card>
      <v-card-title>
        Sambutan <br />

        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="mb-2" @click="openTambah">
          Tambah
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Sambutan</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-row>
                        <v-col cols="12" sm="12" md="12" class="pr-4">
                          <v-sheet color="white" elevation="2">
                            <v-responsive :aspect-ratio="16 / 9">
                              <v-img
                                :key="fotoKey"
                                :src="currentData.FotoUrl"
                                contain
                              ></v-img>
                            </v-responsive>
                          </v-sheet>
                          <v-file-input
                            @change="loadImage"
                            accept="image/*"
                            v-model="currentData.file_foto"
                            prepend-icon="mdi-image"
                            show-size
                            label="Foto"
                            :rules="currentData.fotoRules"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Nama"
                            outlined
                            dense
                            v-model="currentData.nama"
                            required
                            :rules="currentData.namaRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Jabatan"
                            outlined
                            dense
                            v-model="currentData.jabatan"
                            required
                            :rules="currentData.jabatanRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Kalimat"
                            dense
                            value=""
                            v-model="currentData.kalimat"
                            :rules="currentData.kalimatRules"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.photo`]="{ item }">
          <v-btn
            v-if="item.photo_original"
            color="blue"
            dark
            dense
            @click="showFoto(item.photo)"
          >
            View
          </v-btn>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal -->
    <v-dialog v-model="dialogPreview" max-width="800px">
      <v-card>
        <v-img height="auto" width="auto" :src="fotoPreview"></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InasiaSambutanService from "../../services/inasia/InasiaSambutanService";
import * as moment from "moment";
import CommonService from "../../services/CommonService";

export default {
  props: {
    id: String,
  },
  components: {},
  data: () => ({
    dialogPreview: false,
    fotoPreview: "",
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Jabatan", value: "jabatan", sortable: false },
      { text: "Foto", value: "photo", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    currentData: {
      pib_id: "",
      id: null,
      FotoUrl: "",
      file_foto: null,
      nama: "",
      jabatan: "",
      kalimat: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      kalimatRules: [(v) => !!v || "Kalimat harus diisi"],
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },
    fotoKey: 0,

    layananList: [],
    currentLayanan: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    showFoto(foto) {
      this.fotoPreview = foto;
      this.dialogPreview = true;
    },
    truncate: function (input) {
      if (input && input.length > 10) {
        return input.substring(0, 5) + "...";
      }
      return input;
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      InasiaSambutanService.getList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.currentData.FotoUrl = "";
      this.currentData.file_foto = null;
      this.currentData.nama = "";
      this.currentData.jabatan = "";
      this.currentData.kalimat = "";
      this.dialog = true;
      this.isEditable = 1;

      this.currentData.fotoRules = [(v) => !!v || "Foto harus diisi"];
    },

    editItem(item) {
      this.currentData.fotoRules = [];

      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      // this.currentData = this.editedItem;

      this.currentData.id = this.editedItem.id;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.jabatan = this.editedItem.jabatan;
      this.currentData.kalimat = this.editedItem.kalimat;
      this.currentData.FotoUrl = this.editedItem.photo;

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.inasia_id = this.id;
        InasiaSambutanService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Sambutan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Tambah Sambutan gagal. Coba lagi");
          });
      } else {
        this.currentData.inasia_id = this.id;

        InasiaSambutanService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update Sambutan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Update Sambutan gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      InasiaSambutanService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus Sambutan berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus Sambutan gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
