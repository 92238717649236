<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app v-if="$route.meta.isHome === 1">
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar v-if="currentUser.photo" class="mx-auto">
            <v-avatar color="primary" size="56">
              <v-img :src="currentUser.photo"></v-img>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-icon v-else class="mx-auto mb-0">
            <v-icon style="font-size: 55px">mdi-account-circle</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content class="text-center">
            <v-list-item-title class="text-h6">
              {{ currentUser.nama }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item v-if="getRoleId == 1" to="/dashboard" link>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-group v-if="getRoleId == 1" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Web Content</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list class="py-0 pl-2">
            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-icon>
                  <!-- <v-icon>mdi-information</v-icon> -->
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap"
                    >Tentang Perdatin</v-list-item-title
                  >
                </v-list-item-content>
              </template>

              <v-list-item to="/webcontent/sejarah" link>
                <v-list-item-content>
                  <v-list-item-title>Sejarah</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/webcontent/pesanketuaperdatin" link>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap"
                    >Pesan Ketua Umum PP Perdatin</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/webcontent/pesan-video" link>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap"
                    >Pesan Video</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/webcontent/programprioritas" link>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap"
                    >Program Prioritas Perdatin</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/webcontent/adrt" link>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap"
                    >AD & RT</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/webcontent/visi-misi" link>
                <v-list-item-content>
                  <v-list-item-title>Visi Misi</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/webcontent/ketuaperdatin" link>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap"
                    >Ketua Perdatin dari Masa ke Masa</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/webcontent/instagram" link>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap"
                    >Konten Instagram</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>

          <v-list class="py-0 pl-2">
            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-icon>
                  <!-- <v-icon>mdi-medical-bag</v-icon> -->
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap"
                    >Seminat</v-list-item-title
                  >
                </v-list-item-content>
              </template>

              <v-list-item to="/webcontent/seminat" link>
                <v-list-item-content>
                  <v-list-item-title>Tentang Seminat</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item to="/webcontent/keseminatan" link>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap"
                    >Daftar Seminat</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>

          <v-list-item to="/webcontent/bankdatapenelitian" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Bank Data Penelitian</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/webcontent/cabangperdatin" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Cabang Perdatin</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/webcontent/mitraperdatin" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Mitra Perdatin</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/webcontent/galeri" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">Galeri</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/webcontent/pengaduan" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">Pengaduan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/webcontent/kontak" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">Kontak</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/webcontent/slider" link>
            <v-list-item-content>
              <v-list-item-title>Slider</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/webcontent/berita" link>
            <v-list-item-content>
              <v-list-item-title>Berita</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/webcontent/katalogbuku" link>
            <v-list-item-content>
              <v-list-item-title>Katalog Buku</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group v-if="getRoleId == 1" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-library</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Pengurus</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item to="/webcontent/pengurus" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Pengurus Pusat</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/webcontent/penguruscabang" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Pengurus Cabang</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/webcontent/penguruskati" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Pengurus Kolegium</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group v-if="getRoleId == 1 || getUser.pengurus == 1" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-wallet-membership</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Membership</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item to="/calon-anggota" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Calon Anggota</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/nakes" link>
            <v-list-item-content>
              <v-list-item-title>Anggota</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/akun-satu-sehat" link>
            <v-list-item-content>
              <v-list-item-title>Akun Satu Sehat SDMK</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/iuran" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">Keuangan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/borang" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Borang Online</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/mutasi" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Pengajuan Mutasi</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/anumerta" link>
            <v-list-item-content>
              <v-list-item-title>Anumerta</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="getRoleId == 1 || getUser.pengurus == 1"
          to="/rumahsakit"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-hospital-building</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Rumah Sakit</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="getRoleId == 1 || getUser.pengurus == 1"
          to="/klinik"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-home-assistant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Klinik</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-if="
            getRoleId == 1 ||
            getUser.pengurus_pusat == 1 ||
            getUser.pengurus == 1 ||
            getUser.admin_diklatlit == 1 ||
            getUser.admin_p2kb == 1
          "
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-presentation</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>DIKLATLIT PP PERDATIN</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item to="/skp-kegiatan" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Data Pengajuan SKP Kemkes
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/skp-kemkes/penyelenggara" link>
            <v-list-item-content>
              <v-list-item-title>Data penyelenggara</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="
              getRoleId == 1 ||
              getUser.pengurus_pusat == 1 ||
              getUser.pengurus == 1
            "
            to="/skp-kemkes/admin-diklatlit"
            link
          >
            <v-list-item-content>
              <v-list-item-title>Admin Diklatlit</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="
              getRoleId == 1 ||
              getUser.pengurus_pusat == 1 ||
              getUser.pengurus == 1
            "
            to="/skp-kemkes/admin-p2kb"
            link
          >
            <v-list-item-content>
              <v-list-item-title>Admin P2KB</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/data-fasilitator" link>
            <v-list-item-content>
              <v-list-item-title>Data Fasilitator</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="
              getRoleId == 1 ||
              getUser.pengurus_pusat == 1 ||
              getUser.pengurus == 1
            "
            to="/skp-kemkes/pengendali-pelatihan"
            link
          >
            <v-list-item-content>
              <v-list-item-title>Data Pengendali Pelatihan </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="getRoleId == 1" to="/skala-skp" link>
            <v-list-item-content>
              <v-list-item-title>Skala Penilaian Skp </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <router-link
          v-if="getRoleId == 1"
          to="#"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Manajemen SKP</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="getRoleId == 1"
          to="/kecukupan-skp-kemkes"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Kecukupan SKP Kemkes</v-list-item-title>
          </v-list-item>
        </router-link>

        <v-list-group
          v-if="getRoleId == 1 || getUser.admin_kati == 1"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-clipboard-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Kati</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-if="getRoleId == 1 || getUser.admin_kati == 1"
            to="/serkom"
            link
          >
            <v-list-item-title class="text-wrap"
              >Manajemen Serkom</v-list-item-title
            >
          </v-list-item>

          <v-list-item v-if="getRoleId == 1" to="/admin-serkom" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Admin Kati</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-if="getRoleId == 1 || getUser.pengurus_pusat == 1"
          to="/kegiatan"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-comment-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>P2KB Online</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="getRoleId == 1 || getUser.pengurus == 1"
          to="/event"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-calendar-multiple-check</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Event</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-if="
            getRoleId == 1 ||
            getUser.admin_pib == 1 ||
            getUser.admin_pusat == 1 ||
            getUser.admin_pib_additional == 1
          "
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-account-convert</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>PIB</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-if="getRoleId == 1 || getUser.admin_pib == 1"
            to="/pib"
            link
          >
            <v-list-item-title class="text-wrap"
              >Pertemuan Ilmiah Berkala</v-list-item-title
            >
          </v-list-item>

          <v-list-item
            v-if="
              getRoleId == 1 ||
              getUser.admin_pib == 1 ||
              getUser.admin_pib_additional == 1
            "
            to="/pib-registrasi-ulang"
            link
          >
            <v-list-item-title class="text-wrap"
              >Registrasi Ulang</v-list-item-title
            >
          </v-list-item>

          <v-list-item v-if="getRoleId == 1" to="/admin-pib" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">Admin PIB</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="getRoleId == 1" to="/admin-pusat" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Admin Pusat</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/pembicara-pib" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Instruktur PIB</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="
              getRoleId == 1 ||
              getUser.pengurus_pusat == 1 ||
              getUser.admin_pusat == 1
            "
            to="/keuangan-pib"
            link
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap">Keuangan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="
              getRoleId == 1 ||
              getUser.pengurus_pusat == 1 ||
              getUser.admin_pusat == 1 ||
              getUser.admin_pib == 1 ||
              getUser.admin_pib_additional == 1
            "
            to="/verifikasi-identitas"
            link
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Verifikasi Identitas Digital</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="getRoleId == 1 || getUser.admin_inasia == 1"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-book-open-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>INASIA</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-if="getRoleId == 1 || getUser.admin_inasia == 1"
            to="/inasia"
            link
          >
            <v-list-item-title class="text-wrap">INASIA</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="getRoleId == 1 || getUser.admin_inasia == 1"
            to="/inasia-registrasi-ulang"
            link
          >
            <v-list-item-title class="text-wrap"
              >Registrasi Ulang</v-list-item-title
            >
          </v-list-item>

          <v-list-item v-if="getRoleId == 1" to="/admin-inasia" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Admin INASIA</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="getRoleId == 1 || getUser.admin_kppia == 1"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-book-open-page-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>KPPIA</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-if="getRoleId == 1 || getUser.admin_kppia == 1"
            to="/kppia"
            link
          >
            <v-list-item-title class="text-wrap">KPPIA</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="getRoleId == 1" to="/admin-kppia" link>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Admin KPPIA</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-if="getRoleId == 1" to="/informasi-broadcast" link>
          <v-list-item-icon>
            <v-icon>mdi-newspaper</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Informasi Broadcast</v-list-item-title>
        </v-list-item>

        <!-- <v-list-item v-if="getRoleId == 1" to="/timeline" link>
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Timeline Mobile</v-list-item-title>
        </v-list-item> -->

        <v-list-item v-if="getRoleId == 1" to="/absensi" link>
          <v-list-item-icon>
            <v-icon>mdi-fingerprint</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-wrap">Absensi</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="getRoleId == 1" to="/webcontent/advokasi" link>
          <v-list-item-icon>
            <v-icon>mdi-lightbulb-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-wrap">Advokasi</v-list-item-title>
        </v-list-item>

        <v-list-group v-if="getRoleId == 1" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-table</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Master</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item to="/gelar" link>
            <v-list-item-content>
              <v-list-item-title>Gelar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/sponsor" link>
            <v-list-item-content>
              <v-list-item-title>Sponsor</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/bidang-pengurus" link>
            <v-list-item-content>
              <v-list-item-title>Bidang Pengurus</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/master/jabatanlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Jabatan Pengurus</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/master/seminat"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Seminat</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item to="/jenisrs" link>
            <v-list-item-content>
              <v-list-item-title>Jenis RS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/kelasrs" link>
            <v-list-item-content>
              <v-list-item-title>Kelas RS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/kepemilikan" link>
            <v-list-item-content>
              <v-list-item-title>Kepemilikan</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/bank" link>
            <v-list-item-content>
              <v-list-item-title>Bank</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/profesi" link>
            <v-list-item-content>
              <v-list-item-title>Profesi</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/komunitas" link>
            <v-list-item-content>
              <v-list-item-title>Komunitas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item to="/" link @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="primary" dark app dense v-if="$route.meta.isHome === 1">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Perdatin</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-badge color="green" dot>
        <v-icon medium> mdi-vuetify </v-icon>
      </v-badge>
    </v-app-bar>

    <router-view></router-view>

    <!-- <v-footer
      padless
      color="primary"
      v-if="$route.meta.isHome === 1"
    >
      <v-col
        class="text-center white--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Halo Medika</strong>
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
import store from "./store/index"

export default {
  data: () => ({
    drawer: null,
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
    currentUser: {
      nama: "",
      photo: "",
      email: "",
    },
    isVerified: 0,
  }),

  mounted() {
    var myUser = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_USER_PREFIX)
    )

    if (myUser) {
      this.currentUser = myUser
    }

    this.isVerified = store.getters.getUser.is_verified
  },

  methods: {
    logout: async function () {
      this.$store.dispatch("logout").then(() =>
        this.$router.push("/").catch((err) => {
          console.log(err)
        })
      )
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id
    },
    getIsVerified() {
      return this.isVerified
    },
    getUser() {
      return store.getters.getUser
    },
  },
}
</script>
