import http from "../http-common";
/* eslint-disable */
class PibGotTalentService {
  gotTalentList(page, itemPerPage, keyword, id) {
    return http.get(
      "/pib/got-talent/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.pib_id) bodyFormData.append("pib_id", data.pib_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.instansi) bodyFormData.append("instansi", data.instansi);
    if (data.narahubung) bodyFormData.append("narahubung", data.narahubung);
    if (data.whatsapp) bodyFormData.append("whatsapp", data.whatsapp);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.post("/pib/got-talent", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.pib_id) bodyFormData.append("pib_id", data.pib_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.instansi) bodyFormData.append("instansi", data.instansi);
    if (data.narahubung) bodyFormData.append("narahubung", data.narahubung);
    if (data.whatsapp) bodyFormData.append("whatsapp", data.whatsapp);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/pib/got-talent/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/pib/got-talent/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PibGotTalentService();
