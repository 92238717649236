<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-progress-linear
        v-if="isProgressBarShow"
        indeterminate
        color="green"
      ></v-progress-linear>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <v-card>
        <v-card-title>
          Data Pengajuan SKP Kemkes
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn color="primary" dark class="mb-2 ml-4" @click="addKegiatan()">
            Tambah
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogApproveKegiatan" max-width="700px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda yakin menyetujui kegiatan ini?</v-card-title
              >
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeApproveKegiatan"
                      >Tidak</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogApproveKegiatanConfirm"
                      >Ya</v-btn
                    >
                  </v-row>
                </v-container>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogRejectKegiatan" max-width="700px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda yakin menolak kegiatan ini?</v-card-title
              >
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeRejectKegiatan"
                      >Tidak</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogRejectKegiatanConfirm"
                      >Ya</v-btn
                    >
                  </v-row>
                </v-container>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama SKP Kemkes"
              single-line
              outlined
              dense
              hide-details
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Penyelenggara"
              clearable
              dense
              outlined
              v-model="selectedTipePenyelenggara"
              :items="items_tipe_penyelenggara"
              item-text="value"
              @change="changeTipePenyelenggara"
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="4">
            <v-combobox
              v-if="
                selectedTipePenyelenggara && selectedTipePenyelenggara.code == 2
              "
              label="Cabang"
              clearable
              dense
              outlined
              v-model="selectedCabang"
              :items="items_propinsi"
              item-text="name"
              @change="getDataFromApi"
            ></v-combobox>
            <v-combobox
              v-if="
                selectedTipePenyelenggara && selectedTipePenyelenggara.code == 3
              "
              label="Keseminatan"
              clearable
              dense
              outlined
              v-model="selectedKeseminatan"
              :items="items_keseminatan"
              item-text="nama"
              @change="getDataFromApi"
            ></v-combobox>
            <v-combobox
              v-if="
                selectedTipePenyelenggara && selectedTipePenyelenggara.code == 4
              "
              label="Nama Penyelenggara"
              clearable
              dense
              outlined
              v-model="selectedPenyelenggara"
              :items="items_penyelenggara"
              item-text="nama"
              @change="getDataFromApi"
            ></v-combobox>
          </v-col>
        </v-row>
        <!-- Start datatable  -->
        <div class="px-6 pb-6">
          <v-data-table
            :headers="headers"
            :items="list_data"
            :options.sync="options"
            :server-items-length="totalData"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:[`item.nama_penyelenggara`]="{ item }">
              <div v-if="item.tipe_penyelenggara == 2">
                {{ item.cabang_nama }}
              </div>

              <div v-if="item.tipe_penyelenggara == 3">
                {{ item.keseminatan_nama }}
              </div>

              <div v-if="item.tipe_penyelenggara == 4">
                {{ item.penyelenggara_nama }}
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip :color="getColor(item.status)" dark>
                {{ item.status_value }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex flex-row">
                <!-- <v-menu v-if="item.data_status == 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-3" medium v-bind="attrs" v-on="on">
                      mdi-dots-vertical
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="approveKegiatan(item)">
                      <v-list-item-title>Setujui</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="rejectKegiatan(item)">
                      <v-list-item-title>Tolak</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
                <v-icon
                  v-if="
                    item.data_status == 2 &&
                    (item.is_approver == 1 || getRoleId == 1)
                  "
                  medium
                  class="mr-3"
                  @click="approveItem(item)"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-if="
                    item.data_status == 2 &&
                    (item.is_approver == 1 || getRoleId == 1)
                  "
                  medium
                  class="mr-3"
                  @click="rejectItem(item)"
                >
                  mdi-close
                </v-icon>
                <v-icon
                  v-if="
                    item.data_status == 4 &&
                    (item.is_approver == 1 || getRoleId == 1)
                  "
                  medium
                  class="mr-3"
                  @click="approveAnumertaItem(item)"
                >
                  mdi-check
                </v-icon>
                <v-icon
                  v-if="
                    item.data_status == 4 &&
                    (item.is_approver == 1 || getRoleId == 1)
                  "
                  medium
                  class="mr-3"
                  @click="rejectAnumertaItem(item)"
                >
                  mdi-close
                </v-icon>
                <v-icon medium class="mr-3" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon
                  v-if="item.data_status == 1"
                  medium
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </div>
        <!-- end datatable  -->
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import SKPService from "../../services/SKPService";
import MasterService from "../../services/MasterService";
import KeseminatanService from "../../services/KeseminatanService";
import PenyelenggaraSkpKemkesService from "../../services/skp_kemkes/PenyelenggaraSkpKemkesService";
import CommonService from "../../services/CommonService";

export default {
  data: () => ({
    isProgressBarShow: false,
    dialogApprove: false,
    dialogReject: false,
    dialogApproveKegiatan: false,
    dialogRejectKegiatan: false,
    breadcrumbs: [
      {
        text: "Data Kegiatan SKP",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedTipePenyelenggara: "",
    selectedCabang: "",
    selectedKeseminatan: "",
    selectedPenyelenggara: "",
    items_tipe_penyelenggara: [],
    items_propinsi: [],
    items_keseminatan: [],
    items_penyelenggara: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama_kegiatan", sortable: false },
      {
        text: "Penyelenggara",
        value: "tipe_penyelenggara_value",
        sortable: false,
      },
      {
        text: "Nama Penyelenggara",
        value: "nama_penyelenggara",
        sortable: false,
      },
      {
        text: "Jenis Kegiatan",
        value: "jenis_kegiatan_value",
        sortable: false,
      },
      {
        text: "Penyelenggaraan",
        value: "cakupan_value",
        sortable: false,
      },
      {
        text: "SKP",
        value: "skp",
        sortable: false,
      },
      { text: "Status", value: "status", align: "center", sortable: false },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },
    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getTipePenyelenggara();
    this.getListPropinsi();
    this.getKeseminatan();
    this.getPenyelenggara();

    this.getDataFromApi();
  },
  methods: {
    changeTipePenyelenggara() {
      this.$nextTick(() => {
        this.selectedCabang = null;
        this.selectedKeseminatan = null;
        this.selectedPenyelenggara = null;

        this.getDataFromApi();
      });
    },
    async getListStatusPegawai() {
      MasterService.statusPekerjaanList()
        .then((res) => {
          this.items_status_pegawai = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getColor(status) {
      switch (status) {
        case 1:
          return "blue";
        case 2:
          return "green";
        case 3:
          return "green";
        case 4:
          return "green";
        case 5:
          return "red";
        default:
          return "pink";
      }
    },

    getStatusKegiatan(status) {
      if (status == 1) {
        return "Diajukan";
      } else if (status == 2) {
        return "Disetujui";
      } else if (status == 3) {
        return "Direject";
      } else {
        return "-";
      }
    },

    getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      var tipePenyelenggara = "";
      var cabangId = "";
      var keseminatanId = "";
      var penyelenggaraId = "";

      if (this.selectedTipePenyelenggara)
        tipePenyelenggara = this.selectedTipePenyelenggara.code;
      if (this.selectedCabang) cabangId = this.selectedCabang.id;
      if (this.selectedKeseminatan) keseminatanId = this.selectedKeseminatan.id;
      if (this.selectedPenyelenggara)
        penyelenggaraId = this.selectedPenyelenggara.id;

      SKPService.skpKegiatanList(
        page,
        itemsPerPage,
        this.search,
        tipePenyelenggara,
        cabangId,
        keseminatanId,
        penyelenggaraId
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    addKegiatan() {
      this.$router.push("/skp-kegiatan-add").catch((err) => {
        console.log(err);
      });
    },

    editItem(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/skp-kegiatan-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      SKPService.delete(this.editedItem.id)
        .then(() => {
          CommonService.showSuccessToast(
            "Berhasil menghapus pengajuan SKP Kemkes"
          );

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Gagal menghapus pengajuan SKP Kemkes. Coba lagi"
          );
        });
    },

    async getListPropinsi() {
      SKPService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;

          // if (this.getCabangPengurusId) {
          //   this.items_propinsi.forEach((entry) => {
          //     if (entry.id == this.getCabangPengurusId) {
          //       this.selectedPropinsi = entry
          //       this.getDataFromApi()
          //     }
          //   })
          // } else {
          //   this.getDataFromApi()
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getTipePenyelenggara() {
      MasterService.listTipePenyelenggaraKegiatanSkp()
        .then((res) => {
          this.items_tipe_penyelenggara = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getKeseminatan() {
      KeseminatanService.keseminatanListAll()
        .then((res) => {
          this.items_keseminatan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getPenyelenggara() {
      PenyelenggaraSkpKemkesService.penyelenggaraListAll()
        .then((res) => {
          this.items_penyelenggara = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async changePropinsi() {
      this.getDataFromApi();
    },

    approveItem(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    rejectItem(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReject = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeReject() {
      this.dialogReject = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveKegiatan(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApproveKegiatan = true;
    },

    closeApproveKegiatan() {
      this.dialogApproveKegiatan = false;
    },

    rejectKegiatan(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRejectKegiatan = true;
    },

    closeRejectKegiatan() {
      this.dialogRejectKegiatan = false;
    },

    dialogApproveKegiatanConfirm() {
      SKPService.approvalSkpKegiatan(this.editedItem.id, 2)
        .then(() => {
          CommonService.showSuccessToast(
            "Menyetujui pengajuan SKP Kemkes berhasil"
          );

          this.closeApprove();
          this.closeApproveKegiatan();
          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast(
            "Menyetujui pengajuan SKP Kemkes gagal. Coba lagi"
          );
        });
    },

    dialogRejectKegiatanConfirm() {
      SKPService.approvalSkpKegiatan(this.editedItem.id, 3)
        .then(() => {
          CommonService.showSuccessToast(
            "Menolak pengajuan SKP Kemkes berhasil"
          );

          this.closeReject();
          this.closeRejectKegiatan();
          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast(
            "Menolak pengajuan SKP Kemkes gagal. Coba lagi"
          );
        });
    },
  },
  computed: {},
};
</script>
