import http from "../../http-common"

/* eslint-disable */
class InasiaEPosterPesertaService {
  eposterList(page, itemPerPage, keyword, status, kategori, item, id) {
    return http.get(
      "/inasia/eposter-peserta/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&status=" +
        status +
        "&kategori=" +
        kategori +
        "&item=" +
        item +
        "&inasia_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  delete(id) {
    return http.delete(`/inasia/eposter-peserta/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  downloadEposter(search, inasiaId) {
    return http.get(
      "/report/inasia/eposter/download?keyword=" +
        search +
        "&inasia_id=" +
        inasiaId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }
}

export default new InasiaEPosterPesertaService()
