import http from "../../http-common";
/* eslint-disable */
class GaleriService {
  galeriList(page, itemPerPage, keyword) {
    return http.get(
      "/galeri/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("deskripsi", data.deskripsi);

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("tipe", data.selectedKategori.id);

      if (data.selectedKategori.id == 1) {
        if (data.foto_url != null) bodyFormData.append("photo", data.foto_url);
      } else {
        if (data.alamat != null) bodyFormData.append("link", data.alamat);
      }
    }

    return http.post("/galeri", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("deskripsi", data.deskripsi);

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("tipe", data.selectedKategori.id);

      if (data.selectedKategori.id == 1) {
        if (data.foto_url != null) bodyFormData.append("photo", data.foto_url);
      } else {
        if (data.alamat != null) bodyFormData.append("link", data.alamat);
      }
    }

    return http.put(`/galeri/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/galeri/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new GaleriService();
