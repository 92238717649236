import http from "../../http-common";

/* eslint-disable */
class InasiaInvoiceItemService {
  invoiceItemList(page, itemPerPage, keyword, invoiceId) {
    return http.get(
      "/inasia/invoice-item/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&invoice_id=" +
        invoiceId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  invoiceItemByUserIdList(page, itemPerPage, keyword, userId) {
    return http.get(
      "/inasia/invoice-item-by-userid/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }
}

export default new InasiaInvoiceItemService();
