<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Pengurus Kolegium
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn color="purple" dark class="mb-2" @click="doAdd">
            Tambah
          </v-btn>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Pengurus</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Nama Anggota"
                          outlined
                          dense
                          required
                          v-model="currentData.nama"
                          readonly
                          @click="openAnggotaModal"
                          :rules="currentData.namaRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Bidang"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedDivisi"
                          :items="items_divisi"
                          item-text="value"
                        ></v-combobox>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Jabatan"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedJabatan"
                          :items="items_jabatan"
                          item-text="value"
                        ></v-combobox>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Sub Jabatan"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedSubJabatan"
                          :items="items_subjabatan"
                          item-text="value"
                        ></v-combobox>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Urutan"
                          required
                          v-model="currentData.urutan"
                          :rules="currentData.urutanRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          v-model="selected"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:[`item.foto_url`]="{ item }">
            <v-img
              :src="item.foto_url"
              contain
              height="100"
              width="200"
            ></v-img>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ getCurrentDate(item.created_at) }}</span>
          </template>
          <template v-slot:[`item.data_status`]="{ item }">
            <v-chip :color="getColor(item.data_status)" dark>
              {{ getIsHide(item.data_status) }}
            </v-chip>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="getColor(item.status)" dark>
              {{ getStatus(item.status) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>

      <AnggotaSelectComponent
        @pilihAnggota="pilihAnggota"
        ref="modalAnggota"
        v-model="dialogAnggota"
        :id="id"
      />
    </v-container>
  </v-main>
</template>

<script>
import PengurusService from "../../services/web_content/PengurusKatiService";
import CommonService from "../../services/CommonService";
import MasterService from "../../services/MasterService";
import * as moment from "moment";

import AnggotaSelectComponent from "../../components/AnggotaKatiSelect.vue";

export default {
  components: {
    AnggotaSelectComponent,
  },
  data: () => ({
    dialogAnggota: false,
    valid: false,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Pengurus",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false },
      { text: "No NPA", value: "no_npa", sortable: false },
      { text: "Bidang", value: "divisi_nama", sortable: false },
      { text: "Jabatan", value: "jabatan_nama", sortable: false },
      { text: "Sub Jabatan", value: "subjabatan_nama", sortable: false },
      { text: "Urutan", value: "urutan", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      selectedSubJabatan: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Divisi harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_jabatan: [],
    items_subjabatan: [],
    items_divisi: [],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListOrganisasiDivisi();
    this.getListOrganisasiJabatan();
    this.getListOrganisasiSubJabatan();
    this.getDataFromApi();
  },
  methods: {
    async getListOrganisasiDivisi() {
      MasterService.organisasiDivisiList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_divisi = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListOrganisasiJabatan() {
      MasterService.organisasiJabatanList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_jabatan = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListOrganisasiSubJabatan() {
      MasterService.organisasiSubJabatanList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_subjabatan = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "blue";
        case 2:
          return "green";
        default:
          return "cyan";
      }
    },

    getStatus(dataStatus) {
      console.log(dataStatus);
      if (dataStatus == 1) {
        return "Diajukan";
      } else if (dataStatus == 2) {
        return "Diproses";
      } else {
        return "Ditindak";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PengurusService.pengurusList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.selectedDivisi = "";
      this.currentData.selectedJabatan = "";

      console.log("do add");
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        PengurusService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update pengurus berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update pengurus gagal. Coba lagi");
          });
      } else {
        PengurusService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah pengurus berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah pengurus gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.nama = this.editedItem.user_nama;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.id = this.editedItem.id;

      this.items_divisi.forEach((entry) => {
        if (entry.code == this.editedItem.organisasi_divisi_id) {
          this.currentData.selectedDivisi = entry;
        }
      });

      this.items_jabatan.forEach((entry) => {
        if (entry.code == this.editedItem.organisasi_jabatan_id) {
          this.currentData.selectedJabatan = entry;
        }
      });

      this.items_subjabatan.forEach((entry) => {
        if (entry.code == this.editedItem.organisasi_sub_jabatan_id) {
          this.currentData.selectedSubJabatan = entry;
        }
      });

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PengurusService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pengurus berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pengurus gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function (file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },

    openAnggotaModal: async function () {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihAnggota(item) {
      this.dialogAnggota = false;
      console.log(item);
      this.currentData.nama = item.nama;
      this.currentData.user_id = item.id;
    },
  },
};
</script>
