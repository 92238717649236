<template>
  <v-card>
    <v-card class="pl-4 pr-4 pt-4 pb-4">
      <v-card-title> Materi </v-card-title>
      <v-fab-transition>
        <v-btn
          class="mt-8 mr-4"
          v-if="isEditable == 0"
          @click="changeEditable"
          color="pink"
          dark
          absolute
          top
          right
          fab
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
        <v-row dense>
          <v-col cols="12">
            <a
              v-if="currentData.pdf_original"
              :href="currentData.pdf_link"
              target="blank"
              >Link PDF</a
            >
            <v-file-input
              accept="application/pdf"
              v-model="currentData.file_pdf"
              prepend-icon="mdi-file"
              show-size
              label="File PDF"
              :disabled="isEditable == 0"
            />
          </v-col>
          <v-col cols="10">
            <vue-editor
              v-model="currentData.materi"
              useCustomImageHandler
              @image-added="handleImageAdded"
              :disabled="isEditable == 0"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              v-if="isEditable == 1"
              @click="doBatal"
              style="margin-right: 10px"
              >Batal</v-btn
            >
            <v-btn
              v-if="isEditable == 1"
              dark
              color="primary"
              type="button"
              @click="doSubmit"
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-card>
</template>

<script>
import CommonService from "../../services/CommonService"
import MateriService from "../../services/MateriService"
import EventService from "../../services/EventService"
import { VueEditor } from "vue2-editor"
import { uuid } from "vue-uuid"

export default {
  name: "KelasOnlineEdit",
  components: { VueEditor },
  props: {
    id: [Number, String],
    kegiatan_id: [Number, String],
  },
  data: () => ({
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Kegiatan",
        disabled: false,
        exact: true,
        to: { name: "Kegiatan" },
      },
      { text: "Edit Kegiatan", disabled: true },
    ],
    valid: true,
    currentData: {
      id: null,
      materi: "",
      file_pdf: null,
      pdf_link: "",
      pdf_original: "",
      uuid: uuid.v4(),
      judulRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
    items_kategori: [],
    items_berakhir: [
      {
        code: 0,
        value: "Tidak",
      },
      {
        code: 1,
        value: "Ya",
      },
    ],
    fotoKey: 0,
  }),
  mounted: async function () {
    this.getMateri()
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl
    },
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1
    },

    loadImage: function (data) {
      console.log("loadImage:", data)
      if (data) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result
          this.fotoKey++
        }
        reader.readAsDataURL(data)
      } else {
        this.currentData.FotoUrl = ""
        this.currentData.file_foto = null
      }
    },

    getMateri() {
      MateriService.getMateri(this.id)
        .then((res) => {
          console.log(res.data)
          console.log("webinar exists")
          this.currentData.id = res.data.pelatihan.id
          this.currentData.materi = res.data.pelatihan.materi
          this.currentData.pdf_original = res.data.pelatihan.file_pdf_original

          if (this.currentData.pdf_original)
            this.currentData.pdf_link = res.data.pelatihan.file_pdf
        })
        .catch((err) => {
          console.log(err)
        })
    },

    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      )

      var offset = date.getTimezoneOffset() / 60
      var hours = date.getHours()

      newDate.setHours(hours - offset)

      return newDate
    },

    doBatal: function () {
      this.isEditable = 0
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0)
        return
      }

      if (this.currentData.id != null) {
        MateriService.update(this.currentData)
          .then((res) => {
            console.log(res)
            CommonService.showSuccessToast("Update Materi berhasil")

            this.isEditable = 0
            this.currentData.file_pdf = null
            this.getMateri()
          })
          .catch((err) => {
            console.log(err)
            CommonService.showErrorToast("Update Materi gagal. Coba lagi")
          })
      } else {
        MateriService.create(this.id, this.currentData)
          .then((res) => {
            console.log(res)
            CommonService.showSuccessToast("Tambah Materi berhasil")

            this.isEditable = 0
            this.webinar()
          })
          .catch((err) => {
            console.log(err)
            CommonService.showErrorToast("Tambah Materi gagal. Coba lagi")
          })
      }
    },

    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      //1 = kategori event
      EventService.uploadFotoKonten(this.currentData.uuid, file, 2)
        .then((res) => {
          console.log(res)

          Editor.insertEmbed(cursorLocation, "image", res.data.event.foto_url)
          resetUploader()
        })
        .catch((err) => {
          console.log(err)
          CommonService.showSuccessToast("Gagal menambah foto. Coba lagi")
        })
    },
  },
}
</script>

<style scoped></style>
