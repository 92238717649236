import http from "../http-common";
/* eslint-disable */
class PibPembicaraEventService {
  eventList(page, itemPerPage, keyword, id) {
    return http.get(
      "/pib-pembicara-event/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("pembicara_id", data.pembicara_id);

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("category", data.selectedKategori.id);
    }

    if (data.selectedItem && data.selectedItem != undefined) {
      bodyFormData.append("item_id", data.selectedItem.id);
    }

    if (data.selectedRole && data.selectedRole != undefined) {
      bodyFormData.append("role_id", data.selectedRole.id);
    }

    return http.post("/pib-pembicara-event", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/pib-pembicara-event/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PibPembicaraEventService();
