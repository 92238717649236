import http from "../http-common";
/* eslint-disable */
class PibPembicaraService {
  pembicaraList(page, itemPerPage, keyword) {
    return http.get(
      "/pib-pembicara/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("pib_id", data.pib_id);

    // if (data.selectedKategori && data.selectedKategori != undefined) {
    //   bodyFormData.append("category", data.selectedKategori.id);
    // }

    // if (data.selectedItem && data.selectedItem != undefined) {
    //   bodyFormData.append("item_id", data.selectedItem.id);
    // }

    // if (data.selectedRole && data.selectedRole != undefined) {
    //   bodyFormData.append("role_id", data.selectedRole.id);
    // }

    bodyFormData.append("nama", data.nama);
    bodyFormData.append("email", data.email);
    bodyFormData.append("whatsapp", data.whatsapp);
    bodyFormData.append("no_npa", data.no_npa);
    bodyFormData.append("institusi", data.institusi);

    return http.post("/pib-pembicara", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);

    if (data.pib_id) bodyFormData.append("pib_id", data.pib_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.whatsapp) bodyFormData.append("whatsapp", data.whatsapp);
    if (data.email) bodyFormData.append("email", data.email);
    if (data.institusi) bodyFormData.append("institusi", data.institusi);
    if (data.no_npa) bodyFormData.append("no_npa", data.no_npa);

    return http.put(`/pib-pembicara/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/pib-pembicara/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PibPembicaraService();
