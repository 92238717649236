import http from "../http-common";
/* eslint-disable */
class PibIdentitasService {
  getIdentitas(id) {
    return http.get(`/pib-identitas/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, photo) {
    var bodyFormData = new FormData();
    if (data.nama) bodyFormData.append("nama_ktp", data.nama);
    if (data.user_id) bodyFormData.append("user_id", data.user_id);
    if (data.whatsapp) bodyFormData.append("whatsapp", data.whatsapp);
    if (data.email) bodyFormData.append("email", data.email);
    if (data.nik) bodyFormData.append("nik", data.nik);
    if (data.tanggal_lahir)
      bodyFormData.append("tanggal_lahir", data.tanggal_lahir);

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/pib-identitas", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PibIdentitasService();
