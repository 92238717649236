<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-progress-linear
        v-if="isProgressBarShow"
        indeterminate
        color="green"
      ></v-progress-linear>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Absensi

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2 ml-4"
            @click="onDownloadAbsensi()"
          >
            Download
          </v-btn>
        </v-card-title>

        <v-dialog v-model="dialogDatePicker" max-width="350px">
          <v-card>
            <v-card-title class="text-h5"
              ><v-date-picker
                v-model="search.tanggal_pick"
                @change="changeTanggal"
              ></v-date-picker
            ></v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogDatePicker = false"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-row class="mx-6 mb-6">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              outlined
              dense
              v-model="search.nama"
              append-icon="mdi-magnify"
              label="Cari nama"
              single-line
              hide-details
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search.noNpa"
              append-icon="mdi-magnify"
              label="Cari No Pegawai"
              single-line
              outlined
              dense
              hide-details
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Tahun"
              clearable
              dense
              outlined
              v-model="search.tahun"
              :items="items_tahun"
              item-text="name"
              @change="getDataFromApi"
            ></v-combobox
          ></v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Bulan"
              clearable
              dense
              outlined
              v-model="search.bulan"
              :items="items_bulan"
              item-text="name"
              @change="getDataFromApi"
            ></v-combobox
          ></v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              outlined
              dense
              v-model="search.tanggal"
              append-icon="mdi-magnify"
              label="Tanggal"
              single-line
              hide-details
              readonly
              clearable
              @click="dialogDatePicker = true"
              @click:clear="clearTanggal"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.tanggal`]="{ item }">
            <span>{{ getCurrentDate(item.tanggal) }}</span>
          </template>
          <template v-slot:[`item.tanggal_awal`]="{ item }">
            <span>{{ getCurrentHour(item.tanggal_awal) }}</span>
          </template>
          <template v-slot:[`item.tanggal_akhir`]="{ item }">
            <span>{{ getCurrentHour(item.tanggal_akhir) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="
                item.status == 2 && (item.is_approver == 1 || getRoleId == 1)
              "
              medium
              class="mr-3"
              @click="approveItem(item)"
            >
              mdi-check
            </v-icon>
            <!-- <v-icon
            medium
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import AbsensiService from "../services/AbsensiService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";
import NakesService from "../services/NakesService";
import * as moment from "moment";

import store from "../store/index";

export default {
  components: {},
  data: () => ({
    isProgressBarShow: false,
    dialogDatePicker: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Absensi",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      noNpa: "",
      tahun: "",
      tanggal: "",
      tanggal_pick: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No Pegawai", value: "pin", sortable: false },
      { text: "Tanggal", value: "tanggal", sortable: false },
      { text: "Jam Awal", value: "tanggal_awal", sortable: false },
      { text: "Jam Akhir", value: "tanggal_akhir", sortable: false },
      { text: "Range", value: "range", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      selectedCabangTujuan: "",
      selectedKategori: "",
      no_npa: "",
      cabang_asal_nama: "",
      cabang_asal: "",
      tahun: "",
      nominal: "",
      langsung_setujui: false,
      foto_url: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
      tahunRules: [(v) => !!v || "Tahun harus diisi"],
      nominalRules: [(v) => !!v || "Nominal harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },
    items_jabatan: [],
    items_divisi: [],
    items_cabang: [],
    items_tahun: [],
    items_bulan: [
      {
        id: 1,
        name: "Januari",
      },
      {
        id: 2,
        name: "Februari",
      },
      {
        id: 3,
        name: "Maret",
      },
      {
        id: 4,
        name: "April",
      },
      {
        id: 5,
        name: "Mei",
      },
      {
        id: 6,
        name: "Juni",
      },
      {
        id: 7,
        name: "Juli",
      },
      {
        id: 8,
        name: "Agustus",
      },
      {
        id: 9,
        name: "September",
      },
      {
        id: 10,
        name: "Oktober",
      },
      {
        id: 11,
        name: "November",
      },
      {
        id: 12,
        name: "Desember",
      },
    ],
    items_kategori: [
      {
        id: 1,
        name: "Pembayaran Iuran pendaftaran Anggota",
      },
      {
        id: 2,
        name: "Pembayaran Tabsol",
      },
      {
        id: 3,
        name: "Perpanjangan Iuran Anggota",
      },
      {
        id: 4,
        name: "Upload Data Iuran Lama",
      },
    ],
    items_status: [
      {
        id: 1,
        name: "Menunggu Pembayaran Anggota",
      },
      {
        id: 2,
        name: "Menunggu Konfirmasi",
      },
      {
        id: 3,
        name: "Terverifikasi",
      },
    ],

    fotoBuktiBayar: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear(); i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.getListPropinsi();
    this.getDataFromApi();
  },
  methods: {
    getKategori(id) {
      if (id == 1) {
        return "Pembayaran Iuran pendaftaran Anggota";
      } else if (id == 2) {
        return "Pembayaran Tabsol";
      } else if (id == 3) {
        return "Pembayaran perpanjangan Iuran Anggota";
      } else if (id == 4) {
        return "Upload Data Iuran Lama";
      }
    },
    async getListOrganisasiDivisi() {
      MasterService.organisasiDivisiList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_divisi = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListOrganisasiJabatan() {
      MasterService.organisasiJabatanList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_jabatan = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getColorKategori(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "pink lighten-3";
        case 2:
          return "purple lighten-3";
        case 3:
          return "teal lighten-3";
        default:
          return "red lighten-3";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "blue";
        case 2:
          return "green";
        default:
          return "cyan";
      }
    },

    getStatus(id) {
      if (id == 1) {
        return "Menunggu Pembayaran Anggota";
      } else if (id == 2) {
        return "Menunggu Konfirmasi";
      } else if (id == 3) {
        return "Terverifikasi";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getCurrentHour(tanggal) {
      return moment(tanggal).format("HH:mm:ss");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var tahun = "";
      var kategori = "";
      var status = "";
      var propinsiId = "";
      var tanggal = "";
      var bulan = "";

      if (this.search.tahun) {
        tahun = this.search.tahun;
      }

      if (this.search.bulan) {
        bulan = this.search.bulan.id;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedPropinsi) {
        propinsiId = this.search.selectedPropinsi.id;
      }

      if (this.search.tanggal) {
        tanggal = moment(this.search.tanggal, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
      }

      AbsensiService.absensiList(
        page,
        itemsPerPage,
        this.search.nama,
        this.search.noNpa,
        tahun,
        kategori,
        status,
        propinsiId,
        tanggal,
        bulan
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.no_npa = "";
      this.currentData.tahun = "";
      this.currentData.nominal = "";
      this.currentData.foto_url = null;
      this.currentData.FotoUrl = "";
      this.currentData.langsung_setujui = false;
      this.currentData.selectedDivisi = "";
      this.currentData.selectedJabatan = "";
      this.currentData.cabang_asal_nama = "";
      this.currentData.cabang_asal = "";
      this.currentData.selectedKategori = null;
      this.currentData.langsung_setujui = false;

      console.log("do add");
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        AbsensiService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialogUpdate = false;
            CommonService.showSuccessToast("Update iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update iuran gagal. Coba lagi");
          });
      } else {
        AbsensiService.create(this.currentData, this.currentData.foto_url)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah iuran gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogUpdate = true;
      this.currentData.nama = this.editedItem.user_nama;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.id = this.editedItem.id;
      this.currentData.cabang_asal = this.editedItem.cabang_asal;
      this.currentData.cabang_asal_nama = this.editedItem.cabang_asal_nama;

      this.items_cabang.forEach((entry) => {
        if (entry.id == this.editedItem.cabang_tujuan) {
          this.currentData.selectedCabangTujuan = entry;
        }
      });

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      AbsensiService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pengurus berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pengurus gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function (file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },

    openAnggotaModal: async function () {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihAnggota(item) {
      this.dialogAnggota = false;
      console.log(item);
      this.currentData.nama = item.nama;
      this.currentData.user_id = item.id;
      this.currentData.cabang_asal = item.cabang_id;
      this.currentData.cabang_asal_nama = item.propinsi_nama;
      this.currentData.no_npa = item.no_npa;
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      AbsensiService.approve(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve iuran berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve iuran gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_cabang = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showFotoBuktiBayar(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },

    async changePropinsi() {
      this.getDataFromApi();
    },

    changeTanggal(tanggal) {
      console.log(tanggal);
      this.search.tanggal = moment(tanggal, "YYYY-MM-DD").format("DD/MM/YYYY");
      this.dialogDatePicker = false;
      this.getDataFromApi();
    },

    clearTanggal() {
      this.search.tanggal = "";
      this.getDataFromApi();
    },

    onDownloadAbsensi() {
      this.isProgressBarShow = true;

      var tahun = "";
      var kategori = "";
      var status = "";
      var propinsiId = "";
      var tanggal = "";
      var bulan = "";

      if (this.search.tahun) {
        tahun = this.search.tahun;
      }

      if (this.search.bulan) {
        bulan = this.search.bulan.id;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedPropinsi) {
        propinsiId = this.search.selectedPropinsi.id;
      }

      if (this.search.tanggal) {
        tanggal = moment(this.search.tanggal, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        );
      }

      AbsensiService.downloadAbsensiReport(
        this.search.nama,
        this.search.noNpa,
        tahun,
        kategori,
        status,
        propinsiId,
        tanggal,
        bulan
      )
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
