<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-tabs v-model="tab">
          <v-tab>Registrasi Ulang</v-tab>
          <v-tab>History Registrasi</v-tab>
          <v-tab>Peserta</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <v-card-title>
                Registrasi Ulang
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>

                <!-- <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="onDownloadInvoice()"
        >
          Download
        </v-btn> -->
              </v-card-title>

              <v-row class="mx-6 mb-6">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    outlined
                    v-model="search.invoice_no"
                    append-icon="mdi-magnify"
                    label="No Invoice"
                    single-line
                    hide-details
                    v-on:keyup.enter="getDataFromApi"
                    autofocus
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-card id="printJS-form">
              <div class="pa-4" style="font-size: 30px">
                {{ currentUser.user_nama }}
              </div>
            </v-card>
          </v-col>
        </v-row> -->

              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="list_data"
                :options.sync="options"
                :server-items-length="totalData"
                :loading="loading"
                :single-select="true"
                show-select
                class="elevation-1"
              >
                <template v-slot:[`item.tanggal_hadir`]="{ item }">
                  {{ getCurrentDate(item.tanggal_hadir) }}
                </template>
                <template v-slot:[`item.status_registrasi`]="{ item }">
                  <v-chip
                    v-if="item.status_registrasi == 1"
                    :color="getColor(item.status_registrasi)"
                    dark
                  >
                    {{ getStatus(item.status_registrasi) }}
                  </v-chip>
                </template>
                <template v-slot:[`item.nominal`]="{ item }">
                  {{ new Intl.NumberFormat("id-ID").format(item.nominal) }}
                </template>
                <template v-slot:[`item.is_event_sent`]="{ item }">
                  <v-chip
                    v-if="item.metode_bayar == 1"
                    :color="getColorSplitted(item.is_event_sent)"
                    dark
                  >
                    {{ getSplittedType(item.is_event_sent) }}
                  </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="d-flex flex-row">
                    <v-icon medium class="mr-3" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon medium class="mr-3" @click="printCard(item)">
                      mdi-printer
                    </v-icon>
                    <v-icon medium class="mr-3" @click="tambahCatatan(item)">
                      mdi-note-plus
                    </v-icon>
                  </div>
                </template>
              </v-data-table>
            </v-card>

            <v-card class="mt-4">
              <InvoiceItemPibComponent
                v-if="selected != undefined && selected.length > 0"
                :id="selected[0].id"
                ref="mychild"
                :key="childKey"
              />
            </v-card>

            <v-dialog v-model="dialogUpdateIdentitas" persistent>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Peserta</span>
                </v-card-title>
                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="pl-4 pr-4"
                  >
                    <v-container>
                      <v-row dense>
                        <v-col cols="12" sm="6" md="6" class="pr-4">
                          <v-sheet color="white" elevation="2">
                            <v-responsive :aspect-ratio="16 / 9">
                              <v-img
                                :key="fotoKey"
                                :src="currentData.FotoUrl"
                                contain
                              ></v-img>
                            </v-responsive>
                          </v-sheet>
                          <v-file-input
                            @change="loadImageProfile"
                            accept="image/*"
                            v-model="currentData.file_foto"
                            prepend-icon="mdi-image"
                            show-size
                            label="Foto"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-combobox
                                label="Status Profesi"
                                clearable
                                dense
                                outlined
                                v-model="currentData.selectedKategori"
                                :items="items_kategori"
                                item-text="value"
                              ></v-combobox>
                            </v-col>
                          </v-row>

                          <v-row
                            v-if="
                              currentData.selectedKategori &&
                              currentData.selectedKategori.code == 1
                            "
                            dense
                          >
                            <v-col cols="12" sm="12" md="12">
                              <v-combobox
                                label="Cabang"
                                clearable
                                dense
                                outlined
                                v-model="currentData.selectedPropinsi"
                                :items="items_propinsi"
                                item-text="name"
                              ></v-combobox>
                            </v-col>
                          </v-row>

                          <v-row
                            v-if="
                              currentData.selectedKategori &&
                              currentData.selectedKategori.code == 1
                            "
                            dense
                          >
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                label="No NPA"
                                outlined
                                dense
                                v-model="currentData.no_npa"
                                required
                                :rules="currentData.noNpaRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-combobox
                                label="Domisili Propinsi"
                                clearable
                                dense
                                outlined
                                v-model="currentData.selectedDomisiliPropinsi"
                                :items="items_propinsi"
                                item-text="name"
                              ></v-combobox>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                label="Nama & Gelar"
                                outlined
                                dense
                                v-model="currentData.nama"
                                required
                                :rules="currentData.namaRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="No Telepon/HP"
                                outlined
                                dense
                                v-model="currentData.whatsapp"
                                required
                                :rules="currentData.whatsappRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Email"
                                outlined
                                dense
                                v-model="currentData.email"
                                required
                                :rules="currentData.emailRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                label="Institusi"
                                outlined
                                dense
                                v-model="currentData.institusi"
                                required
                                :rules="currentData.institusiRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="text-h5 mx-2">Data Sponsor</v-row>
                          <v-row dense class="mt-6">
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                label="Nama Sponsor"
                                outlined
                                dense
                                v-model="currentData.nama_sponsor"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row dense class="mt-4">
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                label="Alamat Sponsor"
                                outlined
                                dense
                                v-model="currentData.alamat_sponsor"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row dense class="mt-4">
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                label="Nama Kontak Person Sponsor"
                                outlined
                                dense
                                v-model="currentData.nama_kontak_person_sponsor"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row dense class="mt-4">
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                label="Handphone Kontak Person Sponsor"
                                outlined
                                dense
                                v-model="currentData.hp_kontak_person_sponsor"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row dense class="mt-4">
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                label="Email Sponsor"
                                outlined
                                dense
                                v-model="currentData.email_sponsor"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row dense class="mt-4">
                            <v-col cols="12" sm="12" md="12">
                              <v-sheet color="white" elevation="2">
                                <v-responsive :aspect-ratio="16 / 9">
                                  <v-img
                                    :key="fotoKey"
                                    :src="currentData.FotoBuktiUrl"
                                    contain
                                  ></v-img>
                                </v-responsive>
                              </v-sheet>
                              <v-file-input
                                @change="loadBuktiImage"
                                accept="image/*"
                                v-model="currentData.file_bukti_foto"
                                prepend-icon="mdi-image"
                                show-size
                                label="Bukti Transfer / Surat Garansi Sponsor"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogUpdateIdentitas = false"
                  >
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="doSave">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogCatatan" persistent max-width="600">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Update Catatan</span>
                </v-card-title>
                <v-card-text>
                  <v-form
                    ref="formCatatan"
                    v-model="valid"
                    lazy-validation
                    class="pl-4 pr-4"
                  >
                    <v-container>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            label="Catatan"
                            outlined
                            dense
                            v-model="catatan"
                            required
                            :rules="catatanRules"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogCatatan = false"
                  >
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="doSaveCatatan">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>
          <v-tab-item><HistoryRegistrasiComponent /> </v-tab-item>
          <v-tab-item
            ><InvoicePibComponent @registrasiPeserta="registrasiPeserta" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KehadiranPibService from "../services/KehadiranPibService";
import * as moment from "moment";
import store from "../store/index";
import InvoiceItemPibComponent from "./pib/InvoiceItemPib.vue";
import InvoicePibComponent from "./pib/InvoicePibRegistrasi.vue";
import HistoryRegistrasiComponent from "./pib/HistoryRegistrasi.vue";
import PesertaPibService from "../services/PesertaPibService";
import MasterService from "../services/MasterService";
import CommonService from "../services/CommonService";
import print from "print-js";
import NakesService from "../services/NakesService";

export default {
  components: {
    InvoiceItemPibComponent,
    HistoryRegistrasiComponent,
    InvoicePibComponent,
  },
  data: () => ({
    tab: null,
    childKey: 0,
    dialogCatatan: false,
    dialogUpdateIdentitas: false,
    dialogBuktiBayar: false,
    dialogAnggota: false,
    dialogApprove: false,
    dialogReject: false,
    dialogUpdate: false,
    dialogUploadBuktiBayar: false,
    dialogUploadGaransiLetter: false,
    dialogSponsor: false,
    dialogSponsorModal: false,
    isProgressBarShow: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Registrasi Ulang",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      tahun: "",
      invoice_no: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
      selectedSponsor: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false },
      { text: "Invoice No", value: "invoice_no", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
      { text: "Tanggal Hadir", value: "tanggal_hadir", sortable: false },
      { text: "Profesi", value: "profesi_pib", sortable: false },
      { text: "Catatan", value: "catatan", sortable: false },
      {
        text: "Domisili Propinsi",
        value: "domisili_propinsi_nama",
        sortable: false,
      },

      { text: "Status", value: "status_registrasi", sortable: false },
      { text: "Update Identitas", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: false,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    uploadBuktiBayarData: {
      id: "",
      FotoUrl: "",
      file_foto: null,
      fotoKey: 0,
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },

    uploadBuktiGaransiLetter: {
      id: "",
      FotoUrl: "",
      file_foto: null,
      fotoKey: 0,
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },

    currentData: {
      pib_id: "",
      id: null,
      FotoUrl: "",
      FotoBuktiUrl: "",
      file_foto: null,
      nama: "",
      title: "",
      email: "",
      whatsapp: "",
      selectedKategori: "",
      nama_sponsor: "",
      alamat_sponsor: "",
      nama_kontak_person_sponsor: "",
      hp_kontak_person_sponsor: "",
      email_sponsor: "",
      file_bukti_foto: null,
      selectedPropinsi: "",
      selectedDomisiliPropinsi: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      titleRules: [(v) => !!v || "Title harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      whatsappRules: [(v) => !!v || "Whatsapp harus diisi"],
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },

    items_jabatan: [],
    items_divisi: [],
    items_cabang: [],
    items_tahun: [],
    items_sponsor: [],
    items_status: [
      {
        id: 1,
        name: "Menunggu Pembayaran Peserta",
      },
      {
        id: 2,
        name: "Menunggu Konfirmasi Admin",
      },
      {
        id: 4,
        name: "Terverifikasi",
      },
    ],
    items_kategori: [],
    items_item: [],
    items_propinsi: [],

    fotoBuktiBayar: null,
    fotoKey: 0,
    fotoKeyProfile: 0,
    currentInvoiceNo: "",
    currentUser: {
      nama: "",
    },

    catatan: "",
    catatanRules: [(v) => !!v || "Catatan harus diisi"],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear(); i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.search.tahun = new Date().getFullYear();

    this.getListPropinsi();
    this.getListStatusProfesi();
    // this.getDataFromApi();
  },
  methods: {
    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListStatusProfesi() {
      MasterService.getStatusProfesi()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item.id);
      this.currentInvoiceNo = item.invoice_no;
      this.getPesertaPib(item);
    },

    async getPesertaPib(item) {
      PesertaPibService.pesertaPib(item.user_id)
        .then((res) => {
          console.log(res.data.user);
          var myuser = res.data.user;

          this.currentData.id = myuser.id;
          this.currentData.pib_id = myuser.pib_id;
          this.currentData.nama = myuser.nama;
          this.currentData.whatsapp = myuser.whatsapp;
          this.currentData.email = myuser.email;
          this.currentData.institusi = myuser.institusi;
          this.currentData.no_npa = myuser.no_npa;
          this.currentData.nama_sponsor = myuser.nama_sponsor;
          this.currentData.alamat_sponsor = myuser.alamat_sponsor;
          this.currentData.nama_kontak_person_sponsor =
            myuser.nama_kontak_person_sponsor;
          this.currentData.hp_kontak_person_sponsor =
            myuser.hp_kontak_person_sponsor;
          this.currentData.email_sponsor = myuser.email_sponsor;

          this.currentData.FotoUrl = myuser.photo;
          this.currentData.FotoBuktiUrl = myuser.bukti_transfer_sponsor;

          if (myuser.jenis_anggota) {
            this.items_kategori.forEach((entry) => {
              if (entry.code == myuser.jenis_anggota) {
                this.currentData.selectedKategori = entry;
              }
            });
          }

          if (myuser.cabang_id) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == myuser.cabang_id) {
                this.currentData.selectedPropinsi = entry;
              }
            });
          }

          if (myuser.propinsi_id) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == myuser.propinsi_id) {
                this.currentData.selectedDomisiliPropinsi = entry;
              }
            });
          }

          this.dialogUpdateIdentitas = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.currentData.pib_id = this.id;

      PesertaPibService.update(this.currentData)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Update Peserta berhasil");

          this.dialogUpdateIdentitas = false;

          this.getDataFromApiInvoiceNo(this.currentInvoiceNo);
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Update Peserta gagal. Coba lagi");
        });
    },

    doSaveCatatan: async function () {
      if (!this.$refs.formCatatan.validate()) {
        return;
      }

      PesertaPibService.updateCatatan(this.currentUser.invoice_no, this.catatan)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Tambah Catatan berhasil");

          this.dialogCatatan = false;

          this.getDataFromApiInvoiceNo(this.currentUser.invoice_no);
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Tambah Catatan gagal. Coba lagi");
        });
    },

    async printCard(item) {
      console.log(item.id);
      // print("printJS-form", "html");
      PesertaPibService.pesertaCard(item.user_id)
        .then((res) => {
          console.log(res.data.link);
          print({
            printable: [res.data.link],
            type: "image",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loadImageProfile: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKeyProfile++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    loadBuktiImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoBuktiUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoBuktiUrl = "";
        this.currentData.file_bukti_foto = null;
      }
    },

    changeInvoiceNo() {
      this.getDataFromApi();
    },
    checkFotoExists() {
      if (this.currentData.buktiFotoUrlOriginal) {
        return true;
      } else {
        if (this.currentData.bukti_file_foto) {
          return true;
        } else {
          return "Bukti Foto harus diisi";
        }
      }
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadBuktiBayarData.FotoUrl = e.target.result;
          this.uploadBuktiBayarData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.uploadBuktiBayarData.FotoUrl = "";
        this.uploadBuktiBayarData.file_foto = null;
      }
    },
    loadImageGaransiLetter: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadBuktiGaransiLetter.FotoUrl = e.target.result;
          this.uploadBuktiGaransiLetter.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.uploadBuktiGaransiLetter.FotoUrl = "";
        this.uploadBuktiGaransiLetter.file_foto = null;
      }
    },
    getSplittedType(id) {
      if (id == 1) {
        return "Sudah";
      } else {
        return "Belum";
      }
    },
    getKategori(id) {
      if (id == 1) {
        return "Pembayaran Iuran pendaftaran Anggota";
      } else if (id == 2) {
        return "Pembayaran Tabsol";
      } else if (id == 3) {
        return "Pembayaran perpanjangan Iuran Anggota";
      } else if (id == 4) {
        return "Upload Data Iuran Lama";
      } else if (id == 5) {
        return "Pembayaran Pelatihan";
      } else if (id == 6) {
        return "Upload Tabsol Lama";
      }
    },

    getColorKategori(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "pink lighten-3";
        case 2:
          return "purple lighten-3";
        case 3:
          return "teal lighten-3";
        default:
          return "red lighten-3";
      }
    },

    getColor(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "blue";
      }
    },

    getColorSplitted(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "green";
        default:
          return "red";
      }
    },

    getStatus(id) {
      if (id == 1) {
        return "Registrasi Berhasil";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY HH:mm");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    getDataByKategori() {
      this.getDataFromApi();

      if (this.search.selectedKategori)
        this.getKategoriItem(this.search.selectedKategori.id);
      else this.getKategoriItem("");

      this.search.selectedItem = null;
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var status = "";
      var kategori = "";
      var item = "";
      var sponsor = "";

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedItem) {
        item = this.search.selectedItem.id;
      }

      if (this.search.selectedSponsor) {
        sponsor = this.search.selectedSponsor.id;
      }

      if (this.search.invoice_no) {
        KehadiranPibService.invoiceList(
          page,
          itemsPerPage,
          this.search.nama,
          this.search.invoice_no,
          status,
          kategori,
          item,
          sponsor
        )
          .then((res) => {
            this.search.invoice_no = "";
            this.list_data = res.data.list_data;
            this.totalData = res.data.total;
            this.loading = false;
            this.isFirst = true;

            if (this.list_data && this.list_data.length > 0) {
              this.selected.push(this.list_data[0]);
              this.currentUser = this.list_data[0];

              console.log("current user");
              console.log(this.currentUser);
            } else {
              this.selected = [];
            }
          })
          .catch((err) => {
            this.search.invoice_no = "";
            this.loading = false;
            this.isFirst = true;
            console.log(err);
          });
      } else {
        this.search.invoice_no = "";
        this.list_data = [];
        this.totalData = 0;
        this.loading = false;
        this.isFirst = true;

        if (this.list_data && this.list_data.length > 0) {
          this.selected.push(this.list_data[0]);
        } else {
          this.selected = [];
        }
      }
    },

    getDataFromApiInvoiceNo(invoiceNo) {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var status = "";
      var kategori = "";
      var item = "";
      var sponsor = "";

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedItem) {
        item = this.search.selectedItem.id;
      }

      if (this.search.selectedSponsor) {
        sponsor = this.search.selectedSponsor.id;
      }

      if (invoiceNo) {
        KehadiranPibService.invoiceList(
          page,
          itemsPerPage,
          this.search.nama,
          invoiceNo,
          status,
          kategori,
          item,
          sponsor
        )
          .then((res) => {
            this.search.invoice_no = "";
            this.list_data = res.data.list_data;
            this.totalData = res.data.total;
            this.loading = false;
            this.isFirst = true;

            if (this.list_data && this.list_data.length > 0) {
              this.selected.push(this.list_data[0]);
              this.currentUser = this.list_data[0];
            } else {
              this.selected = [];
            }
          })
          .catch((err) => {
            this.search.invoice_no = "";
            this.loading = false;
            this.isFirst = true;
            console.log(err);
          });
      } else {
        this.search.invoice_no = "";
        this.list_data = [];
        this.totalData = 0;
        this.loading = false;
        this.isFirst = true;

        if (this.list_data && this.list_data.length > 0) {
          this.selected.push(this.list_data[0]);
        } else {
          this.selected = [];
        }
      }
    },

    async changePropinsi() {
      this.getDataFromApi();
    },

    registrasiPeserta(item) {
      // alert(this.tab);
      this.tab = 0;
      this.search.invoice_no = item.invoice_no;
      this.getDataFromApi();
    },

    tambahCatatan(item) {
      console.log(item);
      this.catatan = item.catatan;
      this.dialogCatatan = true;
    },
  },

  computed: {
    nominalTotal() {
      var nominalCabang = 0;
      var nominalPusat = 0;

      if (this.nominal_cabang) {
        nominalCabang = this.nominal_cabang;
      }

      if (this.nominal_pusat) {
        nominalPusat = this.nominal_pusat;
      }

      return nominalPusat + nominalCabang;
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
