import http from "../http-common";
/* eslint-disable */
class AnggotaRumahSakitService {
  nakesList(page, itemPerPage, keyword, rumahSakitId) {
    return http.get(
      "/nakes/rumahsakit/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&rumah_sakit_id=" +
        rumahSakitId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  nakesNonList(page, itemPerPage, keyword, rumahSakitId) {
    return http.get(
      "/nakes-non/rumahsakit/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&rumah_sakit_id=" +
        rumahSakitId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.rumah_sakit_id)
      bodyFormData.append("rumah_sakit_id", data.rumah_sakit_id);
    if (data.user_id) bodyFormData.append("user_id", data.user_id);

    return http.post("/nakes/rumahsakit", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/nakes/rumahsakit/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new AnggotaRumahSakitService();
