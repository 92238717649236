<template>
  <div>
    <v-card>
      <v-tabs>
        <v-tab>Profil Anggota</v-tab>
        <v-tab>Borang/P2KB</v-tab>
        <v-tab>Invoice</v-tab>
        <v-tab-item>
          <NakesEditComponent :id="userId" />
        </v-tab-item>
        <v-tab-item>
          <BorangComponent :id="userId" />
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-title>
              Invoice
              <v-spacer></v-spacer>

              <v-dialog v-model="dialogBuktiBayar" max-width="600px">
                <v-card>
                  <v-img
                    height="auto"
                    width="auto"
                    :src="fotoBuktiBayar"
                  ></v-img>
                </v-card>
              </v-dialog>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="list_data"
              :options.sync="options"
              :server-items-length="totalData"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:[`item.kategori`]="{ item }">
                <v-chip :color="getColorKategori(item.kategori)" dark>
                  {{ getKategori(item.kategori) }}
                </v-chip>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ getCurrentDate(item.created_at) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getColor(item.status)" dark>
                  {{ getStatus(item.status) }}
                </v-chip>
              </template>
              <template v-slot:[`item.foto_url`]="{ item }">
                <v-btn
                  v-if="item.kategori == 4"
                  color="blue"
                  dark
                  class="mb-2"
                  @click="showFotoBuktiBayar(item.foto_url)"
                >
                  View
                </v-btn>
              </template>
              <template v-slot:[`item.nominal`]="{ item }">
                {{ new Intl.NumberFormat("id-ID").format(item.nominal) }}
              </template>
              <template v-slot:[`item.nominal_pusat`]="{ item }">
                <div v-if="item.nominal_pusat">
                  <div v-if="item.kategori == 1 || item.kategori == 3">
                    {{
                      new Intl.NumberFormat("id-ID").format(item.nominal_pusat)
                    }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.nominal_cabang`]="{ item }">
                <div v-if="item.nominal_cabang">
                  <div v-if="item.kategori == 1 || item.kategori == 3">
                    {{
                      new Intl.NumberFormat("id-ID").format(item.nominal_cabang)
                    }}
                  </div>
                </div>
              </template>
              <template v-slot:[`item.virtual_account`]="{ item }">
                <div v-if="item.virtual_account">
                  {{ item.virtual_account }}
                </div>
              </template>
              <template v-slot:[`item.pusat_splitted`]="{ item }">
                <div v-if="item.kategori == 1 || item.kategori == 3">
                  <v-chip :color="getColorSplitted(item.pusat_splitted)" dark>
                    {{ getSplittedType(item.pusat_splitted) }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:[`item.cabang_splitted`]="{ item }">
                <div v-if="item.kategori == 1 || item.kategori == 3">
                  <v-chip :color="getColorSplitted(item.cabang_splitted)" dark>
                    {{ getSplittedType(item.cabang_splitted) }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex flex-row">
                  <v-icon
                    v-if="
                      item.kategori == 4 &&
                      (item.status == 2 || item.status == 3) &&
                      (item.is_approver == 1 || getRoleId == 1)
                    "
                    medium
                    class="mr-3"
                    @click="approveItem(item)"
                  >
                    mdi-check
                  </v-icon>
                  <v-icon
                    v-if="
                      item.kategori == 4 &&
                      (item.status == 2 || item.status == 3) &&
                      (item.is_approver == 1 || getRoleId == 1)
                    "
                    medium
                    class="mr-3"
                    @click="rejectItem(item)"
                  >
                    mdi-close
                  </v-icon>
                </div>
                <!-- <v-icon
            medium
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import IuranService from "../services/IuranService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";
import NakesService from "../services/NakesService";
import * as moment from "moment";

import store from "../store/index";

import BorangComponent from "./user/Borang.vue";
import NakesEditComponent from "./NakesEditSub.vue";

export default {
  props: {
    id: Number,
    userId: Number,
  },
  components: { BorangComponent, NakesEditComponent },
  data: () => ({
    dialogBuktiBayar: false,
    dialogAnggota: false,
    dialogApprove: false,
    dialogUpdate: false,
    dialogReject: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Keuangan",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      invoice_no: "",
      tahun: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Cabang", value: "cabang_nama", sortable: false, width: "120px" },
      { text: "No NPA", value: "no_npa", sortable: false },
      { text: "Invoice No", value: "invoice_no", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Tahun", value: "tahun", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
      { text: "Pusat Terkirim", value: "pusat_splitted", sortable: false },
      {
        text: "No VA",
        value: "virtual_account",
        sortable: false,
        width: "100px",
      },
      { text: "Status", value: "status", sortable: false },
      { text: "Foto", value: "foto_url", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      selectedCabangTujuan: "",
      selectedKategori: "",
      selectedCabang: "",
      no_npa: "",
      cabang_asal_nama: "",
      cabang_asal: "",
      tahun: "",
      nominal: "",
      langsung_setujui: false,
      foto_url: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      nominal_pusat: 900000,
      nominal_cabang: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
      tahunRules: [(v) => !!v || "Tahun harus diisi"],
      nominalRules: [(v) => !!v || "Nominal harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_jabatan: [],
    items_divisi: [],
    items_cabang: [],
    items_tahun: [],
    items_kategori: [
      {
        id: 1,
        name: "Pembayaran Iuran pendaftaran Anggota",
      },
      {
        id: 3,
        name: "Perpanjangan Iuran Anggota",
      },
      {
        id: 4,
        name: "Upload Data Iuran Lama",
      },
    ],
    items_status: [
      {
        id: 1,
        name: "Menunggu Pembayaran Anggota",
      },
      {
        id: 2,
        name: "Menunggu Konfirmasi Cabang",
      },
      {
        id: 3,
        name: "Menunggu Konfirmasi Pusat",
      },
      {
        id: 4,
        name: "Terverifikasi",
      },
    ],

    fotoBuktiBayar: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear() + 5; i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.search.tahun = new Date().getFullYear();

    // this.getListPropinsi();
    this.getDataFromApi();
  },
  methods: {
    getSplittedType(id) {
      if (id == 1) {
        return "Sudah";
      } else {
        return "Belum";
      }
    },
    getKategori(id) {
      if (id == 1) {
        return "Pembayaran Iuran pendaftaran Anggota";
      } else if (id == 2) {
        return "Pembayaran Tabsol";
      } else if (id == 3) {
        return "Pembayaran perpanjangan Iuran Anggota";
      } else if (id == 4) {
        return "Upload Data Iuran Lama";
      } else if (id == 5) {
        return "Pembayaran Pelatihan";
      } else if (id == 6) {
        return "Update Tabsol Lama";
      }
    },
    async getListOrganisasiDivisi() {
      MasterService.organisasiDivisiList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_divisi = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListOrganisasiJabatan() {
      MasterService.organisasiJabatanList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_jabatan = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getColorKategori(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "pink lighten-3";
        case 2:
          return "purple lighten-3";
        case 3:
          return "teal lighten-3";
        default:
          return "red lighten-3";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "light-blue";
        case 2:
          return "green";
        case 3:
          return "cyan";
        case 4:
          return "blue";
        default:
          return "red";
      }
    },

    getColorSplitted(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "green";
        default:
          return "red";
      }
    },

    getStatus(id) {
      if (id == 1) {
        return "Menunggu Pembayaran Anggota";
      } else if (id == 2) {
        return "Menunggu Konfirmasi Cabang";
      } else if (id == 3) {
        return "Menunggu Konfirmasi Pusat";
      } else if (id == 4) {
        return "Terverifikasi";
      } else if (id == 5) {
        return "Rejected";
      } else if (id == 6) {
        return "Canceled";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var tahun = "";
      var kategori = "";
      var status = "";
      var propinsi = "";

      // if (this.search.tahun) {
      //   tahun = this.search.tahun;
      // }

      // if (this.search.selectedKategori) {
      //   kategori = this.search.selectedKategori.id;
      // }

      // if (this.search.selectedStatus) {
      //   status = this.search.selectedStatus.id;
      // }

      // if (this.search.selectedPropinsi) {
      //   propinsi = this.search.selectedPropinsi.id;
      // }

      IuranService.invoiceSerkomList(
        page,
        itemsPerPage,
        this.search.nama,
        tahun,
        kategori,
        status,
        propinsi,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.no_npa = "";
      this.currentData.tahun = "";
      this.currentData.nominal = "";
      this.currentData.foto_url = null;
      this.currentData.FotoUrl = "";
      this.currentData.langsung_setujui = false;
      this.currentData.selectedDivisi = "";
      this.currentData.selectedJabatan = "";
      this.currentData.cabang_asal_nama = "";
      this.currentData.cabang_asal = "";
      this.currentData.selectedKategori = null;
      this.currentData.langsung_setujui = false;

      console.log("do add");
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        IuranService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialogUpdate = false;
            CommonService.showSuccessToast("Update iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update iuran gagal. Coba lagi");
          });
      } else {
        IuranService.create(this.currentData, this.currentData.foto_url)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah iuran gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogUpdate = true;
      this.currentData.nama = this.editedItem.user_nama;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.id = this.editedItem.id;
      this.currentData.cabang_asal = this.editedItem.cabang_asal;
      this.currentData.cabang_asal_nama = this.editedItem.cabang_asal_nama;

      this.items_cabang.forEach((entry) => {
        if (entry.id == this.editedItem.cabang_tujuan) {
          this.currentData.selectedCabangTujuan = entry;
        }
      });

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      IuranService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pengurus berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pengurus gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function (file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },

    openAnggotaModal: async function () {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihAnggota(item) {
      this.dialogAnggota = false;
      console.log(item);
      this.currentData.nama = item.nama;
      this.currentData.user_id = item.id;
      this.currentData.cabang_asal = item.cabang_id;
      this.currentData.cabang_asal_nama = item.propinsi_nama;
      this.currentData.no_npa = item.no_npa;
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      IuranService.approve(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve iuran berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve iuran gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_cabang = res.data.list_data;

          if (this.getCabangPengurusId) {
            this.items_cabang.forEach((entry) => {
              if (entry.id == this.getCabangPengurusId) {
                this.search.selectedPropinsi = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMasterIuran() {
      if (
        this.currentData.selectedKategori &&
        this.currentData.selectedKategori.id != 2 &&
        this.currentData.selectedKategori.id != 6 &&
        this.currentData.nama &&
        this.currentData.tahun
      ) {
        IuranService.getMasterIuran(
          this.currentData.cabang_asal,
          this.currentData.tahun
        )
          .then((res) => {
            var invoice = res.data.invoice;
            if (invoice) this.currentData.nominal_cabang = invoice.nominal;
            else this.currentData.nominal_cabang = 0;

            this.currentData.nominal =
              this.currentData.nominal_cabang + this.currentData.nominal_pusat;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.currentData.nominal = 0;
      }
    },

    showFotoBuktiBayar(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },

    async changePropinsi() {
      this.getDataFromApi();
    },

    rejectItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReject = true;
    },

    closeReject() {
      this.dialogReject = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    rejectItemConfirm() {
      IuranService.reject(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeReject();

          console.log(res);
          this.$toast.success("Reject iuran berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Reject iuran gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },

  computed: {
    nominalTotal() {
      var nominalCabang = 0;
      var nominalPusat = 0;

      if (this.nominal_cabang) {
        nominalCabang = this.nominal_cabang;
      }

      if (this.nominal_pusat) {
        nominalPusat = this.nominal_pusat;
      }

      return nominalPusat + nominalCabang;
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
