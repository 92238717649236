import http from "../http-common";
/* eslint-disable */
class PaketWisataService {
  paketWisataList(page, itemPerPage, keyword, id) {
    return http.get(
      "/pib/paket-wisata/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.pib_id) bodyFormData.append("pib_id", data.pib_id);
    if (data.nama_paket) bodyFormData.append("nama_paket", data.nama_paket);
    if (data.destinasi) bodyFormData.append("destinasi", data.destinasi);
    if (data.alamat) bodyFormData.append("alamat", data.alamat);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.harga) bodyFormData.append("harga", data.harga);
    if (data.link_google_map)
      bodyFormData.append("link_google_map", data.link_google_map);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.post("/pib/paket-wisata", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.pib_id) bodyFormData.append("pib_id", data.pib_id);
    if (data.nama_paket) bodyFormData.append("nama_paket", data.nama_paket);
    if (data.destinasi) bodyFormData.append("destinasi", data.destinasi);
    if (data.alamat) bodyFormData.append("alamat", data.alamat);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.harga) bodyFormData.append("harga", data.harga);
    if (data.link_google_map)
      bodyFormData.append("link_google_map", data.link_google_map);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/pib/paket-wisata/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/pib/paket-wisata/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PaketWisataService();
