<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <v-progress-linear
        v-if="isProgressBarShow"
        indeterminate
        color="green"
      ></v-progress-linear>
      <v-card>
        <v-tabs>
          <v-tab>PIB</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-title>
                PIB
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  dark
                  class="mb-2 ml-4"
                  @click="onDownloadInvoice()"
                >
                  Download
                </v-btn>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Apa anda akan menghapus data ini?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Tidak</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >Ya</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogApprove" max-width="600px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Apa anda akan mengapprove invoice peserta
                      ini?</v-card-title
                    >
                    <v-card-actions>
                      <v-container>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closeApprove"
                            >Tidak</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="approveItemConfirm"
                            >Ya</v-btn
                          >
                        </v-row>
                      </v-container>

                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogReject" max-width="700px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Apa anda akan mereject Invoice ini?</v-card-title
                    >
                    <v-card-actions>
                      <v-container>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeReject"
                            >Tidak</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="rejectItemConfirm"
                            >Ya</v-btn
                          >
                        </v-row>
                      </v-container>

                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog
                  v-model="dialogBuktiBayar"
                  max-width="600px"
                  scrollable
                >
                  <v-card>
                    <v-img
                      height="auto"
                      width="auto"
                      :src="fotoBuktiBayar"
                      contain
                    ></v-img>
                  </v-card>
                </v-dialog>

                <v-dialog
                  v-model="dialogUploadBuktiBayar"
                  persistent
                  max-width="600px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Upload Bukti Bayar</span>
                    </v-card-title>
                    <v-card-text>
                      <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="pl-4 pr-4"
                      >
                        <v-container>
                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-sheet color="white" elevation="2">
                                <v-responsive :aspect-ratio="16 / 9">
                                  <v-img
                                    :key="uploadBuktiBayarData.fotoKey"
                                    :src="uploadBuktiBayarData.FotoUrl"
                                    contain
                                  ></v-img>
                                </v-responsive>
                              </v-sheet>
                              <v-file-input
                                @change="loadImage"
                                accept="image/*"
                                v-model="uploadBuktiBayarData.file_foto"
                                prepend-icon="mdi-image"
                                show-size
                                label="Foto"
                                :rules="uploadBuktiBayarData.fotoRules"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogUploadBuktiBayar = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="doSaveUploadBuktiBayar"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog
                  v-model="dialogUploadGaransiLetter"
                  persistent
                  max-width="600px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Upload Garansi Letter</span>
                    </v-card-title>
                    <v-card-text>
                      <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="pl-4 pr-4"
                      >
                        <v-container>
                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-sheet color="white" elevation="2">
                                <v-responsive :aspect-ratio="16 / 9">
                                  <v-img
                                    :key="uploadBuktiGaransiLetter.fotoKey"
                                    :src="uploadBuktiGaransiLetter.FotoUrl"
                                    contain
                                  ></v-img>
                                </v-responsive>
                              </v-sheet>
                              <v-file-input
                                @change="loadImageGaransiLetter"
                                accept="image/*"
                                v-model="uploadBuktiGaransiLetter.file_foto"
                                prepend-icon="mdi-image"
                                show-size
                                label="Foto"
                                :rules="uploadBuktiGaransiLetter.fotoRules"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogUploadGaransiLetter = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="doSaveUploadGaransiLetter"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-title>

              <v-row class="mx-6 mb-6">
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="search.nama"
                    append-icon="mdi-magnify"
                    label="Cari nama"
                    single-line
                    hide-details
                    v-on:keyup.enter="getDataFromApi"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    label="Event"
                    clearable
                    dense
                    outlined
                    v-model="search.selectedKategori"
                    :items="items_kategori"
                    item-text="name"
                    @change="getDataByKategori"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    label="Metode Pembayaran"
                    clearable
                    dense
                    outlined
                    v-model="search.selectedMetodePembayaran"
                    :items="items_metode_pembayaran"
                    item-text="name"
                    @change="getDataFromApi"
                  ></v-combobox>
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="search.invoice_no"
                    append-icon="mdi-magnify"
                    label="No Invoice"
                    single-line
                    hide-details
                    v-on:keyup.enter="getDataFromApi"
                    @change="changeInvoiceNo"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    label="Item"
                    clearable
                    dense
                    outlined
                    v-model="search.selectedItem"
                    :items="items_item"
                    item-text="nama"
                    @change="getDataFromApi"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    v-if="
                      search.selectedMetodePembayaran &&
                        search.selectedMetodePembayaran.id == 1
                    "
                    label="Status"
                    clearable
                    dense
                    outlined
                    v-model="search.selectedStatus"
                    :items="items_status"
                    item-text="name"
                    @change="getDataFromApi"
                  ></v-combobox>
                  <v-combobox
                    v-if="
                      search.selectedMetodePembayaran &&
                        search.selectedMetodePembayaran.id == 2
                    "
                    label="Status"
                    clearable
                    dense
                    outlined
                    v-model="search.selectedStatusSponsor"
                    :items="items_status_sponsor"
                    item-text="name"
                    @change="getDataFromApi"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    label="Status Profesi"
                    clearable
                    dense
                    outlined
                    v-model="search.selectedStatusPeserta"
                    :items="items_status_peserta"
                    item-text="value"
                    @change="getDataFromApi"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <div class="font-weight-bold">Total Nominal</div>
                  <div style="font-size: 18px">
                    Rp. {{ Intl.NumberFormat("id-ID").format(summary) }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    v-if="
                      search.selectedMetodePembayaran &&
                        search.selectedMetodePembayaran.id == 2
                    "
                    label="Sponsor"
                    clearable
                    dense
                    outlined
                    v-model="search.selectedSponsor"
                    :items="items_sponsor"
                    item-text="nama_perusahaan"
                    @change="getDataFromApi"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="list_data"
                :options.sync="options"
                :server-items-length="totalData"
                :loading="loading"
                :single-select="true"
                show-select
                class="elevation-1"
              >
                <template v-slot:[`item.created_at`]="{ item }">
                  {{ getCurrentDate(item.created_at) }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip :color="getColor(item.status)" dark>
                    {{ getStatus(item.status) }}
                  </v-chip>
                </template>
                <template v-slot:[`item.nominal`]="{ item }">
                  {{ new Intl.NumberFormat("id-ID").format(item.nominal) }}
                </template>
                <template v-slot:[`item.virtual_account`]="{ item }">
                  <div v-if="item.virtual_account">
                    {{ item.virtual_account }}
                  </div>
                </template>
                <template v-slot:[`item.nama_perusahaan`]="{ item }">
                  <div v-if="item.nama_perusahaan">
                    {{ item.nama_perusahaan }}
                  </div>
                  <div v-else>Pribadi</div>
                </template>
                <template v-slot:[`item.is_event_sent`]="{ item }">
                  <v-chip
                    v-if="item.metode_bayar == 1"
                    :color="getColorSplitted(item.is_event_sent)"
                    dark
                  >
                    {{ getSplittedType(item.is_event_sent) }}
                  </v-chip>
                </template>
                <template v-slot:[`item.bukti_transfer_sponsor`]="{ item }">
                  <v-btn
                    v-if="item.bukti_transfer_sponsor_original"
                    color="blue"
                    dark
                    class="mb-2"
                    @click="showFotoBuktiBayar(item.bukti_transfer_sponsor)"
                  >
                    View
                  </v-btn>
                </template>
                <template v-slot:[`item.bukti_bayar_sponsor`]="{ item }">
                  <v-btn
                    v-if="item.bukti_bayar_sponsor_original"
                    color="blue"
                    dark
                    class="mb-2"
                    @click="showFotoBuktiBayar(item.bukti_bayar_sponsor)"
                  >
                    View
                  </v-btn>
                </template>
                <template v-slot:[`item.status_bayar_sponsor`]="{ item }">
                  <v-chip
                    v-if="item.metode_bayar != 1 && item.status == 4"
                    :color="getColorSponsor(item.status_bayar_sponsor)"
                    dark
                  >
                    {{ getStatusSponsor(item.status_bayar_sponsor) }}
                  </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="d-flex flex-row">
                    <v-icon
                      v-if="
                        item.metode_bayar == 2 &&
                          (item.status == 2 || item.status == 3) &&
                          (item.is_approver == 1 || getRoleId == 1)
                      "
                      medium
                      class="mr-3"
                      @click="approveItem(item)"
                    >
                      mdi-check
                    </v-icon>
                    <v-icon
                      v-if="
                        item.metode_bayar == 2 &&
                          (item.status == 2 || item.status == 3) &&
                          (item.is_approver == 1 || getRoleId == 1)
                      "
                      medium
                      class="mr-3"
                      @click="rejectItem(item)"
                    >
                      mdi-close
                    </v-icon>
                    <!-- <v-icon
              v-if="item.metode_bayar == 1"
              medium
              class="mr-3"
              @click="downloaInvoiceItemPeserta(item.id)"
            >
              mdi-download
            </v-icon> -->
                    <v-menu v-if="item.metode_bayar == 1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon medium v-bind="attrs" v-on="on">
                          mdi-dots-vertical
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item @click="updateSponsor(item)">
                          <v-list-item-title>Update Sponsor</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          @click="downloaInvoiceItemPeserta(item.id)"
                        >
                          <v-list-item-title
                            >Download Invoice Lunas</v-list-item-title
                          >
                        </v-list-item>

                        <!-- <v-list-item @click="shareInvoiceWa(item.id)">
                  <v-list-item-title
                    >Share Invoice via Whatsapp</v-list-item-title
                  >
                </v-list-item> -->
                        <v-list-item @click="shareInvoiceWa2(item.id)">
                          <v-list-item-title
                            >Share Invoice via Whatsapp</v-list-item-title
                          >
                        </v-list-item>
                        <!-- <v-list-item @click="jadikanAnggota(item)">
                    <v-list-item-title>Jadikan Anggota</v-list-item-title>
                  </v-list-item> -->
                      </v-list>
                    </v-menu>
                    <!-- <v-icon
              v-if="item.metode_bayar == 2 && item.status == 4"
              medium
              class="mr-3"
              @click="downloaInvoiceItem(item.id)"
            >
              mdi-download
            </v-icon> -->

                    <v-menu v-if="item.metode_bayar != 1 && item.status == 4">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon medium v-bind="attrs" v-on="on">
                          mdi-dots-vertical
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item @click="downloaInvoiceItem(item.id)">
                          <v-list-item-title
                            >Download Invoice Penagihan
                            Sponsor</v-list-item-title
                          >
                        </v-list-item>

                        <v-list-item @click="openUploadGaransiLetter(item.id)">
                          <v-list-item-title
                            >Upload Garansi Letter</v-list-item-title
                          >
                        </v-list-item>

                        <v-list-item @click="openUploadBuktiBayar(item.id)">
                          <v-list-item-title
                            >Upload Bukti Bayar</v-list-item-title
                          >
                        </v-list-item>

                        <v-list-item
                          @click="downloaInvoiceItemPeserta(item.id)"
                        >
                          <v-list-item-title
                            >Download Invoice Lunas</v-list-item-title
                          >
                        </v-list-item>

                        <v-list-item @click="updateSponsor(item)">
                          <v-list-item-title>Update Sponsor</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="shareInvoiceWa2(item.id)">
                          <v-list-item-title
                            >Share Invoice via Whatsapp</v-list-item-title
                          >
                        </v-list-item>
                        <!-- <v-list-item @click="jadikanAnggota(item)">
                    <v-list-item-title>Jadikan Anggota</v-list-item-title>
                  </v-list-item> -->
                      </v-list>
                    </v-menu>
                  </div>
                  <!-- <v-icon
            medium
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
                </template>
              </v-data-table>
            </v-card>

            <v-card class="mt-4">
              <InvoiceItemPibComponent
                v-if="selected != undefined && selected.length > 0"
                :id="selected[0].id"
                ref="mychild"
                :key="childKey"
                :invoice_id="id"
              />
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import InvoicePibService from "../services/InvoicePibService";
import CommonService from "../services/CommonService";
import * as moment from "moment";
import store from "../store/index";
import MasterService from "../services/MasterService";
import InvoiceItemPibComponent from "./pib/InvoiceItemPib.vue";

export default {
  components: { InvoiceItemPibComponent },
  data: () => ({
    childKey: 0,
    dialogBuktiBayar: false,
    dialogAnggota: false,
    dialogApprove: false,
    dialogReject: false,
    dialogUpdate: false,
    dialogUploadBuktiBayar: false,
    dialogUploadGaransiLetter: false,
    dialogSponsor: false,
    dialogSponsorModal: false,
    isProgressBarShow: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Keuangan",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      tahun: "",
      invoice_no: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
      selectedSponsor: "",
      selectedMetodePembayaran: "",
      selectedStatusSponsor: "",
      selectedStatusPeserta: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false, width: "300px" },
      { text: "Invoice No", value: "invoice_no", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
      { text: "No VA", value: "virtual_account", sortable: false },
      {
        text: "Profesi",
        value: "profesi_pib",
        sortable: false,
        width: "200px",
      },
      { text: "Sponsor", value: "nama_perusahaan", sortable: false },
      {
        text: "Bukti Garansi Sponsor",
        value: "bukti_transfer_sponsor",
        sortable: false,
      },
      {
        text: "Bukti Bayar Sponsor",
        value: "bukti_bayar_sponsor",
        sortable: false,
      },

      { text: "Status", value: "status", sortable: false },
      {
        text: "Status Bayar Sponsor",
        value: "status_bayar_sponsor",
        sortable: false,
      },
    ],
    isFirst: false,
    totalData: 0,
    summary: 0,
    list_data: [],
    loading: true,
    options: {
      page: 1,
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    uploadBuktiBayarData: {
      id: "",
      FotoUrl: "",
      file_foto: null,
      fotoKey: 0,
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },

    uploadBuktiGaransiLetter: {
      id: "",
      FotoUrl: "",
      file_foto: null,
      fotoKey: 0,
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      selectedCabangTujuan: "",
      selectedKategori: "",
      selectedCabang: "",
      selectedItem: "",
      no_npa: "",
      cabang_asal_nama: "",
      cabang_asal: "",
      tahun: "",
      nominal: "",
      langsung_setujui: false,
      foto_url: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      nominal_pusat: 900000,
      nominal_cabang: "",
      sponsor: "",
      garansi_sponsor_id: "",
      nama_kontak_person_sponsor: "",
      hp_kontak_person_sponsor: "",
      email_sponsor: "",
      buktiFotoKey: 0,
      buktiFotoUrl: "",
      buktiFotoUrlOriginal: "",
      bukti_file_foto: null,
      user_id_sponsor: "",
      invoice_id: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
      tahunRules: [(v) => !!v || "Tahun harus diisi"],
      nominalRules: [(v) => !!v || "Nominal harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      sponsorRules: [(v) => !!v || "Sponsor harus diisi"],
      namaCpRules: [(v) => !!v || "Nama Kontak Person harus diisi"],
      hpRules: [(v) => !!v || "Hp Kontak Person harus diisi"],
      emailRules: [(v) => !!v || "Email Kontak Person harus diisi"],
      // buktiFotoRules: [(v) => checkFotoExists(v) || "Bukti Foto harus diisi"],

      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_jabatan: [],
    items_divisi: [],
    items_cabang: [],
    items_tahun: [],
    items_sponsor: [],
    items_status: [
      {
        id: 1,
        name: "Menunggu Pembayaran Peserta",
      },
      {
        id: 2,
        name: "Menunggu Konfirmasi Admin",
      },
      {
        id: 4,
        name: "Terverifikasi",
      },
    ],
    items_kategori: [
      {
        id: 1,
        name: "Simposium",
      },
      {
        id: 2,
        name: "Workshop",
      },
      {
        id: 3,
        name: "Paket Wisata",
      },
      {
        id: 4,
        name: "Hotel",
      },
      {
        id: 5,
        name: "Hybrid Simposium",
      },
    ],
    items_item: [],
    items_metode_pembayaran: [
      {
        id: 1,
        name: "Via IPaymu",
      },
      {
        id: 2,
        name: "Via Sponsor Garansi Letter",
      },
    ],
    items_status_sponsor: [
      {
        id: 1,
        name: "Belum Dibayar",
      },
      {
        id: 2,
        name: "Sudah Dibayar",
      },
    ],
    items_status_peserta: [],

    fotoBuktiBayar: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear(); i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.search.tahun = new Date().getFullYear();

    this.getListSponsor();
    this.getDataFromApi();
    this.getStatusProfesi();
  },
  methods: {
    getColorSponsor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "red";
        case 2:
          return "green";
        default:
          return "red";
      }
    },

    getStatusSponsor(id) {
      if (id == 1) {
        return "Belum Bayar";
      } else if (id == 2) {
        return "Sudah Bayar";
      }
    },

    changeInvoiceNo() {
      this.getDataFromApi();
    },
    checkFotoExists() {
      if (this.currentData.buktiFotoUrlOriginal) {
        return true;
      } else {
        if (this.currentData.bukti_file_foto) {
          return true;
        } else {
          return "Bukti Foto harus diisi";
        }
      }
    },
    loadImage: function(data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadBuktiBayarData.FotoUrl = e.target.result;
          this.uploadBuktiBayarData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.uploadBuktiBayarData.FotoUrl = "";
        this.uploadBuktiBayarData.file_foto = null;
      }
    },
    loadImageGaransiLetter: function(data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadBuktiGaransiLetter.FotoUrl = e.target.result;
          this.uploadBuktiGaransiLetter.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.uploadBuktiGaransiLetter.FotoUrl = "";
        this.uploadBuktiGaransiLetter.file_foto = null;
      }
    },
    getSplittedType(id) {
      if (id == 1) {
        return "Sudah";
      } else {
        return "Belum";
      }
    },
    getKategori(id) {
      if (id == 1) {
        return "Pembayaran Iuran pendaftaran Anggota";
      } else if (id == 2) {
        return "Pembayaran Tabsol";
      } else if (id == 3) {
        return "Pembayaran perpanjangan Iuran Anggota";
      } else if (id == 4) {
        return "Upload Data Iuran Lama";
      } else if (id == 5) {
        return "Pembayaran Pelatihan";
      } else if (id == 6) {
        return "Upload Tabsol Lama";
      }
    },

    getColorKategori(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "pink lighten-3";
        case 2:
          return "purple lighten-3";
        case 3:
          return "teal lighten-3";
        default:
          return "red lighten-3";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "red";
        case 2:
          return "orange";
        case 3:
          return "cyan";
        case 4:
          return "blue";
        default:
          return "red";
      }
    },

    getColorSplitted(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "green";
        default:
          return "red";
      }
    },

    getStatus(id) {
      if (id == 1) {
        return "Menunggu Pembayaran Anggota";
      } else if (id == 2) {
        return "Menunggu Konfirmasi Admin";
      } else if (id == 3) {
        return "Menunggu Konfirmasi Pusat";
      } else if (id == 4) {
        return "Terverifikasi";
      } else if (id == 5) {
        return "Rejected";
      } else if (id == 6) {
        return "Canceled";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    getDataByKategori() {
      this.getDataFromApi();

      if (this.search.selectedKategori)
        this.getKategoriItem(this.search.selectedKategori.id);
      else {
        this.items_item = [];
      }

      this.search.selectedItem = null;
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      console.log(this.options);

      this.loading = true;

      var status = "";
      var kategori = "";
      var item = "";
      var sponsor = "";
      var metodePembayaran = "";
      var statusSponsor = "";
      var statusProfesi = "";

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedItem) {
        item = this.search.selectedItem.id;
      }

      if (this.search.selectedSponsor) {
        sponsor = this.search.selectedSponsor.id;
      }

      if (this.search.selectedMetodePembayaran) {
        metodePembayaran = this.search.selectedMetodePembayaran.id;
      }

      if (this.search.selectedStatusSponsor) {
        statusSponsor = this.search.selectedStatusSponsor.id;
      }

      if (this.search.selectedStatusPeserta) {
        statusProfesi = this.search.selectedStatusPeserta.code;
      }

      InvoicePibService.invoiceList(
        page,
        itemsPerPage,
        this.search.nama,
        this.search.invoice_no,
        status,
        kategori,
        item,
        sponsor,
        metodePembayaran,
        statusSponsor,
        statusProfesi
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;

          console.log("summary");
          console.log(res.data.summary);

          this.summary = res.data.summary;
          this.loading = false;
          this.isFirst = true;

          this.selected = [];
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getKategoriItem(kategori) {
      InvoicePibService.getKategoriItem(kategori)
        .then((res) => {
          this.items_item = res.data.list_data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    doAdd: async function() {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.no_npa = "";
      this.currentData.tahun = "";
      this.currentData.nominal = "";
      this.currentData.foto_url = null;
      this.currentData.FotoUrl = "";
      this.currentData.langsung_setujui = false;
      this.currentData.selectedDivisi = "";
      this.currentData.selectedJabatan = "";
      this.currentData.cabang_asal_nama = "";
      this.currentData.cabang_asal = "";
      this.currentData.selectedKategori = null;
      this.currentData.langsung_setujui = false;

      console.log("do add");
    },

    doSave: async function() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        InvoicePibService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialogUpdate = false;
            CommonService.showSuccessToast("Update iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update iuran gagal. Coba lagi");
          });
      } else {
        InvoicePibService.create(this.currentData, this.currentData.foto_url)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah iuran gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogUpdate = true;
      this.currentData.nama = this.editedItem.user_nama;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.id = this.editedItem.id;
      this.currentData.cabang_asal = this.editedItem.cabang_asal;
      this.currentData.cabang_asal_nama = this.editedItem.cabang_asal_nama;

      this.items_cabang.forEach((entry) => {
        if (entry.id == this.editedItem.cabang_tujuan) {
          this.currentData.selectedCabangTujuan = entry;
        }
      });

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      InvoicePibService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pengurus berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pengurus gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function(file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },

    openAnggotaModal: async function() {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihAnggota(item) {
      this.dialogAnggota = false;
      console.log(item);
      this.currentData.nama = item.nama;
      this.currentData.user_id = item.id;
      this.currentData.cabang_asal = item.cabang_id;
      this.currentData.cabang_asal_nama = item.propinsi_nama;
      this.currentData.no_npa = item.no_npa;
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    rejectItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReject = true;
    },

    closeReject() {
      this.dialogReject = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      InvoicePibService.approvePib(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve invoice berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve invoice gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    rejectItemConfirm() {
      InvoicePibService.rejectPib(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeReject();

          console.log(res);
          this.$toast.success("Reject invoice berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Reject invoice gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    async getMasterIuran() {
      if (
        this.currentData.selectedKategori &&
        this.currentData.selectedKategori.id != 2 &&
        this.currentData.nama &&
        this.currentData.tahun
      ) {
        InvoicePibService.getMasterIuran(
          this.currentData.cabang_asal,
          this.currentData.tahun
        )
          .then((res) => {
            var invoice = res.data.invoice;
            if (invoice) this.currentData.nominal_cabang = invoice.nominal;
            else this.currentData.nominal_cabang = 0;

            this.currentData.nominal =
              this.currentData.nominal_cabang + this.currentData.nominal_pusat;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.currentData.nominal = 0;
      }
    },

    showFotoBuktiBayar(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },

    async changePropinsi() {
      this.getDataFromApi();
    },

    downloaInvoiceItem(invoiceId) {
      this.isProgressBarShow = true;

      InvoicePibService.downloadInvoice(invoiceId)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download invoice. Coba lagi");
          console.log(err);
        });
    },

    downloaInvoiceItemPeserta(invoiceId) {
      this.isProgressBarShow = true;

      InvoicePibService.downloadInvoicePeserta(invoiceId)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download invoice. Coba lagi");
          console.log(err);
        });
    },

    shareInvoiceWa(invoiceId) {
      this.isProgressBarShow = true;

      InvoicePibService.shareInvoiceWa(invoiceId)
        .then((res) => {
          console.log(res);
          this.isProgressBarShow = false;

          CommonService.showSuccessToast(
            "Invoice berhasil dishare ke Whatsapp"
          );
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal men-share invoice. Coba lagi");
          console.log(err);
        });
    },

    shareInvoiceWa2(invoiceId) {
      this.isProgressBarShow = true;

      InvoicePibService.shareInvoiceWa2(invoiceId)
        .then((res) => {
          console.log(res);
          this.isProgressBarShow = false;

          CommonService.showSuccessToast(
            "Invoice berhasil dishare ke Whatsapp"
          );
        })
        .catch((err) => {
          this.isProgressBarShow = false;

          if (err.response?.data?.code == 1) {
            CommonService.showErrorToast(err.response?.data?.message);
          } else {
            CommonService.showErrorToast("Gagal men-share invoice. Coba lagi");
          }

          console.log(err);
        });
    },

    openUploadBuktiBayar(invoiceId) {
      this.uploadBuktiBayarData.id = invoiceId;
      console.log(invoiceId);
      this.dialogUploadBuktiBayar = true;
    },

    openUploadGaransiLetter(invoiceId) {
      this.uploadBuktiGaransiLetter.id = invoiceId;
      console.log(invoiceId);
      this.dialogUploadGaransiLetter = true;
    },

    doSaveUploadBuktiBayar() {
      if (!this.$refs.form.validate()) {
        return;
      }

      InvoicePibService.uploadBuktiBayarData(this.uploadBuktiBayarData)
        .then((res) => {
          this.dialogUploadBuktiBayar = false;

          console.log(res);

          CommonService.showSuccessToast("Update Bukti Bayar berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Update Bukti Bayar gagal. Coba lagi");
        });
    },

    doSaveUploadGaransiLetter() {
      if (!this.$refs.form.validate()) {
        return;
      }

      InvoicePibService.uploadGaransiLetter(this.uploadBuktiGaransiLetter)
        .then((res) => {
          this.dialogUploadGaransiLetter = false;

          console.log(res);

          CommonService.showSuccessToast("Update Garansi Letter berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast(
            "Update Garansi Letter gagal. Coba lagi"
          );
        });
    },

    async getListSponsor() {
      MasterService.sponsorList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_sponsor = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getStatusProfesi() {
      MasterService.listStatusProfesi()
        .then((res) => {
          this.$nextTick(() => {
            this.items_status_peserta = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async updateSponsor(item) {
      console.log(item);
      this.currentData.user_id_sponsor = item.user_id;
      this.currentData.invoice_id = item.id;

      if (item.garansi_sponsor_id) {
        if (item.nama_kontak_person_sponsor) {
          this.currentData.sponsor = item.nama_perusahaan;
          this.currentData.garansi_sponsor_id = item.garansi_sponsor_id;
          this.currentData.nama_kontak_person_sponsor =
            item.nama_kontak_person_sponsor;
          this.currentData.email_sponsor = item.email_sponsor;
          this.currentData.hp_kontak_person_sponsor =
            item.hp_kontak_person_sponsor;
        } else {
          this.currentData.sponsor = item.nama_perusahaan;
          this.currentData.garansi_sponsor_id = item.garansi_sponsor_id;
          this.currentData.nama_kontak_person_sponsor =
            item.contact_person_vendor;
          this.currentData.email_sponsor = item.email_vendor;
          this.currentData.hp_kontak_person_sponsor = item.hp_vendor;
        }
      } else {
        this.currentData.sponsor = "";
        this.currentData.garansi_sponsor_id = "";
        this.currentData.nama_kontak_person_sponsor = "";
        this.currentData.email_sponsor = "";
        this.currentData.hp_kontak_person_sponsor = "";
      }

      this.currentData.buktiFotoUrl = item.bukti_bayar_sponsor;
      this.currentData.buktiFotoUrlOriginal = item.bukti_bayar_sponsor_original;
      this.dialogSponsor = true;
    },

    async doSaveSponsor() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.currentData.pib_id = this.id;
      InvoicePibService.updateSponsor(this.currentData)
        .then((res) => {
          console.log(res);
          this.dialog = false;

          CommonService.showSuccessToast("Update sponsor berhasil");

          this.getDataFromApi();
          this.dialogSponsor = false;
        })
        .catch((err) => {
          console.log(err);
          this.dialogSponsor = false;

          CommonService.showErrorToast("Update Sponsor gagal. Coba lagi");
        });
    },

    openSponsorModal: async function() {
      this.$refs.modalSponsor.getDataFromApi();
      this.dialogSponsorModal = true;
    },

    pilihSponsor(item) {
      this.dialogSponsorModal = false;
      console.log(item);
      this.currentData.sponsor = item.nama_perusahaan;
      this.currentData.garansi_sponsor_id = item.id;
      this.currentData.nama_kontak_person_sponsor = item.contact_person;
      this.currentData.email_sponsor = item.email;
      this.currentData.hp_kontak_person_sponsor = item.hp;
    },

    loadImageBuktiFoto: function(data) {
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.buktiFotoUrl = e.target.result;
          this.currentData.buktiFotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.buktiFotoUrl = "";
        this.currentData.bukti_file_foto = null;
      }
    },

    onDownloadInvoice() {
      this.isProgressBarShow = true;

      var status = "";
      var kategori = "";
      var item = "";
      var sponsor = "";
      var metodePembayaran = "";
      var statusSponsor = "";
      var statusProfesi = "";

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedItem) {
        item = this.search.selectedItem.id;
      }

      if (this.search.selectedSponsor) {
        sponsor = this.search.selectedSponsor.id;
      }

      if (this.search.selectedMetodePembayaran) {
        metodePembayaran = this.search.selectedMetodePembayaran.id;
      }

      if (this.search.selectedStatusSponsor) {
        statusSponsor = this.search.selectedStatusSponsor.id;
      }

      if (this.search.selectedStatusPeserta) {
        statusProfesi = this.search.selectedStatusPeserta.code;
      }

      InvoicePibService.downloadInvoiceReportKeuangan(
        this.search.nama,
        this.search.invoice_no,
        status,
        kategori,
        item,
        sponsor,
        metodePembayaran,
        statusSponsor,
        statusProfesi
      )
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },
  },

  computed: {
    nominalTotal() {
      var nominalCabang = 0;
      var nominalPusat = 0;

      if (this.nominal_cabang) {
        nominalCabang = this.nominal_cabang;
      }

      if (this.nominal_pusat) {
        nominalPusat = this.nominal_pusat;
      }

      return nominalPusat + nominalCabang;
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
