<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs>
          <v-tab>Pengurus Pusat</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-title> Edit Pengurus </v-card-title>
              <v-fab-transition v-if="getRoleId == 1">
                <v-btn
                  class="mt-8 mr-4"
                  v-if="isEditable == 0"
                  @click="changeEditable"
                  color="pink"
                  dark
                  absolute
                  top
                  right
                  fab
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4 pt-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Nama"
                          clearable
                          dense
                          outlined
                          v-model="bidangPengurus.selectedUser"
                          :items="items_user"
                          item-text="nama"
                          :disabled="isEditable == 0"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6" md="6">
                    <v-combobox
                      label="Bidang"
                      clearable
                      dense
                      outlined
                      v-model="bidangPengurus.selectedBidang"
                      :items="items_bidang"
                      item-text="nama"
                      @change="changeBidang"
                      :disabled="isEditable == 0"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-combobox
                      label="Divisi"
                      clearable
                      dense
                      outlined
                      v-model="bidangPengurus.selectedDivisi"
                      :items="items_divisi"
                      item-text="nama"
                      @click="getListDivisi"
                      :disabled="isEditable == 0"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6" md="6">
                    <v-combobox
                      label="Jabatan Mukisi"
                      clearable
                      dense
                      outlined
                      v-model="bidangPengurus.selectedJabatanMukisi"
                      :items="items_jabatan_mukisi"
                      item-text="nama"
                      @click="getListJabatanMukisi"
                      :disabled="isEditable == 0"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-combobox
                      label="Jabatan Mukisi"
                      clearable
                      dense
                      outlined
                      v-model="bidangPengurus.selectedJabatan"
                      :items="items_jabatan"
                      item-text="value"
                      :disabled="isEditable == 0"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <!-- <v-row dense>
                  <v-col cols="12" sm="6" md="6">
                    <v-combobox
                      label="Propinsi"
                      clearable
                      dense
                      outlined
                      v-model="bidangPengurus.selectedPropinsi"
                      :items="items_propinsi"
                      item-text="nama"
                      @change="changePropinsi"
                      :disabled="isEditable == 0"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-combobox
                      label="Kabupaten/Kota"
                      clearable
                      dense
                      outlined
                      v-model="bidangPengurus.selectedKabupaten"
                      :items="items_kabupaten"
                      item-text="nama"
                      @click="getListKabupaten"
                      :disabled="isEditable == 0"
                    ></v-combobox>
                  </v-col>
                </v-row> -->
                <v-row class="pb-4">
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      @click="doBatal"
                      style="margin-right: 10px"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import BidangPengurusService from "../services/BidangPengurusService";
import JabatanPengurusService from "../services/JabatanPengurusService";
import NakesService from "../services/NakesService";
import PengurusPusatService from "../services/PengurusPusatService";
import MasterService from "../services/MasterService";
import CommonService from "../services/CommonService";
import store from "../store/index";
import DivisiPengurusService from "../services/DivisiPengurusService";

export default {
  name: "PengurusPusatAdd",
  components: {},
  props: {
    id: String,
  },
  data: () => ({
    komunitas_key: 0,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Bidang Pengurus",
        disabled: false,
        exact: true,
        to: { name: "BidangPengurus" },
      },
      { text: "Edit Bidang Pengurus", disabled: true },
    ],
    valid: true,
    bidangPengurus: {
      id: null,
      nama: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      selectedBidang: "",
      selectedJabatan: "",
      selectedUser: "",
      selectedDivisi: "",
      selectedJabatanMukisi: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      propinsiRules: [(v) => !!v || "Propinsi harus diisi"],
      kabupatenRules: [(v) => !!v || "Kabupaten harus diisi"],
      bidangRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
    },
    items_bidang: [],
    items_jabatan: [],
    items_propinsi: [],
    items_kabupaten: [],
    items_user: [],
    items_divisi: [],
    items_jabatan_mukisi: [],
  }),
  mounted: async function () {
    this.getListBidang();
    this.getListJabatanMukisi();
    this.getListJabatan();
    this.getListDivisi();
    this.getListPropinsi();
    this.getListKabupaten();
    this.getListUser();
    this.getPengurusPusat();
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    getPengurusPusat() {
      PengurusPusatService.getPengurusPusat(this.id)
        .then((res) => {
          if (res.data.pengurus_pusat_wilayah.user_id) {
            this.items_user.forEach((entry) => {
              if (entry.id == res.data.pengurus_pusat_wilayah.user_id) {
                this.bidangPengurus.selectedUser = entry;
              }
            });
          }

          if (res.data.pengurus_pusat_wilayah.bidang_pengurus_id) {
            this.items_bidang.forEach((entry) => {
              if (
                entry.id == res.data.pengurus_pusat_wilayah.bidang_pengurus_id
              ) {
                this.bidangPengurus.selectedBidang = entry;
              }
            });

            this.getListDivisiMukisiAll(
              res.data.pengurus_pusat_wilayah.bidang_pengurus_id,
              res.data.pengurus_pusat_wilayah.divisi_pengurus_id
            );

            this.getListJabatanMukisiAll(
              res.data.pengurus_pusat_wilayah.bidang_pengurus_id,
              res.data.pengurus_pusat_wilayah.jabatan_pengurus_id
            );
          }

          if (res.data.pengurus_pusat_wilayah.jabatan_id) {
            this.items_jabatan.forEach((entry) => {
              if (entry.code == res.data.pengurus_pusat_wilayah.jabatan_id) {
                this.bidangPengurus.selectedJabatan = entry;
              }
            });
          }

          if (res.data.pengurus_pusat_wilayah.propinsi_id) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == res.data.pengurus_pusat_wilayah.propinsi_id) {
                this.bidangPengurus.selectedPropinsi = entry;
              }
            });

            this.getListKabupatenAll(
              res.data.pengurus_pusat_wilayah.propinsi_id,
              res.data.pengurus_pusat_wilayah.kabupaten_id
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListUser() {
      NakesService.nakesListPengurusPusatEdit(this.id)
        .then((res) => {
          this.items_user = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListBidang() {
      BidangPengurusService.bidangPengurusListAll()
        .then((res) => {
          this.items_bidang = res.data.list_data;
          console.log(this.items_bidang);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListJabatan() {
      MasterService.jabatanList()
        .then((res) => {
          this.items_jabatan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListJabatanMukisi() {
      JabatanPengurusService.jabatanPengurusListAll(
        this.bidangPengurus.selectedBidang.id
      )
        .then((res) => {
          this.items_jabatan_mukisi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDivisi() {
      DivisiPengurusService.divisiPengurusListAll(
        this.bidangPengurus.selectedBidang.id
      )
        .then((res) => {
          this.items_divisi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDivisiMukisiAll(bidangId, divisiId) {
      DivisiPengurusService.divisiPengurusListAll(bidangId)
        .then((res) => {
          this.items_divisi = res.data.list_data;
          if (divisiId) {
            this.items_divisi.forEach((entry) => {
              if (entry.id == divisiId) {
                this.bidangPengurus.selectedDivisi = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListJabatanMukisiAll(bidangId, jabatanId) {
      JabatanPengurusService.jabatanPengurusListAll(bidangId)
        .then((res) => {
          this.items_jabatan_mukisi = res.data.list_data;
          if (jabatanId) {
            this.items_jabatan_mukisi.forEach((entry) => {
              if (entry.id == jabatanId) {
                this.bidangPengurus.selectedJabatanMukisi = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
          console.log(this.items_propinsi);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.bidangPengurus.selectedKabupaten = null;
    },

    async changeBidang() {
      this.bidangPengurus.selectedJabatan = null;
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.bidangPengurus.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupatenAll(propinsiId, kabupatenId) {
      NakesService.kabupatenList(propinsiId)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
          if (kabupatenId) {
            this.items_kabupaten.forEach((entry) => {
              if (entry.id == kabupatenId) {
                this.bidangPengurus.selectedKabupaten = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBidangPengurus() {
      BidangPengurusService.getBidangPengurus(this.id)
        .then((res) => {
          this.bidangPengurus.nama = res.data.bidang_pengurus.nama;
          this.bidangPengurus.id = res.data.bidang_pengurus.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go();
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      PengurusPusatService.update(this.id, this.bidangPengurus)
        .then((res) => {
          console.log(res);
          this.isEditable = 0;
          CommonService.showSuccessToast("Update pengurus pusat berhasil");
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Update pengurus pusat gagal. Coba lagi"
          );
        });
    },
  },

  computed: {
    getRoleId() {
      console.log("nilai role");
      return store.getters.getUser.role_id;
    },
  },
};
</script>

<style scoped></style>
