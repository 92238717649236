import http from "../http-common";
/* eslint-disable */
class DashboardService {
  getDashboard(tahun, propinsi) {
    return http.get("/dashboard?tahun=" + tahun + "&cabang_id=" + propinsi, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new DashboardService();
