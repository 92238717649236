import http from "../http-common";
/* eslint-disable */
class GaransiSponsorPesertaService {
  pesertaList(page, itemPerPage, keyword, id, statusBayarSponsor) {
    return http.get(
      "/pib/garansi-sponsor-peserta/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword.nama +
        "&sponsor=" +
        keyword.sponsor +
        "&id=" +
        id +
        "&status_bayar_sponsor=" +
        statusBayarSponsor,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.pib_id) bodyFormData.append("pib_id", data.pib_id);
    if (data.garansi_sponsor_id)
      bodyFormData.append("garansi_sponsor_id", data.garansi_sponsor_id);
    if (data.user_id) bodyFormData.append("user_id", data.user_id);
    if (data.nama_kontak_person_sponsor)
      bodyFormData.append(
        "nama_kontak_person_sponsor",
        data.nama_kontak_person_sponsor
      );

    if (data.email_sponsor)
      bodyFormData.append("email_sponsor", data.email_sponsor);

    if (data.hp_kontak_person_sponsor)
      bodyFormData.append(
        "hp_kontak_person_sponsor",
        data.hp_kontak_person_sponsor
      );

    if (data.bukti_file_foto != null)
      bodyFormData.append("photo", data.bukti_file_foto);

    return http.post("/pib/garansi-sponsor-calon", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.pib_id) bodyFormData.append("pib_id", data.pib_id);
    if (data.garansi_sponsor_id)
      bodyFormData.append("garansi_sponsor_id", data.garansi_sponsor_id);
    if (data.user_id) bodyFormData.append("user_id", data.user_id);

    return http.put(`/pib/garansi-sponsor-sponsor/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/pib/garansi-sponsor-peserta/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadPesertaReport(search, pibId, status) {
    return http.get(
      "/report/garansi-letter-pib/download?keyword=" +
        search.nama +
        "&pib_id=" +
        pibId +
        "&sponsor=" +
        search.sponsor +
        "&status_bayar_sponsor=" +
        status,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }
}

export default new GaransiSponsorPesertaService();
