<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-row dense class="pl-4 pr-4 pt-4 ">
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Propinsi"
              clearable
              dense
              outlined
              v-model="selectedPropinsi"
              :items="items_propinsi"
              item-text="nama"
              @change="changePropinsi"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Kabupaten/Kota"
              clearable
              dense
              outlined
              v-model="selectedKabupaten"
              :items="items_kabupaten"
              item-text="nama"
              @click="getListKabupaten"
              @change="changeKabupaten"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              autocomplete="off"
              label="Jenis Anggota"
              clearable
              dense
              outlined
              v-model="jenis_user"
              :items="items_tipe"
              item-text="value"
              @change="changeJenisAnggota"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-card-title>
          Anggota
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <router-link
            v-if="getRoleId == 1"
            to="/fasyankes-add"
            style="text-decoration: none; color: inherit;"
          >
            <v-btn color="blue" dark class="mb-2">
              Tambah
            </v-btn>
          </router-link>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.nama`]="{ item }">
            <span v-if="item.type == 1">
              {{ item.user_nama }}
            </span>
            <span v-else>
              {{ item.nama }}
            </span>
          </template>
          <template v-slot:[`item.alamat`]="{ item }">
            <span v-if="item.type == 1">
              {{ item.user_address }}
            </span>
            <span v-else>
              {{ item.alamat }}
            </span>
          </template>
          <template v-slot:[`item.propinsi_nama`]="{ item }">
            <span v-if="item.type == 1">
              {{ item.user_propinsi }}
            </span>
            <span v-else>
              {{ item.propinsi_nama }}
            </span>
          </template>
          <template v-slot:[`item.kabupaten_nama`]="{ item }">
            <span v-if="item.type == 1">
              {{ item.user_kabupaten }}
            </span>
            <span v-else>
              {{ item.kabupaten_nama }}
            </span>
          </template>
          <template v-slot:[`item.telp`]="{ item }">
            <span v-if="item.type == 1">
              {{ item.user_phone }}
            </span>
            <span v-else>
              {{ item.telp }}
            </span>
          </template>
          <template v-slot:[`item.tanggal_awal`]="{ item }">
            {{ getCurrentDate(item.tanggal_awal) }}
          </template>
          <template v-slot:[`item.tanggal_akhir`]="{ item }">
            {{ getCurrentDate(item.tanggal_akhir) }}
          </template>
          <template v-slot:[`item.harga`]="{ item }">
            {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="getRoleId == 1 || getUserId == item.user_id"
              medium
              class="mr-3"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon v-if="getRoleId == 1" medium @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import FasyankesService from "../services/FasyankesService";
import CommonService from "../services/CommonService";
import * as moment from "moment";
import store from "../store/index";
import NakesService from "../services/NakesService";
import MasterService from "../services/MasterService";

export default {
  data: () => ({
    selectedPropinsi: "",
    selectedKabupaten: "",
    jenis_user: "",
    items_propinsi: [],
    items_kabupaten: [],
    items_tipe: [],
    breadcrumbs: [
      {
        text: "Data Anggota",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Alamat", value: "alamat", sortable: false },
      { text: "Propinsi", value: "propinsi_nama", sortable: false },
      { text: "Kabupaten", value: "kabupaten_nama", sortable: false },
      { text: "Telepon", value: "telp", sortable: false },
      { text: "Tipe", value: "type_value", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListTipeAnggota();
    this.getListPropinsi();
    this.getListKabupaten();
    this.getDataFromApi();
  },
  methods: {
    async getListTipeAnggota() {
      MasterService.tipeAnggotaList()
        .then((res) => {
          this.items_tipe = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.selectedKabupaten = null;
      this.getDataFromApi();
    },

    async changeKabupaten() {
      this.getDataFromApi();
    },

    changeJenisAnggota() {
      this.getDataFromApi();
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var propinsiId = null;
      var kabupatenId = null;
      var type = null;

      console.log(this.selectedPropinsi);
      if (this.selectedPropinsi) {
        propinsiId = this.selectedPropinsi.id;
      }

      if (this.selectedKabupaten) {
        kabupatenId = this.selectedKabupaten.id;
      }

      if (this.jenis_user) {
        type = this.jenis_user.code;
      }

      this.loading = true;
      FasyankesService.fasyankesList(
        page,
        itemsPerPage,
        this.search,
        propinsiId,
        kabupatenId,
        type
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      console.log("ini type");
      console.log(item.type);
      if (item.type == 1) {
        this.$router.push("/nakes-edit/" + item.user_id).catch((err) => {
          console.log(err);
        });
      } else {
        this.$router.push("/fasyankes-edit/" + item.id).catch((err) => {
          console.log(err);
        });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      FasyankesService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus Fasyankes berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus fasyankes gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getUserId() {
      return store.getters.getUser.id;
    },
  },
};
</script>
