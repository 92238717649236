<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card>
      <v-card-title>
        Peserta
        <v-spacer></v-spacer>
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search.nama"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="onDownloadAnggota()"
        >
          Download
        </v-btn>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ getCurrentDate(item.created_at) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ getStatus(item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.nominal`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.nominal) }}
        </template>
        <template v-slot:[`item.virtual_account`]="{ item }">
          <div v-if="item.virtual_account">
            {{ item.virtual_account }}
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import InasiaSimposiumInvoiceService from "../../services/inasia/InasiaSimposiumInvoiceService"
import CommonService from "../../services/CommonService"
import * as moment from "moment"
import store from "../../store/index"

export default {
  props: {
    id: Number,
    inasiaId: String,
    harga: Number,
  },
  components: {},
  data: () => ({
    childKey: 0,
    valid: true,
    breadcrumbs: [
      {
        text: "Data Iuran & TABSOL",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      tahun: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false },
      { text: "Invoice No", value: "invoice_no", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    isProgressBarShow: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY")
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options

      this.loading = true

      var kategori = "1"

      InasiaSimposiumInvoiceService.invoiceList(
        page,
        itemsPerPage,
        this.search.nama,
        kategori,
        "",
        this.inasiaId
      )
        .then((res) => {
          this.list_data = res.data.list_data
          this.totalData = res.data.total
          this.loading = false
          this.isFirst = true
        })
        .catch((err) => {
          this.loading = false
          this.isFirst = true
          console.log(err)
        })
    },

    onDownloadAnggota() {
      this.isProgressBarShow = true

      var kategori = 1

      InasiaSimposiumInvoiceService.downloadPesertaReport(
        this.search.nama,
        kategori,
        "",
        this.inasiaId
      )
        .then((res) => {
          window.open(res.data.file, "_blank")
          this.isProgressBarShow = false
        })
        .catch((err) => {
          this.isProgressBarShow = false
          CommonService.showErrorToast("Gagal download report. Coba lagi")
          console.log(err)
        })
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id
    },
    getIsVerified() {
      return this.isVerified
    },
    getUser() {
      return store.getters.getUser
    },
  },
}
</script>
