import http from "../../http-common";
/* eslint-disable */
class MasterIuranRekeningService {
  masterRekeningEventPusat(inasiaId) {
    return http.get("/inasia/master/rekening-event?inasia_id=" + inasiaId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  updateEvent(data) {
    var bodyFormData = new FormData();
    if (data.id != null) bodyFormData.append("inasia_id", data.id);
    if (data.no_rekening != null)
      bodyFormData.append("no_rekening", data.no_rekening);
    if (data.nama_rekening != null)
      bodyFormData.append("nama_rekening", data.nama_rekening);
    if (data.virtual_account_ipaymu != null)
      bodyFormData.append(
        "virtual_account_ipaymu",
        data.virtual_account_ipaymu
      );

    if (data.user_tanggal_lahir != null)
      bodyFormData.append("user_tanggal_lahir", data.user_tanggal_lahir);
    if (data.user_tempat_lahir != null)
      bodyFormData.append("user_tempat_lahir", data.user_tempat_lahir);

    if (data.selectedJenisKelamin && data.selectedJenisKelamin != undefined) {
      bodyFormData.append("user_jenis_kelamin", data.selectedJenisKelamin.code);
    }

    if (data.user_no_ktp != null)
      bodyFormData.append("user_no_ktp", data.user_no_ktp);

    if (data.user_npwp != null)
      bodyFormData.append("user_npwp", data.user_npwp);

    if (data.user_kode_pos != null)
      bodyFormData.append("user_kode_pos", data.user_kode_pos);

    if (data.user_alamat != null)
      bodyFormData.append("user_alamat", data.user_alamat);

    if (data.user_phone != null)
      bodyFormData.append("user_phone", data.user_phone);
    if (data.user_email != null)
      bodyFormData.append("user_email", data.user_email);

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("user_propinsi_id", data.selectedPropinsi.code);
      bodyFormData.append("user_propinsi_name", data.selectedPropinsi.name);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("user_kabupaten_id", data.selectedKabupaten.code);
      bodyFormData.append("user_kabupaten_name", data.selectedKabupaten.name);
    }

    if (data.selectedKecamatan && data.selectedKecamatan != undefined) {
      bodyFormData.append("user_kecamatan_id", data.selectedKecamatan.code);
      bodyFormData.append("user_kecamatan_name", data.selectedKecamatan.name);
    }

    if (data.selectedKelurahan && data.selectedKelurahan != undefined) {
      bodyFormData.append("user_kelurahan_id", data.selectedKelurahan.code);
      bodyFormData.append("user_kelurahan_name", data.selectedKelurahan.name);
    }

    if (data.selectedBank && data.selectedBank != undefined) {
      bodyFormData.append("bank_code", data.selectedBank.code);
      bodyFormData.append("bank_name", data.selectedBank.name);
    }

    if (data.user_buku_tabungan != null)
      bodyFormData.append("user_buku_tabungan", data.user_buku_tabungan);

    if (data.user_ktp != null) bodyFormData.append("user_ktp", data.user_ktp);
    if (data.user_selfie != null)
      bodyFormData.append("user_selfie", data.user_selfie);

    return http.put(`/inasia/master-rekening-event/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new MasterIuranRekeningService();
