import http from "../http-common";
/* eslint-disable */
class PelatihanService {
  pelatihanList(page, itemPerPage, keyword, kegiatanId) {
    return http.get(
      "/pelatihan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kegiatan_id=" +
        kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getKegiatan(id) {
    return http.get(`/kegiatan/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.kegiatan_id) bodyFormData.append("kegiatan_id", data.kegiatan_id);
    if (data.title) bodyFormData.append("title", data.title);
    if (data.description) bodyFormData.append("description", data.description);
    if (data.informasi) bodyFormData.append("informasi", data.informasi);
    if (data.prasyarat_khusus)
      bodyFormData.append("prasyarat_khusus", data.prasyarat_khusus);
    if (data.tanggal) bodyFormData.append("tanggal", data.tanggal);
    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.skp_narasumber)
      bodyFormData.append("skp_narasumber", data.skp_narasumber);
    if (data.skp_moderator)
      bodyFormData.append("skp_moderator", data.skp_moderator);
    if (data.skp_panitia) bodyFormData.append("skp_panitia", data.skp_panitia);
    if (data.skp_peserta) bodyFormData.append("skp_peserta", data.skp_peserta);
    if (data.nomor_skp) bodyFormData.append("nomor_skp", data.nomor_skp);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.post("/pelatihan", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.kegiatan_id) bodyFormData.append("kegiatan_id", data.kegiatan_id);
    if (data.title) bodyFormData.append("title", data.title);
    if (data.description) bodyFormData.append("description", data.description);
    if (data.informasi) bodyFormData.append("informasi", data.informasi);
    if (data.prasyarat_khusus)
      bodyFormData.append("prasyarat_khusus", data.prasyarat_khusus);
    if (data.tanggal) bodyFormData.append("tanggal", data.tanggal);
    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.skp_narasumber)
      bodyFormData.append("skp_narasumber", data.skp_narasumber);
    if (data.skp_moderator)
      bodyFormData.append("skp_moderator", data.skp_moderator);
    if (data.skp_panitia) bodyFormData.append("skp_panitia", data.skp_panitia);
    if (data.skp_peserta) bodyFormData.append("skp_peserta", data.skp_peserta);
    if (data.nomor_skp) bodyFormData.append("nomor_skp", data.nomor_skp);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/pelatihan/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  delete(id) {
    return http.delete(`/pelatihan/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new PelatihanService();
