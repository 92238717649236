<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Skala Penilaian SKP
          <v-spacer></v-spacer>

          <v-btn color="primary" dark class="mb-2 ml-4" @click="doAdd()">
            Tambah
          </v-btn>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Skala SKP</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-combobox
                          label="Jenis Kegiatan"
                          dense
                          outlined
                          required
                          v-model="currentData.jenisKegiatan"
                          :rules="currentData.jenisKegiatanRules"
                          :items="items_jenis_kegiatan"
                          item-text="value"
                          item-value="code"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-combobox
                          label="Jenis Peserta"
                          dense
                          outlined
                          required
                          v-model="currentData.jenisPeserta"
                          :rules="currentData.jenisPesertaRules"
                          :items="items_jenis_peserta"
                          item-text="value"
                          item-value="code"
                        ></v-combobox>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-combobox
                          label="Cakupan"
                          dense
                          outlined
                          required
                          v-model="currentData.cakupan"
                          :rules="currentData.cakupanRules"
                          :items="items_cakupan"
                          item-text="value"
                          item-value="code"
                        ></v-combobox>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-combobox
                          label="Jumlah Jam"
                          dense
                          outlined
                          required
                          v-model="currentData.jumlahJam"
                          :rules="currentData.jumlahJamRules"
                          :items="items_jumlah_jam"
                          item-text="value"
                          item-value="code"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          outlined
                          label="Skala Minimal"
                          required
                          type="number"
                          v-model="currentData.skalaMin"
                          :rules="currentData.skalaMinRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          outlined
                          label="Skala Maximal"
                          required
                          type="number"
                          v-model="currentData.skalaMax"
                          :rules="currentData.skalaMaxRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" md="3">
            <v-combobox
              label="Jenis Kegiatan"
              dense
              outlined
              clearable
              required
              v-model="filterJenisKegiatan"
              :items="items_jenis_kegiatan"
              item-text="value"
              item-value="code"
              @change="changeFilter"
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="3">
            <v-combobox
              label="Jenis Peserta"
              dense
              outlined
              clearable
              required
              v-model="filterJenisPeserta"
              :items="items_jenis_peserta"
              item-text="value"
              item-value="code"
              @change="changeFilter"
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="3">
            <v-combobox
              label="Cakupan"
              dense
              outlined
              clearable
              required
              v-model="filterCakupan"
              :items="items_cakupan"
              item-text="value"
              item-value="code"
              @change="changeFilter"
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="3">
            <v-combobox
              label="Jenis Jumlah Jam"
              dense
              outlined
              clearable
              required
              v-model="filterJumlahJam"
              :items="items_jumlah_jam"
              item-text="value"
              item-value="code"
              @change="changeFilter"
            ></v-combobox>
          </v-col>
        </v-row>

        <div class="pa-5">
          <v-data-table
            :headers="headers"
            :items="list_data"
            :options.sync="options"
            :server-items-length="totalData"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:[`item.skala_min`]="{ item }">
              <div
                v-if="item.skala_min === item.skala_max"
                class="d-flex flex-row"
              >
                {{ item.skala_min }}
              </div>
              <div v-else class="d-flex flex-row">
                {{ item.skala_min }} - {{ item.skala_max }}
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex flex-row">
                <v-icon medium class="mr-3" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon
                  v-if="item.data_status == 1"
                  medium
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import ManajemenSKPService from "../services/ManajemenSKPService";
import MasterService from "../services/MasterService";
import CommonService from "../services/CommonService";

export default {
  data: () => ({
    valid: true,
    breadcrumbs: [
      {
        text: "Data SKP",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedTotalSkp: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      {
        text: "Jensi Kegiatan",
        value: "jenis_kegiatan_value",
        sortable: false,
      },
      {
        text: "Jenis Peserta",
        value: "jenis_peserta_value",
        sortable: false,
      },
      {
        text: "Cakupan",
        value: "cakupan_value",
        sortable: false,
      },
      {
        text: "Jumlah Jam",
        value: "jenis_jumlah_jam_value",
        sortable: false,
      },
      { text: "Skala", value: "skala_min", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      jenisKegiatan: "",
      jenisPeserta: "",
      cakupan: "",
      jumlahJam: "",
      skalaMin: "",
      skalaMax: "",
      jenisKegiatanRules: [(v) => !!v || "Jenis Kegiatan harus diisi"],
      jenisPesertaRules: [(v) => !!v || "Jenis Peserta harus diisi"],
      cakupanRules: [(v) => !!v || "Cakupan harus diisi"],
      jumlahJamRules: [(v) => !!v || "Jumlah Jam harus diisi"],
      skalaMinRules: [(v) => !!v || "Skala harus diisi"],
      skalaMaxRules: [(v) => !!v || "Skala harus diisi"],
    },
    items_jenis_kegiatan: [],
    items_jenis_peserta: [],
    items_cakupan: [],
    items_jumlah_jam: [],
    filterJenisKegiatan: "",
    filterJenisPeserta: "",
    filterCakupan: "",
    filterJumlahJam: "",

    items_total_skp: [
      {
        id: 1,
        nama: "Kurang Dari 250 SKP",
      },
      { id: 2, nama: "Lebih Dari atau Sama Dengan 250 SKP" },
    ],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getJenisKegiatan();
    this.getJenisPeserta();
    this.getCakupanKegiatan();
    this.getJumlahJamKegiatan();
    this.getDataFromApi();
  },
  methods: {
    async changeFilter() {
      this.getDataFromApi();
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var jenis_kegiatan = "";
      var jenis_peserta = "";
      var cakupan = "";
      var jenis_jumlah_jam = "";

      if (this.filterJenisKegiatan) {
        jenis_kegiatan = this.filterJenisKegiatan.code;
      }
      if (this.filterJenisPeserta) {
        jenis_peserta = this.filterJenisPeserta.code;
      }
      if (this.filterCakupan) {
        cakupan = this.filterCakupan.code;
      }
      if (this.filterJumlahJam) {
        jenis_jumlah_jam = this.filterJumlahJam.code;
      }

      this.loading = true;
      ManajemenSKPService.skpList(
        page,
        itemsPerPage,
        jenis_kegiatan,
        jenis_peserta,
        cakupan,
        jenis_jumlah_jam
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async getJenisKegiatan() {
      MasterService.listJenisKegiatanSkp()
        .then((res) => {
          this.items_jenis_kegiatan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getJenisPeserta() {
      MasterService.listJenisPesertaKegiatanSkp()
        .then((res) => {
          this.items_jenis_peserta = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getCakupanKegiatan() {
      MasterService.listCakupanKegiatanSkp()
        .then((res) => {
          this.items_cakupan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getJumlahJamKegiatan() {
      MasterService.listJenisJumlahJamKegiatanSkp()
        .then((res) => {
          this.items_jumlah_jam = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.jenisKegiatan = "";
      this.currentData.jenisPeserta = "";
      this.currentData.cakupan = "";
      this.currentData.jumlahJam = "";
      this.currentData.skalaMin = "";
      this.currentData.skalaMax = "";
      this.currentData.id = null;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        ManajemenSKPService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Skala SKP berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah Skala SKP gagal. Coba lagi");
          });
      } else {
        ManajemenSKPService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update Skala SKP berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update Skala SKP gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      // this.dialog = true;

      this.items_jenis_kegiatan.forEach((entry) => {
        if (entry.code == item.jenis_kegiatan) {
          this.currentData.jenisKegiatan = entry;
        }
      });
      this.items_jenis_peserta.forEach((entry) => {
        if (entry.code == item.jenis_peserta) {
          this.currentData.jenisPeserta = entry;
        }
      });
      this.items_cakupan.forEach((entry) => {
        if (entry.code == item.cakupan) {
          this.currentData.cakupan = entry;
        }
      });
      this.items_jumlah_jam.forEach((entry) => {
        if (entry.code == item.jenis_jumlah_jam) {
          this.currentData.jumlahJam = entry;
        }
      });
      this.currentData.skalaMin = this.editedItem.skala_min;
      this.currentData.skalaMax = this.editedItem.skala_max;
      this.currentData.id = this.editedItem.id;
      this.dialog = true;
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      ManajemenSKPService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus Skala SKP berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus Skala SKP gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
