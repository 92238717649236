<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title>
          Informasi Klinik

          <v-spacer />
          <v-btn
            class="mr-4"
            v-if="isEditable == 0"
            @click="changeEditable"
            color="pink"
            dark
          >
            Edit Klinik
          </v-btn>
          <router-link
            to="/klinik-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="primary" dark class=""> Tambah Klinik </v-btn>
          </router-link>
        </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Nama Klinik"
                    outlined
                    dense
                    v-model="userData.nama"
                    required
                    :rules="userData.namaRules"
                    :disabled="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="No Telepon"
                    outlined
                    dense
                    v-model="userData.phone"
                    :disabled="isEditable == 0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                    v-model="userData.email"
                    :disabled="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Propinsi"
                clearable
                dense
                outlined
                v-model="userData.selectedPropinsi"
                :items="items_propinsi"
                item-text="name"
                @change="changePropinsi"
                :disabled="isEditable == 0"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Kabupaten/Kota"
                clearable
                dense
                outlined
                v-model="userData.selectedKabupaten"
                :items="items_kabupaten"
                item-text="name"
                @click="getListKabupaten"
                @change="changeKabupaten"
                :disabled="isEditable == 0"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Kecamatan"
                clearable
                dense
                outlined
                v-model="userData.selectedKecamatan"
                :items="items_kecamatan"
                item-text="name"
                @click="getListKecamatan"
                @change="changeKecamatan"
                :disabled="isEditable == 0"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Kelurahan"
                clearable
                dense
                outlined
                v-model="userData.selectedKelurahan"
                :items="items_kelurahan"
                item-text="name"
                @click="getListKelurahan"
                :disabled="isEditable == 0"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Alamat Lengkap"
                    outlined
                    dense
                    v-model="userData.alamat"
                    :disabled="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                v-if="isEditable == 1"
                @click="doBatal"
                style="margin-right: 10px"
                >Batal</v-btn
              >
              <v-btn
                v-if="isEditable == 1"
                dark
                color="primary"
                type="button"
                @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KlinikService from "../services/KlinikService";
import CommonService from "../services/CommonService";
import store from "../store/index";
import NakesService from "../services/NakesService";
import JenisRSService from "../services/JenisRSService";
import KelasRSService from "../services/KelasRSService";

export default {
  name: "KlinikEdit",
  components: {},
  props: {
    id: String,
  },
  data: () => ({
    isEditable: 0,
    komunitas_key: 0,
    breadcrumbs: [
      {
        text: "Data Klinik",
        disabled: false,
        exact: true,
        to: { name: "Klinik" },
      },
      { text: "Update Klinik", disabled: true },
    ],
    valid: true,
    userData: {
      nama: "",
      email: "",
      phone: "",
      alamat: "",
      latitude: "",
      longitude: "",

      gelar_belakang: "",

      selectedPropinsi: "",
      selectedKabupaten: "",
      selectedKecamatan: "",
      selectedKelurahan: "",
      selectedJenisRs: "",
      selectedKelasRs: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
    },
    items_propinsi: [],
    items_kabupaten: [],
    items_kecamatan: [],
    items_kelurahan: [],
    items_jenis_rs: [],
    items_kelas_rs: [],
    result: {},
    loading: true,
  }),
  mounted: async function () {
    this.getListPropinsi();
    this.getListJenisRs();
    this.getListKelasRs();
    this.getRumahSakit();
  },
  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getRolesId() {
      return store.getters.getUser.roles_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUserData() {
      return store.getters.getUser;
    },
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.userData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatan() {
      NakesService.kecamatanList(this.userData.selectedKabupaten.id)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahan() {
      NakesService.kelurahanList(this.userData.selectedKecamatan.id)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListJenisRs() {
      JenisRSService.jenisRSAll()
        .then((res) => {
          this.items_jenis_rs = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelasRs() {
      KelasRSService.kelasRSAll()
        .then((res) => {
          this.items_kelas_rs = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.userData.selectedKabupaten = null;
      this.userData.selectedKecamatan = null;
      this.userData.selectedKelurahan = null;
    },

    async changeKabupaten() {
      this.userData.selectedKecamatan = null;
      this.userData.selectedKelurahan = null;
    },

    async changeKecamatan() {
      this.userData.selectedKelurahan = null;
    },

    async getListKabupatenAll(propinsiId, kabupatenId) {
      NakesService.kabupatenList(propinsiId)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
          if (kabupatenId) {
            this.items_kabupaten.forEach((entry) => {
              if (entry.id == kabupatenId) {
                this.userData.selectedKabupaten = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatanAll(kabupatenId, kecamatanId) {
      NakesService.kecamatanList(kabupatenId)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;

          this.items_kecamatan.forEach((entry) => {
            if (entry.id == kecamatanId) {
              this.userData.selectedKecamatan = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahanAll(kecamatanId, kelurahanId) {
      NakesService.kelurahanList(kecamatanId)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;

          this.items_kelurahan.forEach((entry) => {
            if (entry.id == kelurahanId) {
              this.userData.selectedKelurahan = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getRumahSakit() {
      KlinikService.getKlinik(this.id)
        .then((res) => {
          this.userData.nama = res.data.rumahsakit.nama;
          this.userData.phone = res.data.rumahsakit.phone;
          this.userData.email = res.data.rumahsakit.email;
          this.userData.alamat = res.data.rumahsakit.alamat;
          this.userData.latitude = res.data.rumahsakit.latitude;
          this.userData.longitude = res.data.rumahsakit.longitude;

          this.$nextTick(() => {
            if (res.data.rumahsakit.jenis_rs_id) {
              this.items_jenis_rs.forEach((entry) => {
                if (entry.code == res.data.rumahsakit.jenis_rs_id) {
                  this.userData.selectedJenisRs = entry;
                }
              });
            }

            if (res.data.rumahsakit.kelas_rs_id) {
              this.items_kelas_rs.forEach((entry) => {
                if (entry.code == res.data.rumahsakit.kelas_rs_id) {
                  this.userData.selectedKelasRs = entry;
                }
              });
            }

            if (res.data.rumahsakit.propinsi_id) {
              this.items_propinsi.forEach((entry) => {
                if (entry.id == res.data.rumahsakit.propinsi_id) {
                  this.userData.selectedPropinsi = entry;
                }
              });

              this.getListKabupatenAll(
                res.data.rumahsakit.propinsi_id,
                res.data.rumahsakit.kabupaten_id
              );
            }

            if (res.data.rumahsakit.kecamatan_id) {
              this.getListKecamatanAll(
                res.data.rumahsakit.kabupaten_id,
                res.data.rumahsakit.kecamatan_id
              );
            }

            if (res.data.rumahsakit.kelurahan_id) {
              this.getListKelurahanAll(
                res.data.rumahsakit.kecamatan_id,
                res.data.rumahsakit.kelurahan_id
              );
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go();
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      this.userData.id = this.id;
      KlinikService.update(this.userData)
        .then((res) => {
          this.isEditable = 0;
          console.log(res);
          CommonService.showSuccessToast("Update Klinik berhasil");
          this.getRumahSakit();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Update Klinik gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped>
</style>
