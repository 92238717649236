import http from "../http-common";
/* eslint-disable */
class MasterIuranService {
  iuranList(page, itemPerPage, keyword, tahun, kategori, status, propinsi) {
    return http.get(
      "/iuran-nominal/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&tahun=" +
        tahun +
        "&kategori=" +
        kategori +
        "&status=" +
        status +
        "&cabang_id=" +
        propinsi,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getInvoice(id) {
    return http.get(`/iuran-nominal/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, photo) {
    var bodyFormData = new FormData();
    if (data.tahun) bodyFormData.append("tahun", data.tahun);
    if (data.nominal) bodyFormData.append("nominal", data.nominal);

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("cabang_id", data.selectedPropinsi.id);
    }

    return http.post("/iuran-nominal", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data) {
    console.log("nilai");
    console.log(data);
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    if (data.tahun) bodyFormData.append("tahun", data.tahun);
    if (data.nominal) bodyFormData.append("nominal", data.nominal);

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("cabang_id", data.selectedPropinsi.id);
    }

    return http.put(`/iuran-nominal/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/iuran-nominal/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new MasterIuranService();
