import http from "../../http-common";

/* eslint-disable */
class InasiaVenueService {
  getList(page, itemPerPage, keyword, id) {
    return http.get(
      "/inasia/venue/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.alamat) bodyFormData.append("alamat", data.alamat);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);

    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.pic_nama) bodyFormData.append("pic_nama", data.pic_nama);
    if (data.pic_phone) bodyFormData.append("pic_phone", data.pic_phone);
    if (data.link_google_map)
      bodyFormData.append("link_google_map", data.link_google_map);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.post("/inasia/venue", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);

    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.alamat) bodyFormData.append("alamat", data.alamat);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);

    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.pic_nama) bodyFormData.append("pic_nama", data.pic_nama);
    if (data.pic_phone) bodyFormData.append("pic_phone", data.pic_phone);
    if (data.link_google_map)
      bodyFormData.append("link_google_map", data.link_google_map);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/inasia/venue/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/inasia/venue/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InasiaVenueService();
