<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Advokasi
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn color="purple" dark class="mb-2" @click="doAdd">
            Tambah
          </v-btn>

          <v-dialog v-model="dialog" persistent max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Advokasi</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Nama"
                          required
                          v-model="currentData.nama"
                          :rules="currentData.namaRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="No Telepon"
                          required
                          v-model="currentData.no_telepon"
                          :rules="currentData.noTelpRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="No KTP"
                          required
                          v-model="currentData.no_ktp"
                          :rules="currentData.ktpRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <vue-editor
                          v-model="currentData.pengaduan"
                          useCustomImageHandler
                          @image-added="handleImageAdded"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Status"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedStatus"
                          :items="items_status"
                          item-text="value"
                          :rules="currentData.statusRules"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          v-model="selected"
          :single-select="true"
          show-select
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:[`item.foto_url`]="{ item }">
            <v-img
              :src="item.foto_url"
              contain
              height="100"
              width="200"
            ></v-img>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ getCurrentDate(item.created_at) }}</span>
          </template>
          <template v-slot:[`item.pengaduan`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-list-status
            </v-icon>
          </template>
          <template v-slot:[`item.data_status`]="{ item }">
            <v-chip :color="getColor(item.data_status)" dark>
              {{ getIsHide(item.data_status) }}
            </v-chip>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="getColor(item.status)" dark>
              {{ getStatus(item.status) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>

      <v-card class="mt-4">
        <BeritaFotoComponent
          v-if="selected != undefined && selected.length > 0"
          :id="selected[0].id"
          ref="mychild"
          :key="childKey"
        />
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import AdvokasiService from "../../services/web_content/AdvokasiService";
import CommonService from "../../services/CommonService";
import * as moment from "moment";
import { VueEditor } from "vue2-editor";
import BeritaFotoComponent from "./BeritaFotoList.vue";
import EventService from "../../services/EventService";
import { uuid } from "vue-uuid";

export default {
  components: {
    BeritaFotoComponent,
    VueEditor,
  },
  data: () => ({
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Advokasi",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No Telepon", value: "no_telepon", sortable: false },
      { text: "No KTP", value: "no_ktp", sortable: false },
      { text: "Advokasi", value: "pengaduan", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      uuid: uuid.v4(),
      id: null,
      nama: "",
      no_telepon: "",
      pengaduan: "",
      selectedStatus: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Advokasi harus diisi"],
      statusRules: [(v) => !!v || "Status harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_status: [
      {
        text: 1,
        value: "Diajukan",
      },
      {
        text: 2,
        value: "Diproses",
      },
      {
        text: 3,
        value: "Ditindak",
      },
    ],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "blue";
        case 2:
          return "green";
        default:
          return "cyan";
      }
    },

    getStatus(dataStatus) {
      console.log(dataStatus);
      if (dataStatus == 1) {
        return "Diajukan";
      } else if (dataStatus == 2) {
        return "Diproses";
      } else {
        return "Ditindak";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      AdvokasiService.advokasiList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.no_telepon = "";
      this.currentData.no_ktp = "";
      this.currentData.pengaduan = "";
      this.currentData.selectedStatus = "";

      console.log("do add");
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        AdvokasiService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update advokasi berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update advokasi gagal. Coba lagi");
          });
      } else {
        AdvokasiService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah advokasi berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah advokasi gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.no_telepon = this.editedItem.no_telepon;
      this.currentData.no_ktp = this.editedItem.no_ktp;
      this.currentData.pengaduan = this.editedItem.pengaduan;
      this.currentData.id = this.editedItem.id;

      this.items_status.forEach((entry) => {
        if (entry.text == this.editedItem.status) {
          this.currentData.selectedStatus = entry;
        }
      });

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      AdvokasiService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus advokasi berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus advokasi gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function (file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },

    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      //1 = kategori event
      EventService.uploadFotoKonten(this.currentData.uuid, file, 4)
        .then((res) => {
          console.log(res);

          Editor.insertEmbed(cursorLocation, "image", res.data.event.foto_url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Gagal menambah foto. Coba lagi");
        });
    },
  },
};
</script>
