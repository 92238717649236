import { render, staticRenderFns } from "./TentangKami.vue?vue&type=template&id=49d0ab16&scoped=true"
import script from "./TentangKami.vue?vue&type=script&lang=js"
export * from "./TentangKami.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d0ab16",
  null
  
)

export default component.exports