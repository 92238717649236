import http from "../http-common";
/* eslint-disable */
class JabatanPengurusService {
  jabatanPengurusList(page, itemPerPage, keyword, bidangId) {
    return http.get(
      "/jabatan-pengurus/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&bidang_id=" +
        bidangId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  jabatanPengurusListAll(bidangId) {
    return http.get("/jabatan-pengurus/list/all" + "?bidang_id=" + bidangId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  getJabatanPengurus(id) {
    return http.get(`/jabatan-pengurus/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("bidang_id", data.bidang_id);
    bodyFormData.append("urutan", data.urutan);

    return http.post("/jabatan-pengurus", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("bidang_id", data.bidang_id);
    bodyFormData.append("urutan", data.urutan);

    return http.put(`/jabatan-pengurus/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/jabatan-pengurus/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new JabatanPengurusService();
