import http from "../http-common";
/* eslint-disable */
class PibService {
  pibList(page, itemPerPage, keyword) {
    return http.get(
      "/pib/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getPib(id) {
    return http.get(`/pib/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, photo) {
    var bodyFormData = new FormData();
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    if (data.simposium_lokasi)
      bodyFormData.append("simposium_lokasi", data.simposium_lokasi);
    if (data.simposium_tanggal_awal)
      bodyFormData.append(
        "simposium_tanggal_awal",
        data.simposium_tanggal_awal
      );
    if (data.simposium_tanggal_akhir)
      bodyFormData.append(
        "simposium_tanggal_akhir",
        data.simposium_tanggal_akhir
      );
    if (data.simposium_kuota)
      bodyFormData.append("simposium_kuota", data.simposium_kuota);
    if (data.simposium_jam_awal)
      bodyFormData.append("simposium_jam_awal", data.simposium_jam_awal);
    if (data.simposium_jam_akhir)
      bodyFormData.append("simposium_jam_akhir", data.simposium_jam_akhir);
    if (data.simposium_peserta)
      bodyFormData.append("simposium_peserta", data.simposium_peserta);
    if (data.venue_nama) bodyFormData.append("venue_nama", data.venue_nama);
    if (data.venue_alamat)
      bodyFormData.append("venue_alamat", data.venue_alamat);
    if (data.venue_tanggal_awal)
      bodyFormData.append("venue_tanggal_awal", data.venue_tanggal_awal);
    if (data.venue_tanggal_akhir)
      bodyFormData.append("venue_tanggal_akhir", data.venue_tanggal_akhir);
    if (data.venue_jam_awal)
      bodyFormData.append("venue_jam_awal", data.venue_jam_awal);
    if (data.venue_jam_akhir)
      bodyFormData.append("venue_jam_akhir", data.venue_jam_akhir);
    if (data.venue_pic_nama)
      bodyFormData.append("venue_pic_nama", data.venue_pic_nama);
    if (data.venue_pic_phone)
      bodyFormData.append("venue_pic_phone", data.venue_pic_phone);
    if (data.venue_link_google_map)
      bodyFormData.append("venue_link_google_map", data.venue_link_google_map);

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/pib", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data, photo, hybridPhoto) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    if (data.simposium_lokasi)
      bodyFormData.append("simposium_lokasi", data.simposium_lokasi);
    if (data.simposium_tanggal_awal)
      bodyFormData.append(
        "simposium_tanggal_awal",
        data.simposium_tanggal_awal
      );
    if (data.simposium_tanggal_akhir)
      bodyFormData.append(
        "simposium_tanggal_akhir",
        data.simposium_tanggal_akhir
      );
    if (data.simposium_kuota)
      bodyFormData.append("simposium_kuota", data.simposium_kuota);
    if (data.simposium_jam_awal)
      bodyFormData.append("simposium_jam_awal", data.simposium_jam_awal);
    if (data.simposium_jam_akhir)
      bodyFormData.append("simposium_jam_akhir", data.simposium_jam_akhir);
    if (data.simposium_peserta)
      bodyFormData.append("simposium_peserta", data.simposium_peserta);
    if (data.simposium_harga)
      bodyFormData.append("simposium_harga", data.simposium_harga);
    if (data.venue_nama) bodyFormData.append("venue_nama", data.venue_nama);
    if (data.venue_alamat)
      bodyFormData.append("venue_alamat", data.venue_alamat);
    if (data.venue_tanggal_awal)
      bodyFormData.append("venue_tanggal_awal", data.venue_tanggal_awal);
    if (data.venue_tanggal_akhir)
      bodyFormData.append("venue_tanggal_akhir", data.venue_tanggal_akhir);
    if (data.venue_jam_awal)
      bodyFormData.append("venue_jam_awal", data.venue_jam_awal);

    if (!data.is_venue_jam_akhir) {
      if (data.venue_jam_akhir)
        bodyFormData.append("venue_jam_akhir", data.venue_jam_akhir);
    }

    if (data.venue_pic_nama)
      bodyFormData.append("venue_pic_nama", data.venue_pic_nama);
    if (data.venue_pic_phone)
      bodyFormData.append("venue_pic_phone", data.venue_pic_phone);
    if (data.venue_link_google_map)
      bodyFormData.append("venue_link_google_map", data.venue_link_google_map);

    if (data.sambutan_kalimat)
      bodyFormData.append("sambutan_kalimat", data.sambutan_kalimat);

    if (data.simposium_hybrid_lokasi)
      bodyFormData.append(
        "simposium_hybrid_lokasi",
        data.simposium_hybrid_lokasi
      );
    if (data.simposium_hybrid_tanggal_awal)
      bodyFormData.append(
        "simposium_hybrid_tanggal_awal",
        data.simposium_hybrid_tanggal_awal
      );
    if (data.simposium_hybrid_tanggal_akhir)
      bodyFormData.append(
        "simposium_hybrid_tanggal_akhir",
        data.simposium_hybrid_tanggal_akhir
      );
    if (data.simposium_hybrid_kuota)
      bodyFormData.append(
        "simposium_hybrid_kuota",
        data.simposium_hybrid_kuota
      );
    if (data.simposium_hybrid_jam_awal)
      bodyFormData.append(
        "simposium_hybrid_jam_awal",
        data.simposium_hybrid_jam_awal
      );
    if (data.simposium_hybrid_jam_akhir)
      bodyFormData.append(
        "simposium_hybrid_jam_akhir",
        data.simposium_hybrid_jam_akhir
      );
    if (data.simposium_hybrid_peserta)
      bodyFormData.append(
        "simposium_hybrid_peserta",
        data.simposium_hybrid_peserta
      );
    if (data.simposium_hybrid_harga)
      bodyFormData.append(
        "simposium_hybrid_harga",
        data.simposium_hybrid_harga
      );

    if (photo != null) bodyFormData.append("photo", photo);
    if (hybridPhoto != null)
      bodyFormData.append("simposium_hybrid_photo", hybridPhoto);

    if (data.file_foto_sambutan != null)
      bodyFormData.append("sambutan_photo", data.file_foto_sambutan);

    return http.put(`/pib/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  updateEPoster(id, data, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    if (data.eposter_judul)
      bodyFormData.append("eposter_judul", data.eposter_judul);
    if (data.eposter_deskripsi)
      bodyFormData.append("eposter_deskripsi", data.eposter_deskripsi);
    if (data.eposter_tanggal_awal)
      bodyFormData.append("eposter_tanggal_awal", data.eposter_tanggal_awal);
    if (data.eposter_tanggal_akhir)
      bodyFormData.append("eposter_tanggal_akhir", data.eposter_tanggal_akhir);
    if (data.eposter_cp) bodyFormData.append("eposter_cp", data.eposter_cp);

    if (photo != null) bodyFormData.append("eposter_photo", photo);

    return http.put(`/pib/eposter/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/pib/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PibService();
