import http from "../http-common";
/* eslint-disable */
class PekerjaanService {
  pekerjaanList(page, itemPerPage, keyword, userId) {
    return http.get(
      "/nakes/pekerjaan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("user_id", data.user_id);
    bodyFormData.append("nama_perusahaan", data.nama_perusahaan);
    bodyFormData.append("jabatan", data.jabatan);
    bodyFormData.append("nik_nip", data.nik_nip);
    bodyFormData.append("sip", data.sip);
    bodyFormData.append("alamat_perusahaan", data.alamat_perusahaan);
    bodyFormData.append("bidang_pekerjaan", data.bidang_pekerjaan);

    if (data.rumah_sakit_id)
      bodyFormData.append("rumah_sakit_id", data.rumah_sakit_id);

    if (data.mulai_kerja)
      bodyFormData.append("mulai_kerja", data.mulai_kerja + "-01");

    if (data.sampai_saat_ini) {
    } else {
      if (data.akhir_kerja) {
        bodyFormData.append("akhir_kerja", data.akhir_kerja + "-01");
      } else {
      }
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    if (data.dokumen_sip != null)
      bodyFormData.append("dokumen_sip", data.dokumen_sip);

    return http.post("/nakes/pekerjaan", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("user_id", data.user_id);
    bodyFormData.append("nama_perusahaan", data.nama_perusahaan);
    bodyFormData.append("jabatan", data.jabatan);
    bodyFormData.append("nik_nip", data.nik_nip);
    bodyFormData.append("sip", data.sip);
    bodyFormData.append("alamat_perusahaan", data.alamat_perusahaan);
    bodyFormData.append("bidang_pekerjaan", data.bidang_pekerjaan);

    if (data.rumah_sakit_id)
      bodyFormData.append("rumah_sakit_id", data.rumah_sakit_id);

    if (data.mulai_kerja)
      bodyFormData.append("mulai_kerja", data.mulai_kerja + "-01");

    if (data.sampai_saat_ini) {
    } else {
      if (data.akhir_kerja) {
        bodyFormData.append("akhir_kerja", data.akhir_kerja + "-01");
      } else {
      }
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    if (data.dokumen_sip != null)
      bodyFormData.append("dokumen_sip", data.dokumen_sip);

    return http.put(`/nakes/pekerjaan/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/nakes/pekerjaan/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new PekerjaanService();
