import http from "../http-common";
/* eslint-disable */
class SimposiumListDetailService {
  simposiumListDetail(page, itemPerPage, keyword, id) {
    return http.get(
      "/pib/simposium-event-detail/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.simposium_event_id)
      bodyFormData.append("simposium_event_id", data.simposium_event_id);
    if (data.kegiatan) bodyFormData.append("kegiatan", data.kegiatan);
    if (data.lokasi) bodyFormData.append("lokasi", data.lokasi);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.link_youtube)
      bodyFormData.append("link_youtube", data.link_youtube);
    if (data.link_zoom) bodyFormData.append("link_zoom", data.link_zoom);
    if (data.username_zoom)
      bodyFormData.append("username_zoom", data.username_zoom);
    if (data.password_zoom)
      bodyFormData.append("password_zoom", data.password_zoom);

    return http.post("/pib/simposium-event-detail", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.simposium_event_id)
      bodyFormData.append("simposium_event_id", data.simposium_event_id);
    if (data.kegiatan) bodyFormData.append("kegiatan", data.kegiatan);
    if (data.lokasi) bodyFormData.append("lokasi", data.lokasi);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.link_youtube)
      bodyFormData.append("link_youtube", data.link_youtube);
    if (data.link_zoom) bodyFormData.append("link_zoom", data.link_zoom);
    if (data.username_zoom)
      bodyFormData.append("username_zoom", data.username_zoom);
    if (data.password_zoom)
      bodyFormData.append("password_zoom", data.password_zoom);

    return http.put(`/pib/simposium-event-detail/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/pib/simposium-event-detail/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new SimposiumListDetailService();
