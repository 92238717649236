<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <div style="position: relative">
        <v-btn
          v-if="readOnly"
          absolute
          fab
          top
          right
          dark
          color="primary"
          @click="editItem"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <br />
      <br />
      <form>
        <v-row dense>
          <v-col cols="12" sm="6" md="6">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="ml-4">
                  <a
                    v-if="currentData.file_pdf_original"
                    :href="currentData.file_pdf"
                    target="_blank"
                  >
                    Link File Ad & Rt
                  </a>
                </div>

                <v-file-input
                  accept="application/pdf"
                  v-model="currentData.file_pdf_val"
                  prepend-icon="mdi-file"
                  show-size
                  label="File PDF"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="!readOnly">
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
            <v-btn dark color="primary" type="submit" @click.prevent="doSubmit"
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </v-container>
  </v-main>
</template>

<script>
import AdRtService from "../../services/web_content/AdRtService";
import CommonService from "../../services/CommonService";

export default {
  name: "AdRt",
  components: {},
  props: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Ad & Rt",
        disabled: false,
        exact: true,
        to: { name: "AdRt" },
      },
      { text: "Detail", disabled: true },
    ],
    readOnly: true,
    currentData: {
      id: null,
      file_pdf_val: null,
    },
  }),
  computed: {
    outlined() {
      return this.readOnly;
    },
    hideDetails() {
      return true;
    },
  },
  mounted: async function () {
    this.fetch();
  },
  methods: {
    fetch: async function () {
      try {
        console.log("fetch");
        AdRtService.adrt()
          .then((res) => {
            if (res.data.adrt) {
              this.currentData = res.data.adrt;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.error("error:", e);
      }
    },

    editItem: async function () {
      this.readOnly = false;
    },

    doBatal: function () {
      this.readOnly = true;
    },

    doSubmit: async function () {
      try {
        if (this.currentData.id != null) {
          AdRtService.update(this.currentData)
            .then((res) => {
              console.log(res);
              CommonService.showSuccessToast("Update Ad & Rt berhasil");

              this.fetch();
              this.readOnly = true;
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast("Update Ad & Rt gagal. Coba lagi");
            });
        } else {
          AdRtService.create(this.currentData)
            .then((res) => {
              console.log(res);
              this.dialog = false;
              CommonService.showSuccessToast("Tambah Ad & Rt berhasil");

              this.fetch();
              this.readOnly = true;
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast("Tambah Ad & Rt gagal. Coba lagi");
            });
        }
      } catch (e) {
        console.error("error:", e);
      }
    },
  },
};
</script>

<style scoped>
</style>
