<template>
  <v-card>
    <v-card-title>
      Master
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Cari Nama"
        single-line
        hide-details
        v-on:keyup.enter="getDataFromApi"
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Apa anda akan menambah Seminat ini?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Tidak</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Ya</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="list_data"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-chip color="cyan" dark @click="deleteItem(item)">
          Tambah Seminat
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CommonService from "../../services/CommonService";
import PeminatanMasterService from "../../services/PeminatanMasterService";

export default {
  props: {
    id: String,
    refresh: Function,
    checkList: Function,
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Seminat", value: "nama", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    currentItem: null,

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PeminatanMasterService.peminatanList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/nakes-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      this.currentItem = item;
      //check jika seminat kurang dari 2
      this.checkList();
    },

    openConfirmAddSeminat() {
      this.editedIndex = this.list_data.indexOf(this.currentItem);
      this.editedItem = Object.assign({}, this.currentItem);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      this.editedItem.user_id = this.id;
      this.editedItem.keseminatan_id = this.editedItem.id;
      PeminatanMasterService.create(this.editedItem)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Tambah seminat berhasil");

          this.getDataFromApi();
          this.refresh();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Tambah seminat gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
