<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab>Fasyankes</v-tab>
          <v-tab v-if="getRoleId == 1">Penanggung Jawab</v-tab>
          <v-tab>Tempat Tidur</v-tab>
          <v-tab>Layanan</v-tab>
          <v-tab>Tenaga SDM</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card class="pl-4 pr-4 pt-4 pb-4">
              <v-card-title>
                Fasyankes
              </v-card-title>
              <v-fab-transition>
                <v-btn
                  class="mt-8 mr-4"
                  v-if="isEditable == 0"
                  @click="changeEditable"
                  color="pink"
                  dark
                  absolute
                  top
                  right
                  fab
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKey"
                          :src="currentData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImage"
                      accept="image/*"
                      v-model="currentData.file_foto"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="No Anggota Mukisi"
                          outlined
                          dense
                          v-model="currentData.no_anggota"
                          required
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Nama"
                          outlined
                          dense
                          v-model="currentData.nama"
                          required
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Alamat"
                          dense
                          value=""
                          v-model="currentData.alamat"
                          :rules="currentData.alamatRules"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          label="Propinsi"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedPropinsi"
                          :items="items_propinsi"
                          item-text="nama"
                          :readonly="isEditable == 0"
                          @change="changePropinsi"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          label="Kabupaten/Kota"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedKabupaten"
                          :items="items_kabupaten"
                          item-text="nama"
                          :readonly="isEditable == 0"
                          @click="getListKabupaten"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="No Telepon"
                          outlined
                          dense
                          v-model="currentData.telp"
                          required
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          autocomplete="off"
                          label="Jenis Fasyankes"
                          clearable
                          dense
                          outlined
                          v-model="currentData.jenis_user"
                          :items="items_tipe"
                          item-text="value"
                          :rules="currentData.tipeRules"
                          :disabled="isEditable == 0"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Jenis RS"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedJenisRS"
                          :items="items_jenisrs"
                          item-text="value"
                          :disabled="isEditable == 0"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Kelas RS"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedKelasRS"
                          :items="items_kelasrs"
                          item-text="value"
                          :disabled="isEditable == 0"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h5>
                      Detil
                    </h5>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="4" md="4">
                    <v-combobox
                      label="Status RS Syariah"
                      clearable
                      dense
                      outlined
                      v-model="currentData.selectedStatusRSSyariah"
                      :items="items_statusrssyariah"
                      item-text="value"
                      :disabled="isEditable == 0"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Status BLU"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedStatusBlu"
                          :items="items_statusblu"
                          item-text="value"
                          :disabled="isEditable == 0"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Kepemilikan"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedKepemilikanRS"
                          :items="items_kepemilikanrs"
                          item-text="value"
                          :disabled="isEditable == 0"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Direktur"
                      outlined
                      dense
                      v-model="currentData.direktur"
                      required
                      :readonly="isEditable == 0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Luas Tanah"
                      outlined
                      dense
                      v-model="currentData.luas_tanah"
                      required
                      :readonly="isEditable == 0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Luas Bangunan"
                      outlined
                      dense
                      v-model="currentData.luas_bangunan"
                      required
                      :readonly="isEditable == 0"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Website"
                      outlined
                      dense
                      v-model="currentData.website"
                      required
                      :readonly="isEditable == 0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <v-text-field
                      label="Link Google Map"
                      outlined
                      dense
                      v-model="currentData.link_google_map"
                      required
                      :readonly="isEditable == 0"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      @click="doBatal"
                      style="margin-right: 10px;"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="getRoleId == 1">
            <PengurusComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <TempatTidurComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <LayananRSComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <SdmRSComponent :id="id" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import FasyankesService from "../services/FasyankesService";
import MasterService from "../services/MasterService";
import TempatTidurComponent from "./fasyankes/TempatTidur.vue";
import LayananRSComponent from "./fasyankes/LayananRS.vue";
import SdmRSComponent from "./fasyankes/SdmRS.vue";
import PengurusComponent from "./fasyankes/FasyankesPengurus.vue";
import NakesService from "../services/NakesService";
import store from "../store/index";

export default {
  name: "FasyankesEdit",
  components: {
    TempatTidurComponent,
    LayananRSComponent,
    SdmRSComponent,
    PengurusComponent,
  },
  props: {
    id: String,
  },
  data: () => ({
    tab: null,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Fasyankes",
        disabled: false,
        exact: true,
        to: { name: "Fasyankes" },
      },
      { text: "Edit Fasyankes", disabled: true },
    ],
    valid: true,
    currentData: {
      FotoUrl: "",
      file_foto: null,
      id: "",
      no_anggota: "",
      nama: "",
      alamat: "",
      telp: "",
      prasyarat_khusus: "",
      harga: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      tanggal_evaluasi_pasca_kegiatan: "",
      jenis_kegiatan: "",
      skp_narasumber: "",
      skp_moderator: "",
      skp_panitia: "",
      skp_peserta: "",
      nomor_skp: "",
      penyelenggara: "",
      kategori: "",
      selectedJenisRS: "",
      selectedKelasRS: "",
      selectedStatusRSSyariah: "",
      selectedStatusBlu: "",
      selectedKepemilikanRS: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      jenis_user: null,
      namaRules: [(v) => !!v || "Nama harus diisi"],
      alamatRules: [(v) => !!v || "Alamat harus diisi"],
      tipeRules: [(v) => !!v || "Jenis Anggota harus diisi"],
    },
    items_jenisrs: [],
    items_kelasrs: [],
    items_statusrssyariah: [],
    items_statusblu: [],
    items_kepemilikanrs: [],
    items_propinsi: [],
    items_kabupaten: [],
    items_tipe: [],
    fotoKey: 0,
  }),
  mounted: async function() {
    this.getListPropinsi();
    this.getListKabupaten();
    this.getListJenisRS();
    this.getListKelasRS();
    this.getListStatusRSSyariah();
    this.getListStatusBlu();
    this.getListKepemilikanRS();
    this.getListTipeAnggota();
    this.getFasyankes();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
  },
  methods: {
    changeEditable: function() {
      this.isEditable = 1;
    },

    async getListTipeAnggota() {
      MasterService.tipeAnggotaList()
        .then((res) => {
          this.items_tipe = res.data.list_data.filter((item) => item.code != 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loadImage: function(data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.currentData.selectedKabupaten = null;
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.currentData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getFasyankes() {
      FasyankesService.getFasyankes(this.id)
        .then((res) => {
          this.currentData = res.data.fasyankes;

          if (res.data.fasyankes.jenis_rs) {
            this.items_jenisrs.forEach((entry) => {
              if (entry.code == res.data.fasyankes.jenis_rs) {
                this.currentData.selectedJenisRS = entry;
              }
            });
          }

          if (res.data.fasyankes.kelas_rs) {
            this.items_kelasrs.forEach((entry) => {
              if (entry.code == res.data.fasyankes.kelas_rs) {
                this.currentData.selectedKelasRS = entry;
              }
            });
          }

          if (res.data.fasyankes.status_rs_syariah) {
            this.items_statusrssyariah.forEach((entry) => {
              if (entry.code == res.data.fasyankes.status_rs_syariah) {
                this.currentData.selectedStatusRSSyariah = entry;
              }
            });
          }

          if (res.data.fasyankes.status_blu) {
            this.items_statusblu.forEach((entry) => {
              if (entry.code == res.data.fasyankes.status_blu) {
                this.currentData.selectedStatusBlu = entry;
              }
            });
          }

          if (res.data.fasyankes.kepemilikan) {
            this.items_kepemilikanrs.forEach((entry) => {
              if (entry.code == res.data.fasyankes.kepemilikan) {
                this.currentData.selectedKepemilikanRS = entry;
              }
            });
          }

          if (res.data.fasyankes.type) {
            this.items_tipe.forEach((entry) => {
              if (entry.code == res.data.fasyankes.type) {
                this.currentData.jenis_user = entry;
              }
            });
          }

          if (res.data.fasyankes.propinsi_id) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == res.data.fasyankes.propinsi_id) {
                this.currentData.selectedPropinsi = entry;
              }
            });

            this.getListKabupatenAll(
              res.data.fasyankes.propinsi_id,
              res.data.fasyankes.kabupaten_id
            );
          }

          this.currentData.FotoUrl = res.data.fasyankes.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupatenAll(propinsiId, kabupatenId) {
      NakesService.kabupatenList(propinsiId)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
          if (kabupatenId) {
            this.items_kabupaten.forEach((entry) => {
              if (entry.id == kabupatenId) {
                this.currentData.selectedKabupaten = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListJenisRS() {
      MasterService.jenisRsList()
        .then((res) => {
          this.items_jenisrs = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelasRS() {
      MasterService.kelasRsList()
        .then((res) => {
          this.items_kelasrs = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListStatusRSSyariah() {
      MasterService.statusRsSyariahList()
        .then((res) => {
          this.items_statusrssyariah = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListStatusBlu() {
      MasterService.statusBluList()
        .then((res) => {
          this.items_statusblu = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKepemilikanRS() {
      MasterService.kepemilikanRSList()
        .then((res) => {
          this.items_kepemilikanrs = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function() {
      this.isEditable = 0;
    },

    doSubmit: async function() {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      FasyankesService.update(
        this.id,
        this.currentData,
        this.currentData.file_foto
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Edit Fasyankes berhasil");

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Edit Fasyankes gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped></style>
