import http from "../../http-common";

/* eslint-disable */
class InasiaInvoiceService {
  invoiceList(
    page,
    itemPerPage,
    keyword,
    inasiaId,
    invoiceNo,
    status,
    kategori,
    item,
    sponsor,
    metodePembayaran = "",
    statusSponsor = "",
    statusProfesi = ""
  ) {
    return http.get(
      "/inasia/invoice/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&inasia_id=" +
        inasiaId +
        "&invoice_no=" +
        invoiceNo +
        "&status=" +
        status +
        "&kategori=" +
        kategori +
        "&item=" +
        item +
        "&sponsor=" +
        sponsor +
        "&metode_pembayaran=" +
        metodePembayaran +
        "&status_sponsor=" +
        statusSponsor +
        "&status_profesi=" +
        statusProfesi,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  invoiceListByPeserta(
    page,
    itemPerPage,
    keyword,
    invoiceNo,
    status,
    kategori,
    item,
    sponsor,
    userId
  ) {
    return http.get(
      "/inasia/invoice-peserta/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&invoice_no=" +
        invoiceNo +
        "&status=" +
        status +
        "&kategori=" +
        kategori +
        "&item=" +
        item +
        "&sponsor=" +
        sponsor +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getKategoriItemByInasia(inasiaId, id) {
    return http.get(`/inasia/kategori-item-byinasia/${inasiaId}/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  uploadBuktiBayarData(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/inasia/bukti-bayar-sponsor/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  updateStatusBayarSponsor(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);

    if (
      data.selectedStatusBayarSponsor &&
      data.selectedStatusBayarSponsor != undefined
    ) {
      bodyFormData.append(
        "status_bayar_sponsor",
        data.selectedStatusBayarSponsor.id
      );
    }

    return http.put(`/inasia/status-bayar-sponsor/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  approvePib(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/inasia/invoice/approve/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  reject(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/inasia/invoice/reject/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadInvoice(invoiceId) {
    return http.get("/report/inasia/invoice/download?invoice_id=" + invoiceId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadInvoicePeserta(invoiceId) {
    return http.get(
      "/report/inasia/invoice-peserta/download?invoice_id=" + invoiceId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  downloadInvoiceReport(
    keyword,
    inasiaId,
    invoiceNo,
    status,
    kategori,
    item,
    sponsor,
    metodePembayaran = "",
    statusSponsor = "",
    statusProfesi = ""
  ) {
    return http.get(
      "/report/inasia/report-invoice-peserta/download?keyword=" +
        keyword +
        "&inasia_id=" +
        inasiaId +
        "&invoice_no=" +
        invoiceNo +
        "&status=" +
        status +
        "&kategori=" +
        kategori +
        "&item=" +
        item +
        "&sponsor=" +
        sponsor +
        "&metode_pembayaran=" +
        metodePembayaran +
        "&status_sponsor=" +
        statusSponsor +
        "&status_profesi=" +
        statusProfesi,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  delete(id) {
    return http.delete(`/inasia/invoice/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InasiaInvoiceService();
