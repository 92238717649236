import http from "../../http-common"

/* eslint-disable */
class InasiaWorkshopInvoiceService {
  invoiceWorkshopList(page, itemPerPage, keyword, kategori, item) {
    return http.get(
      "/inasia-invoice-workshop/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kategori=" +
        kategori +
        "&item=" +
        item,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  downloadPesertaReport(search, kategori, item) {
    return http.get(
      "/report/peserta-workshop-inasia/download?keyword=" +
        search +
        "&kategori=" +
        kategori +
        "&item=" +
        item,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }
}

export default new InasiaWorkshopInvoiceService()
