import { render, staticRenderFns } from "./PesertaSelect.vue?vue&type=template&id=88cd2498&scoped=true"
import script from "./PesertaSelect.vue?vue&type=script&lang=js"
export * from "./PesertaSelect.vue?vue&type=script&lang=js"
import style0 from "./PesertaSelect.vue?vue&type=style&index=0&id=88cd2498&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88cd2498",
  null
  
)

export default component.exports