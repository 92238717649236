import http from "../http-common";
/* eslint-disable */
class ManajemenSKPService {
  skpList(
    page,
    itemPerPage,
    jenis_kegiatan,
    jenis_peserta,
    cakupan,
    jenis_jumlah_jam
  ) {
    return http.get(
      "/skp-kegiatan-skala/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&jenis_kegiatan=" +
        jenis_kegiatan +
        "&jenis_peserta=" +
        jenis_peserta +
        "&cakupan=" +
        cakupan +
        "&jenis_jumlah_jam=" +
        jenis_jumlah_jam,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getSkp(id) {
    return http.get(`/skp-kegiatan-skala/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    const bodyRaw = JSON.stringify({
      jenis_kegiatan: data.jenisKegiatan.code,
      jenis_peserta: data.jenisPeserta.code,
      jenis_jumlah_jam: data.jumlahJam.code,
      skala_min: data.skalaMin,
      skala_max: data.skalaMax,
      cakupan: data.cakupan.code,
    });

    return http.post("/skp-kegiatan-skala", bodyRaw, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data) {
    const bodyRaw = JSON.stringify({
      jenis_kegiatan: data.jenisKegiatan.code,
      jenis_peserta: data.jenisPeserta.code,
      jenis_jumlah_jam: data.jumlahJam.code,
      skala_min: data.skalaMin,
      skala_max: data.skalaMax,
      cakupan: data.cakupan.code,
    });

    return http.put(`/skp-kegiatan-skala/${id}`, bodyRaw, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/skp-kegiatan-skala/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new ManajemenSKPService();
