<template>
  <div>
    <v-card>
      <v-card-title>
        Paket Wisata <br />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Paket Wisata"
          single-line
          hide-details
          v-on:keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="mb-2" @click="openTambah">
          Tambah
        </v-btn>
        <v-dialog v-model="dialog" persistent>
          <v-card>
            <v-card-title>
              <span class="text-h5">Paket Wisata</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6" class="pr-4">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="fotoKey"
                            :src="currentData.FotoUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImage"
                        accept="image/*"
                        v-model="currentData.file_foto"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto"
                        :rules="currentData.fotoRules"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Nama Paket"
                            outlined
                            dense
                            v-model="currentData.nama_paket"
                            required
                            :rules="currentData.namaPaketRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Destinasi"
                            outlined
                            dense
                            v-model="currentData.destinasi"
                            required
                            :rules="currentData.destinasiRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Alamat"
                            outlined
                            dense
                            v-model="currentData.alamat"
                            required
                            :rules="currentData.alamatRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-row dense>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Tanggal Awal"
                                v-model="currentData.tanggal_awal"
                                type="date"
                                dense
                                :rules="currentData.tanggalAwalRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Tanggal Akhir"
                                v-model="currentData.tanggal_akhir"
                                type="date"
                                dense
                                :rules="currentData.tanggalAkhirRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-row dense>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Jam Awal"
                                v-model="currentData.jam_awal"
                                type="time"
                                dense
                                :rules="currentData.jamAwalRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Jam Akhir"
                                v-model="currentData.jam_akhir"
                                type="time"
                                dense
                                :rules="currentData.jamAkhirRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-textarea
                        outlined
                        name="input-7-4"
                        label="Deskripsi"
                        dense
                        value=""
                        rows="6"
                        v-model="currentData.deskripsi"
                        :rules="currentData.deskripsiRules"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Harga"
                            outlined
                            dense
                            v-model="currentData.harga"
                            required
                            :rules="currentData.hargaRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Link Google Map"
                            outlined
                            dense
                            v-model="currentData.link_google_map"
                            required
                            :rules="currentData.linkGoogleMapRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.description`]="{ item }">
          {{ truncate(item.description) }}
        </template>
        <template v-slot:[`item.harga`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-4">
      <PaketWisataDetailComponent
        v-if="selected != undefined && selected.length > 0"
        :id="selected[0].id"
        ref="mychild"
        :key="childKey"
        :paket_wisata_id="id"
      />
    </v-card>
    <!-- Modal -->
  </div>
</template>

<script>
import PaketWisataService from "../../services/PaketWisataService";
import * as moment from "moment";
import CommonService from "../../services/CommonService";
import PaketWisataDetailComponent from "./PaketWisataDetail.vue";

export default {
  props: {
    id: String,
  },
  components: { PaketWisataDetailComponent },
  data: () => ({
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Paket", value: "nama_paket", sortable: false },
      { text: "Destinasi", value: "destinasi", sortable: false },
      { text: "Harga", value: "harga", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    currentData: {
      pib_id: "",
      id: null,
      FotoUrl: "",
      file_foto: null,
      nama_paket: "",
      destinasi: "",
      deskripsi: "",
      harga: "",
      alamat: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      jam_awal: "",
      jam_akhir: "",
      namaPaketRules: [(v) => !!v || "Nama Paket harus diisi"],
      destinasiRules: [(v) => !!v || "Destinasi harus diisi"],
      alamatRules: [(v) => !!v || "Alamat harus diisi"],
      tanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      tanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      jamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      jamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      deskripsiRules: [(v) => !!v || "Deskripsi harus diisi"],
      hargaRules: [(v) => !!v || "Harga harus diisi"],
      linkGoogleMapRules: [(v) => !!v || "Link Google Map harus diisi"],
    },
    fotoKey: 0,

    layananList: [],
    currentLayanan: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    truncate: function (input) {
      if (input && input.length > 10) {
        return input.substring(0, 5) + "...";
      }
      return input;
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PaketWisataService.paketWisataList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.currentData.FotoUrl = "";
      this.currentData.file_foto = null;
      this.currentData.nama_paket = "";
      this.currentData.destinasi = "";
      this.currentData.alamat = "";
      this.currentData.tangal_awal = "";
      this.currentData.tangal_akhir = "";
      this.currentData.jam_awal = "";
      this.currentData.jam_akhir = "";
      this.currentData.deskripsi = "";
      this.currentData.harga = "";
      this.currentData.link_google_map = "";
      this.dialog = true;
      this.isEditable = 1;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      this.currentData = this.editedItem;
      this.currentData.FotoUrl = this.editedItem.photo;

      this.currentData.tanggal_awal = moment(
        this.editedItem.tanggal_awal
      ).format("YYYY-MM-DD");

      this.currentData.tanggal_akhir = moment(
        this.editedItem.tanggal_akhir
      ).format("YYYY-MM-DD");

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.pib_id = this.id;
        PaketWisataService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Paket Wisata berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast(
              "Tambah Paket Wisata gagal. Coba lagi"
            );
          });
      } else {
        this.currentData.pib_id = this.id;

        PaketWisataService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update Paket Wisata berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast(
              "Update Paket Wisata gagal. Coba lagi"
            );
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PaketWisataService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus Paket Wisata berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus Paket Wisata gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
