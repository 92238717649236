import http from "../http-common";
/* eslint-disable */
class PelatihanSubCategoryService {
  pelatihanSubCategoryList(page, itemPerPage, keyword, pelatihanId) {
    return http.get(
      "/pelatihan-sub-category/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&pelatihan_category_id=" +
        pelatihanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    return http.post("/pelatihan-sub-category", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  update(data) {
    return http.put(`/pelatihan-sub-category/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  delete(id) {
    return http.delete(`/pelatihan-sub-category/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new PelatihanSubCategoryService();
