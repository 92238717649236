import http from "../../http-common";

/* eslint-disable */
class InasiaEPosterService {
  eposter(id) {
    return http.get("/inasia/eposter/" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);
    if (data.judul) bodyFormData.append("judul", data.judul);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.cp) bodyFormData.append("cp", data.cp);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.post("/inasia/eposter", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);
    if (data.judul) bodyFormData.append("judul", data.judul);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.cp) bodyFormData.append("cp", data.cp);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/inasia/eposter/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/inasia/eposter/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InasiaEPosterService();
