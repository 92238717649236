<template>
  <div>
    <v-card>
      <v-card-title>
        Invoice
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">P2KB</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Kategori"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedKategori"
                        :items="items_kategori"
                        item-text="name"
                        :rules="currentData.kategoriRules"
                        @change="getMasterIuran"
                      ></v-combobox>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Anggota"
                        outlined
                        dense
                        required
                        v-model="currentData.nama"
                        readonly
                        @click="openAnggotaModal"
                        :rules="currentData.namaRules"
                        @change="getMasterIuran"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="
                      currentData.selectedKategori &&
                        currentData.selectedKategori.id == 4
                    "
                  >
                    <v-col cols="12">
                      <v-combobox
                        label="Cabang"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedCabang"
                        :items="items_cabang"
                        item-text="name"
                        :rules="currentData.cabangRules"
                      ></v-combobox>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="No NPA"
                        readonly
                        outlined
                        dense
                        required
                        v-model="currentData.no_npa"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Tahun"
                        clearable
                        dense
                        outlined
                        v-model="currentData.tahun"
                        :items="items_tahun"
                        item-text="name"
                        :rules="currentData.tahunRules"
                        @change="getMasterIuran"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      currentData.selectedKategori &&
                        currentData.selectedKategori.id != 2
                    "
                    dense
                  >
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Nominal Cabang"
                        outlined
                        dense
                        required
                        readonly
                        v-model="currentData.nominal_cabang"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      currentData.selectedKategori &&
                        currentData.selectedKategori.id != 2
                    "
                    dense
                  >
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Nominal Pusat"
                        outlined
                        dense
                        required
                        readonly
                        v-model="currentData.nominal_pusat"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        :readonly="
                          currentData.selectedKategori &&
                            currentData.selectedKategori.id != 2
                        "
                        label="Nominal"
                        outlined
                        dense
                        required
                        v-model="currentData.nominal"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                        @change="loadImage"
                        accept="image/*"
                        v-model="currentData.foto_url"
                        prepend-icon="mdi-image"
                        show-size
                        label="Bukti Foto"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-responsive
                        v-if="currentData.FotoUrl"
                        :aspect-ratio="16 / 9"
                      >
                        <v-img
                          :key="currentData.fotoKey"
                          :src="currentData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-4">
                    <v-col cols="12">
                      <v-checkbox
                        v-model="currentData.langsung_setujui"
                        label="Langsung Setujui"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogUpdate" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Pengajuan Mutasi</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Anggota"
                        outlined
                        dense
                        required
                        v-model="currentData.nama"
                        readonly
                        :rules="currentData.namaRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Cabang Asal"
                        readonly
                        outlined
                        dense
                        required
                        v-model="currentData.cabang_asal_nama"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Cabang Tujuan"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedCabangTujuan"
                        :items="items_cabang"
                        item-text="name"
                        :rules="currentData.cabangTujuanRules"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogUpdate = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogApprove" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan mengapprove iuran anggota ini?</v-card-title
            >
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeApprove"
                    >Tidak</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="approveItemConfirm"
                    >Ya</v-btn
                  >
                </v-row>
              </v-container>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogBuktiBayar" max-width="600px">
          <v-card>
            <v-img height="auto" width="auto" :src="fotoBuktiBayar"></v-img>
          </v-card>
        </v-dialog>
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search.nama"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Cabang Perdatin"
            clearable
            dense
            outlined
            v-model="search.selectedPropinsi"
            :items="items_cabang"
            item-text="name"
            @change="changePropinsi"
            :disabled="getCabangPengurusId != null"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Tahun"
            clearable
            dense
            outlined
            v-model="search.tahun"
            :items="items_tahun"
            item-text="name"
            @change="getDataFromApi"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Status"
            clearable
            dense
            outlined
            v-model="search.selectedStatus"
            :items="items_status"
            item-text="name"
            @change="getDataFromApi"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.kategori`]="{ item }">
          <v-chip :color="getColorKategori(item.kategori)" dark>
            {{ getKategori(item.kategori) }}
          </v-chip>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ getCurrentDate(item.created_at) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ getStatus(item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.foto_url`]="{ item }">
          <v-btn
            v-if="item.kategori == 4 || item.kategori == 6"
            color="blue"
            dark
            class="mb-2"
            @click="showFotoBuktiBayar(item.foto_url)"
          >
            View
          </v-btn>
        </template>
        <template v-slot:[`item.nominal`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.nominal) }}
        </template>
        <template v-slot:[`item.nominal_pusat`]="{ item }">
          <div v-if="item.nominal_pusat">
            <div v-if="item.kategori == 1 || item.kategori == 3">
              {{ new Intl.NumberFormat("id-ID").format(item.nominal_pusat) }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.nominal_cabang`]="{ item }">
          <div v-if="item.nominal_cabang">
            <div v-if="item.kategori == 1 || item.kategori == 3">
              {{ new Intl.NumberFormat("id-ID").format(item.nominal_cabang) }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.virtual_account`]="{ item }">
          <div v-if="item.virtual_account">
            {{ item.virtual_account }}
          </div>
        </template>
        <template v-slot:[`item.pusat_splitted`]="{ item }">
          <div v-if="item.kategori == 1 || item.kategori == 3">
            <v-chip :color="getColorSplitted(item.pusat_splitted)" dark>
              {{ getSplittedType(item.pusat_splitted) }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.cabang_splitted`]="{ item }">
          <div v-if="item.kategori == 1 || item.kategori == 3">
            <v-chip :color="getColorSplitted(item.cabang_splitted)" dark>
              {{ getSplittedType(item.cabang_splitted) }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.is_tabsol_sent`]="{ item }">
          <div v-if="item.kategori == 2">
            <v-chip :color="getColorSplitted(item.is_tabsol_sent)" dark>
              {{ getSplittedType(item.is_tabsol_sent) }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="
              (item.kategori == 4 || item.kategori == 6) &&
                (item.status == 2 || item.status == 3) &&
                (item.is_approver == 1 || getRoleId == 1)
            "
            medium
            class="mr-3"
            @click="approveItem(item)"
          >
            mdi-check
          </v-icon>
          <!-- <v-icon
            medium
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
        </template>
      </v-data-table>
    </v-card>

    <AnggotaSelectComponent
      @pilihAnggota="pilihAnggota"
      ref="modalAnggota"
      v-model="dialogAnggota"
    />
  </div>
</template>

<script>
import IuranService from "../../services/IuranService";
import CommonService from "../../services/CommonService";
import MasterService from "../../services/MasterService";
import NakesService from "../../services/NakesService";
import * as moment from "moment";

import AnggotaSelectComponent from "../../components/AnggotaSelect.vue";
import store from "../../store/index";

export default {
  props: {
    id: String,
  },
  components: {
    AnggotaSelectComponent,
  },
  data: () => ({
    dialogBuktiBayar: false,
    dialogAnggota: false,
    dialogApprove: false,
    dialogUpdate: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Iuran & TABSOL",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      tahun: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false },
      { text: "Cabang", value: "cabang_nama", sortable: false },
      { text: "No NPA", value: "no_npa", sortable: false },
      { text: "Invoice No", value: "invoice_no", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Tahun", value: "tahun", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
      { text: "Pusat Terkirim", value: "is_tabsol_sent", sortable: false },
      { text: "No VA", value: "virtual_account", sortable: false },
      { text: "Kategori", value: "kategori", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Foto", value: "foto_url", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      selectedCabangTujuan: "",
      selectedKategori: "",
      selectedCabang: "",
      no_npa: "",
      cabang_asal_nama: "",
      cabang_asal: "",
      tahun: "",
      nominal: "",
      langsung_setujui: false,
      foto_url: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      nominal_pusat: 900000,
      nominal_cabang: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
      tahunRules: [(v) => !!v || "Tahun harus diisi"],
      nominalRules: [(v) => !!v || "Nominal harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_jabatan: [],
    items_divisi: [],
    items_cabang: [],
    items_tahun: [],
    items_kategori: [
      {
        id: 2,
        name: "Pembayaran Tabsol",
      },
      {
        id: 6,
        name: "Upload Tabsol Lama",
      },
    ],
    items_status: [
      {
        id: 1,
        name: "Menunggu Pembayaran Anggota",
      },
      {
        id: 2,
        name: "Menunggu Konfirmasi Cabang",
      },
      {
        id: 3,
        name: "Menunggu Konfirmasi Pusat",
      },
      {
        id: 4,
        name: "Terverifikasi",
      },
    ],

    fotoBuktiBayar: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear(); i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.search.tahun = new Date().getFullYear();

    this.getListPropinsi();
    // this.getDataFromApi();
  },
  methods: {
    getSplittedType(id) {
      if (id == 1) {
        return "Sudah";
      } else {
        return "Belum";
      }
    },
    getKategori(id) {
      if (id == 1) {
        return "Pembayaran Iuran pendaftaran Anggota";
      } else if (id == 2) {
        return "Pembayaran Tabsol";
      } else if (id == 3) {
        return "Pembayaran perpanjangan Iuran Anggota";
      } else if (id == 4) {
        return "Upload Data Iuran Lama";
      } else if (id == 5) {
        return "Pembayaran P2KB";
      } else if (id == 6) {
        return "Upload Tabsol Lama";
      }
    },
    async getListOrganisasiDivisi() {
      MasterService.organisasiDivisiList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_divisi = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListOrganisasiJabatan() {
      MasterService.organisasiJabatanList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_jabatan = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getColorKategori(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "pink lighten-3";
        case 2:
          return "purple lighten-3";
        case 3:
          return "teal lighten-3";
        default:
          return "red lighten-3";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "light-blue";
        case 2:
          return "green";
        case 3:
          return "cyan";
        case 4:
          return "blue";
        default:
          return "red";
      }
    },

    getColorSplitted(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "green";
        default:
          return "red";
      }
    },

    getStatus(id) {
      if (id == 1) {
        return "Menunggu Pembayaran Anggota";
      } else if (id == 2) {
        return "Menunggu Konfirmasi Cabang";
      } else if (id == 3) {
        return "Menunggu Konfirmasi Pusat";
      } else if (id == 4) {
        return "Terverifikasi";
      } else if (id == 5) {
        return "Rejected";
      } else if (id == 6) {
        return "Canceled";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    loadImage: function(data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var tahun = "";
      var kategori = "";
      var status = "";
      var propinsi = "";

      if (this.search.tahun) {
        tahun = this.search.tahun;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedPropinsi) {
        propinsi = this.search.selectedPropinsi.id;
      }

      IuranService.p2kbListKegiatan(
        page,
        itemsPerPage,
        this.search.nama,
        tahun,
        kategori,
        status,
        propinsi,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function() {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.no_npa = "";
      this.currentData.tahun = "";
      this.currentData.nominal = "";
      this.currentData.foto_url = null;
      this.currentData.FotoUrl = "";
      this.currentData.langsung_setujui = false;
      this.currentData.selectedDivisi = "";
      this.currentData.selectedJabatan = "";
      this.currentData.cabang_asal_nama = "";
      this.currentData.cabang_asal = "";
      this.currentData.selectedKategori = null;
      this.currentData.langsung_setujui = false;

      console.log("do add");
    },

    doSave: async function() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        IuranService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialogUpdate = false;
            CommonService.showSuccessToast("Update iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update iuran gagal. Coba lagi");
          });
      } else {
        IuranService.create(this.currentData, this.currentData.foto_url)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah iuran gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogUpdate = true;
      this.currentData.nama = this.editedItem.user_nama;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.id = this.editedItem.id;
      this.currentData.cabang_asal = this.editedItem.cabang_asal;
      this.currentData.cabang_asal_nama = this.editedItem.cabang_asal_nama;

      this.items_cabang.forEach((entry) => {
        if (entry.id == this.editedItem.cabang_tujuan) {
          this.currentData.selectedCabangTujuan = entry;
        }
      });

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      IuranService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pengurus berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pengurus gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function(file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },

    openAnggotaModal: async function() {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihAnggota(item) {
      this.dialogAnggota = false;
      console.log(item);
      this.currentData.nama = item.nama;
      this.currentData.user_id = item.id;
      this.currentData.cabang_asal = item.cabang_id;
      this.currentData.cabang_asal_nama = item.propinsi_nama;
      this.currentData.no_npa = item.no_npa;
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      IuranService.approve(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve iuran berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve iuran gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_cabang = res.data.list_data;

          if (this.getCabangPengurusId) {
            this.items_cabang.forEach((entry) => {
              if (entry.id == this.getCabangPengurusId) {
                this.search.selectedPropinsi = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMasterIuran() {
      if (
        this.currentData.selectedKategori &&
        this.currentData.selectedKategori.id != 2 &&
        this.currentData.nama &&
        this.currentData.tahun
      ) {
        IuranService.getMasterIuran(
          this.currentData.cabang_asal,
          this.currentData.tahun
        )
          .then((res) => {
            var invoice = res.data.invoice;
            if (invoice) this.currentData.nominal_cabang = invoice.nominal;
            else this.currentData.nominal_cabang = 0;

            this.currentData.nominal =
              this.currentData.nominal_cabang + this.currentData.nominal_pusat;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.currentData.nominal = 0;
      }
    },

    showFotoBuktiBayar(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },

    async changePropinsi() {
      this.getDataFromApi();
    },
  },

  computed: {
    nominalTotal() {
      var nominalCabang = 0;
      var nominalPusat = 0;

      if (this.nominal_cabang) {
        nominalCabang = this.nominal_cabang;
      }

      if (this.nominal_pusat) {
        nominalPusat = this.nominal_pusat;
      }

      return nominalPusat + nominalCabang;
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
