import http from "../../http-common"
/* eslint-disable */
class AdminP2kbService {
  userList(page, itemPerPage, keyword) {
    return http.get(
      "/skp-kegiatan/admin-p2kb/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  userNonList(page, itemPerPage, keyword) {
    return http.get(
      "/skp-kegiatan/admin-p2kb-non/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  create(data) {
    var bodyFormData = new FormData()
    bodyFormData.append("user_id", data.id)

    return http.post("/skp-kegiatan/admin-p2kb", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  delete(id) {
    return http.delete(`/skp-kegiatan/admin-p2kb/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }
}

export default new AdminP2kbService()
