<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card>
      <v-card-title>
        Registrasi Ulang
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <!-- <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="onDownloadInvoice()"
        >
          Download
        </v-btn> -->
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            outlined
            v-model="search.invoice_no"
            append-icon="mdi-magnify"
            label="No Invoice"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
            autofocus
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.tanggal_hadir`]="{ item }">
          {{ getCurrentDate(item.tanggal_hadir) }}
        </template>
        <template v-slot:[`item.status_registrasi`]="{ item }">
          <v-chip
            v-if="item.status_registrasi == 1"
            :color="getColor(item.status_registrasi)"
            dark
          >
            {{ getStatus(item.status_registrasi) }}
          </v-chip>
        </template>
        <template v-slot:[`item.nominal`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.nominal) }}
        </template>
        <template v-slot:[`item.is_event_sent`]="{ item }">
          <v-chip
            v-if="item.metode_bayar == 1"
            :color="getColorSplitted(item.is_event_sent)"
            dark
          >
            {{ getSplittedType(item.is_event_sent) }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <v-icon
              v-if="
                item.metode_bayar == 2 &&
                (item.status == 2 || item.status == 3) &&
                (item.is_approver == 1 || getRoleId == 1)
              "
              medium
              class="mr-3"
              @click="approveItem(item)"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-if="
                item.metode_bayar == 2 &&
                (item.status == 2 || item.status == 3) &&
                (item.is_approver == 1 || getRoleId == 1)
              "
              medium
              class="mr-3"
              @click="rejectItem(item)"
            >
              mdi-close
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-4">
      <InvoiceItemPibComponent
        v-if="selected != undefined && selected.length > 0"
        :id="selected[0].id"
        ref="mychild"
        :key="childKey"
        :invoice_id="id"
      />
    </v-card>
  </div>
</template>

<script>
import KehadiranPibService from "../../services/KehadiranPibService";
import * as moment from "moment";
import store from "../../store/index";
import InvoiceItemPibComponent from "./InvoiceItemPib.vue";

export default {
  components: { InvoiceItemPibComponent },
  data: () => ({
    childKey: 0,
    dialogBuktiBayar: false,
    dialogAnggota: false,
    dialogApprove: false,
    dialogReject: false,
    dialogUpdate: false,
    dialogUploadBuktiBayar: false,
    dialogUploadGaransiLetter: false,
    dialogSponsor: false,
    dialogSponsorModal: false,
    isProgressBarShow: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Iuran & TABSOL",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      tahun: "",
      invoice_no: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
      selectedSponsor: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false },
      { text: "Invoice No", value: "invoice_no", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
      { text: "Tanggal Hadir", value: "tanggal_hadir", sortable: false },

      { text: "Status", value: "status_registrasi", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: false,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    uploadBuktiBayarData: {
      id: "",
      FotoUrl: "",
      file_foto: null,
      fotoKey: 0,
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },

    uploadBuktiGaransiLetter: {
      id: "",
      FotoUrl: "",
      file_foto: null,
      fotoKey: 0,
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      selectedCabangTujuan: "",
      selectedKategori: "",
      selectedCabang: "",
      selectedItem: "",
      no_npa: "",
      cabang_asal_nama: "",
      cabang_asal: "",
      tahun: "",
      nominal: "",
      langsung_setujui: false,
      foto_url: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      nominal_pusat: 900000,
      nominal_cabang: "",
      sponsor: "",
      garansi_sponsor_id: "",
      nama_kontak_person_sponsor: "",
      hp_kontak_person_sponsor: "",
      email_sponsor: "",
      buktiFotoKey: 0,
      buktiFotoUrl: "",
      buktiFotoUrlOriginal: "",
      bukti_file_foto: null,
      user_id_sponsor: "",
      invoice_id: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
      tahunRules: [(v) => !!v || "Tahun harus diisi"],
      nominalRules: [(v) => !!v || "Nominal harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      sponsorRules: [(v) => !!v || "Sponsor harus diisi"],
      namaCpRules: [(v) => !!v || "Nama Kontak Person harus diisi"],
      hpRules: [(v) => !!v || "Hp Kontak Person harus diisi"],
      emailRules: [(v) => !!v || "Email Kontak Person harus diisi"],
      // buktiFotoRules: [(v) => checkFotoExists(v) || "Bukti Foto harus diisi"],

      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_jabatan: [],
    items_divisi: [],
    items_cabang: [],
    items_tahun: [],
    items_sponsor: [],
    items_status: [
      {
        id: 1,
        name: "Menunggu Pembayaran Peserta",
      },
      {
        id: 2,
        name: "Menunggu Konfirmasi Admin",
      },
      {
        id: 4,
        name: "Terverifikasi",
      },
    ],
    items_kategori: [
      {
        id: 1,
        name: "Simposium",
      },
      {
        id: 2,
        name: "Workshop",
      },
      {
        id: 3,
        name: "Paket Wisata",
      },
      {
        id: 4,
        name: "Hotel",
      },
    ],
    items_item: [],

    fotoBuktiBayar: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear(); i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.search.tahun = new Date().getFullYear();

    // this.getDataFromApi();
  },
  methods: {
    changeInvoiceNo() {
      this.getDataFromApi();
    },
    checkFotoExists() {
      if (this.currentData.buktiFotoUrlOriginal) {
        return true;
      } else {
        if (this.currentData.bukti_file_foto) {
          return true;
        } else {
          return "Bukti Foto harus diisi";
        }
      }
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadBuktiBayarData.FotoUrl = e.target.result;
          this.uploadBuktiBayarData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.uploadBuktiBayarData.FotoUrl = "";
        this.uploadBuktiBayarData.file_foto = null;
      }
    },
    loadImageGaransiLetter: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadBuktiGaransiLetter.FotoUrl = e.target.result;
          this.uploadBuktiGaransiLetter.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.uploadBuktiGaransiLetter.FotoUrl = "";
        this.uploadBuktiGaransiLetter.file_foto = null;
      }
    },
    getSplittedType(id) {
      if (id == 1) {
        return "Sudah";
      } else {
        return "Belum";
      }
    },
    getKategori(id) {
      if (id == 1) {
        return "Pembayaran Iuran pendaftaran Anggota";
      } else if (id == 2) {
        return "Pembayaran Tabsol";
      } else if (id == 3) {
        return "Pembayaran perpanjangan Iuran Anggota";
      } else if (id == 4) {
        return "Upload Data Iuran Lama";
      } else if (id == 5) {
        return "Pembayaran Pelatihan";
      } else if (id == 6) {
        return "Upload Tabsol Lama";
      }
    },

    getColorKategori(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "pink lighten-3";
        case 2:
          return "purple lighten-3";
        case 3:
          return "teal lighten-3";
        default:
          return "red lighten-3";
      }
    },

    getColor(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "blue";
      }
    },

    getColorSplitted(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "green";
        default:
          return "red";
      }
    },

    getStatus(id) {
      if (id == 1) {
        return "Registrasi Berhasil";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY HH:mm");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    getDataByKategori() {
      this.getDataFromApi();

      if (this.search.selectedKategori)
        this.getKategoriItem(this.search.selectedKategori.id);
      else this.getKategoriItem("");

      this.search.selectedItem = null;
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var status = "";
      var kategori = "";
      var item = "";
      var sponsor = "";

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedItem) {
        item = this.search.selectedItem.id;
      }

      if (this.search.selectedSponsor) {
        sponsor = this.search.selectedSponsor.id;
      }

      if (this.search.invoice_no) {
        KehadiranPibService.invoiceList(
          page,
          itemsPerPage,
          this.search.nama,
          this.search.invoice_no,
          status,
          kategori,
          item,
          sponsor
        )
          .then((res) => {
            this.search.invoice_no = "";
            this.list_data = res.data.list_data;
            this.totalData = res.data.total;
            this.loading = false;
            this.isFirst = true;

            if (this.list_data && this.list_data.length > 0) {
              this.selected.push(this.list_data[0]);
            } else {
              this.selected = [];
            }
          })
          .catch((err) => {
            this.search.invoice_no = "";
            this.loading = false;
            this.isFirst = true;
            console.log(err);
          });
      } else {
        this.search.invoice_no = "";
        this.list_data = [];
        this.totalData = 0;
        this.loading = false;
        this.isFirst = true;

        if (this.list_data && this.list_data.length > 0) {
          this.selected.push(this.list_data[0]);
        } else {
          this.selected = [];
        }
      }
    },

    async changePropinsi() {
      this.getDataFromApi();
    },
  },

  computed: {
    nominalTotal() {
      var nominalCabang = 0;
      var nominalPusat = 0;

      if (this.nominal_cabang) {
        nominalCabang = this.nominal_cabang;
      }

      if (this.nominal_pusat) {
        nominalPusat = this.nominal_pusat;
      }

      return nominalPusat + nominalCabang;
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
