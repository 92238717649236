<template>
  <div>
    <v-card>
      <v-card-title>
        Master Rekening Event Pusat
        <v-spacer></v-spacer>
        <v-fab-transition v-if="getCabangPengurusId == null">
          <v-btn
            class="mt-8 mr-4"
            v-if="isEditable == 0"
            @click="changeEditable"
            color="pink"
            dark
            absolute
            top
            right
            fab
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4 mt-4">
        <v-row>
          <v-col cols="6">
            <h3 class="">Informasi Pemilik Rekening</h3>
            <v-row dense class="mt-4">
              <v-col cols="12">
                <v-text-field
                  label="Tanggal Lahir"
                  v-model="currentData.user_tanggal_lahir"
                  type="date"
                  outlined
                  dense
                  :rules="currentData.tanggalLahirRules"
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Tempat Lahir"
                  v-model="currentData.user_tempat_lahir"
                  outlined
                  dense
                  :rules="currentData.tempatLahirRules"
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense
              ><v-col cols="12">
                <v-combobox
                  label="Jenis Kelamin"
                  clearable
                  outlined
                  dense
                  v-model="currentData.selectedJenisKelamin"
                  :items="items_jenis_kelamin"
                  item-text="name"
                  :rules="currentData.jenisKelaminRules"
                  :readonly="isEditable == 0"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row dense
              ><v-col cols="12">
                <v-text-field
                  label="No KTP"
                  outlined
                  dense
                  v-model="currentData.user_no_ktp"
                  :rules="currentData.noKtpRules"
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense
              ><v-col cols="12">
                <v-text-field
                  label="No NPWP"
                  outlined
                  dense
                  v-model="currentData.user_npwp"
                  :rules="currentData.noNpwpRules"
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense
              ><v-col cols="12">
                <v-text-field
                  label="Kode POS"
                  outlined
                  dense
                  v-model="currentData.user_kode_pos"
                  :rules="currentData.kodePosRules"
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense
              ><v-col cols="12">
                <v-text-field
                  label="Alamat Lengkap"
                  outlined
                  dense
                  v-model="currentData.user_alamat"
                  :rules="currentData.addressRules"
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                ><v-combobox
                  label="Propinsi"
                  clearable
                  dense
                  outlined
                  v-model="currentData.selectedPropinsi"
                  :items="items_propinsi"
                  item-text="name"
                  @change="changePropinsi"
                  :rules="currentData.propinsiRules"
                  :readonly="isEditable == 0"
                ></v-combobox
              ></v-col>
              <v-col cols="12">
                <v-combobox
                  label="Kabupaten/Kota"
                  clearable
                  dense
                  outlined
                  v-model="currentData.selectedKabupaten"
                  :items="items_kabupaten"
                  item-text="name"
                  @click="getListKabupatenIPaymu"
                  @change="changeKabupaten"
                  :rules="currentData.kabupatenRules"
                  :readonly="isEditable == 0"
                ></v-combobox>
              </v-col>
              <v-col cols="12">
                <v-combobox
                  label="Kecamatan"
                  clearable
                  dense
                  outlined
                  v-model="currentData.selectedKecamatan"
                  :items="items_kecamatan"
                  item-text="name"
                  @click="getListKecamatanIPaymu"
                  @change="changeKecamatan"
                  :rules="currentData.kecamatanRules"
                  :readonly="isEditable == 0"
                ></v-combobox>
              </v-col>
              <v-col cols="12">
                <v-combobox
                  label="Kelurahan"
                  clearable
                  dense
                  outlined
                  v-model="currentData.selectedKelurahan"
                  :items="items_kelurahan"
                  item-text="name"
                  @click="getListKelurahanIPaymu"
                  :rules="currentData.kelurahanRules"
                  :readonly="isEditable == 0"
                ></v-combobox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <h3>Rekening</h3>
            <v-row class="mt-2">
              <v-col>
                <v-combobox
                  label="Bank"
                  clearable
                  dense
                  outlined
                  v-model="currentData.selectedBank"
                  :items="items_bank"
                  item-text="name"
                  :rules="currentData.bankRules"
                  :readonly="isEditable == 0"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Nama Rekening"
                  outlined
                  dense
                  v-model="currentData.nama_rekening"
                  :readonly="isEditable == 0"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="No Rekening"
                  outlined
                  dense
                  v-model="currentData.no_rekening"
                  :readonly="isEditable == 0"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Virtual Account IPaymu"
                  outlined
                  dense
                  v-model="currentData.virtual_account"
                  :readonly="isEditable == 0"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="No Handphone"
                  required
                  v-model="currentData.telp"
                  outlined
                  dense
                  :rules="currentData.phoneRules"
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  required
                  v-model="currentData.email"
                  outlined
                  dense
                  :rules="currentData.emailRules"
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-file-input
                      @change="loadImage"
                      accept="image/png, image/jpeg, image/jpg"
                      v-model="currentData.foto_tabungan"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto Buku Tabungan"
                      outlined
                      dense
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-responsive
                      v-if="currentData.FotoUrl"
                      :aspect-ratio="16 / 9"
                    >
                      <v-img
                        :key="currentData.fotoKey"
                        :src="currentData.FotoUrl"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-file-input
                      @change="loadImageKtp"
                      accept="image/png, image/jpeg, image/jpg"
                      v-model="currentData.user_ktp"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto KTP"
                      outlined
                      dense
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-responsive
                      v-if="currentData.FotoKtpUrl"
                      :aspect-ratio="16 / 9"
                    >
                      <v-img
                        :key="currentData.fotoKtpKey"
                        :src="currentData.FotoKtpUrl"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-file-input
                      @change="loadImageSelfie"
                      accept="image/png, image/jpeg, image/jpg"
                      v-model="currentData.user_selfie"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto Selfie dengan KTP"
                      outlined
                      dense
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-responsive
                      v-if="currentData.FotoSelfieUrl"
                      :aspect-ratio="16 / 9"
                    >
                      <v-img
                        :key="currentData.fotoSelfieKey"
                        :src="currentData.FotoSelfieUrl"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="pb-4">
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              v-if="isEditable == 1"
              @click="doBatal"
              style="margin-right: 10px"
              >Batal</v-btn
            >
            <v-btn
              v-if="isEditable == 1"
              dark
              color="primary"
              type="button"
              @click="doSave"
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import MasterIuranRekeningService from "../services/MasterIuranRekeningService";
import CommonService from "../services/CommonService";
import NakesService from "../services/NakesService";
import store from "../store/index";
import * as moment from "moment";

export default {
  data: () => ({
    valid: true,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Cabang Perdatin",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      selectedPropinsi: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Nama Rekening", value: "nama_rekening", sortable: false },
      { text: "No Rekening", value: "no_rekening", sortable: false },
      {
        text: "Virtual Akun IPaymu",
        value: "virtual_account",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      iuran: "",
      no_rekening: "",
      nama_rekening: "",
      virtual_account: "",
      selectedBank: "",
      urutan: "",
      telp: "",
      fax: "",
      email: "",
      alamat: "",
      website: "",
      latitude: "",
      longitude: "",
      foto_tabungan: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      fotoKtpKey: 0,
      fotoSelfieKey: 0,
      user_tanggal_lahir: "",
      user_tempat_lahir: "",
      selectedGender: "",
      user_no_ktp: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      selectedKecamatan: "",
      selectedKelurahan: "",
      user_kode_pos: "",
      user_alamat: "",
      user_npwp: "",
      user_ktp: null,
      user_selfie: null,
      bankRules: [(v) => !!v || "Bank harus diisi"],
      namaRekeningRules: [(v) => !!v || "Nama Rekening Bank harus diisi"],
      noRekeningRules: [(v) => !!v || "No Rekening Bank harus diisi"],
      phoneRules: [(v) => !!v || "No Handphone harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      tanggalLahirRules: [(v) => !!v || "Tanggal Lahir harus diisi"],
      tempatLahirRules: [(v) => !!v || "Tempat Lahir harus diisi"],
      jenisKelaminRules: [(v) => !!v || "Jenis Kelamin harus diisi"],
      noKtpRules: [(v) => !!v || "No KTP harus diisi"],
      noNpwpRules: [(v) => !!v || "No NPWP harus diisi"],
      kodePosRules: [(v) => !!v || "Kode Pos harus diisi"],
      propinsiRules: [(v) => !!v || "Propinsi harus diisi"],
      kabupatenRules: [(v) => !!v || "Kota/Kabupaten harus diisi"],
      kecamatanRules: [(v) => !!v || "Kecamatan harus diisi"],
      kelurahanRules: [(v) => !!v || "Kelurahan harus diisi"],
      addressRules: [
        (v) => !!v || "Alamat harus diisi",
        (v) => (v && v.length <= 200) || "Maksimal alamat 200 karakter",
      ],
    },
    items_cabang: [],
    items_bank: [],
    items_jenis_kelamin: [
      {
        code: "L",
        name: "Laki-Laki",
      },
      {
        code: "P",
        name: "Perempuan",
      },
    ],
    items_propinsi: [],
    items_kabupaten: [],
    items_kecamatan: [],
    items_kelurahan: [],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListPropinsiIPaymu();
    this.getListMasterBank();
    this.getDataFromApi();
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },
    doBatal: function () {
      this.isEditable = 0;
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$nextTick(() => {
            this.currentData.FotoUrl = e.target.result;
            this.currentData.fotoKey++;
          });
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_tabungan = null;
      }
    },

    loadImageKtp: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$nextTick(() => {
            this.currentData.FotoKtpUrl = e.target.result;
            this.currentData.fotoKtpKey++;
          });
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoKtpUrl = "";
        this.currentData.user_ktp = null;
      }
    },

    loadImageSelfie: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$nextTick(() => {
            this.currentData.FotoSelfieUrl = e.target.result;
            this.currentData.fotoSelfieKey++;
          });
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoSelfieUrl = "";
        this.currentData.user_selfie = null;
      }
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var propinsi = "";

      if (this.search.selectedPropinsi) {
        propinsi = this.search.selectedPropinsi.id;
      }

      this.loading = true;
      MasterIuranRekeningService.masterRekeningEventPusat(
        page,
        itemsPerPage,
        this.search.nama,
        propinsi
      )
        .then((res) => {
          if (res.data.nama_rekening) {
            this.currentData.nama_rekening = res.data.nama_rekening.value;
          }
          if (res.data.no_rekening) {
            this.currentData.no_rekening = res.data.no_rekening.value;
          }
          if (res.data.virtual_account) {
            this.currentData.virtual_account = res.data.virtual_account.value;
          }

          console.log(res.data.pusat);
          this.editedItem = res.data.pusat;

          this.currentData.urutan = this.editedItem.urutan;
          this.currentData.telp = this.editedItem.telp;
          this.currentData.fax = this.editedItem.fax;
          this.currentData.email = this.editedItem.email;
          this.currentData.alamat = this.editedItem.alamat;
          this.currentData.website = this.editedItem.website;

          if (this.editedItem.foto_tabungan_original)
            this.currentData.FotoUrl = this.editedItem.foto_tabungan;
          else this.currentData.FotoUrl = null;

          if (this.editedItem.user_ktp_original)
            this.currentData.FotoKtpUrl = this.editedItem.user_ktp;
          else this.currentData.FotoKtpUrl = null;

          if (this.editedItem.user_selfie_original)
            this.currentData.FotoSelfieUrl = this.editedItem.user_selfie;
          else this.currentData.FotoSelfieUrl = null;

          this.currentData.id = this.editedItem.id;
          this.currentData.user_tempat_lahir =
            this.editedItem.user_tempat_lahir;
          this.currentData.user_no_ktp = this.editedItem.user_no_ktp;
          this.currentData.user_kode_pos = this.editedItem.user_kode_pos;
          this.currentData.user_alamat = this.editedItem.user_alamat;
          this.currentData.user_npwp = this.editedItem.user_npwp;
          this.currentData.fotoKey++;

          if (this.editedItem.user_tanggal_lahir) {
            this.currentData.user_tanggal_lahir = moment(
              this.editedItem.user_tanggal_lahir
            ).format("YYYY-MM-DD");
          }

          this.currentData.selectedBank = null;

          if (this.editedItem.bank_code) {
            this.$nextTick(() => {
              this.currentData.selectedBank = {
                code: this.editedItem.bank_code,
                name: this.editedItem.bank_name,
              };
            });
          }

          this.currentData.selectedJenisKelamin = null;

          this.items_jenis_kelamin.forEach((entry) => {
            if (entry.code == this.editedItem.user_gender) {
              this.currentData.selectedJenisKelamin = entry;
            }
          });

          if (this.editedItem.user_propinsi) {
            this.currentData.selectedPropinsi = {
              code: this.editedItem.user_propinsi,
              name: this.editedItem.user_propinsi_name,
            };
          }

          if (this.editedItem.user_kabupaten) {
            this.currentData.selectedKabupaten = {
              code: this.editedItem.user_kabupaten,
              name: this.editedItem.user_kabupaten_name,
            };
          }

          if (this.editedItem.user_kecamatan) {
            this.currentData.selectedKecamatan = {
              code: this.editedItem.user_kecamatan,
              name: this.editedItem.user_kecamatan_name,
            };
          }

          if (this.editedItem.user_kelurahan) {
            this.currentData.selectedKelurahan = {
              code: this.editedItem.user_kelurahan,
              name: this.editedItem.user_kelurahan_name,
            };
          }

          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      console.log(2);

      if (
        !this.editedItem.foto_tabungan_original &&
        !this.currentData.foto_tabungan
      ) {
        CommonService.showErrorToast("Foto Buku Tabungan harus diisi");
        return;
      }

      console.log(3);
      if (!this.editedItem.user_ktp_original && !this.currentData.user_ktp) {
        CommonService.showErrorToast("Foto KTP harus diisi");
        return;
      }

      console.log(4);
      if (
        !this.editedItem.user_selfie_original &&
        !this.currentData.user_selfie
      ) {
        CommonService.showErrorToast("Foto Selfie dengan KTP harus diisi");
        return;
      }

      console.log(5);
      //check ukuran foto buku tabungan
      if (
        this.currentData.foto_tabungan &&
        this.currentData.foto_tabungan.size > 2 * 1024 * 1024
      ) {
        CommonService.showErrorToast("Maksimal ukuran foto buku tabungan 2MB");
        return;
      }

      console.log(6);
      if (
        this.currentData.user_ktp &&
        this.currentData.user_ktp.size > 2 * 1024 * 1024
      ) {
        CommonService.showErrorToast("Maksimal ukuran foto KTP 2MB");
        return;
      }

      console.log(7);
      if (
        this.currentData.user_selfie &&
        this.currentData.user_selfie.size > 2 * 1024 * 1024
      ) {
        CommonService.showErrorToast(
          "Maksimal ukuran foto Selfie dengan KTP 2MB"
        );
        return;
      }

      MasterIuranRekeningService.updateEvent(this.currentData)
        .then((res) => {
          this.isEditable = 0;
          console.log(res);
          this.dialog = false;
          CommonService.showSuccessToast(
            "Update Master Rekening Event berhasil"
          );

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Update Master Rekening Event gagal. Coba lagi"
          );
        });
    },

    editItem(item) {
      console.log(this.editedItem.id);
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.no_rekening = this.editedItem.no_rekening;
      this.currentData.nama_rekening = this.editedItem.nama_rekening;
      this.currentData.virtual_account = this.editedItem.virtual_account;

      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.telp = this.editedItem.telp;
      this.currentData.fax = this.editedItem.fax;
      this.currentData.email = this.editedItem.email;
      this.currentData.alamat = this.editedItem.alamat;
      this.currentData.website = this.editedItem.website;

      if (this.editedItem.foto_tabungan_original)
        this.currentData.FotoUrl = this.editedItem.foto_tabungan;
      else this.currentData.FotoUrl = null;

      if (this.editedItem.user_ktp_original)
        this.currentData.FotoKtpUrl = this.editedItem.user_ktp;
      else this.currentData.FotoKtpUrl = null;

      if (this.editedItem.user_selfie_original)
        this.currentData.FotoSelfieUrl = this.editedItem.user_selfie;
      else this.currentData.FotoSelfieUrl = null;

      this.currentData.id = this.editedItem.id;
      this.currentData.user_tempat_lahir = this.editedItem.user_tempat_lahir;
      this.currentData.user_no_ktp = this.editedItem.user_no_ktp;
      this.currentData.user_kode_pos = this.editedItem.user_kode_pos;
      this.currentData.user_alamat = this.editedItem.user_alamat;
      this.currentData.user_npwp = this.editedItem.user_npwp;
      this.currentData.fotoKey++;

      if (this.editedItem.user_tanggal_lahir) {
        this.currentData.user_tanggal_lahir = moment(
          this.editedItem.user_tanggal_lahir
        ).format("YYYY-MM-DD");
      }

      this.currentData.selectedBank = null;

      if (this.editedItem.bank_code) {
        this.$nextTick(() => {
          this.currentData.selectedBank = {
            code: this.editedItem.bank_code,
            name: this.editedItem.bank_name,
          };
        });
      }

      this.currentData.selectedJenisKelamin = null;

      this.items_jenis_kelamin.forEach((entry) => {
        if (entry.code == this.editedItem.user_gender) {
          this.currentData.selectedJenisKelamin = entry;
        }
      });

      if (this.editedItem.user_propinsi) {
        this.currentData.selectedPropinsi = {
          code: this.editedItem.user_propinsi,
          name: this.editedItem.user_propinsi_name,
        };
      }

      if (this.editedItem.user_kabupaten) {
        this.currentData.selectedKabupaten = {
          code: this.editedItem.user_kabupaten,
          name: this.editedItem.user_kabupaten_name,
        };
      }

      if (this.editedItem.user_kecamatan) {
        this.currentData.selectedKecamatan = {
          code: this.editedItem.user_kecamatan,
          name: this.editedItem.user_kecamatan_name,
        };
      }

      if (this.editedItem.user_kelurahan) {
        this.currentData.selectedKelurahan = {
          code: this.editedItem.user_kelurahan,
          name: this.editedItem.user_kelurahan_name,
        };
      }

      console.log("do edit");
    },

    async getListMasterBank() {
      NakesService.masterBankList()
        .then((res) => {
          if (res.data.resp.Status == 200) {
            this.items_bank = res.data.resp.Data.bank;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      MasterIuranRekeningService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus Master Rekening berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast(
            "Hapus Master Rekening gagal. Coba lagi"
          );
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async getListPropinsiIPaymu() {
      NakesService.propinsiListIpaymu()
        .then((res) => {
          if (res.data.resp.Status == 200) {
            this.items_propinsi = res.data.resp.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupatenIPaymu() {
      console.log(this.currentData.selectedPropinsi);
      NakesService.kabupatenListIpaymu(this.currentData.selectedPropinsi.code)
        .then((res) => {
          if (res.data.resp.Status == 200) {
            this.items_kabupaten = res.data.resp.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatanIPaymu() {
      NakesService.kecamatanListIpaymu(this.currentData.selectedKabupaten.code)
        .then((res) => {
          if (res.data.resp.Status == 200) {
            this.items_kecamatan = res.data.resp.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahanIPaymu() {
      NakesService.kelurahanListIpaymu(this.currentData.selectedKecamatan.code)
        .then((res) => {
          if (res.data.resp.Status == 200) {
            this.items_kelurahan = res.data.resp.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.currentData.selectedKabupaten = null;
      this.currentData.selectedKecamatan = null;
      this.currentData.selectedKelurahan = null;
    },

    async changeKabupaten() {
      this.currentData.selectedKecamatan = null;
      this.currentData.selectedKelurahan = null;
    },
    async changeKecamatan() {
      this.currentData.selectedKelurahan = null;
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
