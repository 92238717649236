import http from "../../http-common";
/* eslint-disable */
class KatalogBukuService {
  katalogList(page, itemPerPage, keyword) {
    return http.get(
      "/katalogbuku/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getKatalog(id) {
    return http.get(`/katalogbuku/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, foto, ebook) {
    var bodyFormData = new FormData();
    bodyFormData.append("judul", data.judul);
    bodyFormData.append("penulis", data.penulis);
    bodyFormData.append("isbn", data.isbn);
    bodyFormData.append("tahun", data.tahun);
    bodyFormData.append("ukuran_buku", data.ukuran_buku);
    bodyFormData.append("jumlah_halaman", data.jumlah_halaman);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    bodyFormData.append("html", data.content);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("publish", data.selectedHide.text);
    }

    if (foto != null) bodyFormData.append("photo", foto);
    if (ebook != null) bodyFormData.append("ebook", ebook);

    return http.post("/katalogbuku", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data, foto, ebook) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("judul", data.judul);
    bodyFormData.append("penulis", data.penulis);
    bodyFormData.append("isbn", data.isbn);
    bodyFormData.append("tahun", data.tahun);
    bodyFormData.append("ukuran_buku", data.ukuran_buku);
    bodyFormData.append("jumlah_halaman", data.jumlah_halaman);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    bodyFormData.append("html", data.content);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("publish", data.selectedHide.text);
    }

    if (foto != null) bodyFormData.append("photo", foto);
    if (ebook != null) bodyFormData.append("ebook", ebook);

    return http.put(`/katalogbuku/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/katalogbuku/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new KatalogBukuService();
