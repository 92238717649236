<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <loading v-model="isLoading"
              :active="isLoading"/>

    <v-container fluid>
      <v-card>
        <v-card-title>
          <div>Informasi pengajuan SKP Kemkes</div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="userData.status == 0 || userData.status == 5"
            class="mt-8 mr-4"
            @click="ajukanKegiatan"
            color="green"
            dark
          >
            Ajukan
          </v-btn>
          <v-btn
            v-if="
              userData.status != 0 &&
              userData.status != 4 &&
              userData.status != 5 &&
              (getRoleId == 1 ||
                (getUser.admin_diklatlit == 1 && userData.status == 1) ||
                (getUser.admin_p2kb == 1 && userData.status == 2) ||
                ((getUser.admin_diklatlit == 1 || getUser.admin_p2kb == 1) &&
                  userData.status == 3))
            "
            class="mt-8 mr-4"
            @click="approveKegiatan"
            color="green"
            dark
          >
            Setujui
          </v-btn>
          <v-btn
            v-if="
              userData.status != 0 &&
              userData.status != 4 &&
              userData.status != 5 &&
              (getRoleId == 1 ||
                (getUser.admin_diklatlit == 1 && userData.status == 1) ||
                (getUser.admin_p2kb == 1 && userData.status == 2) ||
                ((getUser.admin_diklatlit == 1 || getUser.admin_p2kb == 1) &&
                  userData.status == 3))
            "
            class="mt-8 mr-4"
            @click="rejectKegiatan"
            color="pink"
            dark
          >
            Tolak
          </v-btn>
          <v-btn
            class="mt-8 mr-4"
            v-if="isEditable == 0"
            @click="changeEditable"
            color="blue"
            dark
          >
            Edit Data
          </v-btn>
        </v-card-title>

        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="pl-4 pr-4 pt-4"
        >
          <v-row dense class="ml-1 mb-2">
            <div class="text-h6">Status Kemkes</div>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-combobox
                label="Status pengajuan ke kemes"
                dense
                outlined
                v-model="userData.selectedStatus"
                :items="items_status_pengajuan"
                item-text="value"
                readonly
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-if="
                  userData.selectedStatus && userData.selectedStatus.code == 4
                "
                label="SKP"
                outlined
                dense
                v-model="userData.skp"
                :rules="userData.skpRules"
                :readonly="isEditable == 0"
              ></v-text-field> </v-col
            ><v-col cols="12">
              <v-textarea
                v-if="
                  userData.selectedStatus && userData.selectedStatus.code == 5
                "
                outlined
                label="Catatan Reject"
                v-model="userData.catatan_reject"
                rows="3"
                row-height="5"
                :readonly="isEditable == 0"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row dense class="ml-1 mb-2">
            <div class="text-h6">Info Kegiatan</div>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nama Kegiatan"
                outlined
                dense
                v-model="userData.nama_kegiatan"
                :rules="userData.namaSkpRules"
                :readonly="isEditable == 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Tanggal Kegiatan Terdekat"
                v-model="userData.tanggal_kegiatan_terdekat"
                :rules="userData.tanggalRules"
                type="date"
                dense
                :readonly="isEditable == 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                label="Jenis Kegiatan"
                dense
                outlined
                v-model="userData.selectedJenisKegiatan"
                :items="items_jenis_kegiatan"
                item-text="value"
                :rules="userData.jenisSkpKegiatanRules"
                :readonly="isEditable == 0"
                :clearable="isEditable == 1"
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                label="Kategori Kegiatan"
                dense
                outlined
                v-model="userData.selectedKategoriKegiatan"
                :items="items_kategori_kegiatan"
                item-text="value"
                :rules="userData.kategoriKegiatanRules"
                :readonly="isEditable == 0"
                :clearable="isEditable == 1"
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                label="Cakupan"
                dense
                outlined
                v-model="userData.selectedCakupan"
                :items="items_cakupan_kegiatan"
                item-text="value"
                :rules="userData.cakupanRules"
                :readonly="isEditable == 0"
                :clearable="isEditable == 1"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-combobox
                label="Penyelenggara"
                dense
                outlined
                v-model="userData.selectedTipePenyelenggara"
                :items="items_tipe_penyelenggara"
                item-text="value"
                :rules="userData.jenisSkpKegiatanRules"
                @change="changeTipePenyelenggara"
                :readonly="isEditable == 0"
                :clearable="isEditable == 1"
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                v-if="
                  userData.selectedTipePenyelenggara &&
                  userData.selectedTipePenyelenggara.code == 2
                "
                label="Cabang"
                dense
                outlined
                v-model="userData.selectedCabang"
                :items="items_propinsi"
                item-text="name"
                :rules="userData.cabangRules"
                :readonly="isEditable == 0"
                :clearable="isEditable == 1"
              ></v-combobox>
              <v-combobox
                v-if="
                  userData.selectedTipePenyelenggara &&
                  userData.selectedTipePenyelenggara.code == 3
                "
                label="Keseminatan"
                dense
                outlined
                v-model="userData.selectedKeseminatan"
                :items="items_keseminatan"
                item-text="nama"
                :rules="userData.keseminatanRules"
                :readonly="isEditable == 0"
                :clearable="isEditable == 1"
              ></v-combobox>
              <v-combobox
                v-if="
                  userData.selectedTipePenyelenggara &&
                  userData.selectedTipePenyelenggara.code == 4
                "
                label="Nama Penyelenggara"
                dense
                outlined
                v-model="userData.selectedPenyelenggara"
                :items="items_penyelenggara"
                item-text="nama"
                :rules="userData.penyelenggaraRules"
                :readonly="isEditable == 0"
                :clearable="isEditable == 1"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="Sasaran Profesi"
                outlined
                dense
                v-model="userData.sasaran_profesi"
                :rules="userData.sasaranRules"
                :readonly="isEditable == 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Jumlah JLP"
                outlined
                dense
                v-model="userData.jumlah_jep"
                type="number"
                :rules="userData.jepRules"
                :readonly="isEditable == 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                label="Pengendali Pelatihan"
                dense
                outlined
                v-model="userData.selectedPengendaliPelatihan"
                :items="items_pengendali_pelatihan"
                item-text="nama"
                :rules="userData.pengendaliPelatihanRules"
                :readonly="isEditable == 0"
                :clearable="isEditable == 1"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="Biaya Pelatihan"
                outlined
                dense
                type="number"
                v-model="userData.biaya_pelatihan"
                :rules="userData.biayaPelatihan"
                :readonly="isEditable == 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Moderator"
                outlined
                dense
                v-model="userData.moderator"
                :rules="userData.moderatorRules"
                :readonly="isEditable == 0"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="ml-1 mb-2">
            <div class="text-h6">Penanggung Jawab</div>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nama Penanggung Jawab"
                outlined
                dense
                v-model="userData.nama_penanggung_jawab"
                :rules="userData.namaPjRules"
                :readonly="isEditable == 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Whatsapp Penanggung Jawab"
                outlined
                dense
                v-model="userData.whatsapp_penanggung_jawab"
                :rules="userData.waPjRules"
                :readonly="isEditable == 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nama PIC"
                outlined
                dense
                v-model="userData.nama_pic"
                :rules="userData.namaPicRules"
                :readonly="isEditable == 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Whatsapp PIC"
                outlined
                dense
                v-model="userData.whatsapp_pic"
                :rules="userData.waPicRules"
                :readonly="isEditable == 0"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="ml-1 mb-2">
            <div class="text-h6">Lokasi</div>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Alamat"
                outlined
                dense
                v-model="userData.alamat"
                :rules="userData.alamatRules"
                :readonly="isEditable == 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Propinsi"
                dense
                outlined
                v-model="userData.selectedPropinsi"
                :items="items_propinsi"
                :rules="userData.propinsiRules"
                item-text="name"
                @change="changePropinsi"
                :readonly="isEditable == 0"
                :clearable="isEditable == 1"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Kabupaten/Kota"
                dense
                outlined
                v-model="userData.selectedKabupaten"
                :items="items_kabupaten"
                :rules="userData.kabupatenRules"
                item-text="name"
                @click="getListKabupaten"
                :readonly="isEditable == 0"
                :clearable="isEditable == 1"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-card v-if="userData.file_dokumen_kak_original" class="pa-2"
                ><a :href="userData.fileUrlDokumenKak" target="_blank"
                  >Link File Dokumen Kurikulum</a
                ></v-card
              >
              <v-file-input
                @change="loadDokumenKak"
                accept=".pdf"
                v-model="userData.file_dokumen_kak"
                prepend-icon="mdi-image"
                show-size
                label="Upload File Dokumen Kurikulum"
                :disabled="isEditable == 0"
              />
            </v-col>
          </v-row>

          <v-card>
            <v-card-title>
              Data Fasilitator

              <v-spacer></v-spacer>

              <v-btn
                v-if="isEditable == 1"
                color="primary"
                dark
                class="mb-2 ml-4"
                @click="addFasilitator()"
              >
                Tambah Fasilitator
              </v-btn></v-card-title
            >
            <v-data-table
              :headers="headers"
              :items="list_data"
              :options.sync="options"
              :server-items-length="totalData"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:[`item.file_materi`]="{ item }">
                <a :href="item.file_dokumen_materi" target="_blank"
                  >Link File</a
                >
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon v-if="isEditable == 1" medium @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>

          <v-row class="pb-4 mt-4">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                v-if="isEditable == 1"
                @click="doBatal"
                style="margin-right: 10px"
                >Batal</v-btn
              >
              <v-btn
                v-if="isEditable == 1"
                dark
                color="primary"
                type="button"
                @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <FasilitatorSkpKegiatanSelectComponent
        @pilihFasilitator="pilihFasilitator"
        ref="modalFasilitator"
        v-model="dialogFasilitator"
      />

      <v-dialog v-model="dialogAjukanKegiatan" max-width="700px">
        <v-card>
          <v-card-title class="text-h5"
            >Apa anda akan mengajukan kegiatan ini?</v-card-title
          >
          <v-card-actions>
            <v-container>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogAjukanKegiatan = false"
                  >Tidak</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogAjukanKegiatanConfirm"
                  >Ya</v-btn
                >
              </v-row>
            </v-container>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogApproveKegiatan" max-width="700px">
        <v-card>
          <v-card-title class="text-h5"
            >Apa anda yakin menyetujui kegiatan ini?</v-card-title
          >
          <v-card-actions>
            <v-container>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeApproveKegiatan"
                  >Tidak</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogApproveKegiatanConfirm"
                  >Ya</v-btn
                >
              </v-row>
            </v-container>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogRejectKegiatan" max-width="700px">
        <v-card>
          <v-card-title class="text-h5"
            >Apa anda yakin menolak kegiatan ini?</v-card-title
          >
          <v-card-body>
            <v-form
              ref="formreject"
              v-model="validReject"
              lazy-validation
              class="pl-4 pr-4 pt-4"
            >
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Catatan Reject"
                  v-model="userData.rejectReason"
                  rows="3"
                  row-height="5"
                  :rules="userData.reasonRejectRules"
                ></v-textarea>
              </v-col>
            </v-form>
          </v-card-body>
          <v-card-actions>
            <v-container>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogRejectKegiatan = false"
                  >Tidak</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogRejectKegiatanConfirm"
                  >Ya</v-btn
                >
              </v-row>
            </v-container>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogTambahFasilitatorMateri" max-width="700px">
        <v-card>
          <v-card-title class="text-h5">Tambah Fasilitator</v-card-title>
          <v-form
            ref="formfasilitator"
            v-model="validFasilitator"
            lazy-validation
            class="pl-4 pr-4 pt-4"
          >
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Nama Fasilitator"
                  outlined
                  dense
                  required
                  v-model="userData.fasilitator_nama"
                  readonly
                  @click="openFasilitatorModel"
                  :rules="userData.fasilitatorRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Judul Materi"
                  outlined
                  dense
                  v-model="userData.judul_materi"
                  :rules="userData.judulRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  @change="loadFileMateri"
                  accept=".pdf"
                  v-model="userData.dokumen_materi"
                  prepend-icon="mdi-image"
                  show-size
                  label="Upload File Materi Narasumber"
                  :rules="userData.fileMateriRules"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-container>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogTambahFasilitatorMateri = false"
                  >Tidak</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogAddFasilitatorConfirm"
                  >Ya</v-btn
                >
              </v-row>
            </v-container>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Apa anda akan menghapus data ini?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogDelete = false"
              >Tidak</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Ya</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import SKPService from "../../services/SKPService"
import MasterService from "../../services/MasterService"
import KeseminatanService from "../../services/KeseminatanService"
import PengendaliPelatihanService from "../../services/skp_kemkes/PengendaliPelatihanService"
import PenyelenggaraSkpKemkesService from "../../services/skp_kemkes/PenyelenggaraSkpKemkesService"
import FasilitatorSkpKegiatanSelectComponent from "../../components/FasilitatorSkpKegiatanSelect.vue"
import CommonService from "../../services/CommonService"
import NakesService from "../../services/NakesService"
import * as moment from "moment"
import store from "../../store/index"
import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: "SkpKegiatanEdit",
  components: {
    FasilitatorSkpKegiatanSelectComponent,
    Loading,
  },
  props: {
    id: String,
  },
  data: () => ({
    isLoading: true,
    dialogAjukanKegiatan: false,
    dialogApproveKegiatan: false,
    dialogRejectKegiatan: false,
    dialogFasilitator: false,
    dialogTambahFasilitatorMateri: false,
    isEditable: 0,
    showFileMateri: false,
    showFileKurikulum: false,
    breadcrumbs: [
      {
        text: "Data pengajuan SKP Kemkes",
        exact: true,
        to: { name: "SkpKegiatan" },
      },
      { text: "Edit pengajuan SKP Kemkes", disabled: true },
    ],
    valid: true,
    validFasilitator: true,
    validReject: true,
    userData: {
      status: "",
      rejectReason: "",
      nama_kegiatan: "",
      selectedJenisKegiatan: "",
      selectedKategoriKegiatan: "",
      selectedCakupan: "",
      selectedTipePenyelenggara: "",
      selectedKeseminatan: "",
      selectedPenyelenggara: "",
      selectedStatus: "",
      selectedPengendaliPelatihan: "",
      sasaran_profesi: "",
      biaya_pelatihan: "",
      moderator: "",
      jumlah_jep: "",
      nama_pic: "",
      whatsapp_pic: "",
      tanggal_kegiatan_terdekat: "",
      skp: "",
      dokumen_kak: "",
      file_dokumen_kak: null,
      file_dokumen_kak_original: null,
      fileUrlDokumenKak: "",
      selectedCabang: "",
      selectedFasilitator: "",
      alamat: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      nama_penanggung_jawab: "",
      whatsapp_penanggung_jawab: "",
      judul_materi: "",
      file_dokumen_materi: "",
      file_dokumen_materi_original: "",
      fileUrlDokumenMateri: "",
      catatan_reject: "",
      dokumen_materi: null,
      fasilitator_id: "",
      fasilitator_user_id: "",
      fasilitator_nama: "",
      namaSkpRules: [(v) => !!v || "Nama kegiatan harus diisi"],
      jenisSkpKegiatanRules: [(v) => !!v || "Jenis Kegiatan harus diisi"],
      tipePenyelenggaraRules: [(v) => !!v || "Tipe Penyelenggara harus diisi"],
      penyelenggaraRules: [(v) => !!v || "Penyelenggara harus diisi"],
      namaPenyelenggaraRules: [(v) => !!v || "Nama Penyelenggara harus diisi"],
      kategoriKegiatanRules: [(v) => !!v || "Kategori Kegiatan harus diisi"],
      cakupanRules: [(v) => !!v || "Cakupan Kegiatan harus diisi"],
      fasilitatorRules: [(v) => !!v || "Fasilitator harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      keseminatanRules: [(v) => !!v || "Keseminatan harus diisi"],
      sasaranRules: [(v) => !!v || "Sasaran Profesi harus diisi"],
      biayaPelatihan: [(v) => !!v || "Biaya Pelatihan harus diisi"],
      moderatorRules: [(v) => !!v || "Moderator harus diisi"],
      alamatRules: [(v) => !!v || "Alamat harus diisi"],
      propinsiRules: [(v) => !!v || "Propinsi harus diisi"],
      kabupatenRules: [(v) => !!v || "Kabupaten harus diisi"],
      namaPicRules: [(v) => !!v || "Nama PIC harus diisi"],
      waPicRules: [(v) => !!v || "Whatsapp PIC harus diisi"],
      namaPjRules: [(v) => !!v || "Nama PJ harus diisi"],
      waPjRules: [(v) => !!v || "Whatsapp PJ harus diisi"],
      skpRules: [(v) => !!v || "SKP harus diisi"],
      jepRules: [(v) => !!v || "Jumlah JLP harus diisi"],
      judulRules: [(v) => !!v || "Judul harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      reasonRejectRules: [(v) => !!v || "Catatan Reject harus diisi"],
      fileMateriRules: [(v) => !!v || "File Materi harus diisi"],
    },
    items_jenis_kegiatan: [],
    items_kategori_kegiatan: [],
    items_keseminatan: [],
    items_propinsi: [],
    items_kabupaten: [],
    items_cakupan_kegiatan: [],
    items_fasilitator: [],
    items_status_pengajuan: [],
    items_tipe_penyelenggara: [],
    items_penyelenggara: [],
    items_pengendali_pelatihan: [],
    list_data_fasilitator: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Fasilitator", value: "nama", sortable: false },
      { text: "Judul Materi", value: "judul_materi", sortable: false },
      { text: "File Materi", value: "file_materi", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },
    editedIndex: -1,
    editedItem: {},
    dialogDelete: false,
  }),
  mounted: async function () {
    this.isLoading = true;
    this.getListPropinsi()
    this.getJenisKegiatan()
    this.getKategoriKegiatan()
    this.getCakupanKegiatan()
    this.getStatusKegiatan()
    this.getTipePenyelenggara()
    this.getFasilitator()
    this.getKeseminatan()
    this.getPenyelenggara()
    this.getListPengendaliPelatihan()
    this.getFasilitatorSkpKegiatan()
    this.getSkpKegiatan()
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1
    },

    addFasilitator() {
      // this.$refs.modalFasilitator.getDataFromApi()
      // this.dialogFasilitator = true
      this.userData.fasilitator_id = ""
      this.userData.fasilitator_user_id = ""
      this.userData.fasilitator_nama = ""
      this.userData.judul_materi = ""
      this.userData.dokumen_materi = null
      this.dialogTambahFasilitatorMateri = true
    },
    openFasilitatorModel() {
      this.$refs.modalFasilitator.getDataFromApi()
      this.dialogFasilitator = true
    },
    pilihFasilitator(item) {
      this.dialogAnggota = false

      this.userData.fasilitator_id = item.id
      this.userData.fasilitator_user_id = item.user_id
      this.userData.fasilitator_nama = item.nama

      // const exists = this.list_data_fasilitator.some((val) => val.id == item.id)

      // if (!exists) {
      //   this.list_data_fasilitator.push({
      //     no: this.list_data_fasilitator.length + 1,
      //     nama: item.nama,
      //     no_npa: item.no_npa,
      //     phone: item.phone,
      //     no_ktp: item.no_ktp,
      //     id: item.id,
      //     user_id: item.user_id,
      //   })
      // }
    },
    // deleteItem(val) {
    //   this.list_data_fasilitator = this.list_data_fasilitator.filter(
    //     (item) => item.id !== val.id
    //   )

    //   for (let i = 0; i < this.list_data_fasilitator.length; i++) {
    //     this.list_data_fasilitator[i].no = i + 1
    //   }
    // },

    deleteItem(item) {
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    changeTipePenyelenggara() {
      this.$nextTick(() => {
        this.userData.selectedCabang = null
        this.userData.selectedKeseminatan = null
        this.userData.selectedPenyelenggara = null
      })
    },
    loadDokumenKak: function (data) {
      if (data) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.userData.dokumen_kak = e.target.result
        }
        reader.readAsDataURL(data)
      } else {
        this.userData.dokumen_kak = null
      }
    },

    loadFileMateri: function (data) {
      if (data) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.userData.file_dokumen_materi = e.target.result
        }
        reader.readAsDataURL(data)
      } else {
        this.userData.file_dokumen_materi = null
      }
    },

    async getJenisKegiatan() {
      MasterService.listJenisKegiatanSkp()
        .then((res) => {
          this.items_jenis_kegiatan = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getKategoriKegiatan() {
      MasterService.listKategoriKegiatanSkp()
        .then((res) => {
          this.items_kategori_kegiatan = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getPenyelenggara() {
      PenyelenggaraSkpKemkesService.penyelenggaraListAll()
        .then((res) => {
          this.items_penyelenggara = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getKeseminatan() {
      KeseminatanService.keseminatanListAll()
        .then((res) => {
          this.items_keseminatan = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getTipePenyelenggara() {
      MasterService.listTipePenyelenggaraKegiatanSkp()
        .then((res) => {
          this.items_tipe_penyelenggara = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getCakupanKegiatan() {
      MasterService.listCakupanKegiatanSkp()
        .then((res) => {
          this.items_cakupan_kegiatan = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getFasilitator() {
      MasterService.listFasilitator()
        .then((res) => {
          this.items_fasilitator = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getStatusKegiatan() {
      MasterService.listStatusKegiatanSkp()
        .then((res) => {
          this.items_status_pengajuan = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getListPengendaliPelatihan() {
      PengendaliPelatihanService.pengendaliPelatihanAll()
        .then((res) => {
          this.items_pengendali_pelatihan = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getListPropinsi() {
      SKPService.propinsiList()
        .then((res) => {
          console.log(res)
          this.items_propinsi = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async changePropinsi() {
      this.userData.selectedKabupaten = null
    },

    async getListKabupaten() {
      SKPService.kabupatenList(this.userData.selectedPropinsi.id)
        .then((res) => {
          console.log(res)
          this.items_kabupaten = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getListKabupatenAll(propinsiId, kabupatenId) {
      NakesService.kabupatenList(propinsiId)
        .then((res) => {
          this.items_kabupaten = res.data.list_data
          if (kabupatenId) {
            this.items_kabupaten.forEach((entry) => {
              if (entry.id == kabupatenId) {
                this.userData.selectedKabupaten = entry
              }
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    getFasilitatorSkpKegiatan() {
      this.loading = true
      const { page, itemsPerPage } = this.options

      SKPService.skpKegiatanFasilitatorList(page, itemsPerPage, this.id)
        .then((res) => {
          this.list_data = res.data.list_data
          this.totalData = res.data.total
          this.loading = false
          this.isFirst = true
        })
        .catch((err) => {
          this.loading = false
          this.isFirst = true
          console.log(err)
        })
    },

    getSkpKegiatan() {
      SKPService.getSkpKegiatan(this.id)
        .then((res) => {
          this.userData.nama_kegiatan = res.data.data.nama_kegiatan

          if (res.data.data.tanggal_kegiatan_terdekat) {
            this.userData.tanggal_kegiatan_terdekat = moment(
              res.data.data.tanggal_kegiatan_terdekat
            ).format("YYYY-MM-DD")
          }

          this.items_jenis_kegiatan.forEach((entry) => {
            if (entry.code == res.data.data.jenis_kegiatan) {
              this.userData.selectedJenisKegiatan = entry
            }
          })
          this.items_kategori_kegiatan.forEach((entry) => {
            if (entry.code == res.data.data.kategori_kegiatan) {
              this.userData.selectedKategoriKegiatan = entry
            }
          })
          this.items_cakupan_kegiatan.forEach((entry) => {
            if (entry.code == res.data.data.cakupan) {
              this.userData.selectedCakupan = entry
            }
          })
          this.items_tipe_penyelenggara.forEach((entry) => {
            if (entry.code == res.data.data.tipe_penyelenggara) {
              this.userData.selectedTipePenyelenggara = entry
            }
          })

          if (res.data.data.cabang_id) {
            this.$nextTick(() => {
              this.items_propinsi.forEach((entry) => {
                if (entry.id == res.data.data.cabang_id) {
                  this.userData.selectedCabang = entry
                }
              })
            })
          }

          if (res.data.data.keseminatan_id) {
            this.$nextTick(() => {
              this.items_keseminatan.forEach((entry) => {
                if (entry.id == res.data.data.keseminatan_id) {
                  this.userData.selectedKeseminatan = entry
                }
              })
            })
          }

          if (res.data.data.penyelenggara_id) {
            this.$nextTick(() => {
              this.items_penyelenggara.forEach((entry) => {
                if (entry.id == res.data.data.penyelenggara_id) {
                  this.userData.selectedPenyelenggara = entry
                }
              })
            })
          }

          this.userData.sasaran_profesi = res.data.data.sasaran_profesi
          this.userData.biaya_pelatihan = res.data.data.biaya_pelatihan
          this.userData.moderator = res.data.data.moderator
          this.userData.jumlah_jep = res.data.data.jumlah_jep

          this.items_pengendali_pelatihan.forEach((entry) => {
            if (entry.id == res.data.data.pengendali_pelatihan_id) {
              this.userData.selectedPengendaliPelatihan = entry
            }
          })

          this.items_status_pengajuan.forEach((entry) => {
            if (entry.code == res.data.data.status) {
              this.userData.selectedStatus = entry
            }
          })

          this.userData.status = res.data.data.status

          this.userData.skp = res.data.data.skp
          this.userData.nama_penanggung_jawab =
            res.data.data.nama_penanggung_jawab
          this.userData.whatsapp_penanggung_jawab =
            res.data.data.whatsapp_penanggung_jawab
          this.userData.nama_pic = res.data.data.nama_pic
          this.userData.whatsapp_pic = res.data.data.whatsapp_pic

          this.userData.alamat = res.data.data.alamat

          this.items_propinsi.forEach((entry) => {
            if (entry.id == res.data.data.propinsi_id) {
              this.userData.selectedPropinsi = entry
            }
          })

          if (res.data.data.kabupaten_id) {
            this.getListKabupatenAll(
              res.data.data.propinsi_id,
              res.data.data.kabupaten_id
            )
          }

          this.userData.judul_materi = res.data.data.judul_materi
          this.userData.catatan_reject = res.data.data.catatan_reject
          this.userData.file_dokumen_kak_original =
            res.data.data.dokumen_kak_original
          this.userData.fileUrlDokumenKak = res.data.data.dokumen_kak
          this.userData.file_dokumen_materi_original =
            res.data.data.dokumen_materi_original
          this.userData.fileUrlDokumenMateri = res.data.data.dokumen_materi

          this.list_data_fasilitator = res.data.list_fasilitator
          this.isLoading = false;

        })
        .catch((err) => {
          console.log(err)
        })
    },

    doBatal: function () {
      this.$router.go()
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0)
        return
      }

      let loader = this.$loading.show()

      SKPService.update(
        this.id,
        this.userData,
        this.userData.file_dokumen_kak,
        this.userData.dokumen_materi,
        this.list_data_fasilitator
      )
        .then(() => {
          loader.hide()
          this.isEditable = 0
          CommonService.showSuccessToast("Update pengajuan SKP Kemkes berhasil")
        })
        .catch((err) => {
          loader.hide()
          console.log(err)
          CommonService.showErrorToast(
            "Update pengajuan SKP Kemkes gagal. Coba lagi"
          )
        })
    },

    ajukanKegiatan() {
      this.dialogAjukanKegiatan = true
    },

    approveKegiatan() {
      this.dialogApproveKegiatan = true
    },

    rejectKegiatan() {
      this.dialogRejectKegiatan = true
    },

    closeApproveKegiatan() {
      this.dialogApproveKegiatan = false
    },

    dialogAjukanKegiatanConfirm() {
      this.dialogAjukanKegiatan = false
      let loader = this.$loading.show()

      SKPService.approvalSkpKegiatan(this.id, 1)
        .then(() => {
          loader.hide()
          CommonService.showSuccessToast("Kegiatan berhasil diajukan")

          this.getSkpKegiatan()
        })
        .catch((err) => {
          loader.hide()
          console.log(err)
          CommonService.showErrorToast("Pengajuan kegiatan gagal. Coba lagi")
        })
    },

    dialogApproveKegiatanConfirm() {
      this.dialogApproveKegiatan = false
      let loader = this.$loading.show()

      SKPService.approvalSkpKegiatan(this.id, 2)
        .then(() => {
          loader.hide()
          CommonService.showSuccessToast("Kegiatan berhasil disetujui")

          this.getSkpKegiatan()
        })
        .catch((err) => {
          loader.hide()
          console.log(err)
          CommonService.showErrorToast("Kegiatan gagal disetujui. Coba lagi")
        })
    },

    dialogRejectKegiatanConfirm() {
      if (!this.$refs.formreject.validate()) {
        window.scrollTo(0, 0)
        return
      }

      this.dialogRejectKegiatan = false
      SKPService.rejectSkpKegiatan(this.id, this.userData.rejectReason)
        .then(() => {
          CommonService.showSuccessToast("Kegiatan berhasil ditolak")

          this.getSkpKegiatan()
        })
        .catch((err) => {
          console.log(err)
          CommonService.showErrorToast("Kegiatan gagal ditolak. Coba lagi")
        })
    },

    dialogAddFasilitatorConfirm() {
      if (!this.$refs.formfasilitator.validate()) {
        window.scrollTo(0, 0)
        return
      }

      let loader = this.$loading.show()

      SKPService.tambahFasilitator(
        this.id,
        this.userData.fasilitator_user_id,
        this.userData.judul_materi,
        this.userData.dokumen_materi
      )
        .then(() => {
          loader.hide()
          CommonService.showSuccessToast("Tambah Fasilitator berhasil")

          this.getFasilitatorSkpKegiatan()
        })
        .catch((err) => {
          loader.hide()
          console.log(err)
          CommonService.showErrorToast("Tambah Fasilitator gagal. Coba lagi")
        })

      this.dialogTambahFasilitatorMateri = false
    },

    deleteItemConfirm() {
      this.dialogDelete = false

      let loader = this.$loading.show()
      SKPService.deleteFasilitator(this.editedItem.fasilitator_id)
        .then(() => {
          loader.hide()
          CommonService.showSuccessToast("Berhasil menghapus Fasilitator")

          this.getFasilitatorSkpKegiatan()
        })
        .catch((err) => {
          loader.hide()
          console.log(err)
          CommonService.showErrorToast("Gagal menghapus fasilitator. Coba lagi")
        })
    },
  },
  computed: {
    getRoleId() {
      return store.getters.getUser.role_id
    },
    getIsVerified() {
      return this.isVerified
    },
    getUser() {
      return store.getters.getUser
    },
  },
}
</script>
