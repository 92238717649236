<template>
  <div>
    <v-card
      ><v-tabs v-model="tab">
        <v-tab>Peserta</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <GaransiSponsorPesertaComponent :id="id" />
      </v-tabs-items>
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import WorkshopKurikulumService from "../../services/WorkshopKurikulumService";
import CommonService from "../../services/CommonService";
import GaransiSponsorPesertaComponent from "./GaransiSponsorPeserta.vue";

export default {
  props: {
    id: String,
    workshop_id: String,
  },
  components: {
    GaransiSponsorPesertaComponent,
  },
  data: () => ({
    tab: null,
    childKey: 0,
    isEditable: 0,
    valid: true,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "judul", sortable: false },
      { text: "Deskripsi", value: "deskripsi", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      pelatihan_id: "",
      id: null,
      judul: "",
      deskripsi: "",
      judulRules: [(v) => !!v || "Judul harus diisi"],
      deskripsiRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      WorkshopKurikulumService.workshopKurikulumList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.dialog = true;
      this.currentData.judul = "";
      this.currentData.deskripsi = "";
      this.isEditable = 1;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      this.currentData = this.editedItem;

      this.isEditable = 1;
    },

    doSave: async function() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.workshop_id = this.id;
        WorkshopKurikulumService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah kurikulum berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showSuccessToast("Tambah kurikulum gagal. Coba lagi");
          });
      } else {
        this.currentData.workshop_id = this.id;

        WorkshopKurikulumService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update kurikulum berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showSuccessToast("Update kurikulum gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      WorkshopKurikulumService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus kurikulum berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Hapus kurikulum gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
