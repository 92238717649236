import { render, staticRenderFns } from "./PengurusWilayahEdit.vue?vue&type=template&id=43b2418c&scoped=true"
import script from "./PengurusWilayahEdit.vue?vue&type=script&lang=js"
export * from "./PengurusWilayahEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43b2418c",
  null
  
)

export default component.exports