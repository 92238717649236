<template>
  <div>
    <v-card>
      <v-card-title>
        Layanan <br />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari layanan"
          single-line
          hide-details
          v-on:keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="mb-2" @click="openTambah">
          Tambah
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Layanan</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-combobox
                      label="Layanan"
                      dense
                      outlined
                      v-model="currentLayanan"
                      :items="layananList"
                      item-text="nama"
                      :readonly="isEditable == 0"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Harga"
                      required
                      v-model="layananData.harga"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-card-title>
        <v-row>
          <h6 class="ml-3">Faskes {{ currentFaskes.nama }}</h6>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import FaskesLayananService from "../services/FaskesLayananService";

export default {
  props: {
    id: String,
  },
  data: () => ({
    isEditable: 0,
    tab: null,
    breadcrumbs: [
      {
        text: "Faskes",
        disabled: false,
        exact: true,
        to: { name: "Faskes" },
      },
      {
        text: "Data Nakes",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Layanan", value: "layanan_nama", sortable: false },
      { text: "Harga", value: "harga", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    layananData: {
      ID: null,
      faskes_id: "",
      layanan_id: "",
      layanan_nama: "",
      harga: 0,
    },

    layananList: [],
    currentLayanan: null,
  }),
  computed: {},
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    optionsNakes: {
      handler() {
        this.getNakesFromApi();
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
    this.getFaskes();
  },
  methods: {
    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      FaskesLayananService.faskesLayananList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      FaskesLayananService.faskesLayananOptionList(this.id)
        .then((res) => {
          console.log(res);
          this.dialog = true;
          this.layananData.faskes_id = this.id;

          this.layananList = res.data.list_data;
          this.isEditable = 1;
          this.currentLayanan = null;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getFaskes() {
      FaskesLayananService.getFaskes(this.id)
        .then((res) => {
          this.currentFaskes = res.data.faskes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      FaskesLayananService.faskesLayananOptionList(this.id)
        .then((res) => {
          console.log(res);
          this.dialog = true;
          this.layananData.faskes_id = this.editedItem.faskes_id;
          this.layananData.layanan_id = this.editedItem.layanan_id;
          this.layananData.harga = this.editedItem.harga;
          this.layananData.ID = this.editedItem.id;

          this.layananList = res.data.list_data;
          this.isEditable = 0;

          if (this.layananData.layanan_id) {
            this.layananList.forEach((entry) => {
              if (entry.id == this.layananData.layanan_id) {
                this.currentLayanan = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      console.log("do add");
    },

    doSave: async function () {
      if (this.layananData.ID == null) {
        if (this.currentLayanan != null) {
          this.layananData.layanan_id = this.currentLayanan.id;
        } else {
          this.$toast.success("Layanan harus diisi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          return;
        }
        FaskesLayananService.create(this.layananData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            this.$toast.success("Tambah layanan berhasil", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error("Tambah layanan gagal. Coba lagi", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          });
      } else {
        FaskesLayananService.update(this.layananData.ID, this.layananData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            this.$toast.success("Update layanan berhasil", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error("Update layanan gagal. Coba lagi", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      FaskesLayananService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Hapus Layanan berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus Layanan gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
