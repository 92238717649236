<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Informasi Rumah Sakit </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <!-- <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img :key="fotoKey" :src="userData.FotoUrl" contain></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                @change="loadImage"
                accept="image/*"
                v-model="userData.file_foto"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
              />
            </v-col> -->
            <v-col cols="12" sm="12" md="12">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Nama Rumah Sakit"
                    outlined
                    dense
                    v-model="userData.nama"
                    required
                    :rules="userData.namaRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    label="Jenis RS"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedJenisRs"
                    :items="items_jenis_rs"
                    item-text="value"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    label="Kelas RS"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedKelasRs"
                    :items="items_kelas_rs"
                    item-text="value"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="No Telepon"
                    outlined
                    dense
                    v-model="userData.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                    v-model="userData.email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Propinsi"
                clearable
                dense
                outlined
                v-model="userData.selectedPropinsi"
                :items="items_propinsi"
                item-text="name"
                @change="changePropinsi"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Kabupaten/Kota"
                clearable
                dense
                outlined
                v-model="userData.selectedKabupaten"
                :items="items_kabupaten"
                item-text="name"
                @click="getListKabupaten"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Kecamatan"
                clearable
                dense
                outlined
                v-model="userData.selectedKecamatan"
                :items="items_kecamatan"
                item-text="name"
                @click="getListKecamatan"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Kelurahan"
                clearable
                dense
                outlined
                v-model="userData.selectedKelurahan"
                :items="items_kelurahan"
                item-text="name"
                @click="getListKelurahan"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Alamat Lengkap"
                    outlined
                    dense
                    v-model="userData.alamat"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="latitude"
                    outlined
                    dense
                    v-model="userData.latitude"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Longitude"
                    outlined
                    dense
                    v-model="userData.longitude"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <!-- Modal -->
      <v-dialog v-model="dialogFaskes" max-width="600px">
        <v-card>
          <v-card-title>
            Faskes
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchNakes"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              hide-details
              v-on:keyup.enter="getNakesFromApi"
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="list_data"
            :options.sync="options"
            :server-items-length="totalData"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                class="ma-2"
                color="primary"
                dark
                @click="pilihFaskes(item)"
              >
                Pilih
                <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogFaskes = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import RumahSakitService from "../services/RumahSakitService";
import CommonService from "../services/CommonService";
import store from "../store/index";
import NakesService from "../services/NakesService";
import JenisRSService from "../services/JenisRSService";
import KelasRSService from "../services/KelasRSService";

export default {
  name: "RumahSakitAdd",
  components: {},
  data: () => ({
    komunitas_key: 0,
    breadcrumbs: [
      {
        text: "Data Rumah Sakit",
        disabled: false,
        exact: true,
        to: { name: "RumahSakit" },
      },
      { text: "Tambah Rumah Sakit", disabled: true },
    ],
    valid: true,
    userData: {
      nama: "",
      email: "",
      phone: "",
      alamat: "",
      FotoUrl: "",
      FotoUrlKtp: "",
      FotoUrlIjazah: "",
      FotoUrlStr: "",
      FotoUrlStm: "",
      file_foto: null,
      file_foto_ktp: null,
      file_foto_ijazah: null,
      file_foto_str: null,
      file_foto_stm: null,
      gelar_depan: "",
      latitude: "",
      longitude: "",

      gelar_belakang: "",

      profesi_id: "",
      str: "",
      no_ktp: "",
      no_npa: "",
      npa_idi: "",
      tempat_lahir: "",
      selectedProfesi: "",
      selectedJenisKelamin: "",
      selectedStatusPegawai: "",
      selectedGolonganPegawai: "",
      selectedAgama: "",
      selectedCommunity: "",
      selectedPropinsi: "",
      selectedCabang: "",
      selectedKabupaten: "",
      selectedKecamatan: "",
      selectedKelurahan: "",
      selectedJenisAnggota: "",
      selectedJenisRs: "",
      selectedKelasRs: "",
      no_anggota: "",
      tanggal_lahir: "",
      address: "",
      masa_berlaku: "",
      perguruan_tinggi: "",
      perguruan_tinggi_spesialis: "",
      tahun_lulus: "",
      tahun_lulus_spesialis: "",
      tahun_masuk_spesialis: "",
      jenisRsRules: [(v) => !!v || "Jenis RS harus diisi"],
      kelasRsRules: [(v) => !!v || "Kelas RS harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      jenisAnggotaRules: [(v) => !!v || "Jenis Anggota harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      phoneRules: [(v) => !!v || "No Telepon harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      noAnggotaRules: [(v) => !!v || "Email harus diisi"],
      perguruanTinggiRules: [
        (v) => !!v || "Universitas Dokter Umum harus diisi",
      ],
      perguruanTinggiSpesialisRules: [
        (v) => !!v || "Universitas Spesialis Anestesiologi harus diisi",
      ],
      tahunLulusRules: [(v) => !!v || "Tahun Lulus Dokter Umum harus diisi"],
      tahunMasukSpesialisRules: [
        (v) => !!v || "Tahun Masuk Spesialis Anestesiologi harus diisi",
      ],
      tahunLulusSpesialisRules: [
        (v) => !!v || "Tahun Lulus Spesialis Anestesiologi harus diisi",
      ],
    },
    items_profesi: [],
    items_jenis_kelamin: [],
    items_status_pegawai: [],
    items_golongan_pegawai: [],
    items_jenis_anggota: [],
    items_agama: [],
    items_community: [],
    items_propinsi: [],
    items_kabupaten: [],
    items_kecamatan: [],
    items_kelurahan: [],
    items_jenis_rs: [],
    items_kelas_rs: [],
    result: {},
    fotoKey: 0,
    fotoKeyKtp: 0,
    fotoKeyIjazah: 0,
    fotoKeyStr: 0,
    fotoKeyStm: 0,
    currentFaskes: {
      id: "",
      nama: "",
    },
    dialogFaskes: false,
    searchNakes: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Kategori", value: "category_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },
  }),
  mounted: async function () {
    this.getListPropinsi();
    this.getListJenisRs();
    this.getListKelasRs();
  },
  computed: {
    imageSrc() {
      return this.userData.FotoUrl;
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getRolesId() {
      return store.getters.getUser.roles_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUserData() {
      return store.getters.getUser;
    },
  },
  methods: {
    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.userData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatan() {
      NakesService.kecamatanList(this.userData.selectedKabupaten.id)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahan() {
      NakesService.kelurahanList(this.userData.selectedKecamatan.id)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListJenisRs() {
      JenisRSService.jenisRSAll()
        .then((res) => {
          this.items_jenis_rs = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelasRs() {
      KelasRSService.kelasRSAll()
        .then((res) => {
          this.items_kelas_rs = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.userData.selectedKabupaten = null;
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      RumahSakitService.create(this.userData)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Rumah Sakit berhasil");

          this.$router
            .push("/rumahsakit-edit/" + res.data.rumahsakit.id)
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data?.code == 1) {
            CommonService.showErrorToast(err.response.data?.message);
          } else {
            CommonService.showErrorToast("Tambah anggota gagal. Coba lagi");
          }
        });
    },
  },
};
</script>

<style scoped>
</style>
