import http from "../http-common"
/* eslint-disable */
class KeseminatanService {
  keseminatanList(page, itemPerPage, keyword, userId) {
    return http.get(
      "/nakes/keseminatan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  keseminatanListAll() {
    return http.get("/keseminatan/list/all", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  create(data) {
    return http.post("/nakes/keseminatan", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  }

  update(data) {
    return http.put(`/nakes/keseminatan/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  }

  delete(id) {
    return http.delete(`/nakes/keseminatan/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  }
}

export default new KeseminatanService()
