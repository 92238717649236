import http from "../http-common";
/* eslint-disable */
class SimposiumService {
  simposiumList(page, itemPerPage, keyword, id) {
    return http.get(
      "/pib-simposium/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.pib_id) bodyFormData.append("pib_id", data.pib_id);
    if (data.harga) bodyFormData.append("jenis_anggota", data.code);
    if (data.harga) bodyFormData.append("harga", data.harga);

    return http.put(`/pib-simposium/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new SimposiumService();
