<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card>
      <v-card-title>
        Peserta<br />

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="onDownloadAnggota()"
        >
          Download
        </v-btn>

        <v-btn color="primary" dark class="ml-4 mb-2" @click="openTambah">
          Tambah
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Peserta</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Sponsor"
                        outlined
                        dense
                        required
                        v-model="currentData.sponsor"
                        readonly
                        @click="openSponsorModal"
                        :rules="currentData.sponsorRules"
                      ></v-text-field>
                      <!-- <GaransiSponsorCalonComponent
                        :id="id"
                        :refresh="refresh"
                      /> -->
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Peserta"
                        outlined
                        dense
                        required
                        v-model="currentData.nama"
                        readonly
                        @click="openAnggotaModal"
                        :rules="currentData.namaRules"
                      ></v-text-field>
                      <!-- <GaransiSponsorCalonComponent
                        :id="id"
                        :refresh="refresh"
                      /> -->
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Kontak Person"
                        outlined
                        dense
                        required
                        v-model="currentData.nama_kontak_person_sponsor"
                        :rules="currentData.namaCpRules"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        label="No Hp Kontak Person"
                        outlined
                        dense
                        required
                        v-model="currentData.hp_kontak_person_sponsor"
                        :rules="currentData.hpRules"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        label="Email"
                        outlined
                        dense
                        required
                        v-model="currentData.email_sponsor"
                        :rules="currentData.emailRules"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="currentData.buktiFotoKey"
                            :src="currentData.buktiFotoUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImageBuktiFoto"
                        accept="image/*"
                        v-model="currentData.bukti_file_foto"
                        prepend-icon="mdi-image"
                        show-size
                        label="Bukti Garansi Letter"
                        :rules="currentData.buktiFotoRules"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogUploadBuktiBayar" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Upload Bukti Bayar</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="uploadBuktiBayarData.fotoKey"
                            :src="uploadBuktiBayarData.FotoUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImage"
                        accept="image/*"
                        v-model="uploadBuktiBayarData.file_foto"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto"
                        :rules="uploadBuktiBayarData.fotoRules"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogUploadBuktiBayar = false"
              >
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSaveUploadBuktiBayar">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogBuktiBayar" max-width="600px" scrollable>
          <v-card>
            <v-img
              height="auto"
              width="auto"
              :src="fotoBuktiBayar"
              contain
            ></v-img>
          </v-card>
        </v-dialog>
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search.nama"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search.sponsor"
            append-icon="mdi-magnify"
            label="Cari Sponsor"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Status Bayar Sponsor"
            clearable
            dense
            outlined
            v-model="search.selectedStatusBayarSponsor"
            :items="items_status_bayar_sponsor"
            @change="getDataFromApi"
            item-text="name"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
        :single-select="true"
        show-select
      >
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>

        <template v-slot:[`item.bukti_transfer_sponsor`]="{ item }">
          <v-btn
            v-if="item.bukti_transfer_sponsor_original"
            color="blue"
            dark
            class="mb-2"
            @click="showFotoBuktiBayar(item.bukti_transfer_sponsor)"
          >
            View
          </v-btn>
        </template>

        <template v-slot:[`item.bukti_bayar_sponsor`]="{ item }">
          <v-btn
            v-if="item.bukti_bayar_sponsor_original"
            color="blue"
            dark
            class="mb-2"
            @click="showFotoBuktiBayar(item.bukti_bayar_sponsor)"
          >
            View
          </v-btn>
        </template>

        <template v-slot:[`item.status_bayar_sponsor`]="{ item }">
          <v-chip :color="getColorSponsor(item.status_bayar_sponsor)" dark>
            {{ getStatusSponsor(item.status_bayar_sponsor) }}
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon> -->
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-icon medium v-bind="attrs" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="openUploadBuktiBayar(item.invoice_id)">
                <v-list-item-title>Upload Bukti Bayar</v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="openUpdateStatusBayarSponsor(item.invoice_id)"
              >
                <v-list-item-title
                  >Update Status Bayar Sponsor</v-list-item-title
                >
              </v-list-item>

              <v-list-item @click="deleteItem(item)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="jadikanAnggota(item)">
                    <v-list-item-title>Jadikan Anggota</v-list-item-title>
                  </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogUpdateStatusBayar" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Status Bayar Sponsor</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="formbayarsponsor"
            v-model="valid"
            lazy-validation
            class="pl-4 pr-4"
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-combobox
                    label="Status"
                    clearable
                    dense
                    outlined
                    v-model="
                      updateStatusBayarSponsor.selectedStatusBayarSponsor
                    "
                    :items="items_status_bayar_sponsor"
                    item-text="name"
                    :rules="
                      updateStatusBayarSponsor.selectedStatusBayarSponsorRules
                    "
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogUpdateStatusBayar = false"
          >
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="doSaveStatusBayarSponsor">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal -->
    <v-card class="mt-4">
      <GaransiSponsorInvoiceItemPibComponent
        v-if="selected != undefined && selected.length > 0"
        :id="selected[0].invoice_id"
        ref="mychild"
        :key="childKey"
      />
    </v-card>

    <AnggotaSelectComponent
      @pilihAnggota="pilihAnggota"
      ref="modalAnggota"
      v-model="dialogAnggota"
      :id="id"
    />

    <SponsorSelectComponent
      @pilihSponsor="pilihSponsor"
      ref="modalSponsor"
      v-model="dialogSponsor"
    />
  </div>
</template>

<script>
import GaransiSponsorPesertaService from "../../services/GaransiSponsorPesertaService";
import CommonService from "../../services/CommonService";
// import GaransiSponsorCalonComponent from "./GaransiSponsorCalon.vue";
import GaransiSponsorInvoiceItemPibComponent from "./GaransiSponsorInvoiceItemPib.vue";
import AnggotaSelectComponent from "../../components/PibAnggotaSelect.vue";
import SponsorSelectComponent from "../../components/SponsorSelect.vue";
import InvoicePibService from "../../services/InvoicePibService";

export default {
  props: {
    id: String,
    workshop_id: String,
  },
  components: {
    // GaransiSponsorCalonComponent,
    GaransiSponsorInvoiceItemPibComponent,
    AnggotaSelectComponent,
    SponsorSelectComponent,
  },
  data: () => ({
    isProgressBarShow: false,
    dialogAnggota: false,
    dialogSponsor: false,
    dialogUpdateStatusBayar: false,
    childKey: 0,
    isEditable: 0,
    valid: true,
    search: {
      nama: "",
      sponsor: "",
      selectedStatusBayarSponsor: "",
    },
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false, width: "300px" },
      { text: "Sponsor", value: "nama_perusahaan", sortable: false },
      { text: "No Invoice", value: "invoice_no", sortable: false },
      {
        text: "Nama Kontak Person",
        value: "nama_kontak_person_sponsor",
        sortable: false,
      },
      { text: "No Hp", value: "hp_kontak_person_sponsor", sortable: false },
      { text: "Email", value: "email_sponsor", sortable: false },
      {
        text: "Bukti Garansi Letter",
        value: "bukti_transfer_sponsor",
        sortable: false,
      },
      {
        text: "Bukti Bayar Sponsor",
        value: "bukti_bayar_sponsor",
        sortable: false,
      },
      {
        text: "Status Bayar Sponsor",
        value: "status_bayar_sponsor",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      sponsor: "",
      garansi_sponsor_id: "",
      email_sponsor: "",
      buktiFotoKey: 0,
      buktiFotoUrl: "",
      bukti_file_foto: null,
      namaRules: [(v) => !!v || "Nama harus diisi"],
      sponsorRules: [(v) => !!v || "Sponsor harus diisi"],
      buktiFotoRules: [(v) => !!v || "Bukti Foto harus diisi"],
      namaCpRules: [(v) => !!v || "Nama Kontak Person harus diisi"],
      hpRules: [(v) => !!v || "No Hp Kontak Person harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
    },

    uploadBuktiBayarData: {
      id: "",
      FotoUrl: "",
      file_foto: null,
      fotoKey: 0,
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },

    dialogUploadBuktiBayar: false,
    fotoBuktiBayar: null,
    dialogBuktiBayar: false,

    items_status_bayar_sponsor: [
      {
        id: 1,
        name: "Belum Bayar",
      },
      {
        id: 2,
        name: "Sudah Bayar",
      },
    ],

    updateStatusBayarSponsor: {
      id: "",
      selectedStatusBayarSponsor: "",
      selectedStatusBayarSponsorRules: [
        (v) => !!v || "Status Bayar harus diisi",
      ],
    },
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    openUpdateStatusBayarSponsor(id) {
      this.updateStatusBayarSponsor.id = id;
      this.dialogUpdateStatusBayar = true;
    },

    getColorSponsor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "red";
        case 2:
          return "green";
        default:
          return "red";
      }
    },

    getStatusSponsor(id) {
      if (id == 1) {
        return "Belum Bayar";
      } else if (id == 2) {
        return "Sudah Bayar";
      }
    },

    doSaveStatusBayarSponsor() {
      if (!this.$refs.formbayarsponsor.validate()) {
        return;
      }

      InvoicePibService.updateStatusBayarSponsor(this.updateStatusBayarSponsor)
        .then((res) => {
          console.log(res);
          this.dialogUpdateStatusBayar = false;
          CommonService.showSuccessToast(
            "Update Status Bayar Sponsor berhasil"
          );

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Update Status Bayar Sponsor gagal. Coba lagi"
          );
        });
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var statusBayarSponsor = "";

      if (this.search.selectedStatusBayarSponsor) {
        statusBayarSponsor = this.search.selectedStatusBayarSponsor.id;
      }

      GaransiSponsorPesertaService.pesertaList(
        page,
        itemsPerPage,
        this.search,
        this.id,
        statusBayarSponsor
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.dialog = true;
      this.currentData.sponsor = "";
      this.currentData.nama = "";
      this.currentData.nama_kontak_person_sponsor = "";
      this.currentData.email_sponsor = "";
      this.currentData.hp_kontak_person_sponsor = "";
      this.currentData.garansi_sponsor_id = "";
      this.currentData.user_id = "";
      this.currentData.buktiFotoUrl = "";
      this.currentData.bukti_file_foto = "";
      this.isEditable = 1;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      this.currentData = this.editedItem;

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.pib_id = this.id;
        GaransiSponsorPesertaService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah peserta berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Tambah peserta gagal. Coba lagi");
          });
      } else {
        this.currentData.pib_id = this.id;

        GaransiSponsorPesertaService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update peserta berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Update peserta gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      GaransiSponsorPesertaService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus peserta berhasil");

          this.selected = [];
          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Hapus peserta gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refresh() {
      this.dialog = false;
      this.getDataFromApi();
    },

    openAnggotaModal: async function () {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihAnggota(item) {
      this.dialogAnggota = false;
      console.log(item);
      this.currentData.nama = item.nama;
      this.currentData.user_id = item.id;
    },

    openSponsorModal: async function () {
      this.$refs.modalSponsor.getDataFromApi();
      this.dialogSponsor = true;
    },

    pilihSponsor(item) {
      this.dialogSponsor = false;
      console.log(item);
      this.currentData.sponsor = item.nama_perusahaan;
      this.currentData.garansi_sponsor_id = item.id;
      this.currentData.nama_kontak_person_sponsor = item.contact_person;
      this.currentData.email_sponsor = item.email;
      this.currentData.hp_kontak_person_sponsor = item.hp;
    },

    openUploadBuktiBayar(invoiceId) {
      this.uploadBuktiBayarData.id = invoiceId;
      console.log(invoiceId);
      this.dialogUploadBuktiBayar = true;
    },

    doSaveUploadBuktiBayar() {
      if (!this.$refs.form.validate()) {
        return;
      }

      InvoicePibService.uploadBuktiBayarData(this.uploadBuktiBayarData)
        .then((res) => {
          this.dialogUploadBuktiBayar = false;

          console.log(res);

          CommonService.showSuccessToast("Update Bukti Bayar berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Update Bukti Bayar gagal. Coba lagi");
        });
    },

    showFotoBuktiBayar(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadBuktiBayarData.FotoUrl = e.target.result;
          this.uploadBuktiBayarData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.uploadBuktiBayarData.FotoUrl = "";
        this.uploadBuktiBayarData.file_foto = null;
      }
    },
    loadImageBuktiFoto: function (data) {
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.buktiFotoUrl = e.target.result;
          this.currentData.buktiFotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.buktiFotoUrl = "";
        this.currentData.bukti_file_foto = null;
      }
    },

    onDownloadAnggota() {
      this.isProgressBarShow = true;

      var statusBayarSponsor = "";

      if (this.search.selectedStatusBayarSponsor) {
        statusBayarSponsor = this.search.selectedStatusBayarSponsor.id;
      }

      GaransiSponsorPesertaService.downloadPesertaReport(
        this.search,
        this.id,
        statusBayarSponsor
      )
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },
  },
};
</script>
