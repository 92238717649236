<template>
  <v-card>
    <v-card-title>
      Anggota
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Cari Nama"
        single-line
        hide-details
        v-on:keyup.enter="getDataFromApi"
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-dialog v-model="dialogAdd" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Tambah sebagai Admin Diklatlit?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeAdd">Tidak</v-btn>
            <v-btn color="blue darken-1" text @click="addItemConfirm">Ya</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="list_data"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-chip color="cyan" dark @click="addItem(item)">
          Tambah Admin Diklatlit
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CommonService from "../../../services/CommonService"
import AdminDiklatlitService from "../../../services/skp_kemkes/AdminDiklatlitService"

export default {
  props: {
    id: String,
    refresh: Function,
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No NPA", value: "no_npa", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialogAdd: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options

      this.loading = true
      AdminDiklatlitService.userNonList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data
          this.totalData = res.data.total
          this.loading = false
          this.isFirst = true
        })
        .catch((err) => {
          this.loading = false
          this.isFirst = true
          console.log(err)
        })
    },

    addItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogAdd = true
    },

    addItemConfirm() {
      this.closeAdd()

      AdminDiklatlitService.create(this.editedItem)
        .then((res) => {
          console.log(res)

          CommonService.showSuccessToast("Tambah data berhasil")

          this.getDataFromApi()
          this.refresh()
        })
        .catch((err) => {
          console.log(err)

          CommonService.showErrorToast("Tambah data gagal. Coba lagi")
        })
    },

    closeAdd() {
      this.dialogAdd = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
