<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab>Inasia</v-tab>
          <v-tab>Simposium</v-tab>
          <v-tab>Hybrid Simposium</v-tab>
          <v-tab>Workshop</v-tab>
          <v-tab>Hotel</v-tab>
          <v-tab>Contact Person</v-tab>
          <v-tab>Speaker</v-tab>
          <v-tab>Inasia Got Talent</v-tab>
          <v-tab>Hospitality</v-tab>
          <v-tab>Peserta</v-tab>
          <v-tab>Invoice</v-tab>
          <v-tab>Garansi Letter Sponsor</v-tab>
          <v-tab>E-Poster</v-tab>
          <v-tab>Master Rekening</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card class="pl-4 pr-4 pt-4 pb-4">
              <v-card-title> </v-card-title>
              <v-fab-transition>
                <v-btn
                  class="mt-8 mr-4"
                  v-if="isEditable == 0"
                  @click="changeEditable"
                  color="pink"
                  dark
                  absolute
                  top
                  right
                  fab
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6" class="pr-4">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="fotoKey"
                            :src="currentData.FotoUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImage"
                        accept="image/*"
                        v-model="currentData.file_foto"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto"
                        :disabled="isEditable == 0"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Judul"
                            dense
                            rows="3"
                            v-model="currentData.nama"
                            :rules="currentData.namaRules"
                            :readonly="isEditable == 0"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Deskripsi"
                            dense
                            value=""
                            v-model="currentData.deskripsi"
                            :rules="currentData.deskripsiRules"
                            :readonly="isEditable == 0"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-row dense>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Tanggal Awal"
                                v-model="currentData.tanggal_awal"
                                type="date"
                                dense
                                :readonly="isEditable == 0"
                                :rules="currentData.tanggalAwalRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Tanggal Akhir"
                                v-model="currentData.tanggal_akhir"
                                type="date"
                                dense
                                :readonly="isEditable == 0"
                                :rules="currentData.tanggalAkhirRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-btn
                        v-if="isEditable == 1"
                        @click="doBatal"
                        style="margin-right: 10px"
                        >Batal</v-btn
                      >
                      <v-btn
                        v-if="isEditable == 1"
                        dark
                        color="primary"
                        type="button"
                        @click="doSubmit"
                        >Simpan</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>

              <InasiaSambutanComponent :id="id" />
              <InasiaVenueComponent :id="id" />
              <InasiaFlyerComponent :id="id" />
              <InasiaHospitalityBannerComponent :id="id" />
              <InasiaGalaDinnerBannerComponent :id="id" />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <SimposiumComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <HybridSimposiumComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <WorkshopComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InasiaHotelComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <ContactPersonComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InasiaSpeakerComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InasiaGotTalentComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InasiaHospitalityComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InasiaPesertaComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InasiaInvoiceComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InasiaGaransiSponsorComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InasiaEposterComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InasiaMasterRekeningEventComponent :id="id" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import CommonService from "../../services/CommonService";
import InasiaService from "../../services/inasia/InasiaService";
import MasterService from "../../services/MasterService";
import * as moment from "moment";
import InasiaSambutanComponent from "./InasiaSambutan.vue";
import InasiaVenueComponent from "./InasiaVenue.vue";
import InasiaFlyerComponent from "./InasiaFlyer.vue";
import InasiaHospitalityBannerComponent from "./InasiaHospitalityBanner.vue";
import InasiaGalaDinnerBannerComponent from "./InasiaGalaDinnerBanner.vue";
import WorkshopComponent from "./InasiaWorkshop.vue";
import SimposiumComponent from "./InasiaSimposium.vue";
import HybridSimposiumComponent from "./InasiaHybridSimposium.vue";
import InasiaHotelComponent from "./InasiaHotel.vue";
import ContactPersonComponent from "./InasiaContactPerson.vue";
import InasiaGotTalentComponent from "./InasiaGotTalent.vue";
import InasiaHospitalityComponent from "./InasiaHospitality.vue";
import InasiaSpeakerComponent from "./InasiaSpeaker.vue";
import InasiaPesertaComponent from "./InasiaPeserta.vue";
import InasiaInvoiceComponent from "./InasiaInvoice.vue";
import InasiaEposterComponent from "./InasiaEPoster.vue";
import InasiaGaransiSponsorComponent from "./InasiaGaransiSponsor.vue";
import InasiaMasterRekeningEventComponent from "./InasiaMasterRekeningEvent.vue";

export default {
  name: "PibEdit",
  components: {
    InasiaSambutanComponent,
    InasiaVenueComponent,
    InasiaFlyerComponent,
    WorkshopComponent,
    SimposiumComponent,
    HybridSimposiumComponent,
    InasiaHotelComponent,
    ContactPersonComponent,
    InasiaGotTalentComponent,
    InasiaHospitalityComponent,
    InasiaSpeakerComponent,
    InasiaEposterComponent,
    InasiaPesertaComponent,
    InasiaInvoiceComponent,
    InasiaGaransiSponsorComponent,
    InasiaHospitalityBannerComponent,
    InasiaGalaDinnerBannerComponent,
    InasiaMasterRekeningEventComponent
  },
  props: {
    id: String,
  },
  data: () => ({
    tab: null,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Inasia",
        disabled: false,
        exact: true,
        to: { name: "Inasia" },
      },
      { text: "Edit Inasia", disabled: true },
    ],
    valid: true,
    currentData: {
      FotoUrl: "",
      FotoUrlHybrid: "",
      FotoUrlSambutan: "",
      file_foto: null,
      file_hybrid_foto: null,
      file_foto_sambutan: null,
      sambutan_kalimat: "",
      id: "",
      nama: "",
      deskripsi: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      simposium_lokasi: "",
      simposium_tanggal_awal: "",
      simposium_tanggal_akhir: "",
      simposium_kuota: "",
      simposium_jam_awal: "",
      simposium_jam_akhir: "",
      simposium_peserta: "",
      simposium_harga: "",
      venue_nama: "",
      venue_alamat: "",
      venue_tanggal_awal: "",
      venue_tanggal_akhir: "",
      venue_jam_awal: "",
      venue_jam_akhir: "",
      venue_pic_nama: "",
      venue_pic_phone: "",
      venue_link_google_maps: "",
      simposium_hybrid_lokasi: "",
      simposium_hybrid_tanggal_awal: "",
      simposium_hybrid_tanggal_akhir: "",
      simposium_hybrid_kuota: "",
      simposium_hybrid_jam_awal: "",
      simposium_hybrid_jam_akhir: "",
      simposium_hybrid_peserta: "",
      simposium_hybrid_harga: "",
      is_venue_jam_akhir: false,
      fotoRules: [(v) => !!v || "Foto harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      deskripsiRules: [(v) => !!v || "Deskripsi harus diisi"],
      tanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      tanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      simposiumLokasiRules: [(v) => !!v || "Lokasi harus diisi"],
      simposiumTanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      simposiumTanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      simposiumKuotaRules: [(v) => !!v || "Kuota harus diisi"],
      simposiumJamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      simposiumJamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      simposiumPesertaRules: [(v) => !!v || "Peserta harus diisi"],
      simposiumHargaRules: [(v) => !!v || "Harga harus diisi"],
      venueNamaRules: [(v) => !!v || "Nama Venue harus diisi"],
      venueAlamatRules: [(v) => !!v || "Alamat harus diisi"],
      venueTanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      venueTanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      venueJamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      venueJamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      venuePicNamaRules: [(v) => !!v || "Nama PIC harus diisi"],
      venuePicPhoneRules: [(v) => !!v || "No Telp PIC harus diisi"],
      venueLinkGoogleMapRules: [(v) => !!v || "Link Google Map harus diisi"],
      sambutanKalimatRulesRules: [(v) => !!v || "Sambutan harus diisi"],
      simposiumHybridLokasiRules: [(v) => !!v || "Lokasi harus diisi"],
      simposiumHybridTanggalAwalRules: [
        (v) => !!v || "Tanggal Awal harus diisi",
      ],
      simposiumHybridTanggalAkhirRules: [
        (v) => !!v || "Tanggal Akhir harus diisi",
      ],
      simposiumHybridKuotaRules: [(v) => !!v || "Kuota harus diisi"],
      simposiumHybridJamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      simposiumHybridJamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      simposiumHybridPesertaRules: [(v) => !!v || "Peserta harus diisi"],
      simposiumHybridHargaRules: [(v) => !!v || "Harga harus diisi"],
    },
    items_kategori: [],
    fotoKey: 0,
    fotoKeyHybrid: 0,
    fotoKeySambutan: 0,
  }),
  mounted: async function () {
    this.getOne();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    loadImageHybrid: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrlHybrid = e.target.result;
          this.fotoKeyHybrid++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrlHybrid = "";
        this.currentData.file_hybrid_foto = null;
      }
    },

    loadImageSambutan: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrlSambutan = e.target.result;
          this.fotoKeySambutan++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrlSambutan = "";
        this.currentData.file_foto_sambutan = null;
      }
    },

    getOne() {
      InasiaService.getOne(this.id)
        .then((res) => {
          this.currentData.nama = res.data.data.nama;
          this.currentData.deskripsi = res.data.data.deskripsi;
          this.currentData.sambutan_kalimat = res.data.data.sambutan_kalimat;
          this.currentData.tanggal_awal = moment(
            res.data.data.tanggal_awal
          ).format("YYYY-MM-DD");
          this.currentData.tanggal_akhir = moment(
            res.data.data.tanggal_akhir
          ).format("YYYY-MM-DD");

          if (res.data.data.photo)
            this.currentData.FotoUrl = res.data.data.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.isEditable = 0;
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        CommonService.showErrorToast("Mohon lengkapi input yang dibutuhkan");
        // window.scrollTo(0, 0);
        return;
      }

      InasiaService.update(
        this.id,
        this.currentData,
        this.currentData.file_foto,
        this.currentData.file_hybrid_foto
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Edit Inasia berhasil");

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Edit Inasia gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped></style>
