import http from "../http-common";
/* eslint-disable */
class KelasRSService {
  kelasRSList(page, itemPerPage, keyword) {
    return http.get(
      "/admin/kelasrs/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  kelasRSAll() {
    return http.get("/admin/kelasrs/all", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("value", data.value);

    return http.post("/kelasrs", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("value", data.value);

    return http.put(`/kelasrs/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/kelasrs/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new KelasRSService();
