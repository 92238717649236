<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card
      ><v-tabs v-model="tab">
        <v-tab>EPoster</v-tab>
        <v-tab>EPoster Peserta</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card class="pl-4 pr-4 pt-4 pb-4">
            <v-card-title> EPoster </v-card-title>
            <v-fab-transition>
              <v-btn
                class="mt-8 mr-4"
                v-if="isEditable == 0"
                @click="changeEditable"
                color="pink"
                dark
                absolute
                top
                right
                fab
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-fab-transition>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="currentData.fotoKey"
                          :src="currentData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImage"
                      accept="image/*"
                      v-model="currentData.file_foto"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto EPoster"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Judul"
                          dense
                          rows="3"
                          v-model="currentData.eposter_judul"
                          :rules="currentData.eposterJudulRules"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Deskripsi"
                          dense
                          value=""
                          v-model="currentData.eposter_deskripsi"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-row dense>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              label="Tanggal Awal"
                              v-model="currentData.eposter_tanggal_awal"
                              type="date"
                              dense
                              :readonly="isEditable == 0"
                              :rules="currentData.tanggalAwalRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              label="Tanggal Akhir"
                              v-model="currentData.eposter_tanggal_akhir"
                              type="date"
                              dense
                              :readonly="isEditable == 0"
                              :rules="currentData.tanggalAkhirRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Kontak Person"
                          outlined
                          dense
                          v-model="currentData.eposter_cp"
                          required
                          :readonly="isEditable == 0"
                          :rules="currentData.eposterCpRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      @click="doBatal"
                      style="margin-right: 10px"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-title>
              E-Poster
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Apa anda akan menghapus data ini?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Tidak</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Ya</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogApprove" max-width="600px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Apa anda akan mengapprove invoice peserta
                    ini?</v-card-title
                  >
                  <v-card-actions>
                    <v-container>
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeApprove"
                          >Tidak</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="approveItemConfirm"
                          >Ya</v-btn
                        >
                      </v-row>
                    </v-container>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogBuktiBayar" max-width="600px">
                <v-card>
                  <v-img
                    height="auto"
                    width="auto"
                    :src="fotoBuktiBayar"
                  ></v-img>
                </v-card>
              </v-dialog>
            </v-card-title>

            <v-row class="mx-6 mb-6">
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="search.nama"
                  append-icon="mdi-magnify"
                  label="Cari nama"
                  single-line
                  hide-details
                  v-on:keyup.enter="getDataFromApi"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                class="mb-2 ml-4"
                @click="onDownloadEposter()"
              >
                Download
              </v-btn>
            </v-row>

            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="list_data"
              :options.sync="options"
              :server-items-length="totalData"
              :loading="loading"
              class="elevation-1"
            >
              <template v-slot:[`item.jenis_eposter`]="{ item }">
                {{ getJeniEPoster(item.jenis_eposter) }}
              </template>
              <template v-slot:[`item.foto_url`]="{ item }">
                <a
                  v-if="item.foto_url_original"
                  :href="item.foto_url"
                  target="_blank"
                  >Link Foto</a
                >
              </template>
              <template v-slot:[`item.file`]="{ item }">
                <a v-if="item.file_original" :href="item.file" target="_blank"
                  >Link File PDF</a
                >
              </template>
              <template v-slot:[`item.abstrak`]="{ item }">
                <a
                  v-if="item.abstrak_original"
                  :href="item.abstrak"
                  target="_blank"
                  >Link File PDF</a
                >
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="
                    (item.kategori == 4 || item.kategori == 6) &&
                    (item.status == 2 || item.status == 3) &&
                    (item.is_approver == 1 || getRoleId == 1)
                  "
                  medium
                  class="mr-3"
                  @click="approveItem(item)"
                >
                  mdi-check
                </v-icon>
                <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import EPosterPibService from "../../services/EPosterPibService";
import CommonService from "../../services/CommonService";
import PibService from "../../services/PibService";
import * as moment from "moment";
import store from "../../store/index";

export default {
  props: {
    id: String,
  },
  components: {},
  data: () => ({
    isProgressBarShow: false,
    tab: null,
    isEditable: 0,
    childKey: 0,
    dialogBuktiBayar: false,
    dialogAnggota: false,
    dialogApprove: false,
    dialogUpdate: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Iuran & TABSOL",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      tahun: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Asal Institusi", value: "asal_institusi", sortable: false },
      { text: "No Telepon", value: "whatsapp", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Jenis E-Poster", value: "jenis_eposter", sortable: false },
      { text: "Foto", value: "foto_url", sortable: false },
      { text: "File PDF", value: "file", sortable: false },
      { text: "Lampiran Abstrak", value: "abstrak", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      file_foto: null,
      FotoUrl: "",
      eposter_judul: "",
      eposter_deskripsi: "",
      eposter_cp: "",
      eposter_tanggal_awal: "",
      eposter_tanggal_akhir: "",
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      selectedCabangTujuan: "",
      selectedKategori: "",
      selectedCabang: "",
      selectedItem: "",
      no_npa: "",
      cabang_asal_nama: "",
      cabang_asal: "",
      tahun: "",
      nominal: "",
      langsung_setujui: false,
      foto_url: null,
      fileKey: 0,
      fotoKey: 0,
      nominal_pusat: 900000,
      nominal_cabang: "",
      fotoBuktiBayar: "",
      eposterPhotoRules: [(v) => !!v || "Foto harus diisi"],
      eposterJudulRules: [(v) => !!v || "Judul harus diisi"],
      tanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      tanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      eposterCpRules: [(v) => !!v || "Kontak Person harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
      tahunRules: [(v) => !!v || "Tahun harus diisi"],
      nominalRules: [(v) => !!v || "Nominal harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_jabatan: [],
    items_divisi: [],
    items_cabang: [],
    items_tahun: [],
    items_status: [
      {
        id: 1,
        name: "Menunggu Pembayaran Peserta",
      },
      {
        id: 4,
        name: "Terverifikasi",
      },
    ],
    items_kategori: [
      {
        id: 1,
        name: "Simposium",
      },
      {
        id: 2,
        name: "Workshop",
      },
      {
        id: 3,
        name: "Paket Wisata",
      },
    ],
    items_item: [],

    fotoBuktiBayar: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getPib();
    this.getDataFromApi();
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },
    lihatFoto(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },
    getSplittedType(id) {
      if (id == 1) {
        return "Sudah";
      } else {
        return "Belum";
      }
    },
    getKategori(id) {
      if (id == 1) {
        return "Pembayaran Iuran pendaftaran Anggota";
      } else if (id == 2) {
        return "Pembayaran Tabsol";
      } else if (id == 3) {
        return "Pembayaran perpanjangan Iuran Anggota";
      } else if (id == 4) {
        return "Upload Data Iuran Lama";
      } else if (id == 5) {
        return "Pembayaran Pelatihan";
      } else if (id == 6) {
        return "Upload Tabsol Lama";
      }
    },

    getColorKategori(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "pink lighten-3";
        case 2:
          return "purple lighten-3";
        case 3:
          return "teal lighten-3";
        default:
          return "red lighten-3";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "red";
        case 2:
          return "green";
        case 3:
          return "cyan";
        case 4:
          return "blue";
        default:
          return "red";
      }
    },

    getColorSplitted(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "green";
        default:
          return "red";
      }
    },

    getJeniEPoster(id) {
      if (id == 1) {
        return "Original Research";
      } else if (id == 2) {
        return "Case Report";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    getDataByKategori() {
      this.getDataFromApi();

      if (this.search.selectedKategori)
        this.getKategoriItem(this.search.selectedKategori.id);
      else this.getKategoriItem("");

      this.search.selectedItem = null;
    },

    getPib() {
      PibService.getPib(this.id)
        .then((res) => {
          this.currentData.eposter_judul = res.data.pib.eposter_judul;
          this.currentData.eposter_deskripsi = res.data.pib.eposter_deskripsi;

          if (res.data.pib.eposter_tanggal_awal)
            this.currentData.eposter_tanggal_awal = moment(
              res.data.pib.eposter_tanggal_awal
            ).format("YYYY-MM-DD");

          if (res.data.pib.eposter_tanggal_akhir)
            this.currentData.eposter_tanggal_akhir = moment(
              res.data.pib.eposter_tanggal_akhir
            ).format("YYYY-MM-DD");

          this.currentData.eposter_cp = res.data.pib.eposter_cp;

          if (res.data.pib.eposter_photo)
            this.currentData.FotoUrl = res.data.pib.eposter_photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var status = "";
      var kategori = "";
      var item = "";

      EPosterPibService.eposterList(
        page,
        itemsPerPage,
        this.search.nama,
        status,
        kategori,
        item,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getKategoriItem(kategori) {
      EPosterPibService.getKategoriItem(kategori)
        .then((res) => {
          this.items_item = res.data.list_data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.no_npa = "";
      this.currentData.tahun = "";
      this.currentData.nominal = "";
      this.currentData.foto_url = null;
      this.currentData.FotoUrl = "";
      this.currentData.langsung_setujui = false;
      this.currentData.selectedDivisi = "";
      this.currentData.selectedJabatan = "";
      this.currentData.cabang_asal_nama = "";
      this.currentData.cabang_asal = "";
      this.currentData.selectedKategori = null;
      this.currentData.langsung_setujui = false;

      console.log("do add");
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        EPosterPibService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialogUpdate = false;
            CommonService.showSuccessToast("Update iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update iuran gagal. Coba lagi");
          });
      } else {
        EPosterPibService.create(this.currentData, this.currentData.foto_url)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah iuran gagal. Coba lagi");
          });
      }
    },

    doBatal: function () {
      this.isEditable = 0;
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      PibService.updateEPoster(
        this.id,
        this.currentData,
        this.currentData.file_foto
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Edit E-Poster berhasil");

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Edit E-Poster gagal. Coba lagi");
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogUpdate = true;
      this.currentData.nama = this.editedItem.user_nama;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.id = this.editedItem.id;
      this.currentData.cabang_asal = this.editedItem.cabang_asal;
      this.currentData.cabang_asal_nama = this.editedItem.cabang_asal_nama;

      this.items_cabang.forEach((entry) => {
        if (entry.id == this.editedItem.cabang_tujuan) {
          this.currentData.selectedCabangTujuan = entry;
        }
      });

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      EPosterPibService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus eposter berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus eposter gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function (file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },

    openAnggotaModal: async function () {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihAnggota(item) {
      this.dialogAnggota = false;
      console.log(item);
      this.currentData.nama = item.nama;
      this.currentData.user_id = item.id;
      this.currentData.cabang_asal = item.cabang_id;
      this.currentData.cabang_asal_nama = item.propinsi_nama;
      this.currentData.no_npa = item.no_npa;
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      EPosterPibService.approve(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve iuran berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve iuran gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    async getMasterIuran() {
      if (
        this.currentData.selectedKategori &&
        this.currentData.selectedKategori.id != 2 &&
        this.currentData.nama &&
        this.currentData.tahun
      ) {
        EPosterPibService.getMasterIuran(
          this.currentData.cabang_asal,
          this.currentData.tahun
        )
          .then((res) => {
            var invoice = res.data.invoice;
            if (invoice) this.currentData.nominal_cabang = invoice.nominal;
            else this.currentData.nominal_cabang = 0;

            this.currentData.nominal =
              this.currentData.nominal_cabang + this.currentData.nominal_pusat;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.currentData.nominal = 0;
      }
    },

    showFotoBuktiBayar(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },

    async changePropinsi() {
      this.getDataFromApi();
    },

    onDownloadEposter() {
      this.isProgressBarShow = true;

      EPosterPibService.downloadEposter(this.search.nama, this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },
  },

  computed: {
    nominalTotal() {
      var nominalCabang = 0;
      var nominalPusat = 0;

      if (this.nominal_cabang) {
        nominalCabang = this.nominal_cabang;
      }

      if (this.nominal_pusat) {
        nominalPusat = this.nominal_pusat;
      }

      return nominalPusat + nominalCabang;
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
