<template>
  <div>
    <v-card>
      <v-card-title>
        Workshop <br />

        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="mb-2" @click="openTambah">
          Tambah
        </v-btn>
        <v-dialog v-model="dialog" persistent>
          <v-card>
            <v-card-title>
              <span class="text-h5">Workshop</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6" class="pr-4">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="fotoKey"
                            :src="currentData.FotoUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImage"
                        accept="image/*"
                        v-model="currentData.file_foto"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto"
                        :rules="currentData.fotoRules"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Nama"
                            outlined
                            dense
                            v-model="currentData.nama"
                            required
                            :rules="currentData.namaRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Deskripsi"
                            dense
                            value=""
                            v-model="currentData.deskripsi"
                            :rules="currentData.deskripsiRules"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Lokasi"
                            outlined
                            dense
                            v-model="currentData.lokasi"
                            required
                            :rules="currentData.lokasiRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Tanggal Awal"
                            v-model="currentData.tanggal_awal"
                            type="date"
                            dense
                            :rules="currentData.tanggalAwalRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Tanggal Akhir"
                            v-model="currentData.tanggal_akhir"
                            type="date"
                            dense
                            :rules="currentData.tanggalAkhirRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Jam Awal"
                            v-model="currentData.jam_awal"
                            type="time"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Jam Akhir"
                            v-model="currentData.jam_akhir"
                            type="time"
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h5>Konten</h5>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        label="Kuota Peserta"
                        outlined
                        dense
                        v-model="currentData.kuota"
                        required
                        :rules="currentData.kuotaRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        label="Partisipan"
                        outlined
                        dense
                        v-model="currentData.partisipan"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menonaktifkan data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAktif" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan meng-aktifkan data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAktif"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="aktifItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Judul"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Status"
            clearable
            dense
            outlined
            v-model="data_status"
            :items="items_status"
            item-text="nama"
            @change="getDataFromApi"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.tanggal_awal`]="{ item }">
          {{ getCurrentDate(item.tanggal_awal) }}
        </template>
        <template v-slot:[`item.description`]="{ item }">
          {{ truncate(item.description) }}
        </template>
        <template v-slot:[`item.data_status`]="{ item }">
          <div v-if="item.data_status == 1">Aktif</div>
          <div v-else>Tidak Aktif</div>
        </template>
        <template v-slot:[`item.harga`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row
            ><v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="item.data_status == 1"
              medium
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon v-else medium @click="aktifItem(item)"> mdi-check </v-icon>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-4">
      <InasiaWorkshopDetailComponent
        v-if="selected != undefined && selected.length > 0"
        :id="selected[0].id"
        ref="mychild"
        :key="childKey"
        :inasiaId="id"
        :harga="selected[0].harga"
      />
    </v-card>
    <!-- Modal -->
  </div>
</template>

<script>
import WorkshopService from "../../services/inasia/InasiaWorkshopService"
import * as moment from "moment"
import CommonService from "../../services/CommonService"
import InasiaWorkshopDetailComponent from "./InasiaWorkshopDetail.vue"

export default {
  props: {
    id: String,
  },
  components: {
    InasiaWorkshopDetailComponent,
  },
  data: () => ({
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    search: "",
    data_status: null,
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "nama", sortable: false },
      { text: "Deskripsi", value: "deskripsi", sortable: false },
      { text: "Partisipan", value: "partisipan", sortable: false },
      { text: "Tanggal Awal", value: "tanggal_awal", sortable: false },
      { text: "Status", value: "data_status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    dialogAktif: false,

    currentFaskes: {
      nama: "",
    },

    items_status: [
      {
        id: 1,
        nama: "Aktif",
      },
      {
        id: 0,
        nama: "Tidak Aktif",
      },
    ],

    currentData: {
      inasia_id: "",
      id: null,
      FotoUrl: "",
      file_foto: null,
      nama: "",
      deskripsi: "",
      lokasi: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      kuota: "",
      harga: "",
      early_bird_tanggal_awal: "",
      early_bird_tanggal_akhir: "",
      harga_early_bird: "",
      partisipan: "",
      jam_awal: "",
      jam_akhir: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      deskripsiRules: [(v) => !!v || "Deskripsi harus diisi"],
      lokasiRules: [(v) => !!v || "Lokasi harus diisi"],
      tanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      tanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      kuotaRules: [(v) => !!v || "Kuota harus diisi"],
      hargaRules: [(v) => !!v || "Harga harus diisi"],
      // fotoRules: [(v) => !!v || "Foto harus diisi"],
      hargaEarlyBirdRules: [(v) => !!v || "Harga Early Bird harus diisi"],
    },
    fotoKey: 0,

    layananList: [],
    currentLayanan: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl
    },
  },
  mounted() {
    this.data_status = this.items_status[0]

    this.getDataFromApi()
  },
  methods: {
    truncate: function (input) {
      if (input && input.length > 10) {
        return input.substring(0, 5) + "..."
      }
      return input
    },
    loadImage: function (data) {
      console.log("loadImage:", data)
      if (data) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result
          this.fotoKey++
        }
        reader.readAsDataURL(data)
      } else {
        this.currentData.FotoUrl = ""
        this.currentData.file_foto = null
      }
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY")
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options

      this.loading = true

      let myStatus = ""

      if (this.data_status) {
        myStatus = this.data_status.id
      }

      WorkshopService.workshopList(
        page,
        itemsPerPage,
        this.search,
        this.id,
        myStatus
      )
        .then((res) => {
          this.list_data = res.data.list_data
          this.totalData = res.data.total
          this.loading = false
          this.isFirst = true
        })
        .catch((err) => {
          this.loading = false
          this.isFirst = true
          console.log(err)
        })
    },

    openTambah() {
      this.currentData.id = null
      this.currentData.FotoUrl = ""
      this.currentData.file_foto = null
      this.currentData.nama = ""
      this.currentData.deskripsi = ""
      this.currentData.lokasi = ""
      this.currentData.tanggal_awal = ""
      this.currentData.tanggal_akhir = ""
      this.currentData.kuota = ""
      this.currentData.harga = ""
      this.currentData.early_bird_tanggal_awal = ""
      this.currentData.early_bird_tanggal_akhir = ""
      this.currentData.harga_early_bird = ""
      this.currentData.partisipan = ""
      this.currentData.jam_awal = ""
      this.currentData.jam_akhir = ""
      this.dialog = true
      this.isEditable = 1

      this.currentData.fotoRules = [(v) => !!v || "Foto harus diisi"]
    },

    editItem(item) {
      this.currentData.fotoRules = []
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.dialog = true
      console.log(this.editedItem)
      // this.currentData = this.editedItem;

      this.currentData.id = this.editedItem.id
      this.currentData.nama = this.editedItem.nama
      this.currentData.deskripsi = this.editedItem.deskripsi
      this.currentData.lokasi = this.editedItem.lokasi
      this.currentData.kuota = this.editedItem.kuota
      this.currentData.partisipan = this.editedItem.partisipan

      if (this.editedItem.jam_awal)
        this.currentData.jam_awal = this.editedItem.jam_awal

      if (this.editedItem.jam_akhir)
        this.currentData.jam_akhir = this.editedItem.jam_akhir

      this.currentData.tanggal_awal = moment(
        this.editedItem.tanggal_awal
      ).format("YYYY-MM-DD")

      this.currentData.tanggal_akhir = moment(
        this.editedItem.tanggal_akhir
      ).format("YYYY-MM-DD")

      if (this.editedItem.early_bird_tanggal_awal) {
        this.currentData.early_bird_tanggal_awal = moment(
          this.editedItem.early_bird_tanggal_awal
        ).format("YYYY-MM-DD")
      }

      if (this.editedItem.early_bird_tanggal_akhir) {
        this.currentData.early_bird_tanggal_akhir = moment(
          this.editedItem.early_bird_tanggal_akhir
        ).format("YYYY-MM-DD")
      }

      this.currentData.FotoUrl = this.editedItem.photo

      this.isEditable = 1
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.currentData.id == null) {
        this.currentData.inasia_id = this.id
        WorkshopService.create(this.currentData)
          .then((res) => {
            console.log(res)
            this.dialog = false

            CommonService.showSuccessToast("Tambah Workshop berhasil")

            this.getDataFromApi()
          })
          .catch((err) => {
            console.log(err)

            CommonService.showErrorToast("Tambah Workshop gagal. Coba lagi")
          })
      } else {
        this.currentData.inasia_id = this.id

        WorkshopService.update(this.currentData)
          .then((res) => {
            this.dialog = false

            console.log(res)

            CommonService.showSuccessToast("Update Workshop berhasil")

            this.getDataFromApi()
          })
          .catch((err) => {
            console.log(err)

            CommonService.showErrorToast("Update Workshop gagal. Coba lagi")
          })
      }
    },

    deleteItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.closeDelete()

      WorkshopService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res)
          CommonService.showSuccessToast("Non Aktif Workshop berhasil")

          this.getDataFromApi()
        })
        .catch((err) => {
          console.log(err)
          CommonService.showErrorToast("Non Aktif Workshop gagal. Coba lagi")
        })
    },

    aktifItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogAktif = true
    },

    aktifItemConfirm() {
      this.closeAktif()

      WorkshopService.aktif(this.editedItem.id)
        .then((res) => {
          console.log(res)
          CommonService.showSuccessToast("Meng-Aktif Workshop berhasil")

          this.getDataFromApi()
        })
        .catch((err) => {
          console.log(err)
          CommonService.showErrorToast("Meng-Aktif Workshop gagal. Coba lagi")
        })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeAktif() {
      this.dialogAktif = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
../../services/inasia/InasiaWorkshopService
