import http from "../../http-common";
/* eslint-disable */
class InasiaWorkshopHargaService {
  workshopHargaList(page, itemPerPage, keyword, workshopId) {
    return http.get(
      "/inasia-workshop-harga/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&workshop_id=" +
        workshopId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    if (data.selectedProfesi && data.selectedProfesi != undefined) {
      data.jenis_anggota = data.selectedProfesi.code;
    }

    return http.post("/inasia-workshop-harga", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    if (data.selectedProfesi && data.selectedProfesi != undefined) {
      data.jenis_anggota = data.selectedProfesi.code;
    }

    return http.put(`/inasia-workshop-harga/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/inasia-workshop-harga/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InasiaWorkshopHargaService();
