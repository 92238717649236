<template>
  <div>
    <v-card>
      <v-card-title>
        History Mutasi
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-btn color="purple" dark class="mb-2" @click="doAdd"> Tambah </v-btn>

        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Pengajuan Mutasi</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Anggota"
                        outlined
                        dense
                        required
                        v-model="currentData.nama"
                        readonly
                        :rules="currentData.namaRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Cabang Asal"
                        readonly
                        outlined
                        dense
                        required
                        v-model="currentData.cabang_asal_nama"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Cabang Tujuan"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedCabangTujuan"
                        :items="items_cabang"
                        item-text="name"
                        :rules="currentData.cabangTujuanRules"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogUpdate" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Pengajuan Mutasi</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Nama Anggota"
                        outlined
                        dense
                        required
                        v-model="currentData.nama"
                        readonly
                        :rules="currentData.namaRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Cabang Asal"
                        readonly
                        outlined
                        dense
                        required
                        v-model="currentData.cabang_asal_nama"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Cabang Tujuan"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedCabangTujuan"
                        :items="items_cabang"
                        item-text="name"
                        :rules="currentData.cabangTujuanRules"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogUpdate = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogApprove" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan mengapprove mutasi anggota ini?</v-card-title
            >
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeApprove"
                    >Tidak</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="approveItemConfirm"
                    >Ya</v-btn
                  >
                </v-row>
              </v-container>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
        v-model="selected"
        :single-select="true"
        show-select
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <v-icon
              v-if="
                item.status < 3 && (getRoleId == 1 || item.is_approver == 1)
              "
              medium
              class="mr-3"
              @click="approveItem(item)"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-if="item.status < 3"
              medium
              class="mr-3"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </div>
        </template>
        <template v-slot:[`item.foto_url`]="{ item }">
          <v-img :src="item.foto_url" contain height="100" width="200"></v-img>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <span>{{ getCurrentDate(item.created_at) }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ getStatus(item.status) }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <AnggotaSelectComponent
      @pilihAnggota="pilihAnggota"
      ref="modalAnggota"
      v-model="dialogAnggota"
    />
  </div>
</template>

<script>
import MutasiService from "../../services/MutasiService";
import CommonService from "../../services/CommonService";
import NakesService from "../../services/NakesService";
import * as moment from "moment";

import AnggotaSelectComponent from "../../components/AnggotaSelect.vue";
import store from "../../store/index";

export default {
  props: {
    id: String,
  },
  components: {
    AnggotaSelectComponent,
  },
  data: () => ({
    dialogAnggota: false,
    dialogApprove: false,
    dialogUpdate: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Pengurus",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false },
      { text: "No NPA", value: "no_npa", sortable: false },
      { text: "Jenis Anggota", value: "jenis_anggota_nama", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Cabang Asal", value: "cabang_asal_nama", sortable: false },
      { text: "Cabang Tujuan", value: "cabang_tujuan_nama", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      selectedCabangTujuan: "",
      cabang_asal_nama: "",
      cabang_asal: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_jabatan: [],
    items_divisi: [],
    items_cabang: [],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListPropinsi();
    this.getDataFromApi();
  },
  methods: {
    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "blue";
        case 2:
          return "green";
        default:
          return "cyan";
      }
    },

    getStatus(dataStatus) {
      console.log(dataStatus);
      if (dataStatus == 1) {
        return "Menunggu persetujuan Cabang Asal";
      } else if (dataStatus == 2) {
        return "Menunggu persetujuan Cabang Tujuan";
      } else {
        return "Mutasi Diterima";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      MutasiService.mutasiAnggotaList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.selectedDivisi = "";
      this.currentData.selectedJabatan = "";
      this.currentData.cabang_asal_nama = "";
      this.currentData.cabang_asal = "";

      this.getAnggota();

      console.log("do add");
    },

    getAnggota() {
      MutasiService.getAnggota(this.id)
        .then((res) => {
          this.currentData.nama = res.data.anggota.nama;
          this.currentData.user_id = res.data.anggota.id;
          this.currentData.cabang_asal_nama = res.data.anggota.cabang_nama;
          this.currentData.cabang_asal = res.data.anggota.cabang_id;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        MutasiService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialogUpdate = false;
            CommonService.showSuccessToast("Update pengajuan mutasi berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update pengajuan mutasi gagal. Coba lagi"
            );
          });
      } else {
        MutasiService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah pengajuan mutasi berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah pengajuan mutasi gagal. Coba lagi"
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogUpdate = true;
      this.currentData.nama = this.editedItem.user_nama;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.id = this.editedItem.id;
      this.currentData.cabang_asal = this.editedItem.cabang_asal;
      this.currentData.cabang_asal_nama = this.editedItem.cabang_asal_nama;

      this.items_cabang.forEach((entry) => {
        if (entry.id == this.editedItem.cabang_tujuan) {
          this.currentData.selectedCabangTujuan = entry;
        }
      });

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      MutasiService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pengurus berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pengurus gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function (file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },

    openAnggotaModal: async function () {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihAnggota(item) {
      this.dialogAnggota = false;
      console.log(item);
      this.currentData.nama = item.nama;
      this.currentData.user_id = item.id;
      this.currentData.cabang_asal = item.cabang_id;
      this.currentData.cabang_asal_nama = item.propinsi_nama;
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      MutasiService.approve(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve mutasi berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve mutasi gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_cabang = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
