<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card class="mb-4">
        <v-card-title>
          <div>Informasi pengajuan SKP Kemkes</div>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="pl-4 pr-4 pt-4"
        >
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nama Kegiatan"
                outlined
                dense
                v-model="userData.nama_kegiatan"
                :rules="userData.namaSkpRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Tanggal Kegiatan Terdekat"
                v-model="userData.tanggal_kegiatan_terdekat"
                :rules="userData.tanggalRules"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                label="Jenis Kegiatan"
                clearable
                dense
                outlined
                v-model="userData.selectedJenisKegiatan"
                :items="items_jenis_kegiatan"
                item-text="value"
                :rules="userData.jenisSkpKegiatanRules"
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                label="Kategori Kegiatan"
                clearable
                dense
                outlined
                v-model="userData.selectedKategoriKegiatan"
                :items="items_kategori_kegiatan"
                item-text="value"
                :rules="userData.kategoriKegiatanRules"
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                label="Cakupan"
                clearable
                dense
                outlined
                v-model="userData.selectedCakupan"
                :items="items_cakupan_kegiatan"
                item-text="value"
                :rules="userData.cakupanRules"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-combobox
                label="Penyelenggara"
                clearable
                dense
                outlined
                v-model="userData.selectedTipePenyelenggara"
                :items="items_tipe_penyelenggara"
                item-text="value"
                :rules="userData.jenisSkpKegiatanRules"
                @change="changeTipePenyelenggara"
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-combobox
                v-if="
                  userData.selectedTipePenyelenggara &&
                  userData.selectedTipePenyelenggara.code == 2
                "
                label="Cabang"
                clearable
                dense
                outlined
                v-model="userData.selectedCabang"
                :items="items_propinsi"
                item-text="name"
                :rules="userData.cabangRules"
              ></v-combobox>
              <v-combobox
                v-if="
                  userData.selectedTipePenyelenggara &&
                  userData.selectedTipePenyelenggara.code == 3
                "
                label="Keseminatan"
                clearable
                dense
                outlined
                v-model="userData.selectedKeseminatan"
                :items="items_keseminatan"
                item-text="nama"
                :rules="userData.keseminatanRules"
              ></v-combobox>
              <v-combobox
                v-if="
                  userData.selectedTipePenyelenggara &&
                  userData.selectedTipePenyelenggara.code == 4
                "
                label="Nama Penyelenggara"
                clearable
                dense
                outlined
                v-model="userData.selectedPenyelenggara"
                :items="items_penyelenggara"
                item-text="nama"
                :rules="userData.penyelenggaraRules"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="Sasaran Profesi"
                outlined
                dense
                v-model="userData.sasaran_profesi"
                :rules="userData.sasaranRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Jumlah JLP"
                outlined
                dense
                v-model="userData.jumlah_jep"
                type="number"
                :rules="userData.jepRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="Biaya Pelatihan"
                outlined
                dense
                type="number"
                v-model="userData.biaya_pelatihan"
                :rules="userData.biayaPelatihan"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Moderator"
                outlined
                dense
                v-model="userData.moderator"
                :rules="userData.moderatorRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="ml-1 mb-2">
            <div class="text-h6">Penanggung Jawab</div>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nama Penanggung Jawab"
                outlined
                dense
                v-model="userData.nama_penanggung_jawab"
                :rules="userData.namaPjRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Whatsapp Penanggung Jawab"
                outlined
                dense
                v-model="userData.whatsapp_penanggung_jawab"
                :rules="userData.waPjRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nama PIC"
                outlined
                dense
                v-model="userData.nama_pic"
                :rules="userData.namaPicRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Whatsapp PIC"
                outlined
                dense
                v-model="userData.whatsapp_pic"
                :rules="userData.waPicRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="ml-1 mb-2">
            <div class="text-h6">Lokasi</div>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Alamat"
                outlined
                dense
                v-model="userData.alamat"
                :rules="userData.alamatRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Propinsi"
                clearable
                dense
                outlined
                v-model="userData.selectedPropinsi"
                :items="items_propinsi"
                :rules="userData.propinsiRules"
                item-text="name"
                @change="changePropinsi"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                label="Kabupaten/Kota"
                clearable
                dense
                outlined
                v-model="userData.selectedKabupaten"
                :items="items_kabupaten"
                :rules="userData.kabupatenRules"
                item-text="name"
                @click="getListKabupaten"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-file-input
                @change="loadDokumenKak"
                accept=".pdf"
                v-model="userData.file_dokumen_kak"
                prepend-icon="mdi-image"
                show-size
                label="Upload File Dokumen Kurikulum"
              />
            </v-col>
          </v-row>

          <v-card>
            <v-card-title>
              Data Fasilitator & Materi

              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                dark
                class="mb-2 ml-4"
                @click="addFasilitator()"
              >
                Tambah Fasilitator
              </v-btn></v-card-title
            >
            <v-data-table
              :headers="headers"
              :items="list_data_fasilitator"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:[`item.file_materi`]="{ item }">
                <a :href="item.file_dokumen_materi" target="_blank"
                  >Link File</a
                >
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
          </v-card>

          <v-row class="pb-4 mt-4">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <FasilitatorSkpKegiatanSelectComponent
        @pilihFasilitator="pilihFasilitator"
        ref="modalFasilitator"
        v-model="dialogFasilitator"
      />

      <v-dialog v-model="dialogTambahFasilitatorMateri" max-width="700px">
        <v-card>
          <v-card-title class="text-h5">Tambah Fasilitator</v-card-title>
          <v-form
            ref="formfasilitator"
            v-model="validFasilitator"
            lazy-validation
            class="pl-4 pr-4 pt-4"
          >
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Nama Fasilitator"
                  outlined
                  dense
                  required
                  v-model="userData.fasilitator_nama"
                  readonly
                  @click="openFasilitatorModel"
                  :rules="userData.fasilitatorRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Judul Materi"
                  outlined
                  dense
                  v-model="userData.judul_materi"
                  :rules="userData.judulRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  @change="loadFileMateri"
                  accept=".pdf"
                  v-model="userData.dokumen_materi"
                  prepend-icon="mdi-image"
                  show-size
                  label="Upload File Materi Narasumber"
                  :rules="userData.fileMateriRules"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-container>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogTambahFasilitatorMateri = false"
                  >Tidak</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogAddFasilitatorConfirm"
                  >Ya</v-btn
                >
              </v-row>
            </v-container>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import SKPService from "../../services/SKPService"
import MasterService from "../../services/MasterService"
import KeseminatanService from "../../services/KeseminatanService"
import PenyelenggaraSkpKemkesService from "../../services/skp_kemkes/PenyelenggaraSkpKemkesService"
import FasilitatorSkpKegiatanSelectComponent from "../../components/FasilitatorSkpKegiatanSelect.vue"
import CommonService from "../../services/CommonService"

export default {
  name: "SkpKegiatanAdd",
  components: {
    FasilitatorSkpKegiatanSelectComponent,
  },
  props: {
    id: String,
  },
  data: () => ({
    dialogFasilitator: false,
    dialogTambahFasilitatorMateri: false,
    breadcrumbs: [
      {
        text: "Data pengajuan SKP Kemkes",
        disabled: false,
        exact: true,
        to: { name: "SkpKegiatan" },
      },
      { text: "Tambah pengajuan SKP Kemkes", disabled: true },
    ],
    valid: true,
    validFasilitator: true,
    userData: {
      nama_kegiatan: "",
      selectedJenisKegiatan: "",
      selectedKategoriKegiatan: "",
      selectedCakupan: "",
      selectedTipePenyelenggara: "",
      selectedKeseminatan: "",
      selectedPenyelenggara: "",
      selectedStatus: "",
      selectedPengendaliPelatihan: "",
      sasaran_profesi: "",
      biaya_pelatihan: "",
      moderator: "",
      jumlah_jep: "",
      nama_pic: "",
      whatsapp_pic: "",
      tanggal_kegiatan_terdekat: "",
      skp: "",
      dokumen_kak: "",
      file_dokumen_kak: null,
      selectedCabang: "",
      selectedFasilitator: "",
      alamat: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      nama_penanggung_jawab: "",
      whatsapp_penanggung_jawab: "",
      judul_materi: "",
      file_dokumen_materi: "",
      catatan_reject: "",
      dokumen_materi: null,
      fasilitator_id: "",
      fasilitator_user_id: "",
      fasilitator_nama: "",
      namaSkpRules: [(v) => !!v || "Nama kegiatan harus diisi"],
      jenisSkpKegiatanRules: [(v) => !!v || "Jenis Kegiatan harus diisi"],
      tipePenyelenggaraRules: [(v) => !!v || "Tipe Penyelenggara harus diisi"],
      penyelenggaraRules: [(v) => !!v || "Penyelenggara harus diisi"],
      namaPenyelenggaraRules: [(v) => !!v || "Nama Penyelenggara harus diisi"],
      kategoriKegiatanRules: [(v) => !!v || "Kategori Kegiatan harus diisi"],
      cakupanRules: [(v) => !!v || "Cakupan Kegiatan harus diisi"],
      fasilitatorRules: [(v) => !!v || "Fasilitator harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      keseminatanRules: [(v) => !!v || "Keseminatan harus diisi"],
      sasaranRules: [(v) => !!v || "Sasaran Profesi harus diisi"],
      biayaPelatihan: [(v) => !!v || "Biaya Pelatihan harus diisi"],
      moderatorRules: [(v) => !!v || "Moderator harus diisi"],
      alamatRules: [(v) => !!v || "Alamat harus diisi"],
      propinsiRules: [(v) => !!v || "Propinsi harus diisi"],
      kabupatenRules: [(v) => !!v || "Kabupaten harus diisi"],
      namaPicRules: [(v) => !!v || "Nama PIC harus diisi"],
      waPicRules: [(v) => !!v || "Whatsapp PIC harus diisi"],
      namaPjRules: [(v) => !!v || "Nama PJ harus diisi"],
      waPjRules: [(v) => !!v || "Whatsapp PJ harus diisi"],
      skpRules: [(v) => !!v || "SKP harus diisi"],
      jepRules: [(v) => !!v || "Jumlah JLP harus diisi"],
      judulRules: [(v) => !!v || "Judul harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      fileMateriRules: [(v) => !!v || "File Materi harus diisi"],
    },
    items_jenis_kegiatan: [],
    items_kategori_kegiatan: [],
    items_keseminatan: [],
    items_propinsi: [],
    items_kabupaten: [],
    items_cakupan_kegiatan: [],
    items_fasilitator: [],
    items_status_pengajuan: [],
    items_tipe_penyelenggara: [],
    items_penyelenggara: [],
    list_data_fasilitator: [],
    list_array_materi: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Fasilitator", value: "nama", sortable: false },
      { text: "Judul Materi", value: "judul_materi", sortable: false },
      { text: "File Materi", value: "file_materi", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  mounted: async function () {
    this.getListPropinsi()
    this.getJenisKegiatan()
    this.getKategoriKegiatan()
    this.getCakupanKegiatan()
    this.getStatusKegiatan()
    this.getTipePenyelenggara()
    this.getFasilitator()
    this.getKeseminatan()
    this.getPenyelenggara()
  },
  methods: {
    addFasilitator() {
      this.userData.fasilitator_id = ""
      this.userData.fasilitator_user_id = ""
      this.userData.fasilitator_nama = ""
      this.userData.judul_materi = ""
      this.userData.dokumen_materi = null
      this.dialogTambahFasilitatorMateri = true
    },
    pilihFasilitator(item) {
      this.dialogAnggota = false

      this.userData.fasilitator_id = item.id
      this.userData.fasilitator_user_id = item.user_id
      this.userData.fasilitator_nama = item.nama
      // const exists = this.list_data_fasilitator.some((val) => val.id == item.id)

      // if (!exists) {
      //   this.list_data_fasilitator.push({
      //     no: this.list_data_fasilitator.length + 1,
      //     nama: item.nama,
      //     no_npa: item.no_npa,
      //     phone: item.phone,
      //     no_ktp: item.no_ktp,
      //     id: item.id,
      //     user_id: item.user_id,
      //   })
      // }
    },
    openFasilitatorModel() {
      this.$refs.modalFasilitator.getDataFromApi()
      this.dialogFasilitator = true
    },
    deleteItem(val) {
      const indexRemove = this.list_data_fasilitator.findIndex(
        (element) => element.id == val.id
      )

      this.list_array_materi.splice(indexRemove, 1)

      this.list_data_fasilitator = this.list_data_fasilitator.filter(
        (item) => item.id !== val.id
      )

      for (let i = 0; i < this.list_data_fasilitator.length; i++) {
        this.list_data_fasilitator[i].no = i + 1
      }
    },
    changeTipePenyelenggara() {
      this.$nextTick(() => {
        this.userData.selectedCabang = null
        this.userData.selectedKeseminatan = null
        this.userData.selectedPenyelenggara = null
      })
    },
    loadDokumenKak: function (data) {
      if (data) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.userData.dokumen_kak = e.target.result
        }
        reader.readAsDataURL(data)
      } else {
        this.userData.dokumen_kak = null
      }
    },

    loadFileMateri: function (data) {
      if (data) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.userData.file_dokumen_materi = e.target.result
        }
        reader.readAsDataURL(data)
      } else {
        this.userData.file_dokumen_materi = null
      }
    },

    async getJenisKegiatan() {
      MasterService.listJenisKegiatanSkp()
        .then((res) => {
          this.items_jenis_kegiatan = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getKategoriKegiatan() {
      MasterService.listKategoriKegiatanSkp()
        .then((res) => {
          this.items_kategori_kegiatan = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getPenyelenggara() {
      PenyelenggaraSkpKemkesService.penyelenggaraListAll()
        .then((res) => {
          this.items_penyelenggara = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getKeseminatan() {
      KeseminatanService.keseminatanListAll()
        .then((res) => {
          this.items_keseminatan = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getTipePenyelenggara() {
      MasterService.listTipePenyelenggaraKegiatanSkp()
        .then((res) => {
          this.items_tipe_penyelenggara = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getCakupanKegiatan() {
      MasterService.listCakupanKegiatanSkp()
        .then((res) => {
          this.items_cakupan_kegiatan = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getFasilitator() {
      MasterService.listFasilitator()
        .then((res) => {
          this.items_fasilitator = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getStatusKegiatan() {
      MasterService.listStatusKegiatanSkp()
        .then((res) => {
          this.items_status_pengajuan = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getListPropinsi() {
      SKPService.propinsiList()
        .then((res) => {
          console.log(res)
          this.items_propinsi = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async changePropinsi() {
      this.userData.selectedKabupaten = null
    },

    async getListKabupaten() {
      SKPService.kabupatenList(this.userData.selectedPropinsi.id)
        .then((res) => {
          console.log(res)
          this.items_kabupaten = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    doBatal: function () {
      this.$router.push("/skp-kegiatan").catch((err) => {
        console.log(err)
      })
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0)
        return
      }

      let loader = this.$loading.show()

      try {
        SKPService.create(
          this.userData,
          this.userData.file_dokumen_kak,
          this.userData.dokumen_materi,
          this.list_data_fasilitator,
          this.list_array_materi
        )
          .then((res) => {
            loader.hide()
            CommonService.showSuccessToast(
              "Tambah pengajuan SKP Kemkes berhasil"
            )

            this.$router
              .push("/skp-kegiatan-edit/" + res.data.data.id)
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            loader.hide()
            console.log(err)
            CommonService.showErrorToast(
              "Tambah pengajuan SKP Kemkes gagal. Coba lagi"
            )
          })
      } catch (e) {
        console.log(e)
        loader.hide()
      }
    },
    dialogAddFasilitatorConfirm() {
      if (!this.$refs.formfasilitator.validate()) {
        window.scrollTo(0, 0)
        return
      }

      const exists = this.list_data_fasilitator.some(
        (val) => val.id == this.userData.fasilitator_id
      )

      if (!exists) {
        const fileUrl = URL.createObjectURL(this.userData.dokumen_materi)

        this.list_data_fasilitator.push({
          no: this.list_data_fasilitator.length + 1,
          nama: this.userData.fasilitator_nama,
          judul_materi: this.userData.judul_materi,
          file_materi: this.userData.dokumen_materi,
          file_dokumen_materi: fileUrl,
          id: this.userData.fasilitator_id,
          user_id: this.userData.fasilitator_user_id,
        })

        this.list_array_materi.push(this.userData.dokumen_materi)
      }

      this.dialogTambahFasilitatorMateri = false
    },
  },
}
</script>
