<template>
  <v-card>
    <v-card class="pl-4 pr-4 pt-4 pb-4">
      <v-card-title> Kelas Offline </v-card-title>
      <v-fab-transition>
        <v-btn
          class="mt-8 mr-4"
          v-if="isEditable == 0"
          @click="changeEditable"
          color="pink"
          dark
          absolute
          top
          right
          fab
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
        <v-row dense>
          <v-col cols="12" sm="6" md="6">
            <v-row dense>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="Judul"
                  dense
                  value=""
                  rows="3"
                  v-model="currentData.judul"
                  :rules="currentData.judulRules"
                  :readonly="isEditable == 0"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="Deskripsi"
                  dense
                  value=""
                  v-model="currentData.deskripsi"
                  :readonly="isEditable == 0"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="Lokasi Pelatihan"
                  dense
                  value=""
                  v-model="currentData.lokasi"
                  :readonly="isEditable == 0"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Waktu Kelas Awal"
                  v-model="currentData.waktu_webinar_awal"
                  type="date"
                  dense
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Jam Kelas Awal"
                  v-model="currentData.jam_webinar_awal"
                  type="time"
                  dense
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="mt-4">
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Waktu Kelas Akhir"
                  v-model="currentData.waktu_webinar_akhir"
                  type="date"
                  dense
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Jam Kelas Akhir"
                  v-model="currentData.jam_webinar_akhir"
                  type="time"
                  dense
                  :readonly="isEditable == 0"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="mt-4">
              <v-col cols="12" sm="6" md="6">
                <v-combobox
                  label="Kelas Berakhir"
                  clearable
                  dense
                  outlined
                  v-model="currentData.is_berakhir"
                  :items="items_berakhir"
                  item-text="value"
                  :disabled="isEditable == 0"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Penyelenggara"
                  outlined
                  dense
                  v-model="currentData.penyelenggara"
                  :readonly="isEditable == 0"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Nilai SKP"
                  outlined
                  dense
                  v-model="currentData.skp_peserta"
                  :readonly="isEditable == 0"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12" sm="12" md="12">
            <h5>Konten</h5>
          </v-col>
        </v-row> -->
        <!-- <v-row dense>
          <v-col cols="12" sm="12" md="12">
            <v-row dense>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Link Video"
                  outlined
                  dense
                  v-model="currentData.url"
                  :readonly="isEditable == 0"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <!-- <v-row dense>
          <v-col cols="12" sm="12" md="12">
            <v-row dense>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Link Room"
                  outlined
                  dense
                  v-model="currentData.link_room"
                  :readonly="isEditable == 0"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <!-- <v-row dense>
          <v-col cols="12" sm="6" md="6">
            <v-row dense>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Meeting ID"
                  outlined
                  dense
                  v-model="currentData.username_room"
                  :readonly="isEditable == 0"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-row dense>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Password"
                  outlined
                  dense
                  v-model="currentData.password_room"
                  :readonly="isEditable == 0"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              v-if="isEditable == 1"
              @click="doBatal"
              style="margin-right: 10px"
              >Batal</v-btn
            >
            <v-btn
              v-if="isEditable == 1"
              dark
              color="primary"
              type="button"
              @click="doSubmit"
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-card>
</template>

<script>
import CommonService from "../../services/CommonService";
import KelasOnlineService from "../../services/KelasOnlineService";
import MasterService from "../../services/MasterService";
import * as moment from "moment";

export default {
  name: "KelasOnlineEdit",
  props: {
    id: [Number, String],
    kegiatan_id: [Number, String],
  },
  data: () => ({
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Kegiatan",
        disabled: false,
        exact: true,
        to: { name: "Kegiatan" },
      },
      { text: "Edit Kegiatan", disabled: true },
    ],
    valid: true,
    currentData: {
      id: null,
      judul: "",
      deskripsi: null,
      lokasi: "",
      waktu_webinar_awal: "",
      waktu_webinar_akhir: "",
      jam_webinar_awal: "",
      jam_webinar_akhir: "",
      is_berakhir: {
        code: 0,
        value: "Tidak",
      },
      penyelenggara: "",
      link_room: "",
      username_room: "",
      password_room: "",
      skp_peserta: 0,
      url: "",
      judulRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
    items_kategori: [],
    items_berakhir: [
      {
        code: 0,
        value: "Tidak",
      },
      {
        code: 1,
        value: "Ya",
      },
    ],
    fotoKey: 0,
  }),
  mounted: async function() {
    this.getListKategoriKegiatan();
    this.webinar();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  methods: {
    changeEditable: function() {
      this.isEditable = 1;
    },

    loadImage: function(data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    webinar() {
      KelasOnlineService.getWebinar(this.id)
        .then((res) => {
          console.log(res.data);
          if (res.data.webinar) {
            console.log("webinar exists");
            this.currentData.id = res.data.webinar.id;
            this.currentData.judul = res.data.webinar.judul;
            this.currentData.url = res.data.webinar.url;
            this.currentData.deskripsi = res.data.webinar.deskripsi;
            this.currentData.lokasi = res.data.webinar.lokasi;
            this.currentData.link_room = res.data.webinar.link_room;
            this.currentData.username_room = res.data.webinar.username_room;
            this.currentData.password_room = res.data.webinar.password_room;
            this.currentData.penyelenggara = res.data.webinar.penyelenggara;
            this.currentData.skp_peserta = res.data.webinar.skp_peserta;

            this.currentData.waktu_webinar_awal = moment(
              res.data.webinar.waktu_webinar_awal
            ).format("YYYY-MM-DD");
            this.currentData.jam_webinar_awal = moment(
              res.data.webinar.waktu_webinar_awal
            ).format("HH:mm");

            this.currentData.waktu_webinar_akhir = moment(
              res.data.webinar.waktu_webinar_akhir
            ).format("YYYY-MM-DD");
            this.currentData.jam_webinar_akhir = moment(
              res.data.webinar.waktu_webinar_akhir
            ).format("HH:mm");

            if (res.data.webinar.is_berakhir != null) {
              this.items_berakhir.forEach((entry) => {
                if (entry.code == res.data.webinar.is_berakhir) {
                  this.currentData.is_berakhir = entry;
                }
              });
            }
          } else {
            console.log("webinar not exists");
            this.webinarExists();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    webinarExists() {
      KelasOnlineService.getWebinarExists(this.kegiatan_id)
        .then((res) => {
          if (res.data.webinar) {
            this.currentData.judul = res.data.webinar.judul;
            this.currentData.url = res.data.webinar.url;
            this.currentData.deskripsi = res.data.webinar.deskripsi;
            this.currentData.lokasi = res.data.webinar.lokasi;
            this.currentData.link_room = res.data.webinar.link_room;
            this.currentData.username_room = res.data.webinar.username_room;
            this.currentData.password_room = res.data.webinar.password_room;
            this.currentData.penyelenggara = res.data.webinar.penyelenggara;
            this.currentData.skp_peserta = res.data.webinar.skp_peserta;

            this.currentData.waktu_webinar_awal = moment(
              res.data.webinar.waktu_webinar_awal
            ).format("YYYY-MM-DD");
            this.currentData.jam_webinar_awal = moment(
              res.data.webinar.waktu_webinar_awal
            ).format("HH:mm");

            this.currentData.waktu_webinar_akhir = moment(
              res.data.webinar.waktu_webinar_akhir
            ).format("YYYY-MM-DD");
            this.currentData.jam_webinar_akhir = moment(
              res.data.webinar.waktu_webinar_akhir
            ).format("HH:mm");

            if (res.data.webinar.is_berakhir != null) {
              this.items_berakhir.forEach((entry) => {
                if (entry.code == res.data.webinar.is_berakhir) {
                  this.currentData.is_berakhir = entry;
                }
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return newDate;
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function() {
      this.isEditable = 0;
    },

    doSubmit: async function() {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      if (this.currentData.id != null) {
        KelasOnlineService.update(this.currentData)
          .then((res) => {
            console.log(res);
            CommonService.showSuccessToast("Edit Kelas offline berhasil");

            this.isEditable = 0;
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Edit Kelas offline gagal. Coba lagi");
          });
      } else {
        KelasOnlineService.create(this.id, this.currentData)
          .then((res) => {
            console.log(res);
            CommonService.showSuccessToast("Tambah Kelas offline berhasil");

            this.isEditable = 0;
            this.webinar();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah Kelas offline gagal. Coba lagi"
            );
          });
      }
    },
  },
};
</script>

<style scoped></style>
