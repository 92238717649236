<template>
  <v-main>
    <v-container fluid>
      <v-progress-linear
        v-if="isProgressBarShow"
        indeterminate
        color="green"
      ></v-progress-linear>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-card>
        <v-card-title>
          Akun Satu Sehat SDMK
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Akun Satu Sehat</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Email"
                          required
                          v-model="currentData.email_satu_sehat"
                          :rules="currentData.emailRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Password"
                          required
                          v-model="currentData.password_satu_sehat"
                          :rules="currentData.passwordRules"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show1 ? 'text' : 'password'"
                          @click:append="show1 = !show1"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Cabang Perdatin"
              clearable
              dense
              outlined
              v-model="selectedPropinsi"
              :items="items_propinsi"
              item-text="name"
              @change="changePropinsi"
              :disabled="isCabangPengurus"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="noNpa"
              append-icon="mdi-magnify"
              label="Cari No NPA"
              single-line
              outlined
              dense
              hide-details
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Status Akun"
              dense
              outlined
              clearable
              v-model="selectedStatus"
              :items="items_status"
              item-text="nama"
              @change="changeStatus"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.password_satu_sehat`]="{ item }">
            <div v-if="item.password_satu_sehat">******</div>
          </template>

          <template v-slot:[`item.is_aktif`]="{ item }">
            <v-chip :color="getColor(item.is_aktif, item.data_status)" dark>
              {{ getStatusKeanggotaan(item.is_aktif, item.data_status) }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex flex-row">
              <v-icon medium class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
import ReportService from "../services/ReportService";
import CommonService from "../services/CommonService";
import GelarService from "../services/GelarService";
import store from "../store/index";
import MasterService from "../services/MasterService";

export default {
  data: () => ({
    show1: false,
    valid: true,
    isProgressBarShow: false,
    dialog: false,
    breadcrumbs: [
      {
        text: "Akun Satu Sehat",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    tahun: "",
    noNpa: "",
    selectedPropinsi: "",
    selectedTahun: "",
    selectedStatus: "",
    selectedUmur: "",
    umur: "",
    selectedGelar: "",
    selectedStatusPegawai: "",
    items_propinsi: [],
    items_tahun: [],
    items_gelar: [],
    items_status_pegawai: [],
    items_status: [
      {
        id: 1,
        nama: "Sudah Mengisi",
      },
      { id: 0, nama: "Belum Mengisi" },
    ],
    items_umur: [
      {
        id: 1,
        nama: "Kurang Dari 42 Tahun",
      },
      { id: 2, nama: "Lebih Dari atau Sama Dengan 42 Tahun" },
      { id: 3, nama: "Belum mengisi Tanggal Lahir" },
    ],
    currentData: {
      id: null,
      email_satu_sehat: "",
      password_satu_sehat: "",
      emailRules: [(v) => !!v || "Email harus diisi"],
      passwordRules: [(v) => !!v || "Password harus diisi"],
    },

    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No NPA", value: "no_npa", sortable: false },
      { text: "Cabang", value: "propinsi_nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      {
        text: "Email Satu Sehat",
        value: "email_satu_sehat",
        sortable: false,
      },
      {
        text: "Password Satu Sehat",
        value: "password_satu_sehat",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear(); i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.selectedTahun = new Date().getFullYear();

    this.getListPropinsi();
    this.getListGelar();
    this.getListStatusPegawai();
  },
  methods: {
    async getListStatusPegawai() {
      MasterService.statusPekerjaanList()
        .then((res) => {
          this.items_status_pegawai = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getColor(dataStatus, status) {
      if (status == 1) {
        switch (dataStatus) {
          case 1:
            return "blue";
          default:
            return "red";
        }
      } else {
        return "";
      }
    },
    getStatusKeanggotaan(id, status) {
      if (status == 1) {
        if (id == 1) {
          return "Aktif";
        } else {
          return "Tidak Aktif";
        }
      } else {
        if (status == 1) {
          return "Aktif";
        } else if (status == 2) {
          return "Menunggu Konfirmasi Penghapusan Anggota";
        } else if (status == 3) {
          return "Tidak Aktif";
        } else if (status == 4) {
          return "Menunggu Konfirmasi Perubahan Anumerta";
        } else if (status == 5) {
          return "Anumerta";
        }
      }
    },
    getStatus(id) {
      if (id == 1) {
        return "Aktif";
      } else if (id == 2) {
        return "Menunggu Konfirmasi Penghapusan Anggota";
      } else if (id == 3) {
        return "Tidak Aktif";
      } else if (id == 4) {
        return "Menunggu Konfirmasi Perubahan Anumerta";
      } else if (id == 5) {
        return "Anumerta";
      }
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var propinsiId = "";
      var status = "";

      if (this.selectedPropinsi) propinsiId = this.selectedPropinsi.id;

      // if (this.getRoleId == 3) {
      // propinsiId = this.getCabangPengurusId;
      // }

      var tahun = new Date().getFullYear();

      if (this.selectedTahun) {
        tahun = this.selectedTahun;
      }

      var statusAkun = "";

      if (this.selectedStatus) {
        statusAkun = this.selectedStatus.id;
      }

      var umur = "";

      if (this.selectedUmur) {
        umur = this.selectedUmur.id;
      }

      var currentUmur = "";

      if (this.umur) {
        currentUmur = this.umur;
      }

      var gelar = "";

      if (this.selectedGelar) {
        gelar = this.selectedGelar.id;
      }

      var statusPegawai = "";

      if (this.selectedStatusPegawai) {
        statusPegawai = this.selectedStatusPegawai.code;
      }

      NakesService.nakesList(
        page,
        itemsPerPage,
        this.search,
        propinsiId,
        this.noNpa,
        tahun,
        status,
        umur,
        currentUmur,
        gelar,
        statusPegawai,
        statusAkun
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    onDownloadAnggota() {
      this.isProgressBarShow = true;

      var propinsiId = "";

      if (this.selectedPropinsi) propinsiId = this.selectedPropinsi.id;

      var tahun = new Date().getFullYear();

      if (this.selectedTahun) {
        tahun = this.selectedTahun;
      }

      var status = "";

      if (this.selectedStatus) {
        status = this.selectedStatus.id;
      }

      var umur = "";

      if (this.selectedUmur) {
        umur = this.selectedUmur.id;
      }

      var currentUmur = "";

      if (this.umur) {
        currentUmur = this.umur;
      }

      var gelar = "";

      if (this.selectedGelar) {
        gelar = this.selectedGelar.id;
      }

      var statusPegawai = "";

      if (this.selectedStatusPegawai) {
        statusPegawai = this.selectedStatusPegawai.code;
      }

      ReportService.downloadAnggotaReport(
        this.search,
        propinsiId,
        this.noNpa,
        tahun,
        status,
        umur,
        currentUmur,
        gelar,
        statusPegawai
      )
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      // this.dialog = true;
      this.currentData.email_satu_sehat = this.editedItem.email_satu_sehat;
      this.currentData.password_satu_sehat =
        this.editedItem.password_satu_sehat;
      this.currentData.id = this.editedItem.id;
      this.dialog = true;
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    doSave() {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      NakesService.updateAkunSatuSehat(this.currentData)
        .then((res) => {
          console.log(res);
          this.isEditable = 0;
          this.dialog = false;
          this.$toast.success("Update Akun Satu Sehat Berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Update Akun Satu Sehat gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    deleteItemConfirm() {
      this.closeDelete();

      NakesService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Mohon menunggu konfirmasi penghapusan anggota", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus anggota gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;

          if (this.getCabangPengurusId) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == this.getCabangPengurusId) {
                this.selectedPropinsi = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListGelar() {
      GelarService.gelarAllList()
        .then((res) => {
          this.items_gelar = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async changePropinsi() {
      this.getDataFromApi();
    },

    async changeTahun() {
      this.getDataFromApi();
    },

    async changeStatus() {
      this.getDataFromApi();
    },

    async changeUmur() {
      this.getDataFromApi();
    },

    async changeGelar() {
      this.getDataFromApi();
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    rejectItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReject = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeReject() {
      this.dialogReject = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeApproveAnumerta() {
      this.dialogApproveAnumerta = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeRejectAnumerta() {
      this.dialogRejectAnumerta = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveAnumertaItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApproveAnumerta = true;
    },

    rejectAnumertaItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRejectAnumerta = true;
    },

    approveItemConfirm() {
      NakesService.approveDelete(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve penghapusan anggota berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve penghapusan anggota gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    rejectItemConfirm() {
      NakesService.rejectDelete(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeReject();

          console.log(res);
          this.$toast.success("Pembatalan penghapusan anggota berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Pembatalan penghapusan anggota gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    jadikanAnumerta(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogJadikanAnumerta = true;
    },

    closeJadikanAnumerta() {
      this.dialogJadikanAnumerta = false;
    },

    jadikanAnumertaConfirm() {
      NakesService.jadikanAnumerta(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeJadikanAnumerta();

          console.log(res);
          this.$toast.success(
            "Mohon menunggu pengurus untuk mengubah status Anumerta",
            {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            }
          );

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Pengajuan anumerta gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    approveAnumertaItemConfirm() {
      NakesService.approveAnumerta(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApproveAnumerta();

          console.log(res);
          this.$toast.success("Anggota telah menjadi Anumerta berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Set Anumerta gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    rejectAnumertaItemConfirm() {
      NakesService.rejectAnumerta(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeRejectAnumerta();

          console.log(res);
          this.$toast.success("Pembatalan status Anumerta berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Pembatalan status Anumerta gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getRolesId() {
      return store.getters.getUser.roles_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
    isCabangPengurus() {
      if (store.getters.getUser.cabang_pengurus_id) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
