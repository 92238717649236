import http from "../../http-common";
/* eslint-disable */
class BeritaService {
  beritaList(page, itemPerPage, keyword) {
    return http.get(
      "/berita/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getBerita(id) {
    return http.get(`/berita/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, foto) {
    var bodyFormData = new FormData();
    bodyFormData.append("judul", data.judul);
    if (data.konten) bodyFormData.append("konten", data.konten);
    bodyFormData.append("html", data.content);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("data_status", data.selectedHide.text);
    }

    if (foto != null) bodyFormData.append("photo", foto);

    return http.post("/berita", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data, foto) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("judul", data.judul);
    if (data.konten) bodyFormData.append("konten", data.konten);
    bodyFormData.append("html", data.content);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("data_status", data.selectedHide.text);
    }

    if (foto != null) bodyFormData.append("photo", foto);

    return http.put(`/berita/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/berita/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new BeritaService();
