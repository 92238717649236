<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab>PIB</v-tab>
          <v-tab>Workshop</v-tab>
          <v-tab>Simposium</v-tab>
          <v-tab>Paket Wisata</v-tab>
          <v-tab>Speaker</v-tab>
          <v-tab>Hotel</v-tab>
          <v-tab>Contact Person</v-tab>
          <v-tab>Pib Got Talent</v-tab>
          <v-tab>Hospitality</v-tab>
          <v-tab>Peserta</v-tab>
          <v-tab>Invoice</v-tab>
          <v-tab>Garansi Letter Sponsor</v-tab>
          <v-tab>E-Poster</v-tab>
          <v-tab>Registrasi Ulang</v-tab>
          <!-- <v-tab>Informasi Broadcast</v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card class="pl-4 pr-4 pt-4 pb-4">
              <v-card-title> Pertemuan Ilmiah Berkala </v-card-title>
              <v-fab-transition>
                <v-btn
                  class="mt-8 mr-4"
                  v-if="isEditable == 0"
                  @click="changeEditable"
                  color="pink"
                  dark
                  absolute
                  top
                  right
                  fab
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6" class="pr-4">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="fotoKey"
                            :src="currentData.FotoUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImage"
                        accept="image/*"
                        v-model="currentData.file_foto"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto Venue"
                        :disabled="isEditable == 0"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Judul"
                            dense
                            rows="3"
                            v-model="currentData.nama"
                            :rules="currentData.namaRules"
                            :readonly="isEditable == 0"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Deskripsi"
                            dense
                            value=""
                            v-model="currentData.deskripsi"
                            :rules="currentData.deskripsiRules"
                            :readonly="isEditable == 0"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-row dense>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Tanggal Awal"
                                v-model="currentData.tanggal_awal"
                                type="date"
                                dense
                                :readonly="isEditable == 0"
                                :rules="currentData.tanggalAwalRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Tanggal Akhir"
                                v-model="currentData.tanggal_akhir"
                                type="date"
                                dense
                                :readonly="isEditable == 0"
                                :rules="currentData.tanggalAkhirRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>Sambutan</h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="fotoKeySambutan"
                            :src="currentData.FotoUrlSambutan"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImageSambutan"
                        accept="image/*"
                        v-model="currentData.file_foto_sambutan"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto Sambutan"
                        :disabled="isEditable == 0"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-textarea
                        outlined
                        name="input-7-4"
                        label="Sambutan"
                        dense
                        rows="6"
                        v-model="currentData.sambutan_kalimat"
                        :rules="currentData.sambutanKalimatRules"
                        :readonly="isEditable == 0"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>Venue</h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Nama Venue"
                            outlined
                            dense
                            v-model="currentData.venue_nama"
                            required
                            :readonly="isEditable == 0"
                            :rules="currentData.venueNamaRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Alamat"
                            dense
                            value=""
                            rows="3"
                            v-model="currentData.venue_alamat"
                            :rules="currentData.venueAlamatRules"
                            :readonly="isEditable == 0"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Link Google Map"
                            outlined
                            dense
                            v-model="currentData.venue_link_google_map"
                            required
                            :readonly="isEditable == 0"
                            :rules="currentData.venueLinkGoogleMapRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row dense>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Tanggal Awal"
                            v-model="currentData.venue_tanggal_awal"
                            type="date"
                            dense
                            :readonly="isEditable == 0"
                            :rules="currentData.venueTanggalAwalRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Tanggal Akhir"
                            v-model="currentData.venue_tanggal_akhir"
                            type="date"
                            dense
                            :readonly="isEditable == 0"
                            :rules="currentData.venueTanggalAkhirRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense class="mt-4">
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Jam Awal"
                            v-model="currentData.venue_jam_awal"
                            type="time"
                            dense
                            :readonly="isEditable == 0"
                            :rules="currentData.venueJamAwalRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-row>
                            <v-col cols="12"
                              ><v-text-field
                                label="Jam Akhir"
                                v-model="currentData.venue_jam_akhir"
                                type="time"
                                dense
                                :readonly="isEditable == 0"
                                :rules="currentData.venueJamAkhirRules"
                                :disabled="currentData.is_venue_jam_akhir"
                              ></v-text-field
                              ><v-checkbox
                                v-model="currentData.is_venue_jam_akhir"
                                label="Sampai Selesai"
                                :readonly="isEditable == 0"
                              ></v-checkbox
                            ></v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Nama PIC"
                            outlined
                            dense
                            v-model="currentData.venue_pic_nama"
                            required
                            :readonly="isEditable == 0"
                            :rules="currentData.venuePicNamaRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="No Telepon PIC"
                            outlined
                            dense
                            v-model="currentData.venue_pic_phone"
                            required
                            :readonly="isEditable == 0"
                            :rules="currentData.venuePicPhoneRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>Simposium Nasional</h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Lokasi"
                        outlined
                        dense
                        v-model="currentData.simposium_lokasi"
                        required
                        :readonly="isEditable == 0"
                        :rules="currentData.simposiumLokasiRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row dense>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Tanggal Awal"
                            v-model="currentData.simposium_tanggal_awal"
                            type="date"
                            dense
                            :readonly="isEditable == 0"
                            :rules="currentData.simposiumTanggalAwalRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Tanggal Akhir"
                            v-model="currentData.simposium_tanggal_akhir"
                            type="date"
                            dense
                            :readonly="isEditable == 0"
                            :rules="currentData.simposiumTanggalAkhirRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Kuota"
                        outlined
                        dense
                        v-model="currentData.simposium_kuota"
                        required
                        :readonly="isEditable == 0"
                        :rules="currentData.simposiumKuotaRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row dense>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Jam Awal"
                            v-model="currentData.simposium_jam_awal"
                            type="time"
                            dense
                            :readonly="isEditable == 0"
                            :rules="currentData.simposiumJamAwalRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Jam Akhir"
                            v-model="currentData.simposium_jam_akhir"
                            type="time"
                            dense
                            :readonly="isEditable == 0"
                            :rules="currentData.simposiumJamAkhirRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Peserta"
                        outlined
                        dense
                        v-model="currentData.simposium_peserta"
                        required
                        :readonly="isEditable == 0"
                        :rules="currentData.simposiumPesertaRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <!-- <v-text-field
                        label="Harga"
                        outlined
                        dense
                        v-model="currentData.simposium_harga"
                        required
                        :readonly="isEditable == 0"
                        :rules="currentData.simposiumHargaRules"
                      ></v-text-field> -->
                    </v-col>
                  </v-row>

                  <SimposiumComponent :id="id" />

                  <v-row dense> </v-row>

                  <v-row class="mt-10">
                    <v-col cols="12" sm="12" md="12">
                      <h3>Hybrid Simposium</h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="pr-4">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="fotoKeyHybrid"
                            :src="currentData.FotoUrlHybrid"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImageHybrid"
                        accept="image/*"
                        v-model="currentData.file_hybrid_foto"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto Hybrid Simposium"
                        :disabled="isEditable == 0"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="Lokasi"
                        outlined
                        dense
                        v-model="currentData.simposium_hybrid_lokasi"
                        required
                        :readonly="isEditable == 0"
                        :rules="currentData.simposiumHybridLokasiRules"
                      ></v-text-field>
                      <v-row class="mt-4" dense>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Tanggal Awal"
                            v-model="currentData.simposium_hybrid_tanggal_awal"
                            type="date"
                            dense
                            :readonly="isEditable == 0"
                            :rules="currentData.simposiumHybridTanggalAwalRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Tanggal Akhir"
                            v-model="currentData.simposium_hybrid_tanggal_akhir"
                            type="date"
                            dense
                            :readonly="isEditable == 0"
                            :rules="
                              currentData.simposiumHybridTanggalAkhirRules
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="12" md="12">
                          <v-text-field
                            label="Kuota"
                            outlined
                            dense
                            v-model="currentData.simposium_hybrid_kuota"
                            required
                            :readonly="isEditable == 0"
                            :rules="currentData.simposiumHybridKuotaRules"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="12" md="6">
                          <v-text-field
                            label="Jam Awal"
                            v-model="currentData.simposium_hybrid_jam_awal"
                            type="time"
                            dense
                            :readonly="isEditable == 0"
                            :rules="currentData.simposiumHybridJamAwalRules"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="12" md="6">
                          <v-text-field
                            label="Jam Akhir"
                            v-model="currentData.simposium_hybrid_jam_akhir"
                            type="time"
                            dense
                            :readonly="isEditable == 0"
                            :rules="currentData.simposiumHybridJamAkhirRules"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="12" md="12">
                          <v-text-field
                            label="Peserta"
                            outlined
                            dense
                            v-model="currentData.simposium_hybrid_peserta"
                            required
                            :readonly="isEditable == 0"
                            :rules="currentData.simposiumHybridPesertaRules"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="12" md="12">
                          <v-text-field
                            label="Harga"
                            outlined
                            dense
                            v-model="currentData.simposium_hybrid_harga"
                            required
                            :readonly="isEditable == 0"
                            :rules="currentData.simposiumHybridHargaRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-btn
                        v-if="isEditable == 1"
                        @click="doBatal"
                        style="margin-right: 10px"
                        >Batal</v-btn
                      >
                      <v-btn
                        v-if="isEditable == 1"
                        dark
                        color="primary"
                        type="button"
                        @click="doSubmit"
                        >Simpan</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <WorkshopComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <SimposiumListComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PaketWisataComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <SpeakerComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <HotelComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <ContactPersonComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PibGotTalentComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PibHospitalityComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PesertaPibComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InvoicePibComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <GaransiSponsorDetailComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <EPosterPibComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <KehadiranPibComponent :id="id" />
          </v-tab-item>
          <!-- <v-tab-item>
            <PibBroadcastComponent :id="id" />
          </v-tab-item> -->
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import PibService from "../services/PibService";
import MasterService from "../services/MasterService";
import * as moment from "moment";
import WorkshopComponent from "./pib/Workshop.vue";
import SimposiumListComponent from "./pib/SimposiumList.vue";
import PaketWisataComponent from "./pib/PaketWisata.vue";
import SpeakerComponent from "./pib/Speaker.vue";
import HotelComponent from "./pib/Hotel.vue";
// import PibBroadcastComponent from "./pib/PibBroadcast.vue";
import PibHospitalityComponent from "./pib/PibHospitality.vue";
import ContactPersonComponent from "./pib/ContactPerson.vue";
import PesertaPibComponent from "./pib/PesertaPib.vue";
import InvoicePibComponent from "./pib/InvoicePib.vue";
import KehadiranPibComponent from "./pib/KehadiranPib.vue";
import SimposiumComponent from "./pib/Simposium.vue";
import EPosterPibComponent from "./pib/EPosterPib.vue";
// import GaransiSponsorComponent from "./pib/GaransiSponsor.vue";
import GaransiSponsorDetailComponent from "./pib/GaransiSponsorDetail.vue";
import PibGotTalentComponent from "./pib/PibGotTalent.vue";

export default {
  name: "PibEdit",
  components: {
    WorkshopComponent,
    SimposiumListComponent,
    PaketWisataComponent,
    SpeakerComponent,
    HotelComponent,
    ContactPersonComponent,
    PesertaPibComponent,
    InvoicePibComponent,
    SimposiumComponent,
    EPosterPibComponent,
    // GaransiSponsorComponent,
    GaransiSponsorDetailComponent,
    PibGotTalentComponent,
    PibHospitalityComponent,
    KehadiranPibComponent,
    // PibBroadcastComponent,
  },
  props: {
    id: String,
  },
  data: () => ({
    tab: null,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data PIB",
        disabled: false,
        exact: true,
        to: { name: "Pib" },
      },
      { text: "Edit PIB", disabled: true },
    ],
    valid: true,
    currentData: {
      FotoUrl: "",
      FotoUrlHybrid: "",
      FotoUrlSambutan: "",
      file_foto: null,
      file_hybrid_foto: null,
      file_foto_sambutan: null,
      sambutan_kalimat: "",
      id: "",
      nama: "",
      deskripsi: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      simposium_lokasi: "",
      simposium_tanggal_awal: "",
      simposium_tanggal_akhir: "",
      simposium_kuota: "",
      simposium_jam_awal: "",
      simposium_jam_akhir: "",
      simposium_peserta: "",
      simposium_harga: "",
      venue_nama: "",
      venue_alamat: "",
      venue_tanggal_awal: "",
      venue_tanggal_akhir: "",
      venue_jam_awal: "",
      venue_jam_akhir: "",
      venue_pic_nama: "",
      venue_pic_phone: "",
      venue_link_google_maps: "",
      simposium_hybrid_lokasi: "",
      simposium_hybrid_tanggal_awal: "",
      simposium_hybrid_tanggal_akhir: "",
      simposium_hybrid_kuota: "",
      simposium_hybrid_jam_awal: "",
      simposium_hybrid_jam_akhir: "",
      simposium_hybrid_peserta: "",
      simposium_hybrid_harga: "",
      is_venue_jam_akhir: false,
      fotoRules: [(v) => !!v || "Foto harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      deskripsiRules: [(v) => !!v || "Deskripsi harus diisi"],
      tanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      tanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      simposiumLokasiRules: [(v) => !!v || "Lokasi harus diisi"],
      simposiumTanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      simposiumTanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      simposiumKuotaRules: [(v) => !!v || "Kuota harus diisi"],
      simposiumJamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      simposiumJamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      simposiumPesertaRules: [(v) => !!v || "Peserta harus diisi"],
      simposiumHargaRules: [(v) => !!v || "Harga harus diisi"],
      venueNamaRules: [(v) => !!v || "Nama Venue harus diisi"],
      venueAlamatRules: [(v) => !!v || "Alamat harus diisi"],
      venueTanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      venueTanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      venueJamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      venueJamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      venuePicNamaRules: [(v) => !!v || "Nama PIC harus diisi"],
      venuePicPhoneRules: [(v) => !!v || "No Telp PIC harus diisi"],
      venueLinkGoogleMapRules: [(v) => !!v || "Link Google Map harus diisi"],
      sambutanKalimatRulesRules: [(v) => !!v || "Sambutan harus diisi"],
      simposiumHybridLokasiRules: [(v) => !!v || "Lokasi harus diisi"],
      simposiumHybridTanggalAwalRules: [
        (v) => !!v || "Tanggal Awal harus diisi",
      ],
      simposiumHybridTanggalAkhirRules: [
        (v) => !!v || "Tanggal Akhir harus diisi",
      ],
      simposiumHybridKuotaRules: [(v) => !!v || "Kuota harus diisi"],
      simposiumHybridJamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      simposiumHybridJamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      simposiumHybridPesertaRules: [(v) => !!v || "Peserta harus diisi"],
      simposiumHybridHargaRules: [(v) => !!v || "Harga harus diisi"],
    },
    items_kategori: [],
    fotoKey: 0,
    fotoKeyHybrid: 0,
    fotoKeySambutan: 0,
  }),
  mounted: async function () {
    this.getPib();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    loadImageHybrid: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrlHybrid = e.target.result;
          this.fotoKeyHybrid++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrlHybrid = "";
        this.currentData.file_hybrid_foto = null;
      }
    },

    loadImageSambutan: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrlSambutan = e.target.result;
          this.fotoKeySambutan++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrlSambutan = "";
        this.currentData.file_foto_sambutan = null;
      }
    },

    getPib() {
      PibService.getPib(this.id)
        .then((res) => {
          this.currentData.nama = res.data.pib.nama;
          this.currentData.deskripsi = res.data.pib.deskripsi;
          this.currentData.sambutan_kalimat = res.data.pib.sambutan_kalimat;
          this.currentData.tanggal_awal = moment(
            res.data.pib.tanggal_awal
          ).format("YYYY-MM-DD");
          this.currentData.tanggal_akhir = moment(
            res.data.pib.tanggal_akhir
          ).format("YYYY-MM-DD");
          this.currentData.simposium_lokasi = res.data.pib.simposium_lokasi;
          this.currentData.simposium_tanggal_awal = moment(
            res.data.pib.simposium_tanggal_awal
          ).format("YYYY-MM-DD");
          this.currentData.simposium_tanggal_akhir = moment(
            res.data.pib.simposium_tanggal_akhir
          ).format("YYYY-MM-DD");
          this.currentData.simposium_kuota = res.data.pib.simposium_kuota;
          this.currentData.simposium_jam_awal = res.data.pib.simposium_jam_awal;
          this.currentData.simposium_jam_akhir =
            res.data.pib.simposium_jam_akhir;
          this.currentData.simposium_peserta = res.data.pib.simposium_peserta;
          this.currentData.simposium_harga = res.data.pib.simposium_harga;
          this.currentData.venue_nama = res.data.pib.venue_nama;
          this.currentData.venue_alamat = res.data.pib.venue_alamat;
          this.currentData.venue_tanggal_awal = moment(
            res.data.pib.venue_tanggal_awal
          ).format("YYYY-MM-DD");
          this.currentData.venue_tanggal_akhir = moment(
            res.data.pib.venue_tanggal_akhir
          ).format("YYYY-MM-DD");
          this.currentData.venue_jam_awal = res.data.pib.venue_jam_awal;

          if (res.data.pib.venue_jam_akhir) {
            this.currentData.venue_jam_akhir = res.data.pib.venue_jam_akhir;
          } else {
            this.currentData.is_venue_jam_akhir = true;
          }
          this.currentData.venue_pic_nama = res.data.pib.venue_pic_nama;
          this.currentData.venue_pic_phone = res.data.pib.venue_pic_phone;
          this.currentData.venue_link_google_map =
            res.data.pib.venue_link_google_map;

          if (res.data.pib.photo) this.currentData.FotoUrl = res.data.pib.photo;
          if (res.data.pib.sambutan_photo)
            this.currentData.FotoUrlSambutan = res.data.pib.sambutan_photo;

          this.currentData.simposium_hybrid_lokasi =
            res.data.pib.simposium_hybrid_lokasi;

          if (res.data.pib.simposium_hybrid_tanggal_awal)
            this.currentData.simposium_hybrid_tanggal_awal = moment(
              res.data.pib.simposium_hybrid_tanggal_awal
            ).format("YYYY-MM-DD");

          if (res.data.pib.simposium_hybrid_tanggal_akhir)
            this.currentData.simposium_hybrid_tanggal_akhir = moment(
              res.data.pib.simposium_hybrid_tanggal_akhir
            ).format("YYYY-MM-DD");
          this.currentData.simposium_hybrid_kuota =
            res.data.pib.simposium_hybrid_kuota;

          if (res.data.pib.simposium_hybrid_jam_awal)
            this.currentData.simposium_hybrid_jam_awal =
              res.data.pib.simposium_hybrid_jam_awal;

          if (res.data.pib.simposium_hybrid_jam_akhir)
            this.currentData.simposium_hybrid_jam_akhir =
              res.data.pib.simposium_hybrid_jam_akhir;
          this.currentData.simposium_hybrid_peserta =
            res.data.pib.simposium_hybrid_peserta;
          this.currentData.simposium_hybrid_harga =
            res.data.pib.simposium_hybrid_harga;

          if (res.data.pib.simposium_hybrid_photo)
            this.currentData.FotoUrlHybrid =
              res.data.pib.simposium_hybrid_photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.isEditable = 0;
    },

    doSubmit: async function () {
      // if (!this.$refs.form.validate()) {
      //   CommonService.showSuccessToast("Mohon lengkapi input yang dibutuhkan");
      //   // window.scrollTo(0, 0);
      //   return;
      // }

      PibService.update(
        this.id,
        this.currentData,
        this.currentData.file_foto,
        this.currentData.file_hybrid_foto
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast(
            "Edit Pertemuan Ilmiah Berkala berhasil"
          );

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Edit Pertemuan Ilmiah Berkala gagal. Coba lagi"
          );
        });
    },
  },
};
</script>

<style scoped></style>
