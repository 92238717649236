<template>
  <div>
    <v-card>
      <v-card-title>
        Harga Simposium <br />

        <v-spacer></v-spacer>

        <!-- <v-btn color="primary" dark class="mb-2" @click="openTambah">
          Tambah
        </v-btn> -->
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Harga Simposium</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Peserta"
                            outlined
                            dense
                            v-model="currentData.value"
                            required
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Harga"
                            outlined
                            dense
                            v-model="currentData.harga"
                            required
                            :rules="currentData.hargaRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.description`]="{ item }">
          {{ truncate(item.description) }}
        </template>
        <template v-slot:[`item.harga`]="{ item }">
          <span v-if="item.harga">
            {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <!-- <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon> -->
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import SimposiumService from "../../services/SimposiumService";
import * as moment from "moment";
import CommonService from "../../services/CommonService";

export default {
  props: {
    id: String,
  },
  components: {},
  data: () => ({
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Peserta", value: "value", sortable: false },
      { text: "Harga", value: "harga", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    currentData: {
      pib_id: "",
      id: null,
      FotoUrl: "",
      file_foto: null,
      nama_paket: "",
      destinasi: "",
      deskripsi: "",
      harga: "",
      alamat: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      jam_awal: "",
      jam_akhir: "",
      namaPaketRules: [(v) => !!v || "Nama Paket harus diisi"],
      destinasiRules: [(v) => !!v || "Destinasi harus diisi"],
      alamatRules: [(v) => !!v || "Alamat harus diisi"],
      tanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      tanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      jamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      jamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      deskripsiRules: [(v) => !!v || "Deskripsi harus diisi"],
      hargaRules: [(v) => !!v || "Harga harus diisi"],
      linkGoogleMapRules: [(v) => !!v || "Link Google Map harus diisi"],
    },
    fotoKey: 0,

    layananList: [],
    currentLayanan: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    truncate: function (input) {
      if (input && input.length > 10) {
        return input.substring(0, 5) + "...";
      }
      return input;
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      SimposiumService.simposiumList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.currentData.FotoUrl = "";
      this.currentData.file_foto = null;
      this.currentData.nama_paket = "";
      this.currentData.destinasi = "";
      this.currentData.alamat = "";
      this.currentData.tangal_awal = "";
      this.currentData.tangal_akhir = "";
      this.currentData.jam_awal = "";
      this.currentData.jam_akhir = "";
      this.currentData.deskripsi = "";
      this.currentData.harga = "";
      this.currentData.link_google_map = "";
      this.dialog = true;
      this.isEditable = 1;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      this.currentData = this.editedItem;

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.pib_id = this.id;
        SimposiumService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Harga berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Tambah Harga gagal. Coba lagi");
          });
      } else {
        this.currentData.pib_id = this.id;

        SimposiumService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update Harga berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Update Harga gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      SimposiumService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus Paket Wisata berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus Paket Wisata gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
