<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Berita
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari judul"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <router-link
            to="/berita-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="purple" dark class="mb-2"> Tambah </v-btn>
          </router-link>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Berita</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Judul"
                        required
                        v-model="currentData.judul"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        outlined
                        label="Konten"
                        v-model="currentData.konten"
                        rows="12"
                        row-height="15"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Is Hide"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedHide"
                        :items="currentData.items_hide"
                        item-text="value"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                        @change="loadImage"
                        accept="image/*"
                        v-model="currentData.foto_url"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="currentData.fotoKey"
                          :src="currentData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          v-model="selected"
          :single-select="true"
          show-select
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:[`item.foto_url`]="{ item }">
            <v-img :src="item.foto_url" cover height="100" width="100"></v-img>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ getCurrentDate(item.created_at) }}</span>
          </template>
          <template v-slot:[`item.data_status`]="{ item }">
            <v-chip :color="getColor(item.data_status)" dark>
              {{ getIsHide(item.data_status) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>

      <v-card class="mt-4">
        <BeritaFotoComponent
          v-if="selected != undefined && selected.length > 0"
          :id="selected[0].id"
          ref="mychild"
          :key="childKey"
        />
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import BeritaService from "../../services/web_content/BeritaService";
import CommonService from "../../services/CommonService";
import * as moment from "moment";

import BeritaFotoComponent from "./BeritaFotoList.vue";

export default {
  components: {
    BeritaFotoComponent,
  },
  data: () => ({
    selected: [],
    breadcrumbs: [
      {
        text: "Data Berita",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "judul", sortable: false },
      { text: "Foto", value: "foto_url", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Status", value: "data_status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      judul: "",
      konten: "",
      foto_url: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      items_hide: [
        {
          text: 0,
          value: "Off",
        },
        {
          text: 1,
          value: "On",
        },
      ],
      selectedHide: "",
    },
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getIsHide(dataStatus) {
      console.log(dataStatus);
      if (dataStatus == 1) {
        return "Published";
      } else {
        return "Unpublished";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 0:
          return "red";
        case 1:
          return "cyan";
        default:
          return "red";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      BeritaService.beritaList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.judul = "";
      this.currentData.konten = "";
      this.currentData.foto_url = null;

      console.log("do add");
    },

    doSave: async function () {
      if (this.currentData.id != null) {
        BeritaService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update berita berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update berita gagal. Coba lagi");
          });
      } else {
        BeritaService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah berita berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah berita gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      // console.log(item);
      // this.editedIndex = this.list_data.indexOf(item);
      // this.editedItem = Object.assign({}, item);

      // this.dialog = true;
      // this.currentData.judul = this.editedItem.judul;
      // this.currentData.konten = this.editedItem.konten;
      // this.currentData.FotoUrl = this.editedItem.foto_url;
      // this.currentData.id = this.editedItem.id;
      // this.currentData.fotoKey++;

      // if (this.editedItem.data_status) {
      //   this.currentData.items_hide.forEach((entry) => {
      //     if (entry.text == this.editedItem.data_status) {
      //       this.currentData.selectedHide = entry;
      //     }
      //   });
      // }

      // console.log("do edit");

      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/berita-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      BeritaService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapur berita berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapur berita gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function (file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },
  },
};
</script>
