import http from "../../http-common";

/* eslint-disable */
class InasiaSimposiumRundownService {
  rundownList(page, itemPerPage, keyword, id) {
    return http.get(
      "/inasia/simposium-rundown/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.inasia_simposium_id)
      bodyFormData.append("inasia_simposium_id", data.inasia_simposium_id);
    if (data.kegiatan) bodyFormData.append("kegiatan", data.kegiatan);
    if (data.lokasi) bodyFormData.append("lokasi", data.lokasi);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.link_youtube)
      bodyFormData.append("link_youtube", data.link_youtube);
    if (data.link_zoom) bodyFormData.append("link_zoom", data.link_zoom);
    if (data.username_zoom)
      bodyFormData.append("username_zoom", data.username_zoom);
    if (data.password_zoom)
      bodyFormData.append("password_zoom", data.password_zoom);

    if (data.file != null) bodyFormData.append("file", data.file);

    return http.post("/inasia/simposium-rundown", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.inasia_simposium_id)
      bodyFormData.append("inasia_simposium_id", data.inasia_simposium_id);
    if (data.kegiatan) bodyFormData.append("kegiatan", data.kegiatan);
    if (data.lokasi) bodyFormData.append("lokasi", data.lokasi);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.link_youtube)
      bodyFormData.append("link_youtube", data.link_youtube);
    if (data.link_zoom) bodyFormData.append("link_zoom", data.link_zoom);
    if (data.username_zoom)
      bodyFormData.append("username_zoom", data.username_zoom);
    if (data.password_zoom)
      bodyFormData.append("password_zoom", data.password_zoom);

    if (data.file != null) bodyFormData.append("file", data.file);

    return http.put(`/inasia/simposium-rundown/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/inasia/simposium-rundown/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InasiaSimposiumRundownService();
