import http from "../../http-common";
/* eslint-disable */
class InasiaPaketWisataPertanyaanService {
  pertanyaanList(page, itemPerPage, keyword, paketWisataId) {
    return http.get(
      "/inasia-paket-wisata-pertanyaan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&paket_wisata_id=" +
        paketWisataId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    return http.post("/inasia-paket-wisata-pertanyaan", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  update(data) {
    return http.put(`/inasia-paket-wisata-pertanyaan/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  delete(id) {
    return http.delete(`/inasia-paket-wisata-pertanyaan/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new InasiaPaketWisataPertanyaanService();
