<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs>
          <v-tab>Bidang</v-tab>
          <!-- <v-tab>Divisi</v-tab> -->
          <v-tab>Jabatan Bidang</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-title> Edit Bidang </v-card-title>
              <v-fab-transition>
                <v-btn
                  class="mt-8 mr-4"
                  v-if="isEditable == 0"
                  @click="changeEditable"
                  color="pink"
                  dark
                  absolute
                  top
                  right
                  fab
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4 pt-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Nama"
                          outlined
                          dense
                          v-model="bidangPengurus.nama"
                          :rules="bidangPengurus.namaRules"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Urutan"
                          outlined
                          dense
                          v-model="bidangPengurus.urutan"
                          :rules="bidangPengurus.urutanRules"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="pb-4">
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      @click="doBatal"
                      style="margin-right: 10px"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <DivisiPengurusComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <JabatanPengurusComponent :id="id" />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import BidangPengurusService from "../services/BidangPengurusService";
import CommonService from "../services/CommonService";
import JabatanPengurusComponent from "./JabatanPengurus.vue";
import DivisiPengurusComponent from "./DivisiPengurus.vue";

export default {
  name: "BidangPengurusEdit",
  components: { JabatanPengurusComponent, DivisiPengurusComponent },
  props: {
    id: String,
  },
  data: () => ({
    komunitas_key: 0,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Bidang Pengurus",
        disabled: false,
        exact: true,
        to: { name: "BidangPengurus" },
      },
      { text: "Edit Bidang Pengurus", disabled: true },
    ],
    valid: true,
    bidangPengurus: {
      id: null,
      nama: "",
      urutan: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
    },
  }),
  mounted: async function () {
    this.getBidangPengurus();
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    getBidangPengurus() {
      BidangPengurusService.getBidangPengurus(this.id)
        .then((res) => {
          this.bidangPengurus.nama = res.data.bidang_pengurus.nama;
          this.bidangPengurus.urutan = res.data.bidang_pengurus.urutan;
          this.bidangPengurus.id = res.data.bidang_pengurus.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go();
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      BidangPengurusService.update(this.id, this.bidangPengurus)
        .then((res) => {
          console.log(res);
          this.isEditable = 0;
          CommonService.showSuccessToast("Update bidang pengurus berhasil");
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Update bidang pengurus gagal. Coba lagi"
          );
        });
    },
  },
};
</script>

<style scoped></style>
