<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <v-card>
        <v-card-title>
          Data Penyelenggara
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            dark
            class="mb-2 ml-4"
            @click="addPenyelenggara()"
          >
            Tambah
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex flex-row">
              <v-icon medium class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PenyelenggaraSkpKemkesService from "../../../services/skp_kemkes/PenyelenggaraSkpKemkesService"
import CommonService from "../../../services/CommonService"

export default {
  data: () => ({
    isProgressBarShow: false,
    breadcrumbs: [
      {
        text: "Data Penyelenggara",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama Penyelenggara", value: "nama", sortable: false },
      { text: "Email", value: "email", sortable: false },
      {
        text: "Nama Penanggung Jawab",
        value: "nama_penanggung_jawab",
        sortable: false,
      },
      {
        text: "Whatsapp Penanggung Jawab",
        value: "whatsapp_penanggung_jawab",
        sortable: false,
      },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },
    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      const { page, itemsPerPage } = this.options

      PenyelenggaraSkpKemkesService.penyelenggaraList(
        page,
        itemsPerPage,
        this.search
      )
        .then((res) => {
          this.list_data = res.data.list_data
          this.totalData = res.data.total
          this.loading = false
          this.isFirst = true
        })
        .catch((err) => {
          this.loading = false
          this.isFirst = true
          console.log(err)
        })
    },

    addPenyelenggara() {
      this.$router.push("/skp-kemkes/penyelenggara-add").catch((err) => {
        console.log(err)
      })
    },

    editItem(item) {
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.$router
        .push("/skp-kemkes/penyelenggara-edit/" + item.id)
        .catch((err) => {
          console.log(err)
        })
    },

    deleteItem(item) {
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.closeDelete()

      PenyelenggaraSkpKemkesService.delete(this.editedItem.id)
        .then(() => {
          CommonService.showSuccessToast("Hapus penyelenggara berhasil")

          this.getDataFromApi()
        })
        .catch((err) => {
          console.log(err)
          CommonService.showErrorToast("Hapus penyelenggara gagal. Coba lagi")
        })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
