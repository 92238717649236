import http from "../http-common"
/* eslint-disable */
class MateriService {
  getMateri(id) {
    return http.get(`/pelatihan-detail/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  create(id, data) {
    var bodyFormData = new FormData()
    bodyFormData.append("pelatihan_detail_id", id)
    if (data.judul) bodyFormData.append("judul", data.judul)
    if (data.file_pdf) bodyFormData.append("file", data.file_pdf)

    return http.post("/webinar", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }

  update(data) {
    var bodyFormData = new FormData()
    bodyFormData.append("id", data.id)
    if (data.materi) bodyFormData.append("materi", data.materi)
    if (data.file_pdf) bodyFormData.append("file", data.file_pdf)

    return http.put(`/pelatihan-detail/materi/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }

  delete(id) {
    return http.delete(`/webinar/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }
}

export default new MateriService()
