<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <div style="position: relative">
        <v-btn
          v-if="readOnly"
          absolute
          fab
          top
          right
          dark
          color="primary"
          @click="editItem"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <br />
      <br />
      <form>
        <v-row dense>
          <v-col cols="12" sm="6" md="6">
            <v-img
              v-if="sejarah.foto_url_original"
              :src="sejarah.foto_url"
              contain
              :key="sejarah.fotoKey"
              max-height="300"
            ></v-img>
            <v-img
              v-else
              src="https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
              contain
              max-height="300"
            ></v-img>
            <v-file-input
              v-if="!readOnly"
              @change="loadImage"
              accept="image/*"
              v-model="sejarah.poster_url"
              prepend-icon="mdi-image"
              show-size
              label="Gambar (1000x1510) px"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  rows="15"
                  label="Konten"
                  v-model="sejarah.description"
                  :outlined="outlined"
                  :readonly="readOnly"
                  dense
                  :hide-details="hideDetails"
                  placeholder="Konten"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="!readOnly">
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
            <v-btn dark color="primary" type="submit" @click.prevent="doSubmit"
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </v-container>
  </v-main>
</template>

<script>
import KontakService from "../../services/web_content/KontakService";
import CommonService from "../../services/CommonService";

export default {
  name: "Kontak",
  components: {},
  props: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Kontak",
        disabled: false,
        exact: true,
        to: { name: "Kontak" },
      },
      { text: "Detail", disabled: true },
    ],
    readOnly: true,
    sejarah: {
      id: null,
      foto_url_original: null,
      foto_url: null,
      fotoKey: 0,
      poster_url: null,
      description: "",
    },
  }),
  computed: {
    outlined() {
      return this.readOnly;
    },
    hideDetails() {
      return true;
    },
  },
  mounted: async function () {
    this.fetch();
  },
  methods: {
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.sejarah.foto_url = e.target.result;
          this.sejarah.foto_url_original = this.sejarah.foto_url;
          this.sejarah.fotoKey++;
        };
        reader.readAsDataURL(data);
      }
    },
    fetch: async function () {
      try {
        console.log("fetch");
        KontakService.pesan()
          .then((res) => {
            this.sejarah = res.data.pesan;
            console.log(this.sejarah);
            console.log(this.sejarah.id);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.error("error:", e);
      }
    },

    editItem: async function () {
      this.readOnly = false;
    },

    doBatal: function () {
      this.readOnly = true;
    },

    doSubmit: async function () {
      try {
        console.log(this.sejarah.id);
        if (this.sejarah.id != null) {
          KontakService.update(this.sejarah)
            .then((res) => {
              console.log(res);
              CommonService.showSuccessToast("Update Kontak berhasil");

              this.fetch();
              this.readOnly = true;
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast("Update Kontak gagal. Coba lagi");
            });
        } else {
          KontakService.create(this.sejarah)
            .then((res) => {
              console.log(res);
              this.dialog = false;
              CommonService.showSuccessToast("Tambah Kontak berhasil");

              this.getDataFromApi();
              this.readOnly = true;
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast("Tambah Kontak gagal. Coba lagi");
            });
        }
      } catch (e) {
        console.error("error:", e);
      }
    },
  },
};
</script>

<style scoped>
</style>
