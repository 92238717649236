import http from "../http-common";
/* eslint-disable */
class EventService {
  eventList(page, itemPerPage, keyword) {
    return http.get(
      "/event/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  kegiatanListByKategori(page, itemPerPage, keyword, kategori, waktu) {
    return http.get(
      "/kegiatan/list/bycategory?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kategori=" +
        kategori +
        "&waktu=" +
        waktu,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getEvent(id) {
    return http.get(`/event/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  uploadFotoKonten(uuid, photo, kategori) {
    var bodyFormData = new FormData();
    if (uuid) bodyFormData.append("uuid", uuid);

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/foto-konten/" + kategori, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  create(data, photo) {
    var bodyFormData = new FormData();
    if (data.judul) bodyFormData.append("judul", data.judul);
    if (data.tanggal_event)
      bodyFormData.append("tanggal_event", data.tanggal_event);
    if (data.penyelenggara)
      bodyFormData.append("penyelenggara", data.penyelenggara);
    if (data.uuid) bodyFormData.append("uuid", data.uuid);

    if (data.linkYoutube) bodyFormData.append("link_youtube", data.linkYoutube);

    if (data.message_broadcast)
      bodyFormData.append("message_broadcast", data.message_broadcast);

    if (data.konten) bodyFormData.append("konten", data.konten);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("data_status", data.selectedHide.text);
    }

    if (data.selectedPeserta && data.selectedPeserta != undefined) {
      bodyFormData.append("peserta", data.selectedPeserta.text);
    }

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/event", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    if (data.judul) bodyFormData.append("judul", data.judul);
    if (data.tanggal_event)
      bodyFormData.append("tanggal_event", data.tanggal_event);
    if (data.penyelenggara)
      bodyFormData.append("penyelenggara", data.penyelenggara);

    if (data.linkYoutube) bodyFormData.append("link_youtube", data.linkYoutube);

    if (data.message_broadcast)
      bodyFormData.append("message_broadcast", data.message_broadcast);

    if (data.konten) bodyFormData.append("konten", data.konten);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("data_status", data.selectedHide.text);
    }

    if (data.selectedPeserta && data.selectedPeserta != undefined) {
      bodyFormData.append("peserta", data.selectedPeserta.text);
    }

    if (photo != null) bodyFormData.append("photo", photo);

    return http.put(`/event/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/event/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new EventService();
