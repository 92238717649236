import http from "../../http-common";
/* eslint-disable */
class InasiaSimposiumService {
  getList(page, itemPerPage, keyword, id, dataStatus) {
    return http.get(
      "/inasia/simposium/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id +
        "&data_status=" +
        dataStatus,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.lokasi) bodyFormData.append("lokasi", data.lokasi);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.kuota) bodyFormData.append("kuota", data.kuota);
    if (data.partisipan) bodyFormData.append("partisipan", data.partisipan);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.post("/inasia/simposium", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.lokasi) bodyFormData.append("lokasi", data.lokasi);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    if (data.jam_awal) bodyFormData.append("jam_awal", data.jam_awal);
    if (data.jam_akhir) bodyFormData.append("jam_akhir", data.jam_akhir);
    if (data.kuota) bodyFormData.append("kuota", data.kuota);
    if (data.partisipan) bodyFormData.append("partisipan", data.partisipan);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/inasia/simposium/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/inasia/simposium/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  aktif(id) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.post("/inasia/simposium-aktif", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InasiaSimposiumService();
