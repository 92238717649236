<template>
  <v-dialog v-model="show" max-width="1000px">
    <v-card>
      <v-card-title>
        Rumah Sakit
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
      </v-card-title>
      <div>
        <v-row class="px-6">
          <v-col>
            <v-text-field
              outlined
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              hide-details
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col
            ><v-combobox
              label="Propinsi"
              clearable
              dense
              outlined
              v-model="selectedPropinsi"
              :items="items_propinsi"
              item-text="name"
              @change="changePropinsi"
              :disabled="isCabangPengurus"
            ></v-combobox
          ></v-col>
        </v-row>
      </div>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="ma-2" color="primary" dark @click.stop="pilih(item)">
            Pilih
            <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="show = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RumahSakitService from "../services/RumahSakitService";
import NakesService from "../services/NakesService";
import store from "../store/index";

export default {
  name: "RumahSakitSelect",
  props: {
    value: Boolean,
    id: String,
  },
  data: () => ({
    dialogCustomer: false,
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Rumah Sakit", value: "nama", sortable: false },
      { text: "Propinsi", value: "propinsi_nama", sortable: false },
      { text: "Alamat", value: "alamat", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      page: 1,
      itemsPerPage: 5,
    },
    selectedPropinsi: "",
    items_propinsi: [],
  }),
  mounted() {
    console.log("open");
    this.getDataFromApi();
    this.getListPropinsi();
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;

          if (this.id) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == this.id) {
                this.$nextTick(() => {
                  this.selectedPropinsi = entry;
                });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.getDataFromApi();
    },

    getDataFromApiParam(propinsiId) {
      if (propinsiId) {
        this.items_propinsi.forEach((entry) => {
          if (entry.id == propinsiId) {
            this.$nextTick(() => {
              this.selectedPropinsi = entry;
            });
          }
        });
      }

      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var propinsi = "";
      var kabupaten = "";

      if (propinsiId) {
        propinsi = propinsiId;
      }

      RumahSakitService.rumahSakitListMaster(
        page,
        itemsPerPage,
        this.search,
        propinsi,
        kabupaten
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var propinsi = "";
      var kabupaten = "";

      if (this.selectedPropinsi) {
        propinsi = this.selectedPropinsi.id;
      }

      RumahSakitService.rumahSakitListMaster(
        page,
        itemsPerPage,
        this.search,
        propinsi,
        kabupaten
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    pilih: function (item) {
      this.$emit("pilihRumahSakit", item);

      this.show = false;
    },
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getRolesId() {
      return store.getters.getUser.roles_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
    isCabangPengurus() {
      if (store.getters.getUser.cabang_pengurus_id) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
