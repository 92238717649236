import http from "../../http-common"

/* eslint-disable */
class InasiaPesertaService {
  pesertaList(
    page,
    itemPerPage,
    keyword,
    id,
    kepesertaan = "",
    kategori = "",
    item = "",
    statusProfesi = "",
    cabangId = ""
  ) {
    return http.get(
      "/inasia/peserta/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id +
        "&kepesertaan=" +
        kepesertaan +
        "&kategori=" +
        kategori +
        "&item=" +
        item +
        "&jenis_anggota=" +
        statusProfesi +
        "&cabang_id=" +
        cabangId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  pesertaInasia(id) {
    return http.get("/inasia/user/" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  pesertaCard(id) {
    return http.get("/inasia/user/card/" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  pesertaCardFull(id) {
    return http.get("/pib-user/card-full/" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  update(data) {
    var bodyFormData = new FormData()
    bodyFormData.append("id", data.id)

    if (data.nama) bodyFormData.append("nama", data.nama)
    if (data.whatsapp) bodyFormData.append("phone", data.whatsapp)
    if (data.email) bodyFormData.append("email", data.email)
    if (data.institusi) bodyFormData.append("institusi", data.institusi)

    // if (data.selectedKategori && data.selectedKategori != undefined) {
    //   bodyFormData.append("jenis_anggota", data.selectedKategori.code)
    // }

    if (
      data.selectedDomisiliPropinsi &&
      data.selectedDomisiliPropinsi != undefined
    ) {
      bodyFormData.append("propinsi_id", data.selectedDomisiliPropinsi.id)
    }

    return http.put(`/inasia/user/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  updateCatatan(invoiceNo, catatan) {
    var bodyFormData = new FormData()

    bodyFormData.append("catatan", catatan)
    bodyFormData.append("invoice_no", invoiceNo)

    return http.post(`/pib/kehadiran/catatan`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  downloadPesertaReport(
    keyword,
    id,
    kepesertaan = "",
    kategori = "",
    item = "",
    statusProfesi = "",
    cabangId = ""
  ) {
    return http.get(
      "/report/inasia/peserta/download?keyword=" +
        keyword +
        "&id=" +
        id +
        "&kepesertaan=" +
        kepesertaan +
        "&kategori=" +
        kategori +
        "&item=" +
        item +
        "&jenis_anggota=" +
        statusProfesi +
        "&cabang_id=" +
        cabangId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  downloadPesertaOnlyReport(
    keyword,
    id,
    kepesertaan = "",
    kategori = "",
    item = "",
    statusProfesi = "",
    cabangId = ""
  ) {
    return http.get(
      "/report/inasia/peserta-only/download?keyword=" +
        keyword +
        "&id=" +
        id +
        "&kepesertaan=" +
        kepesertaan +
        "&kategori=" +
        kategori +
        "&item=" +
        item +
        "&jenis_anggota=" +
        statusProfesi +
        "&cabang_id=" +
        cabangId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  resetPassword(id) {
    var bodyFormData = new FormData()
    bodyFormData.append("id", id)

    return http.put(`/pib-user/reset-password/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }
}

export default new InasiaPesertaService()
