<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card>
      <v-card-title>
        E-Poster
        <v-spacer></v-spacer>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search.nama"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="onDownloadEposter()"
        >
          Download
        </v-btn>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.jenis_eposter`]="{ item }">
          {{ getJeniEPoster(item.jenis_eposter) }}
        </template>
        <template v-slot:[`item.foto_url`]="{ item }">
          <a v-if="item.foto_url_original" :href="item.foto_url" target="_blank"
            >Link Foto</a
          >
        </template>
        <template v-slot:[`item.file`]="{ item }">
          <a v-if="item.file_original" :href="item.file" target="_blank"
            >Link File PDF</a
          >
        </template>
        <template v-slot:[`item.abstrak`]="{ item }">
          <a v-if="item.abstrak_original" :href="item.abstrak" target="_blank"
            >Link File PDF</a
          >
        </template>
        <template v-slot:[`item.manuskrip`]="{ item }">
          <a
            v-if="item.manuskrip_original"
            :href="item.manuskrip"
            target="_blank"
            >Link Manuskrip</a
          >
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="
              (item.kategori == 4 || item.kategori == 6) &&
              (item.status == 2 || item.status == 3) &&
              (item.is_approver == 1 || getRoleId == 1)
            "
            medium
            class="mr-3"
            @click="approveItem(item)"
          >
            mdi-check
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import EPosterPibService from "../../services/inasia/InasiaEPosterPesertaService";
import CommonService from "../../services/CommonService";
import store from "../../store/index";

export default {
  props: {
    id: String,
  },
  components: {},
  data: () => ({
    isProgressBarShow: false,
    search: {
      nama: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Asal Institusi", value: "asal_institusi", sortable: false },
      { text: "No Telepon", value: "phone", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Jenis E-Poster", value: "jenis_eposter", sortable: false },
      { text: "Foto", value: "foto_url", sortable: false },
      { text: "Lampiran Abstrak", value: "abstrak", sortable: false },
      { text: "PDF E-Poster", value: "file", sortable: false },
      { text: "Manuskrip", value: "manuskrip", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    dialogDelete: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getJeniEPoster(id) {
      if (id == 1) {
        return "Original Research";
      } else if (id == 2) {
        return "Case Report";
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var status = "";
      var kategori = "";
      var item = "";

      EPosterPibService.eposterList(
        page,
        itemsPerPage,
        this.search.nama,
        status,
        kategori,
        item,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      EPosterPibService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus eposter berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus eposter gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      EPosterPibService.approve(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve iuran berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve iuran gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    onDownloadEposter() {
      this.isProgressBarShow = true;

      EPosterPibService.downloadEposter(this.search.nama, this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
