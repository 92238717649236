import Vue from "vue"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import router from "./router/index"
import store from "./store"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import LoadingPlugin from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
})

Vue.use(LoadingPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
