<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <v-card>
        <v-card-title>
          Data Fasilitator
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus fasilitator ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          v-model="selected"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <div>
        <UserFasilitatorComponent
          ref="mychild"
          :key="childKey"
          :refresh="getDataFromApi"
        />
      </div>
    </v-container>
  </v-main>
</template>

<script>
import DataFasilitatorService from "../../services/DataFasilitatorService"
import CommonService from "../../services/CommonService"

import UserFasilitatorComponent from "./UserFasilitator.vue"

export default {
  components: {
    UserFasilitatorComponent,
  },
  data: () => ({
    dialogAnggota: false,
    childKey: 0,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "DIKLATLIT",
        disabled: true,
      },
      {
        text: "Data Fasilitator",
        disabled: true,
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No NPA", value: "no_npa", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "No KTP", value: "no_ktp", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_jabatan: [],
    items_divisi: [],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options

      this.loading = true
      DataFasilitatorService.dataFasilitatorList(
        page,
        itemsPerPage,
        this.search
      )
        .then((res) => {
          this.list_data = res.data.list_data
          this.totalData = res.data.total
          this.loading = false
          this.isFirst = true
        })
        .catch((err) => {
          this.loading = false
          this.isFirst = true
          console.log(err)
        })
    },

    editItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)

      if (item.is_anggota == 1) {
        this.$router.push("/nakes-edit/" + item.user_id).catch((err) => {
          console.log(err)
        })
      } else {
        this.$router.push("/fasilitator-edit/" + item.user_id).catch((err) => {
          console.log(err)
        })
      }
    },

    deleteItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.closeDelete()

      DataFasilitatorService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res)
          CommonService.showSuccessToast("Hapus data berhasil")

          this.getDataFromApi()
          this.$refs.mychild.reload()
        })
        .catch((err) => {
          console.log(err)

          CommonService.showErrorToast("Hapus data gagal. Coba lagi")
        })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
