import http from "../http-common"
/* eslint-disable */
class DataFasilitatorService {
  dataFasilitatorList(page, itemPerPage, keyword) {
    return http.get(
      "/skp-kegiatan/fasilitator/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  dataFasilitatorNonList(page, itemPerPage, keyword) {
    return http.get(
      "/skp-kegiatan/fasilitator-non/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  dataFasilitatorNonNewList(page, itemPerPage, keyword) {
    return http.get(
      "/skp-kegiatan/fasilitator-non-new/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  create(data) {
    var bodyFormData = new FormData()
    bodyFormData.append("user_id", data.id)

    return http.post("/skp-kegiatan/fasilitator", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }

  delete(id) {
    return http.delete(`/skp-kegiatan/fasilitator/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }

  detailFasilitatorNon(id) {
    return http.get("/skp-kegiatan/fasilitator-non/" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  registerNonAnggota(data, foto, ijazah) {
    var bodyFormData = new FormData()
    if (data.nama) bodyFormData.append("nama", data.nama)
    if (data.selectedProfesi && data.selectedProfesi != undefined) {
      bodyFormData.append("profesi_id", data.selectedProfesi.code)
    }
    if (data.no_ktp) bodyFormData.append("no_ktp", data.no_ktp)
    if (data.phone) bodyFormData.append("phone", data.phone)
    if (data.email) bodyFormData.append("email ", data.email)
    if (foto != null) bodyFormData.append("photo", foto)
    if (ijazah != null) bodyFormData.append("foto_ijazah", ijazah)

    return http.post("/skp-kegiatan/fasilitator-non/register", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  updateNonAnggota(id, data, foto, ijazah) {
    var bodyFormData = new FormData()
    if (data.nama) bodyFormData.append("nama", data.nama)
    if (data.selectedProfesi && data.selectedProfesi != undefined) {
      bodyFormData.append("profesi_id", data.selectedProfesi.code)
    }
    if (data.no_ktp) bodyFormData.append("no_ktp", data.no_ktp)
    if (data.phone) bodyFormData.append("phone", data.phone)
    if (data.email) bodyFormData.append("email ", data.email)
    if (foto != null) bodyFormData.append("photo", foto)
    if (ijazah != null) bodyFormData.append("foto_ijazah", ijazah)

    return http.put("/skp-kegiatan/fasilitator-non/" + id, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }
}

export default new DataFasilitatorService()
