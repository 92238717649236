import http from "../../http-common";

/* eslint-disable */
class GaransiSponsorPesertaService {
  pesertaList(page, itemPerPage, keyword, id, statusBayarSponsor, sponsor) {
    return http.get(
      "/inasia/garansi-sponsor-peserta/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword.nama +
        "&sponsor=" +
        sponsor +
        "&id=" +
        id +
        "&status_bayar_sponsor=" +
        statusBayarSponsor,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);
    if (data.garansi_sponsor_id)
      bodyFormData.append("sponsor_id", data.garansi_sponsor_id);
    if (data.user_id) bodyFormData.append("user_id", data.user_id);
    if (data.nama_kontak_person_sponsor)
      bodyFormData.append(
        "nama_kontak_person_sponsor",
        data.nama_kontak_person_sponsor
      );

    if (data.email_sponsor)
      bodyFormData.append("email_sponsor", data.email_sponsor);

    if (data.hp_kontak_person_sponsor)
      bodyFormData.append(
        "hp_kontak_person_sponsor",
        data.hp_kontak_person_sponsor
      );

    if (data.bukti_file_foto != null)
      bodyFormData.append("photo", data.bukti_file_foto);

    return http.post("/inasia/garansi-sponsor-calon", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);
    if (data.garansi_sponsor_id)
      bodyFormData.append("vendor_sponsor_id", data.garansi_sponsor_id);
    if (data.user_id) bodyFormData.append("user_id", data.user_id);

    return http.put(
      `/inasia/garansi-sponsor-sponsor/${data.id}`,
      bodyFormData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  delete(id) {
    return http.delete(`/inasia/garansi-sponsor-peserta/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadPesertaReport(keyword, id, statusBayarSponsor, sponsor) {
    return http.get(
      "/report/inasia/garansi-letter/download?keyword=" +
        keyword +
        "&inasia_id=" +
        id +
        "&sponsor=" +
        sponsor +
        "&status_bayar_sponsor=" +
        statusBayarSponsor,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }
}

export default new GaransiSponsorPesertaService();
