<template>
  <v-main>
    <!--test2-->
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <v-card>
        <v-tabs>
          <v-tab>Borang</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-title>
                Borang
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialogReject" max-width="700px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Apa anda akan mereject Borang ini?</v-card-title
                    >
                    <v-card-actions>
                      <v-container>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeReject"
                            >Tidak</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="rejectItemConfirm"
                            >Ya</v-btn
                          >
                        </v-row>
                      </v-container>

                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogApprove" max-width="600px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Apa anda akan mengapprove borang anggota
                      ini?</v-card-title
                    >
                    <v-card-actions>
                      <v-container>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closeApprove"
                            >Tidak</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="approveItemConfirm"
                            >Ya</v-btn
                          >
                        </v-row>
                      </v-container>

                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogBuktiBayar" max-width="600px">
                  <v-card>
                    <v-img
                      height="auto"
                      width="auto"
                      :src="fotoBuktiBayar"
                    ></v-img>
                  </v-card>
                </v-dialog>
              </v-card-title>

              <v-row class="mx-6 mb-6">
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="search.nama"
                    append-icon="mdi-magnify"
                    label="Cari Nama Anggota"
                    single-line
                    hide-details
                    v-on:keyup.enter="getDataFromApi"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="search.kegiatan"
                    append-icon="mdi-magnify"
                    label="Cari nama kegiatan"
                    single-line
                    hide-details
                    v-on:keyup.enter="getDataFromApi"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    label="Cabang Perdatin"
                    clearable
                    dense
                    outlined
                    v-model="search.selectedPropinsi"
                    :items="items_cabang"
                    item-text="name"
                    @change="changePropinsi"
                    :disabled="getCabangPengurusId != null"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    label="Tahun"
                    clearable
                    dense
                    outlined
                    v-model="search.tahun"
                    :items="items_tahun"
                    item-text="name"
                    @change="getDataFromApi"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    label="Kategori"
                    clearable
                    dense
                    outlined
                    v-model="search.selectedKategori"
                    :items="items_kategori"
                    item-text="value"
                    @change="getDataFromApi"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    label="Status"
                    clearable
                    dense
                    outlined
                    v-model="search.selectedStatus"
                    :items="items_status"
                    item-text="name"
                    @change="getDataFromApi"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-data-table
                :headers="headers"
                :items="list_data"
                :options.sync="options"
                :server-items-length="totalData"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip :color="getColor(item.status)" dark>
                    {{ getStatus(item.status) }}
                  </v-chip>
                </template>
                <template v-slot:[`item.foto_kegiatan`]="{ item }">
                  <v-btn
                    v-if="item.foto_kegiatan_original"
                    color="blue"
                    dark
                    class="mb-2"
                    @click="showFotoBuktiBayar(item.foto_kegiatan)"
                  >
                    View
                  </v-btn>
                </template>
                <template v-slot:[`item.foto_skp`]="{ item }">
                  <v-btn
                    v-if="item.foto_skp_original"
                    color="blue"
                    dark
                    class="mb-2"
                    @click="showFotoBuktiBayar(item.foto_skp)"
                  >
                    View
                  </v-btn>
                </template>
                <template v-slot:[`item.file_skp`]="{ item }">
                  <div v-if="item.file_skp_original">
                    <a target="_blank" :href="item.file_skp">Link PDF</a>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="d-flex flex-row">
                    <v-icon
                      v-if="
                        (item.status == 1 || item.status == 2) &&
                        (item.is_approver == 1 || getRoleId == 1)
                      "
                      medium
                      class="mr-3"
                      @click="approveItem(item)"
                    >
                      mdi-check
                    </v-icon>
                    <v-icon
                      v-if="
                        (item.status == 1 || item.status == 2) &&
                        (item.is_approver == 1 || getRoleId == 1)
                      "
                      medium
                      class="mr-3"
                      @click="rejectItem(item)"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                  <!-- <v-icon
            medium
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <TabsolComponent />
          </v-tab-item>
          <v-tab-item>
            <MasterIuranRekeningPusatComponent />
          </v-tab-item>
          <v-tab-item>
            <MasterIuranComponent />
          </v-tab-item>
          <v-tab-item>
            <MasterRekeningComponent />
          </v-tab-item>
        </v-tabs>
      </v-card>

      <AnggotaSelectComponent
        @pilihAnggota="pilihAnggota"
        ref="modalAnggota"
        v-model="dialogAnggota"
      />
    </v-container>
  </v-main>
</template>

<script>
import BorangService from "../services/BorangService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";
import NakesService from "../services/NakesService";
import * as moment from "moment";

import AnggotaSelectComponent from "../components/AnggotaSelect.vue";
import MasterIuranComponent from "./MasterIuran.vue";
import MasterRekeningComponent from "./MasterRekening.vue";
import MasterIuranRekeningPusatComponent from "./MasterIuranRekeningPusat.vue";
import TabsolComponent from "./Tabsol.vue";
import store from "../store/index";

export default {
  components: {
    AnggotaSelectComponent,
    MasterIuranComponent,
    TabsolComponent,
    MasterRekeningComponent,
    MasterIuranRekeningPusatComponent,
  },
  data: () => ({
    dialogBuktiBayar: false,
    dialogAnggota: false,
    dialogApprove: false,
    dialogUpdate: false,
    dialogReject: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Borang",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      kegiatan: "",
      tahun: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      {
        text: "Nama",
        value: "user_nama",
        sortable: false,
        width: "200px",
      },
      {
        text: "Kegiatan",
        value: "kegiatan_type_nama",
        sortable: false,
        width: "200px",
      },
      { text: "Cabang", value: "cabang_nama", sortable: false },
      {
        text: "Kategori",
        value: "borang_type_nama",
        sortable: false,
        width: "200px",
      },
      { text: "Tahun", value: "tahun", sortable: false },
      {
        text: "Nama Kegiatan",
        value: "nama_kegiatan",
        sortable: false,
        width: "200px",
      },
      { text: "Foto Kegiatan", value: "foto_kegiatan", sortable: false },
      { text: "No Sertifikat", value: "nomor_sertifikat", sortable: false },
      { text: "Jumlah SKP", value: "skp", sortable: false, width: "100px" },
      { text: "Status", value: "status", sortable: false },
      {
        text: "Borang Item",
        value: "borang_item_nama",
        sortable: false,
        width: "400px",
      },
      {
        text: "Borang Sub Item",
        value: "borang_sub_item_nama",
        sortable: false,
        width: "200px",
      },
      { text: "Foto Sertifikat", value: "foto_skp", sortable: false },
      { text: "PDF Sertifikat", value: "file_skp", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      selectedCabangTujuan: "",
      selectedKategori: "",
      selectedCabang: "",
      no_npa: "",
      cabang_asal_nama: "",
      cabang_asal: "",
      tahun: "",
      nominal: "",
      langsung_setujui: false,
      foto_url: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      nominal_pusat: 900000,
      nominal_cabang: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
      tahunRules: [(v) => !!v || "Tahun harus diisi"],
      nominalRules: [(v) => !!v || "Nominal harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_jabatan: [],
    items_divisi: [],
    items_cabang: [],
    items_tahun: [],
    items_kategori: [],
    items_status: [
      {
        id: 1,
        name: "Menunggu Konfirmasi Cabang",
      },
      {
        id: 2,
        name: "Menunggu Konfirmasi Pusat",
      },
      {
        id: 3,
        name: "Terverifikasi",
      },
      {
        id: 4,
        name: "Rejected",
      },
    ],

    fotoBuktiBayar: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear(); i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.search.tahun = new Date().getFullYear();

    this.getListPropinsi();
    this.getKategoriType();
    // this.getDataFromApi();
  },
  methods: {
    getSplittedType(id) {
      if (id == 1) {
        return "Sudah";
      } else {
        return "Belum";
      }
    },
    getKategori(id) {
      if (id == 1) {
        return "Pembayaran Iuran pendaftaran Anggota";
      } else if (id == 2) {
        return "Pembayaran Tabsol";
      } else if (id == 3) {
        return "Pembayaran perpanjangan Iuran Anggota";
      } else if (id == 4) {
        return "Upload Data Iuran Lama";
      } else if (id == 5) {
        return "Pembayaran Pelatihan";
      } else if (id == 6) {
        return "Update Tabsol Lama";
      }
    },
    async getListOrganisasiDivisi() {
      MasterService.organisasiDivisiList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_divisi = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListOrganisasiJabatan() {
      MasterService.organisasiJabatanList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_jabatan = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getColorKategori(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "pink lighten-3";
        case 2:
          return "purple lighten-3";
        case 3:
          return "teal lighten-3";
        default:
          return "red lighten-3";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "light-blue";
        case 2:
          return "green";
        case 3:
          return "cyan";
        default:
          return "red";
      }
    },

    getColorSplitted(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "green";
        default:
          return "red";
      }
    },

    getStatus(id) {
      if (id == 1) {
        return "Menunggu Konfirmasi Cabang";
      } else if (id == 2) {
        return "Menunggu Konfirmasi Pusat";
      } else if (id == 3) {
        return "Terverifikasi";
      } else if (id == 4) {
        return "Rejected";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var tahun = "";
      var kategori = "";
      var status = "";
      var propinsi = "";

      if (this.search.tahun) {
        tahun = this.search.tahun;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.code;
      }

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedPropinsi) {
        propinsi = this.search.selectedPropinsi.id;
      }

      BorangService.borangList(
        page,
        itemsPerPage,
        this.search.nama,
        this.search.kegiatan,
        tahun,
        kategori,
        status,
        propinsi
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.isFirst = true;
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.no_npa = "";
      this.currentData.tahun = "";
      this.currentData.nominal = "";
      this.currentData.foto_url = null;
      this.currentData.FotoUrl = "";
      this.currentData.langsung_setujui = false;
      this.currentData.selectedDivisi = "";
      this.currentData.selectedJabatan = "";
      this.currentData.cabang_asal_nama = "";
      this.currentData.cabang_asal = "";
      this.currentData.selectedKategori = null;
      this.currentData.langsung_setujui = false;

      console.log("do add");
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        BorangService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialogUpdate = false;
            CommonService.showSuccessToast("Update iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update iuran gagal. Coba lagi");
          });
      } else {
        BorangService.create(this.currentData, this.currentData.foto_url)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah iuran gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialogUpdate = true;
      this.currentData.nama = this.editedItem.user_nama;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.id = this.editedItem.id;
      this.currentData.cabang_asal = this.editedItem.cabang_asal;
      this.currentData.cabang_asal_nama = this.editedItem.cabang_asal_nama;

      this.items_cabang.forEach((entry) => {
        if (entry.id == this.editedItem.cabang_tujuan) {
          this.currentData.selectedCabangTujuan = entry;
        }
      });

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      BorangService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pengurus berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pengurus gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function (file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },

    openAnggotaModal: async function () {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihAnggota(item) {
      this.dialogAnggota = false;
      console.log(item);
      this.currentData.nama = item.nama;
      this.currentData.user_id = item.id;
      this.currentData.cabang_asal = item.cabang_id;
      this.currentData.cabang_asal_nama = item.propinsi_nama;
      this.currentData.no_npa = item.no_npa;
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      BorangService.approve(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve Borang berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve Borang gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    async getKategoriType() {
      MasterService.getKategoriType()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_cabang = res.data.list_data;

          if (this.getCabangPengurusId) {
            this.items_cabang.forEach((entry) => {
              if (entry.id == this.getCabangPengurusId) {
                this.search.selectedPropinsi = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMasterIuran() {
      if (
        this.currentData.selectedKategori &&
        this.currentData.selectedKategori.id != 2 &&
        this.currentData.selectedKategori.id != 6 &&
        this.currentData.nama &&
        this.currentData.tahun
      ) {
        BorangService.getMasterIuran(
          this.currentData.cabang_asal,
          this.currentData.tahun
        )
          .then((res) => {
            var invoice = res.data.invoice;
            if (invoice) this.currentData.nominal_cabang = invoice.nominal;
            else this.currentData.nominal_cabang = 0;

            this.currentData.nominal =
              this.currentData.nominal_cabang + this.currentData.nominal_pusat;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.currentData.nominal = 0;
      }
    },

    showFotoBuktiBayar(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },

    async changePropinsi() {
      this.getDataFromApi();
    },

    rejectItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReject = true;
    },

    closeReject() {
      this.dialogReject = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    rejectItemConfirm() {
      BorangService.reject(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeReject();

          console.log(res);
          this.$toast.success("Reject Borang berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Reject Borang gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },

  computed: {
    nominalTotal() {
      var nominalCabang = 0;
      var nominalPusat = 0;

      if (this.nominal_cabang) {
        nominalCabang = this.nominal_cabang;
      }

      if (this.nominal_pusat) {
        nominalPusat = this.nominal_pusat;
      }

      return nominalPusat + nominalCabang;
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
