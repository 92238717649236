<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-card>
        <v-card-title>
          Informasi Broadcast <br />

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn color="primary" dark class="mb-2" @click="openTambah">
            Tambah
          </v-btn>
          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Informasi Broadcast</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-container>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-sheet color="white" elevation="2">
                          <v-responsive :aspect-ratio="16 / 9">
                            <v-img
                              :key="fotoKey"
                              :src="currentData.FotoUrl"
                              contain
                            ></v-img>
                          </v-responsive>
                        </v-sheet>
                        <v-file-input
                          @change="loadImage"
                          accept="image/*"
                          v-model="currentData.file_foto"
                          prepend-icon="mdi-image"
                          show-size
                          label="Foto"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Nama"
                          outlined
                          dense
                          v-model="currentData.nama"
                          required
                          :rules="currentData.namaRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Deskripsi"
                          dense
                          value=""
                          v-model="currentData.deskripsi"
                          :rules="currentData.deskripsiRules"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox
                          label="Kategori"
                          clearable
                          dense
                          outlined
                          v-model="currentData.kategori"
                          :items="items_kategori"
                          item-text="name"
                        ></v-combobox>
                      </v-col>
                      <v-col
                        v-if="
                          currentData &&
                          currentData.kategori &&
                          currentData.kategori.id == 1
                        "
                        cols="12"
                      >
                        <v-combobox
                          label="Nama Event"
                          clearable
                          dense
                          outlined
                          v-model="currentData.event"
                          :items="items_event"
                          item-text="nama"
                          :rules="currentData.eventRules"
                          @click="openEventModal"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogBroadcast" max-width="600px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan mengirim informasi broadcast ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeBroadcast"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="broadcastItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogCancelBroadcast" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan mencancel broadcast ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeCancelBroadcast"
                  >Tidak</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="cancelBroadcastItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          :single-select="true"
          show-select
          class="elevation-1"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.status && item.status != 1"
              :color="getColor(item.status)"
              dark
            >
              {{ getStatus(item.status) }}
            </v-chip>
          </template>
          <template v-slot:[`item.tanggal`]="{ item }">
            {{ getCurrentDate(item.tanggal) }}
          </template>
          <template v-slot:[`item.deskripsi`]="{ item }">
            {{ truncate(item.deskripsi) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="item.status == 1 || item.status == 3 || item.status == 4"
              medium
              class="mr-3"
              @click="sendBroadcast(item)"
            >
              mdi-send
            </v-icon>
            <v-icon
              v-if="item.status == 2"
              medium
              class="mr-3"
              @click="cancelBroadcast(item)"
            >
              mdi-cancel
            </v-icon>
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-card class="mt-4">
        <PibBroadcastUserComponent
          v-if="selected != undefined && selected.length > 0"
          :id="selected[0].id"
          ref="mychild"
          :key="childKey"
          :pib_id="id"
        />
      </v-card>

      <!-- Modal -->

      <EventSelectComponent
        @pilihEvent="pilihEvent"
        ref="modalEvent"
        v-model="dialogEvent"
      />
    </v-container>
  </v-main>
</template>

<script>
import InformasiBroadcastService from "../services/InformasiBroadcastService";
import * as moment from "moment";
import CommonService from "../services/CommonService";

import PibBroadcastUserComponent from "./PibBroadcastUser.vue";
import EventSelectComponent from "../components/EventSelect.vue";

export default {
  props: {
    id: String,
  },
  components: {
    PibBroadcastUserComponent,
    EventSelectComponent,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Informasi Broadcast",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Deskripsi", value: "deskripsi", sortable: false },
      { text: "Terkirim", value: "terkirim", sortable: false },
      { text: "Tidak Terkirim", value: "tidak_terkirim", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    dialogBroadcast: false,
    dialogCancelBroadcast: false,

    currentFaskes: {
      nama: "",
    },

    currentData: {
      pib_id: "",
      id: null,
      FotoUrl: "",
      file_foto: null,
      nama: "",
      deskripsi: "",
      kategori: "",
      event: "",
      event_id: "",
      eventRules: [(v) => !!v || "Event harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      deskripsiRules: [(v) => !!v || "Deskripsi harus diisi"],
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },
    fotoKey: 0,

    layananList: [],
    currentLayanan: null,
    items_kategori: [
      {
        id: 1,
        name: "Event",
      },
    ],
    items_event: [],
    dialogEvent: false,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    openEventModal: async function () {
      this.$refs.modalEvent.getDataFromApi();
      this.dialogEvent = true;
    },

    pilihEvent(item) {
      this.dialogEvent = false;
      console.log(item);
      this.currentData.event = item.judul;
      this.currentData.event_id = item.id;

      this.getDataByKategori();
    },

    getStatus(id) {
      //1=belum dikirim, 2=sedang dikirim, 3=selesai dikirim, 4=di cancel
      if (id == 1) {
        return "Belum Dikirim";
      } else if (id == 2) {
        return "Sedang Mengirim";
      } else if (id == 3) {
        return "Selesai Dikirim";
      } else if (id == 4) {
        return "Dicancel";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "green";
        case 2:
          return "orange";
        case 3:
          return "green";
        case 4:
          return "pink";
      }
    },

    truncate: function (input) {
      if (input && input.length > 100) {
        return input.substring(0, 100) + "...";
      }
      return input;
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      InformasiBroadcastService.broadcastList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.currentData.FotoUrl = "";
      this.currentData.file_foto = null;
      this.currentData.nama = "";
      this.currentData.deskripsi = "";
      this.dialog = true;
      this.isEditable = 1;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      // this.currentData = this.editedItem;
      this.currentData.id = this.editedItem.id;
      this.currentData.FotoUrl = this.editedItem.photo;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.deskripsi = this.editedItem.deskripsi;

      if (this.editedItem.kategori) {
        this.items_kategori.forEach((entry) => {
          if (entry.id == this.editedItem.kategori) {
            this.currentData.kategori = entry;
          }
        });

        this.currentData.event_id = this.editedItem.event_id;
        this.currentData.event = this.editedItem.event_nama;
      }

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.pib_id = this.id;
        InformasiBroadcastService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast(
              "Tambah Informasi Broadcast berhasil"
            );

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast(
              "Tambah Informasi Broadcast. Coba lagi"
            );
          });
      } else {
        this.currentData.pib_id = this.id;

        InformasiBroadcastService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast(
              "Update Informasi Broadcast berhasil"
            );

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast(
              "Update Informasi Broadcast gagal. Coba lagi"
            );
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    sendBroadcast(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogBroadcast = true;
    },

    cancelBroadcast(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogCancelBroadcast = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      InformasiBroadcastService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus Informasi Broadcast berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Hapus Informasi Broadcast gagal. Coba lagi"
          );
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeBroadcast() {
      this.dialogBroadcast = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    broadcastItemConfirm() {
      this.closeBroadcast();

      InformasiBroadcastService.sendBroadcast(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Sedang diproses");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Gagal memproses. Coba lagi");
        });
    },

    closeCancelBroadcast() {
      this.dialogCancelBroadcast = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    cancelBroadcastItemConfirm() {
      this.closeCancelBroadcast();

      InformasiBroadcastService.cancelBroadcast(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Broadcast berhasil dicancel");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Gagal mencancel broadcast. Coba lagi");
        });
    },
  },
};
</script>
