<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab>Verifikasi Identitas Digital</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card class="pl-4 pr-4 pt-4 pb-4">
            <v-fab-transition>
              <v-btn
                class="mt-8 mr-4"
                v-if="isEditable == 0"
                @click="changeEditable"
                color="pink"
                dark
                absolute
                top
                right
                fab
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-fab-transition>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKey"
                          :src="currentData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImage"
                      accept="image/*"
                      v-model="currentData.file_foto"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto Selfie"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Nama KTP"
                          dense
                          rows="1"
                          v-model="currentData.nama"
                          :rules="currentData.namaRules"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="No Telepon / HP"
                          dense
                          rows="1"
                          v-model="currentData.whatsapp"
                          :rules="currentData.whatsappRules"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Email"
                          dense
                          rows="1"
                          v-model="currentData.email"
                          :rules="currentData.emailRules"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Nomor Induk Kependudukan (NIK)"
                          dense
                          rows="1"
                          v-model="currentData.nik"
                          :rules="currentData.nikRules"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          outlined
                          label="Tanggal Lahir"
                          v-model="currentData.tanggal_lahir"
                          type="date"
                          dense
                          :readonly="isEditable == 0"
                          :rules="currentData.tanggalLahirRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      @click="doBatal"
                      style="margin-right: 10px"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import CommonService from "../../services/CommonService";
import PibIdentitasService from "../../services/PibIdentitasService";
import * as moment from "moment";

export default {
  name: "VerifikasiIdentitasDetail",
  components: {},
  props: {
    id: String,
  },
  data: () => ({
    tab: null,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data PIB",
        disabled: false,
        exact: true,
        to: { name: "Pib" },
      },
      { text: "Edit PIB", disabled: true },
    ],
    valid: true,
    currentData: {
      FotoUrl: "",
      file_foto: null,
      id: "",
      nama: "",
      whatsapp: "",
      email: "",
      nik: "",
      tanggal_lahir: "",
      user_id: "",
      fotoRules: [(v) => !!v || "Foto harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      whatsappRules: [(v) => !!v || "No Telepon/HP harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      nikRules: [(v) => !!v || "NIK harus diisi"],
      tanggalLahirRules: [(v) => !!v || "Tanggal Lahir harus diisi"],
    },
    fotoKey: 0,
  }),
  mounted: async function () {
    this.getPib();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    getPib() {
      PibIdentitasService.getIdentitas(this.id)
        .then((res) => {
          this.currentData.nama = res.data.user.nama_ktp;
          this.currentData.whatsapp = res.data.user.whatsapp;
          this.currentData.email = res.data.user.email;
          this.currentData.nik = res.data.user.nik;
          this.currentData.tanggal_lahir = moment(
            res.data.user.tanggal_lahir
          ).format("YYYY-MM-DD");

          this.currentData.FotoUrl = res.data.user.selfie;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.isEditable = 0;
    },

    doSubmit: async function () {
      this.currentData.user_id = this.id;
      PibIdentitasService.create(this.currentData, this.currentData.file_foto)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Update Identitas berhasil");

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Update Identitas gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped></style>
