<template>
  <div>
    <v-card>
      <v-card-title>
        Pekerjaan <br />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Pekerjaan"
          single-line
          hide-details
          v-on:keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="mb-2" @click="openTambah">
          Tambah
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Pekerjaan</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        label="Nama Institusi"
                        required
                        v-model="currentData.nama_perusahaan"
                        :rules="currentData.namaRules"
                      ></v-text-field>
                    </v-col>
                    <v-text-field
                      label="Rumah Sakit"
                      outlined
                      dense
                      required
                      v-model="currentData.rumah_sakit"
                      readonly
                      @click="openRumahSakitModal"
                      :rules="currentData.rumahSakitRules"
                    ></v-text-field>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        label="Jabatan"
                        required
                        v-model="currentData.jabatan"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        label="NIP"
                        required
                        v-model="currentData.nik_nip"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        label="SIP"
                        required
                        v-model="currentData.sip"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        label="Alamat Kantor"
                        required
                        v-model="currentData.alamat_perusahaan"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        label="Propinsi"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedPropinsi"
                        :items="items_propinsi"
                        item-text="name"
                        @change="changePropinsi"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        label="Kabupaten/Kota"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedKabupaten"
                        :items="items_kabupaten"
                        item-text="name"
                        @click="getListKabupaten"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        label="Bidang Pekerjaan"
                        required
                        v-model="currentData.bidang_pekerjaan"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Mulai Bekerja"
                        v-model="currentData.mulai_kerja"
                        type="month"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-if="!currentData.sampai_saat_ini"
                        label="Akhir Bekerja"
                        v-model="currentData.akhir_kerja"
                        type="month"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="currentData.sampai_saat_ini"
                        label="Sampai saat ini"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="fotoKey"
                            :src="currentData.FotoUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImage"
                        accept="image/*"
                        v-model="currentData.dokumen_sip"
                        prepend-icon="mdi-image"
                        show-size
                        label="Dokumen SIP"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.mulai_kerja`]="{ item }">
          {{ getCurrentDate(item.mulai_kerja) }}
        </template>
        <template v-slot:[`item.akhir_kerja`]="{ item }">
          {{ getAkhirKerja(item.akhir_kerja) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <RumahSakitSelectComponent
      @pilihRumahSakit="pilihRumahSakit"
      ref="modalRumahSakit"
      v-model="dialogRumahSakit"
    />

    <!-- Modal -->
  </div>
</template>

<script>
import PekerjaanService from "../../services/PekerjaanService";
import NakesService from "../../services/NakesService";
import * as moment from "moment";
import CommonService from "../../services/CommonService";
import RumahSakitSelectComponent from "../../components/RumahSakitSelect.vue";

export default {
  props: {
    id: String,
  },
  components: {
    RumahSakitSelectComponent,
  },
  data: () => ({
    dialogRumahSakit: false,
    fotoKey: 0,
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Jabatan", value: "jabatan", sortable: false },
      { text: "Institusi", value: "nama_perusahaan", sortable: false },
      { text: "Rumah Sakit", value: "rumah_sakit_nama", sortable: false },
      { text: "Mulai Kerja", value: "mulai_kerja", sortable: false },
      { text: "Akhir Kerja", value: "akhir_kerja", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    currentData: {
      user_id: "",
      id: null,
      nama_perusahaan: "",
      jabatan: "",
      nik_nip: "",
      sip: "",
      alamat_perusahaan: "",
      bidang_pekerjaan: "",
      mulai_kerja: "",
      akhir_kerja: "",
      sampai_saat_ini: false,
      FotoUrl: "",
      dokumen_sip: "",
      rumah_sakit: "",
      rumah_sakit_id: "",
      namaRules: [(v) => !!v || "Judul harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      jamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      jamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
    },

    layananList: [],
    items_propinsi: [],
    items_kabupaten: [],
    currentLayanan: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListPropinsi();
    this.getDataFromApi();
  },
  methods: {
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.dokumen_sip = null;
      }
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("MMMM YYYY");
    },

    getAkhirKerja(tanggal) {
      if (tanggal == null) {
        return "Sampai saat ini";
      } else {
        return moment(tanggal).format("MMMM YYYY");
      }
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PekerjaanService.pekerjaanList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.user_id = "";
      this.currentData.id = null;
      this.currentData.nama_perusahaan = "";
      this.currentData.jabatan = "";
      this.currentData.nik_nip = "";
      this.currentData.sip = "";
      this.currentData.alamat_perusahaan = "";
      this.currentData.selectedPropinsi = null;
      this.currentData.selectedKabupaten = null;
      this.currentData.bidang_pekerjaan = "";
      this.currentData.mulai_kerja = "";
      this.currentData.akhir_kerja = "";
      this.currentData.sampai_saat_ini = false;
      this.currentData.dokumen_sip = null;
      this.currentData.FotoUrl = "";
      this.currentData.rumah_sakit = "";
      this.currentData.rumah_sakit_id = "";

      this.dialog = true;
      this.isEditable = 1;
    },

    openRumahSakitModal: async function () {
      this.$refs.modalRumahSakit.getDataFromApi();
      this.dialogRumahSakit = true;
    },

    pilihRumahSakit(item) {
      this.dialogRumahSakit = false;
      console.log(item);
      this.currentData.rumah_sakit = item.nama;
      this.currentData.rumah_sakit_id = item.id;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);

      this.currentData.id = this.editedItem.id;
      this.currentData.nama_perusahaan = this.editedItem.nama_perusahaan;
      this.currentData.jabatan = this.editedItem.jabatan;
      this.currentData.nik_nip = this.editedItem.nik_nip;
      this.currentData.sip = this.editedItem.sip;
      this.currentData.alamat_perusahaan = this.editedItem.alamat_perusahaan;
      this.currentData.bidang_pekerjaan = this.editedItem.bidang_pekerjaan;
      this.currentData.FotoUrl = this.editedItem.dokumen_sip;

      this.currentData.rumah_sakit_id = this.editedItem.rumah_sakit_id;
      this.currentData.rumah_sakit = this.editedItem.rumah_sakit_nama;

      this.currentData.mulai_kerja = moment(this.editedItem.mulai_kerja).format(
        "YYYY-MM"
      );

      if (this.editedItem.akhir_kerja) {
        this.currentData.akhir_kerja = moment(
          this.editedItem.akhir_kerja
        ).format("YYYY-MM");

        this.currentData.sampai_saat_ini = false;
      } else {
        this.currentData.sampai_saat_ini = true;
      }

      if (this.editedItem.propinsi_id) {
        this.items_propinsi.forEach((entry) => {
          if (entry.id == this.editedItem.propinsi_id) {
            this.currentData.selectedPropinsi = entry;
          }
        });

        if (this.editedItem.kabupaten_id) {
          this.getListKabupatenAll(
            this.editedItem.propinsi_id,
            this.editedItem.kabupaten_id
          );
        } else {
          this.currentData.selectedKabupaten = null;
        }
      }

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.user_id = this.id;
        PekerjaanService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah pekerjaan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Tambah pekerjaan gagal. Coba lagi");
          });
      } else {
        this.currentData.user_id = this.id;

        PekerjaanService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update pekerjaan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Update pekerjaan gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PekerjaanService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pekerjaan berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus pekerjaan gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async changePropinsi() {
      this.$nextTick(() => {
        this.currentData.selectedKabupaten = null;
      });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.currentData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupatenAll(propinsiId, kabupatenId) {
      NakesService.kabupatenList(propinsiId)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
          if (kabupatenId) {
            this.items_kabupaten.forEach((entry) => {
              if (entry.id == kabupatenId) {
                this.currentData.selectedKabupaten = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
