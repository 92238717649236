<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card>
      <v-card-title>
        Peserta <br />

        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="onDownloadAnggota()"
        >
          Download
        </v-btn>
        <!-- <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="onDownloadAnggota()"
        >
          Download
        </v-btn> -->

        <v-dialog v-model="dialog" persistent>
          <v-card>
            <v-card-title>
              <span class="text-h5">Peserta</span>
            </v-card-title>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="pl-4 pr-4"
            >
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-sheet color="white" elevation="2">
                    <v-responsive :aspect-ratio="16 / 9">
                      <v-img
                        :key="fotoDiriKey"
                        :src="currentData.FotoDiriUrl"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-sheet>
                  <v-file-input
                    @change="loadImageDiri"
                    accept="image/*"
                    v-model="currentData.file_diri"
                    prepend-icon="mdi-image"
                    show-size
                    label="Foto Diri"
                  />

                  <v-sheet color="white" elevation="2">
                    <v-responsive :aspect-ratio="16 / 9">
                      <v-img
                        :key="fotoKtpKey"
                        :src="currentData.FotoKtpUrl"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-sheet>
                  <v-file-input
                    @change="loadImageKtp"
                    accept="image/*"
                    v-model="currentData.file_ktp"
                    prepend-icon="mdi-image"
                    show-size
                    label="Foto KTP"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Nama & Gelar"
                        outlined
                        dense
                        v-model="currentData.nama"
                        required
                        :rules="currentData.namaRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        label="No Telepon/HP"
                        outlined
                        dense
                        v-model="currentData.whatsapp"
                        required
                        :rules="currentData.whatsappRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        label="Email"
                        outlined
                        dense
                        v-model="currentData.email"
                        required
                        :rules="currentData.emailRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Cabang"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedCabang"
                        :items="items_propinsi"
                        item-text="name"
                        :rules="currentData.cabangRules"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="No NPA"
                        outlined
                        dense
                        v-model="currentData.no_npa"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Asal Institusi"
                        outlined
                        dense
                        v-model="currentData.asal_institusi"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Tempat Lahir"
                        outlined
                        dense
                        v-model="currentData.tempat_lahir"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        outlined
                        label="Tanggal Lahir"
                        v-model="currentData.tanggal_lahir"
                        type="date"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Propinsi"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedPropinsi"
                        :items="items_propinsi"
                        item-text="name"
                        @change="changePropinsi"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Kabupaten/Kota"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedKabupaten"
                        :items="items_kabupaten"
                        item-text="name"
                        @click="getListKabupaten"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Kecamatan"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedKecamatan"
                        :items="items_kecamatan"
                        item-text="name"
                        @click="getListKecamatan"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Kelurahan"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedKelurahan"
                        :items="items_kelurahan"
                        item-text="name"
                        @click="getListKelurahan"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Alamat"
                        outlined
                        dense
                        v-model="currentData.alamat"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogSend" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan mengirim informasi broadcast ke peserta
              ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogSend = false"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="sendItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogBatal" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan membatalkan kepesertaan peserta ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogBatal = false"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="batalKepesertaanConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
        <template v-slot:[`item.description`]="{ item }">
          {{ truncate(item.description) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium class="mr-3" @click="sendItem(item)">
            mdi-send
          </v-icon>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-icon medium v-bind="attrs" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="getDownloadSertifikat(item)">
                <v-list-item-title>Download Sertifikat</v-list-item-title>
              </v-list-item>
              <v-list-item @click="batalKepesertaan(item)">
                <v-list-item-title>Batalkan Kepesertaan</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import PesertaEventService from "../../services/PesertaEventService";
import * as moment from "moment";
import CommonService from "../../services/CommonService";
import NakesService from "../../services/NakesService";
import SertifikatService from "../../services/SertifikatService";

export default {
  props: {
    id: String,
  },
  components: {},
  data: () => ({
    childKey: 0,
    isEditable: 0,
    valid: true,
    tab: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Whatsapp", value: "whatsapp", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    dialogSend: false,
    dialogBatal: false,

    currentFaskes: {
      nama: "",
    },

    items_propinsi: [],
    items_kabupaten: [],
    items_kecamatan: [],
    items_kelurahan: [],

    currentData: {
      pib_id: "",
      id: null,
      FotoDiriUrl: "",
      FotoKtpUrl: "",
      nama: "",
      email: "",
      whatsapp: "",
      no_npa: "",
      asal_institusi: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      selectedCabang: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      selectedKecamatan: "",
      selectedKelurahan: "",
      file_diri: null,
      file_ktp: null,
      alamat: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      whatsappRules: [(v) => !!v || "No Whatsapp harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      tempatLahirRules: [(v) => !!v || "Tempat Lahir harus diisi"],
      tanggalLahirRules: [(v) => !!v || "Tanggal Lahir harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      propinsiRules: [(v) => !!v || "Propinsi harus diisi"],
      kabupatenRules: [(v) => !!v || "Kota/Kabupaten harus diisi"],
      kecamatanRules: [(v) => !!v || "Kecamatan harus diisi"],
      kelurahanRules: [(v) => !!v || "Kelurahan harus diisi"],
      alamatRules: [(v) => !!v || "Alamat harus diisi"],
      fotoDiriRules: [(v) => !!v || "Foto Diri harus diisi"],
      fotoKtpRules: [(v) => !!v || "Foto Ktp harus diisi"],
    },
    fotoDiriKey: 0,
    fotoKtpKey: 0,

    layananList: [],
    items_kategori: [],
    currentLayanan: null,
    isProgressBarShow: false,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  mounted() {
    this.getListPropinsi();
    this.getDataFromApi();
  },
  methods: {
    loadImageDiri: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoDiriUrl = e.target.result;
          this.fotoDiriKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoDiriUrl = "";
        this.currentData.file_diri = null;
      }
    },

    loadImageKtp: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoKtpUrl = e.target.result;
          this.fotoKtpKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoKtpUrl = "";
        this.currentData.file_ktp = null;
      }
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.currentData.selectedKabupaten = null;
      this.currentData.selectedKecamatan = null;
      this.currentData.selectedKelurahan = null;
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.currentData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupatenAll(propinsiId, kabupatenId) {
      NakesService.kabupatenList(propinsiId)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
          if (kabupatenId) {
            this.items_kabupaten.forEach((entry) => {
              if (entry.id == kabupatenId) {
                this.currentData.selectedKabupaten = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatanAll(kabupatenId, kecamatanId) {
      NakesService.kecamatanList(kabupatenId)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;

          this.items_kecamatan.forEach((entry) => {
            if (entry.id == kecamatanId) {
              this.currentData.selectedKecamatan = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahanAll(kecamatanId, kelurahanId) {
      NakesService.kelurahanList(kecamatanId)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;

          this.items_kelurahan.forEach((entry) => {
            if (entry.id == kelurahanId) {
              this.currentData.selectedKelurahan = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatan() {
      NakesService.kecamatanList(this.currentData.selectedKabupaten.id)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahan() {
      NakesService.kelurahanList(this.currentData.selectedKecamatan.id)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    truncate: function (input) {
      if (input && input.length > 10) {
        return input.substring(0, 5) + "...";
      }
      return input;
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },
    loadBuktiImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoBuktiUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoBuktiUrl = "";
        this.currentData.file_bukti_foto = null;
      }
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PesertaEventService.pesertaEventList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.currentData.FotoUrl = "";
      this.currentData.file_foto = null;
      this.currentData.nama = "";
      this.currentData.title = "";
      this.currentData.email = "";
      this.currentData.whatsapp = "";
      this.dialog = true;
      this.isEditable = 1;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      // this.currentData = this.editedItem;
      this.currentData.id = this.editedItem.id;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.whatsapp = this.editedItem.whatsapp;
      this.currentData.email = this.editedItem.email;
      this.currentData.tempat_lahir = this.editedItem.tempat_lahir;
      this.currentData.alamat = this.editedItem.alamat;
      this.currentData.FotoDiriUrl = this.editedItem.foto_diri;
      this.currentData.FotoKtpUrl = this.editedItem.foto_ktp;
      this.currentData.no_npa = this.editedItem.no_npa;
      this.currentData.asal_institusi = this.editedItem.asal_institusi;

      if (this.editedItem.cabang_id) {
        this.items_propinsi.forEach((entry) => {
          if (entry.id == this.editedItem.cabang_id) {
            this.currentData.selectedCabang = entry;
          }
        });
      }

      if (this.editedItem.tanggal_lahir) {
        this.currentData.tanggal_lahir = moment(
          this.editedItem.tanggal_lahir
        ).format("YYYY-MM-DD");
      }

      if (this.editedItem.propinsi_id) {
        this.items_propinsi.forEach((entry) => {
          if (entry.id == this.editedItem.propinsi_id) {
            this.currentData.selectedPropinsi = entry;
          }
        });

        this.getListKabupatenAll(
          this.editedItem.propinsi_id,
          this.editedItem.kabupaten_id
        );
      }

      if (this.editedItem.kecamatan_id) {
        this.getListKecamatanAll(
          this.editedItem.kabupaten_id,
          this.editedItem.kecamatan_id
        );
      }

      if (this.editedItem.kelurahan_id) {
        this.getListKelurahanAll(
          this.editedItem.kecamatan_id,
          this.editedItem.kelurahan_id
        );
      }

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.pib_id = this.id;
        PesertaEventService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Peserta berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Tambah Peserta gagal. Coba lagi");
          });
      } else {
        this.currentData.pib_id = this.id;

        PesertaEventService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update Peserta berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Update Peserta gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PesertaEventService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus contact person berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus contact person gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onDownloadAnggota() {
      this.isProgressBarShow = true;

      PesertaEventService.downloadPesertaReport(this.search, this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    sendItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogSend = true;
    },

    sendItemConfirm() {
      this.dialogSend = false;
      this.isProgressBarShow = true;

      PesertaEventService.send(this.editedItem.user_event_id)
        .then((res) => {
          this.isProgressBarShow = false;
          console.log(res);
          CommonService.showSuccessToast("Kirim Informasi Broadcast berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          console.log(err);
          CommonService.showErrorToast(
            "Kirim Informasi Broadcast gagal. Coba lagi"
          );
        });
    },

    getDownloadSertifikat(item) {
      this.isProgressBarShow = true;
      SertifikatService.downloadSertifikatEventPeserta(item.user_event_id)
        .then((res) => {
          this.isProgressBarShow = false;
          console.log(res.data.file);
          window.open(res.data.file, "_blank");
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          console.log(err);
          CommonService.showErrorToast("Download sertifikat gagal. Coba lagi");
        });
    },

    batalKepesertaan(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogBatal = true;
    },

    batalKepesertaanConfirm() {
      this.dialogBatal = false;
      this.isProgressBarShow = true;
      PesertaEventService.batalkanKepesertaan(this.editedItem.user_event_id)
        .then((res) => {
          console.log(res);
          this.isProgressBarShow = false;

          CommonService.showSuccessToast("Peserta berhasil dibatalkan");
          this.getDataFromApi();
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          console.log(err);
          CommonService.showErrorToast("Membatalkan peserta gagal. Coba lagi");
        });
    },
  },
};
</script>
