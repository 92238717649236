import { render, staticRenderFns } from "./FasyankesPengurus.vue?vue&type=template&id=2e69fe27"
import script from "./FasyankesPengurus.vue?vue&type=script&lang=js"
export * from "./FasyankesPengurus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports