<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Kecukupan SKP Kemkes
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Gelar</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Gelar"
                          required
                          v-model="currentData.gelar"
                          :rules="currentData.gelarRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Deskripsi"
                          required
                          v-model="currentData.deskripsi"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Total SKP"
              dense
              outlined
              clearable
              v-model="selectedTotalSkp"
              :items="items_total_skp"
              item-text="nama"
              @change="changeTotalSkp"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.nama_ktp`]="{ item }">
            <div>{{ item.nama_ktp }}</div>
            <div>NIK: {{ item.no_ktp }}</div>
          </template>
          <template v-slot:[`item.tanggal_lahir`]="{ item }">
            {{ getCurrentDate(item.tanggal_lahir) }}
          </template>
          <template v-slot:[`item.masa_berlaku`]="{ item }">
            {{ getCurrentDate(item.masa_berlaku) }}
          </template>
          <template v-slot:[`item.is_submit_kemkes`]="{ item }">
            <div v-if="item.is_submit_kemkes == 1">Terkirim</div>
          </template>
          <template v-slot:[`item.total_skp`]="{ item }">
            <div v-if="item.total_skp < 250">
              <span style="color: red"> Perdatin : {{ item.total_skp }}</span>
            </div>
            <div v-else>
              <span style="color: green"> Perdatin : {{ item.total_skp }}</span>
            </div>

            <div v-if="item.total_skp_kemkes < 250">
              <span style="color: red">
                Kemkes : {{ item.total_skp_kemkes }}</span
              >
            </div>
            <div v-else>
              <span style="color: green">
                Kemkes : {{ item.total_skp_kemkes }}</span
              >
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KecukupanSkpKemkesService from "../services/KecukupanSkpKemkesService"
import CommonService from "../services/CommonService"
import * as moment from "moment"

export default {
  data: () => ({
    valid: true,
    breadcrumbs: [
      {
        text: "Data SKP",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedTotalSkp: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama KTP", value: "nama_ktp", sortable: false, width: "200px" },
      {
        text: "Total SKP",
        value: "total_skp",
        sortable: false,
        width: "120px",
      },
      {
        text: "Status Kemkes",
        value: "is_submit_kemkes",
        sortable: false,
      },
      { text: "Tanggal Lahir", value: "tanggal_lahir", sortable: false },
      {
        text: "Jenis Kelamin",
        value: "jenis_kelamin_value",
        sortable: false,
        width: "90px",
      },
      { text: "No STR", value: "str", sortable: false },
      {
        text: "Tanggal Berakhir STR",
        value: "masa_berlaku",
        sortable: false,
      },
      {
        text: "Praktik Profesi",
        value: "praktik_profesi",
        sortable: false,
      },
      {
        text: "Pendidikan Berkelanjutan",
        value: "pendidikan_berkelanjutan",
        sortable: false,
      },
      {
        text: "Pengabdian Masyarakat",
        value: "pengabdian_masyarakat",
        sortable: false,
      },
      {
        text: "Pengembangan Profesi",
        value: "pengembangan_profesi",
        sortable: false,
      },
      {
        text: "Penelitian dan Publikasi Ilmiah",
        value: "penelitian_dan_publikasi_llmiah",
        sortable: false,
      },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      gelar: "",
      urutan: "",
      nama_rek: "",
      no_rek: "",
      gelarRules: [(v) => !!v || "Nama harus diisi"],
      alamatRules: [(v) => !!v || "Alamat harus diisi"],
      contactPersonRules: [(v) => !!v || "Kontak Person harus diisi"],
      hpRules: [(v) => !!v || "Handphone harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
    },

    items_total_skp: [
      {
        id: 1,
        nama: "Kurang Dari 250 SKP",
      },
      { id: 2, nama: "Lebih Dari atau Sama Dengan 250 SKP" },
    ],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    async changeTotalSkp() {
      this.getDataFromApi()
    },
    getCurrentDate(tanggal) {
      if (tanggal) return moment(tanggal).format("DD/MM/YYYY")
      else return ""
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options

      var totalSkp = ""

      if (this.selectedTotalSkp) {
        totalSkp = this.selectedTotalSkp.id
      }

      this.loading = true
      KecukupanSkpKemkesService.nakesList(
        page,
        itemsPerPage,
        this.search,
        totalSkp
      )
        .then((res) => {
          this.list_data = res.data.list_data
          this.totalData = res.data.total
          this.loading = false
          this.isFirst = true
        })
        .catch((err) => {
          this.loading = false
          this.isFirst = true
          console.log(err)
        })
    },

    doAdd: async function () {
      this.dialog = true
      this.currentData.gelar = ""
      this.currentData.deskripsi = ""
      this.currentData.id = null
      console.log("do add")
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.currentData.id == null) {
        KecukupanSkpKemkesService.create(this.currentData)
          .then((res) => {
            console.log(res)
            this.dialog = false

            CommonService.showSuccessToast("Tambah Gelar berhasil")

            this.getDataFromApi()
          })
          .catch((err) => {
            console.log(err)
            CommonService.showErrorToast("Tambah Gelar gagal. Coba lagi")
          })
      } else {
        KecukupanSkpKemkesService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false

            console.log(res)
            CommonService.showSuccessToast("Update Gelar berhasil")

            this.getDataFromApi()
          })
          .catch((err) => {
            console.log(err)
            CommonService.showErrorToast("Update gelar gagal. Coba lagi")
          })
      }
    },

    editItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)

      // this.dialog = true;
      this.currentData.gelar = this.editedItem.gelar
      this.currentData.deskripsi = this.editedItem.deskripsi
      this.currentData.id = this.editedItem.id
      this.dialog = true
    },

    deleteItem(item) {
      console.log(item)
      this.editedIndex = this.list_data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.closeDelete()

      KecukupanSkpKemkesService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res)

          CommonService.showSuccessToast("Hapus Sponsor berhasil")

          this.getDataFromApi()
        })
        .catch((err) => {
          console.log(err)
          CommonService.showErrorToast("Hapus Sponsor gagal. Coba lagi")
        })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
