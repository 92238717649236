import http from "../../http-common";

/* eslint-disable */
class InasiaHotelService {
  hotelList(page, itemPerPage, keyword, id) {
    return http.get(
      "/inasia/hotel/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.alamat) bodyFormData.append("alamat", data.alamat);
    if (data.rating) bodyFormData.append("rating", data.rating);
    if (data.jarak) bodyFormData.append("jarak", data.jarak);
    if (data.waktu) bodyFormData.append("waktu", data.waktu);
    if (data.link_google_map)
      bodyFormData.append("link_google_map", data.link_google_map);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.post("/inasia/hotel", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.alamat) bodyFormData.append("alamat", data.alamat);
    if (data.rating) bodyFormData.append("rating", data.rating);
    if (data.jarak) bodyFormData.append("jarak", data.jarak);
    if (data.waktu) bodyFormData.append("waktu", data.waktu);
    if (data.link_google_map)
      bodyFormData.append("link_google_map", data.link_google_map);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/inasia/hotel/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/inasia/hotel/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InasiaHotelService();
