import http from "../http-common";
/* eslint-disable */
class GaransiSponsorInvoiceItemPibService {
  invoiceItemList(page, itemPerPage, keyword, invoiceId) {
    return http.get(
      "/pib/garansi-sponsor-invoice-item/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&invoice_id=" +
        invoiceId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.invoice_id) bodyFormData.append("invoice_id", data.invoice_id);

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("category", data.selectedKategori.id);
    }

    if (data.selectedItem && data.selectedItem != undefined) {
      bodyFormData.append("item_id", data.selectedItem.id);
    }

    if (data.tanggal_check_in) {
      bodyFormData.append("tanggal_check_in", data.tanggal_check_in);
    }

    if (data.tanggal_check_out) {
      bodyFormData.append("tanggal_check_out", data.tanggal_check_out);
    }

    if (data.jumlah_pax) {
      bodyFormData.append("jumlah_pax", data.jumlah_pax);
    }

    if (data.tanggal_hospitality) {
      bodyFormData.append("tanggal_hospitality", data.tanggal_hospitality);
    }

    bodyFormData.append("pib_id", 1);

    return http.post("/pib/garansi-sponsor-invoice-item", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/pib/garansi-sponsor-invoice-item/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new GaransiSponsorInvoiceItemPibService();
