<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Informasi Fasilitator </v-card-title>
        <v-fab-transition>
          <v-btn
            v-if="isEditable == 0"
            @click="changeEditable"
            color="pink"
            dark
            absolute
            top
            right
            fab
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4">
          <v-row dense>
            <v-col cols="12" md="6" class="pr-4">
              <v-row>
                <v-col cols="12">
                  <v-sheet color="white" elevation="2">
                    <v-responsive :aspect-ratio="16 / 9">
                      <v-img
                        :key="fotoKey"
                        :src="userData.FotoUrl"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-sheet>
                  <v-file-input
                    @change="loadImage"
                    accept="image/*"
                    v-model="userData.file_foto"
                    prepend-icon="mdi-image"
                    show-size
                    label="Foto"
                    :disabled="isEditable == 0"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="pr-4">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Nama"
                    outlined
                    dense
                    v-model="userData.nama"
                    required
                    :rules="userData.namaRules"
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-combobox
                    label="Profesi"
                    :clearable="isEditable == 1"
                    dense
                    outlined
                    v-model="userData.selectedProfesi"
                    :items="items_profesi"
                    item-text="value"
                    item-value="code"
                    :rules="userData.profesiRules"
                    :readonly="isEditable == 0"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="No KTP"
                    outlined
                    dense
                    v-model="userData.no_ktp"
                    :rules="userData.ktpRules"
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="No Telepon"
                    outlined
                    dense
                    v-model="userData.phone"
                    :rules="userData.phoneRules"
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img
                    :key="fotoKeyIjazah"
                    :src="userData.FotoUrlIjazah"
                    contain
                  ></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                @change="loadImageIjazah"
                accept="image/*"
                v-model="userData.file_foto_ijazah"
                prepend-icon="mdi-image"
                show-size
                label="Foto Ijazah"
                :disabled="isEditable == 0"
              />
            </v-col>
          </v-row>
          <v-row class="pb-4 mb-2 pr-4">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                v-if="isEditable == 1"
                @click="doBatal"
                style="margin-right: 10px"
                >Batal</v-btn
              >
              <v-btn
                v-if="isEditable == 1"
                dark
                color="primary"
                type="button"
                @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import DataFasilitatorService from "../../services/DataFasilitatorService"
import MasterService from "../../services/MasterService"
import CommonService from "../../services/CommonService"

export default {
  name: "FasilitatorEdit",
  props: {
    id: String,
  },
  data: () => ({
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Fasilitator",
        disabled: false,
        exact: true,
        to: { name: "DataFasilitator" },
      },
      { text: "Edit Data Fasilitator (Non Anggota)", disabled: true },
    ],
    valid: true,
    userData: {
      FotoUrl: "",
      FotoUrlIjazah: "",
      file_foto: null,
      file_foto_ijazah: null,
      nama: "",
      email: "",
      phone: "",
      profesi_id: "",
      no_ktp: "",
      selectedProfesi: "",
      profesiRules: [(v) => !!v || "Profesi harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      ktpRules: [(v) => !!v || "No KTP harus diisi"],
      phoneRules: [(v) => !!v || "No Telepon harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
    },
    items_profesi: [],
    fotoKey: 0,
    fotoKeyIjazah: 0,
  }),
  mounted: async function () {
    let loader = this.$loading.show()
    Promise.all([this.getListProfesi(), this.getDetailFasilitatorNon()]).then(
      () => {
        loader.hide()
      }
    )
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1
    },
    loadImage: function (data) {
      if (data) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result
          this.fotoKey++
        }
        reader.readAsDataURL(data)
      } else {
        this.userData.FotoUrl = ""
        this.userData.file_foto = null
      }
    },

    loadImageIjazah: function (data) {
      if (data) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.userData.FotoUrlIjazah = e.target.result
          this.fotoKeyIjazah++
        }
        reader.readAsDataURL(data)
      } else {
        this.userData.FotoUrlIjazah = ""
        this.userData.file_foto_ijazah = null
      }
    },

    async getListProfesi() {
      await MasterService.profesiFasilitatorList()
        .then((res) => {
          this.items_profesi = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getDetailFasilitatorNon() {
      await DataFasilitatorService.detailFasilitatorNon(this.id)
        .then((res) => {
          console.log(res)
          this.userData.nama = res.data.data.nama
          this.userData.no_ktp = res.data.data.no_ktp
          this.userData.phone = res.data.data.phone
          this.userData.email = res.data.data.email
          this.userData.FotoUrl = res.data.data.photo
          this.userData.FotoUrlIjazah = res.data.data.foto_ijazah

          this.$nextTick(() => {
            if (res.data.data.profesi_id) {
              this.items_profesi.forEach((entry) => {
                if (entry.code == res.data.data.profesi_id) {
                  this.userData.selectedProfesi = entry
                }
              })
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    doBatal: function () {
      this.$router.go()
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0)
        return
      }

      let loader = this.$loading.show()

      DataFasilitatorService.updateNonAnggota(
        this.id,
        this.userData,
        this.userData.file_foto,
        this.userData.file_foto_ijazah
      )
        .then((res) => {
          loader.hide()
          console.log(res)
          CommonService.showSuccessToast("Edit Data Fasilitator berhasil")

          this.isEditable = 0
        })
        .catch((err) => {
          loader.hide()
          console.log(err)
          if (err.response.data?.code == 1) {
            CommonService.showErrorToast(err.response.data?.message)
          } else {
            CommonService.showErrorToast(
              "Edit Data Fasilitator gagal. Coba lagi"
            )
          }
        })
    },
  },
}
</script>
