import { render, staticRenderFns } from "./KlinikAdd.vue?vue&type=template&id=3acc5e86&scoped=true"
import script from "./KlinikAdd.vue?vue&type=script&lang=js"
export * from "./KlinikAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3acc5e86",
  null
  
)

export default component.exports