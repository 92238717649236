<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Data Penyelenggara </v-card-title>
        <v-fab-transition>
          <v-btn
            v-if="isEditable == 0"
            @click="changeEditable"
            color="pink"
            dark
            absolute
            top
            right
            fab
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-form ref="form" v-model="valid" lazy-validation class="pt-4 px-4">
          <v-row>
            <v-col cols="12" md="6">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    label="Nama Penyelenggara"
                    outlined
                    dense
                    v-model="userData.nama"
                    :rules="userData.namaRules"
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                    v-model="userData.email"
                    :rules="userData.emailRules"
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Alamat"
                    outlined
                    dense
                    v-model="userData.alamat"
                    :rules="userData.alamatRules"
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    label="Propinsi"
                    :clearable="isEditable != 0"
                    dense
                    outlined
                    v-model="userData.selectedPropinsi"
                    :items="items_propinsi"
                    item-text="name"
                    @change="changePropinsi"
                    :rules="userData.propinsiRules"
                    :readonly="isEditable == 0"
                  ></v-combobox>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    label="Kabupaten/Kota"
                    :clearable="isEditable != 0"
                    dense
                    outlined
                    v-model="userData.selectedKabupaten"
                    :items="items_kabupaten"
                    item-text="name"
                    @click="getListKabupaten"
                    :rules="userData.kabupatenRules"
                    :readonly="isEditable == 0"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    label="Nama Penanggung Jawab"
                    outlined
                    dense
                    v-model="userData.nama_penanggung_jawab"
                    :rules="userData.namaPenanggungJawabRules"
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Whatsapp Penanggung Jawab"
                    outlined
                    dense
                    v-model="userData.whatsapp_penanggung_jawab"
                    :rules="userData.whatsappPenanggungJawabRules"
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Nama PIC"
                    outlined
                    dense
                    v-model="userData.nama_pic"
                    :rules="userData.namaPicRules"
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Whatsapp PIC"
                    outlined
                    dense
                    v-model="userData.whatsapp_pic"
                    :rules="userData.whatsappPicRules"
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="pb-4">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                v-if="isEditable == 1"
                @click="doBatal"
                style="margin-right: 10px"
                >Batal</v-btn
              >
              <v-btn
                v-if="isEditable == 1"
                dark
                color="primary"
                type="button"
                @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PenyelenggaraSkpKemkesService from "../../../services/skp_kemkes/PenyelenggaraSkpKemkesService"
import CommonService from "../../../services/CommonService"
import NakesService from "../../../services/NakesService"

export default {
  name: "PenyelenggaraSkpKegiatanEdit",
  props: {
    id: String,
  },
  data: () => ({
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Penyelenggara",
        disabled: false,
        exact: true,
        to: { name: "PenyelenggaraSkpKegiatan" },
      },
      { text: "Edit Penyelenggara", disabled: true },
    ],
    valid: true,
    userData: {
      nama: "",
      email: "",
      alamat: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      nama_penanggung_jawab: "",
      whatsapp_penanggung_jawab: "",
      nama_pic: "",
      whatsapp_pic: "",
      namaRules: [(v) => !!v || "Nama Penyelenggara harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      alamatRules: [(v) => !!v || "Alamat harus diisi"],
      propinsiRules: [(v) => !!v || "Propinsi harus diisi"],
      kabupatenRules: [(v) => !!v || "Kabupaten harus diisi"],
      namaPenanggungJawabRules: [
        (v) => !!v || "Nama Penanggung Jawab harus diisi",
      ],
      whatsappPenanggungJawabRules: [
        (v) => !!v || "Whatsapp Penanggung Jawab harus diisi",
      ],
      namaPicRules: [(v) => !!v || "Nama PIC harus diisi"],
      whatsappPicRules: [(v) => !!v || "Whatsapp PIC harus diisi"],
    },
    items_propinsi: [],
    items_kabupaten: [],
    loading: true,
  }),
  mounted: async function () {
    this.getListPropinsi()
    this.getPenyelenggara()
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1
    },
    getPenyelenggara() {
      PenyelenggaraSkpKemkesService.getPenyelenggara(this.id)
        .then((res) => {
          this.userData.nama = res.data.data.nama
          this.userData.email = res.data.data.email
          this.userData.alamat = res.data.data.alamat
          this.userData.nama_penanggung_jawab =
            res.data.data.nama_penanggung_jawab
          this.userData.whatsapp_penanggung_jawab =
            res.data.data.whatsapp_penanggung_jawab
          this.userData.nama_pic = res.data.data.nama_pic
          this.userData.whatsapp_pic = res.data.data.whatsapp_pic

          if (res.data.data.propinsi_id) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == res.data.data.propinsi_id) {
                this.userData.selectedPropinsi = entry
              }
            })

            this.getListKabupatenAll(
              res.data.data.propinsi_id,
              res.data.data.kabupaten_id
            )
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async changePropinsi() {
      this.userData.selectedKabupaten = null
    },
    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.userData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getListKabupatenAll(propinsiId, kabupatenId) {
      NakesService.kabupatenList(propinsiId)
        .then((res) => {
          this.items_kabupaten = res.data.list_data
          if (kabupatenId) {
            this.items_kabupaten.forEach((entry) => {
              if (entry.id == kabupatenId) {
                this.userData.selectedKabupaten = entry
              }
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    doBatal: function () {
      this.$router.go()
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0)
        return
      }

      PenyelenggaraSkpKemkesService.update(this.id, this.userData)
        .then((res) => {
          console.log(res)
          CommonService.showSuccessToast("Update penyelenggara berhasil")

          this.isEditable = 0
        })
        .catch((err) => {
          console.log(err)
          CommonService.showSuccessToast(
            "Update penyelenggara gagal. Coba lagi"
          )
        })
    },
  },
}
</script>

<style scoped></style>
