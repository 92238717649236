import { render, staticRenderFns } from "./Bantuan.vue?vue&type=template&id=661ee588&scoped=true"
import script from "./Bantuan.vue?vue&type=script&lang=js"
export * from "./Bantuan.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661ee588",
  null
  
)

export default component.exports