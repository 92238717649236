import http from "../../http-common";
/* eslint-disable */
class InasiaPaketWisataFotoLainnyaService {
  fotoList(page, itemPerPage, beritaId) {
    return http.get(
      "/inasia-paket-wisata-foto/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&paket_wisata_id=" +
        beritaId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("paket_wisata_id", data.paket_wisata_id);

    if (data.foto_url != null) bodyFormData.append("photo", data.foto_url);

    return http.post("/inasia-paket-wisata-foto", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("paket_wisata_id", data.paket_wisata_id);

    if (data.foto_url != null) bodyFormData.append("photo", data.foto_url);

    return http.put(`/inasia-paket-wisata-foto/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/inasia-paket-wisata-foto/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new InasiaPaketWisataFotoLainnyaService();
