<template>
  <div>
    <v-progress-linear
      v-if="isProgressBarShow"
      indeterminate
      color="green"
    ></v-progress-linear>
    <v-card>
      <v-card-title>
        Peserta <br />

        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-4"
          @click="onDownloadAnggota()"
        >
          Download
        </v-btn>

        <v-dialog v-model="dialog" persistent>
          <v-card>
            <v-card-title>
              <span class="text-h5">Peserta</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6" class="pr-4">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="fotoKey"
                            :src="currentData.FotoUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImage"
                        accept="image/*"
                        v-model="currentData.file_foto"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto"
                        :rules="currentData.fotoRules"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-combobox
                            label="Status Profesi"
                            clearable
                            dense
                            outlined
                            v-model="currentData.selectedKategori"
                            :items="items_kategori"
                            item-text="value"
                            @change="changeStatusProfesi"
                          ></v-combobox>
                        </v-col>
                      </v-row>

                      <v-row
                        v-if="
                          currentData.selectedKategori &&
                          currentData.selectedKategori.code == 1
                        "
                        dense
                      >
                        <v-col cols="12" sm="12" md="12">
                          <v-combobox
                            label="Cabang"
                            clearable
                            dense
                            outlined
                            v-model="currentData.selectedPropinsi"
                            :items="items_propinsi"
                            item-text="name"
                          ></v-combobox>
                        </v-col>
                      </v-row>

                      <v-row
                        v-if="
                          currentData.selectedKategori &&
                          currentData.selectedKategori.code == 1
                        "
                        dense
                      >
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="No NPA"
                            outlined
                            dense
                            v-model="currentData.no_npa"
                            required
                            :rules="currentData.noNpaRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-combobox
                            label="Domisili Propinsi"
                            clearable
                            dense
                            outlined
                            v-model="currentData.selectedDomisiliPropinsi"
                            :items="items_propinsi"
                            item-text="name"
                          ></v-combobox>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Nama & Gelar"
                            outlined
                            dense
                            v-model="currentData.nama"
                            required
                            :rules="currentData.namaRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="No Telepon/HP"
                            outlined
                            dense
                            v-model="currentData.whatsapp"
                            required
                            :rules="currentData.whatsappRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            label="Email"
                            outlined
                            dense
                            v-model="currentData.email"
                            required
                            :rules="currentData.emailRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Institusi"
                            outlined
                            dense
                            v-model="currentData.institusi"
                            required
                            :rules="currentData.institusiRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="text-h5 mx-2">Data Sponsor</v-row>
                      <v-row dense class="mt-6">
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Nama Sponsor"
                            outlined
                            dense
                            v-model="currentData.nama_sponsor"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense class="mt-4">
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Alamat Sponsor"
                            outlined
                            dense
                            v-model="currentData.alamat_sponsor"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense class="mt-4">
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Nama Kontak Person Sponsor"
                            outlined
                            dense
                            v-model="currentData.nama_kontak_person_sponsor"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense class="mt-4">
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Handphone Kontak Person Sponsor"
                            outlined
                            dense
                            v-model="currentData.hp_kontak_person_sponsor"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense class="mt-4">
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Email Sponsor"
                            outlined
                            dense
                            v-model="currentData.email_sponsor"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense class="mt-4">
                        <v-col cols="12" sm="12" md="12">
                          <v-sheet color="white" elevation="2">
                            <v-responsive :aspect-ratio="16 / 9">
                              <v-img
                                :key="fotoKey"
                                :src="currentData.FotoBuktiUrl"
                                contain
                              ></v-img>
                            </v-responsive>
                          </v-sheet>
                          <v-file-input
                            @change="loadBuktiImage"
                            accept="image/*"
                            v-model="currentData.file_bukti_foto"
                            prepend-icon="mdi-image"
                            show-size
                            label="Bukti Transfer / Surat Garansi Sponsor"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogReset" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan me-reset password Peserta ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeReset"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="resetItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Kepesertaan"
            clearable
            dense
            outlined
            v-model="selectedKepesertaan"
            :items="items_kepesertaan"
            item-text="name"
            @change="getDataFromApi"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Kategori"
            clearable
            dense
            outlined
            v-model="selectedKategori"
            :items="items_kategori_event"
            item-text="name"
            @change="getDataByKategori"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Item"
            clearable
            dense
            outlined
            v-model="selectedItem"
            :items="items_item"
            item-text="nama"
            @change="getDataFromApi"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Status Profesi"
            clearable
            dense
            outlined
            v-model="selectedStatusPeserta"
            :items="items_kategori"
            item-text="value"
            @change="getDataFromApi"
          ></v-combobox>
        </v-col>
        <v-col
          v-if="selectedStatusPeserta && selectedStatusPeserta.code == 1"
          cols="12"
          sm="4"
          md="4"
        >
          <v-combobox
            label="Cabang"
            clearable
            dense
            outlined
            v-model="selectedPropinsi"
            :items="items_propinsi"
            item-text="name"
            @change="getDataFromApi"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        :single-select="true"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
        <template v-slot:[`item.description`]="{ item }">
          {{ truncate(item.description) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium class="mr-3" @click="printCard(item)">
              mdi-printer
            </v-icon>
            <v-icon medium class="mr-3" @click="printCardFull(item)">
              mdi-printer
            </v-icon>
            <v-icon medium class="" @click="getDownloadSertifikat(item)">
              mdi-download
            </v-icon>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-icon medium v-bind="attrs" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="resetPassword(item)">
                  <v-list-item-title>Reset Password</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-card v-if="selected != undefined && selected.length > 0" class="mt-4">
      <v-tabs v-model="tab">
        <v-tab>Invoice</v-tab>
        <v-tab>Sertifikat</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <InvoicePibPesertaComponent
            :id="selected[0].id"
            ref="mychild"
            :key="childKey"
            :invoice_id="id"
          />
        </v-tab-item>
        <v-tab-item>
          <SertifikatPesertaComponent
            :id="selected[0].id"
            ref="mySertifikat"
            :key="childKey"
            :invoice_id="id"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import PesertaPibService from "../../services/PesertaPibService";
import * as moment from "moment";
import CommonService from "../../services/CommonService";
import MasterService from "../../services/MasterService";
import InvoicePibService from "../../services/InvoicePibService";
import NakesService from "../../services/NakesService";
import InvoicePibPesertaComponent from "./InvoicePibPeserta.vue";
import SertifikatPesertaComponent from "./SertifikatPeserta.vue";
import print from "print-js";
import SertifikatService from "../../services/SertifikatService";

export default {
  props: {
    id: String,
  },
  components: { InvoicePibPesertaComponent, SertifikatPesertaComponent },
  data: () => ({
    childKey: 0,
    isEditable: 0,
    valid: true,
    dialogReset: false,
    tab: null,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Whatsapp", value: "whatsapp", sortable: false },
      { text: "Profesi", value: "profesi_pib", sortable: false },
      { text: "Cabang", value: "cabang_nama", sortable: false },
      { text: "Domisili Propinsi", value: "propinsi_nama", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentFaskes: {
      nama: "",
    },

    currentData: {
      pib_id: "",
      id: null,
      FotoUrl: "",
      FotoBuktiUrl: "",
      file_foto: null,
      nama: "",
      title: "",
      email: "",
      whatsapp: "",
      selectedKategori: "",
      selectedPropinsi: "",
      selectedDomisiliPropinsi: "",
      nama_sponsor: "",
      alamat_sponsor: "",
      nama_kontak_person_sponsor: "",
      hp_kontak_person_sponsor: "",
      email_sponsor: "",
      file_bukti_foto: null,
      namaRules: [(v) => !!v || "Nama harus diisi"],
      titleRules: [(v) => !!v || "Title harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      whatsappRules: [(v) => !!v || "Whatsapp harus diisi"],
      fotoRules: [(v) => !!v || "Foto harus diisi"],
    },
    fotoKey: 0,

    layananList: [],
    items_kategori: [],

    currentLayanan: null,
    isProgressBarShow: false,
    items_kepesertaan: [
      {
        id: 1,
        name: "Ikut Event",
      },
      {
        id: 2,
        name: "Tidak Ikut Event",
      },
    ],
    items_kategori_event: [
      {
        id: 1,
        name: "Simposium",
      },
      {
        id: 2,
        name: "Workshop",
      },
      {
        id: 3,
        name: "Paket Wisata",
      },
      {
        id: 4,
        name: "Hotel",
      },
      {
        id: 5,
        name: "Hybrid Simposium",
      },
    ],
    items_item: [],
    items_status_peserta: [],
    items_propinsi: [],
    selectedKepesertaan: "",
    selectedKategori: "",
    selectedItem: "",
    selectedStatusPeserta: "",
    selectedPropinsi: "",
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  mounted() {
    this.getListStatusProfesi();
    this.getListPropinsi();
    this.getDataFromApi();
  },
  methods: {
    changeStatusProfesi() {
      this.$nextTick(() => {
        this.fotoKey++;
        console.log(this.currentData.selectedKategori.code);
      });
    },
    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListStatusProfesi() {
      MasterService.getStatusProfesi()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataByKategori() {
      this.getDataFromApi();

      if (this.selectedKategori) this.getKategoriItem(this.selectedKategori.id);
      else this.getKategoriItem("");

      this.selectedItem = null;
    },

    getKategoriItem(kategori) {
      InvoicePibService.getKategoriItem(kategori)
        .then((res) => {
          this.items_item = res.data.list_data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    truncate: function (input) {
      if (input && input.length > 10) {
        return input.substring(0, 5) + "...";
      }
      return input;
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },
    loadBuktiImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoBuktiUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoBuktiUrl = "";
        this.currentData.file_bukti_foto = null;
      }
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    async getDataFromApi() {
      this.selected = [];
      const { page, itemsPerPage } = this.options;

      var kepesertaan = "";
      var kategori = "";
      var item = "";
      var statusProfesi = "";
      var cabangId = "";

      if (this.selectedKepesertaan) {
        kepesertaan = this.selectedKepesertaan.id;
      }

      if (this.selectedKategori) {
        kategori = this.selectedKategori.id;
      }

      if (this.selectedItem) {
        item = this.selectedItem.id;
      }

      if (this.selectedStatusPeserta) {
        statusProfesi = this.selectedStatusPeserta.code;

        if (statusProfesi != 1) {
          this.selectedPropinsi = null;
        }
      }

      if (this.selectedPropinsi) {
        cabangId = this.selectedPropinsi.id;
      }

      this.loading = true;
      PesertaPibService.pesertaPibList(
        page,
        itemsPerPage,
        this.search,
        this.id,
        kepesertaan,
        kategori,
        item,
        statusProfesi,
        cabangId
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.currentData.FotoUrl = "";
      this.currentData.file_foto = null;
      this.currentData.nama = "";
      this.currentData.title = "";
      this.currentData.email = "";
      this.currentData.whatsapp = "";
      this.dialog = true;
      this.isEditable = 1;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData = this.editedItem;
      this.currentData.FotoUrl = this.editedItem.photo;
      this.currentData.FotoBuktiUrl = this.editedItem.bukti_transfer_sponsor;

      if (this.editedItem.jenis_anggota) {
        this.items_kategori.forEach((entry) => {
          if (entry.code == this.editedItem.jenis_anggota) {
            this.currentData.selectedKategori = entry;
          }
        });
      }

      if (this.editedItem.cabang_id) {
        this.items_propinsi.forEach((entry) => {
          if (entry.id == this.editedItem.cabang_id) {
            this.currentData.selectedPropinsi = entry;
          }
        });
      }

      if (this.editedItem.propinsi_id) {
        this.items_propinsi.forEach((entry) => {
          if (entry.id == this.editedItem.propinsi_id) {
            this.currentData.selectedDomisiliPropinsi = entry;
          }
        });
      }

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.pib_id = this.id;
        PesertaPibService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Peserta berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Tambah Peserta gagal. Coba lagi");
          });
      } else {
        this.currentData.pib_id = this.id;

        PesertaPibService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update Peserta berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Update Peserta gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PesertaPibService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus contact person berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus contact person gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onDownloadAnggota() {
      this.isProgressBarShow = true;

      var kepesertaan = "";
      var kategori = "";
      var item = "";

      if (this.selectedKepesertaan) {
        kepesertaan = this.selectedKepesertaan.id;
      }

      if (this.selectedKategori) {
        kategori = this.selectedKategori.id;
      }

      if (this.selectedItem) {
        item = this.selectedItem.id;
      }

      PesertaPibService.downloadPesertaReport(
        this.search,
        this.id,
        kepesertaan,
        kategori,
        item
      )
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    async printCard(item) {
      console.log(item.id);
      // print("printJS-form", "html");
      PesertaPibService.pesertaCard(item.id)
        .then((res) => {
          console.log(res.data.link);
          print({
            printable: [res.data.link],
            type: "image",
            imageStyle: "width:100%;",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async printCardFull(item) {
      console.log(item.id);
      // print("printJS-form", "html");
      PesertaPibService.pesertaCardFull(item.id)
        .then((res) => {
          console.log(res.data.link);
          print({
            printable: [res.data.link],
            type: "image",
            imageStyle: "width:100%;",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDownloadSertifikat(item) {
      this.isProgressBarShow = true;
      SertifikatService.downloadSertifikatPib(item.id)
        .then((res) => {
          this.isProgressBarShow = false;
          console.log(res.data.file);
          window.open(res.data.file, "_blank");
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          console.log(err);
          CommonService.showErrorToast("Download sertifikat gagal. Coba lagi");
        });
    },

    resetPassword(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReset = true;
    },

    closeReset() {
      this.dialogReset = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    resetItemConfirm() {
      this.closeReset();

      PesertaPibService.resetPassword(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Reset password peserta berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Reset password peserta gagal. Coba lagi"
          );
        });
    },
  },
};
</script>
