import { render, staticRenderFns } from "./PeminatanMaster.vue?vue&type=template&id=9f3ced0c"
import script from "./PeminatanMaster.vue?vue&type=script&lang=js"
export * from "./PeminatanMaster.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports