import http from "../../http-common";

/* eslint-disable */
class KppiaAdminService {
  adminList(page, itemPerPage, keyword, kppiaId) {
    return http.get(
      "/admin-kppia/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kppia_id=" +
        kppiaId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  adminNonList(page, itemPerPage, keyword, kppiaId) {
    return http.get(
      "/admin-kppia-non/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kppia_id=" +
        kppiaId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("kppia_id", data.kppia_id);
    bodyFormData.append("user_id", data.id);

    return http.post("/admin-kppia", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/admin-kppia/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new KppiaAdminService();
