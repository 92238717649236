import http from "../http-common";
/* eslint-disable */
class RumahSakitService {
  rumahSakitList(page, itemPerPage, keyword, propinsi, kabupaten) {
    return http.get(
      "/rumahsakit/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  rumahSakitListMaster(page, itemPerPage, keyword, propinsi, kabupaten) {
    return http.get(
      "/rumahsakit/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&propinsi=" +
        propinsi +
        "&kabupaten=" +
        kabupaten,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  rumahSakitListAllMaster(page, itemPerPage, keyword, propinsi, kabupaten) {
    return http.get(
      "/rumahsakit/all/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&propinsi=" +
        propinsi +
        "&kabupaten=" +
        kabupaten,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  nakesPropinsi(page, itemPerPage, keyword, propinsi, rumahSakitId) {
    return http.get(
      "/rumahsakit/nakespropinsi/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&propinsi=" +
        propinsi +
        "&rumah_sakit_id=" +
        rumahSakitId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getRumahSakit(id) {
    return http.get(`/rumahsakit/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("phone", data.phone);
    bodyFormData.append("email", data.email);
    bodyFormData.append("alamat", data.alamat);

    if (data.selectedJenisRs && data.selectedJenisRs != undefined) {
      bodyFormData.append("jenis_rs_id", data.selectedJenisRs.code);
    }

    if (data.selectedKelasRs && data.selectedKelasRs != undefined) {
      bodyFormData.append("kelas_rs_id", data.selectedKelasRs.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    if (data.selectedKecamatan && data.selectedKecamatan != undefined) {
      bodyFormData.append("kecamatan_id", data.selectedKecamatan.id);
    }

    if (data.selectedKelurahan && data.selectedKelurahan != undefined) {
      bodyFormData.append("kelurahan_id", data.selectedKelurahan.id);
    }

    if (data.latitude) bodyFormData.append("latitude", data.latitude);
    if (data.longitude) bodyFormData.append("longitude", data.longitude);

    return http.post("/rumahsakit", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("phone", data.phone);
    bodyFormData.append("email", data.email);
    bodyFormData.append("alamat", data.alamat);

    if (data.selectedJenisRs && data.selectedJenisRs != undefined) {
      bodyFormData.append("jenis_rs_id", data.selectedJenisRs.code);
    }

    if (data.selectedKelasRs && data.selectedKelasRs != undefined) {
      bodyFormData.append("kelas_rs_id", data.selectedKelasRs.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    if (data.selectedKecamatan && data.selectedKecamatan != undefined) {
      bodyFormData.append("kecamatan_id", data.selectedKecamatan.id);
    }

    if (data.selectedKelurahan && data.selectedKelurahan != undefined) {
      bodyFormData.append("kelurahan_id", data.selectedKelurahan.id);
    }

    if (data.latitude) bodyFormData.append("latitude", data.latitude);
    if (data.longitude) bodyFormData.append("longitude", data.longitude);

    return http.put(`/rumahsakit/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/rumahsakit/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new RumahSakitService();
