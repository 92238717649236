import http from "../http-common";
/* eslint-disable */
class PibAdminService {
  adminPibList(page, itemPerPage, keyword, pibId) {
    return http.get(
      "/admin-pib/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&pib_id=" +
        pibId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  adminPibNonList(page, itemPerPage, keyword, pibId) {
    return http.get(
      "/admin-pib-non/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&pib_id=" +
        pibId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("pib_id", 1);
    bodyFormData.append("user_id", data.id);

    return http.post("/admin-pib", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/admin-pib/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new PibAdminService();
