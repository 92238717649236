import http from "../../http-common";
/* eslint-disable */
class PengurusCabangService {
  pengurusCabangList(page, itemPerPage, keyword, propinsiId) {
    return http.get(
      "/pengurus-cabang/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&cabang_id=" +
        propinsiId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    // bodyFormData.append("nama", data.nama);
    bodyFormData.append("user_id", data.user_id);
    bodyFormData.append("urutan", data.urutan);

    if (data.selectedDivisi && data.selectedDivisi != undefined) {
      bodyFormData.append("organisasi_divisi_id", data.selectedDivisi.code);
    }

    if (data.selectedJabatan && data.selectedJabatan != undefined) {
      bodyFormData.append("organisasi_jabatan_id", data.selectedJabatan.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("cabang_id", data.selectedPropinsi.id);
    }

    return http.post("/pengurus-cabang", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    // bodyFormData.append("nama", data.nama);
    bodyFormData.append("user_id", data.user_id);
    bodyFormData.append("urutan", data.urutan);

    if (data.selectedDivisi && data.selectedDivisi != undefined) {
      bodyFormData.append("organisasi_divisi_id", data.selectedDivisi.code);
    }

    if (data.selectedJabatan && data.selectedJabatan != undefined) {
      bodyFormData.append("organisasi_jabatan_id", data.selectedJabatan.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("cabang_id", data.selectedPropinsi.id);
    }

    return http.put(`/pengurus/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/pengurus/cabang/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new PengurusCabangService();
