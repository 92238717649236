import http from "../http-common";
/* eslint-disable */
class KehadiranPibService {
  invoiceList(
    page,
    itemPerPage,
    keyword,
    invoiceNo,
    status,
    kategori,
    item,
    sponsor
  ) {
    return http.get(
      "/pib/kehadiran/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&invoice_no=" +
        invoiceNo +
        "&status=" +
        status +
        "&kategori=" +
        kategori +
        "&item=" +
        item +
        "&sponsor=" +
        sponsor,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  kehadiranList(
    page,
    itemPerPage,
    keyword,
    invoiceNo,
    status,
    kategori,
    item,
    sponsor
  ) {
    return http.get(
      "/pib/history-kehadiran/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&invoice_no=" +
        invoiceNo +
        "&status=" +
        status +
        "&kategori=" +
        kategori +
        "&item=" +
        item +
        "&sponsor=" +
        sponsor,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  downloadKehadiranReport(keyword, invoiceNo, kategori, item) {
    return http.get(
      "/report/pib-kehadiran-peserta/download?" +
        "keyword=" +
        keyword +
        "&invoice_no=" +
        invoiceNo +
        "&kategori=" +
        kategori +
        "&item=" +
        item,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }
}

export default new KehadiranPibService();
