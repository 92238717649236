<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title>
          <div>Data Penyelenggara</div>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-form ref="form" v-model="valid" lazy-validation class="pt-4 px-4">
          <v-row>
            <v-col cols="12" md="6">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    label="Nama Penyelenggara"
                    outlined
                    dense
                    v-model="userData.nama"
                    :rules="userData.namaRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                    v-model="userData.email"
                    :rules="userData.emailRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Alamat"
                    outlined
                    dense
                    v-model="userData.alamat"
                    :rules="userData.alamatRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    label="Propinsi"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedPropinsi"
                    :items="items_propinsi"
                    item-text="name"
                    @change="changePropinsi"
                    :rules="userData.propinsiRules"
                  ></v-combobox>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    label="Kabupaten/Kota"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedKabupaten"
                    :items="items_kabupaten"
                    item-text="name"
                    @click="getListKabupaten"
                    :rules="userData.kabupatenRules"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    label="Nama Penanggung Jawab"
                    outlined
                    dense
                    v-model="userData.nama_penanggung_jawab"
                    :rules="userData.namaPenanggungJawabRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Whatsapp Penanggung Jawab"
                    outlined
                    dense
                    v-model="userData.whatsapp_penanggung_jawab"
                    :rules="userData.whatsappPenanggungJawabRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Nama PIC"
                    outlined
                    dense
                    v-model="userData.nama_pic"
                    :rules="userData.namaPicRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Whatsapp PIC"
                    outlined
                    dense
                    v-model="userData.whatsapp_pic"
                    :rules="userData.whatsappPicRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="pb-4">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PenyelenggaraSkpKemkesService from "../../../services/skp_kemkes/PenyelenggaraSkpKemkesService"
import CommonService from "../../../services/CommonService"
import NakesService from "../../../services/NakesService"

export default {
  name: "PenyelenggaraSkpKegiatanAdd",
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Penyelenggara",
        disabled: false,
        exact: true,
        to: { name: "PenyelenggaraSkpKegiatan" },
      },
      { text: "Tambah Penyelenggara", disabled: true },
    ],
    valid: true,
    userData: {
      nama: "",
      email: "",
      alamat: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      nama_penanggung_jawab: "",
      whatsapp_penanggung_jawab: "",
      nama_pic: "",
      whatsapp_pic: "",
      namaRules: [(v) => !!v || "Nama Penyelenggara harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      alamatRules: [(v) => !!v || "Alamat harus diisi"],
      propinsiRules: [(v) => !!v || "Propinsi harus diisi"],
      kabupatenRules: [(v) => !!v || "Kabupaten harus diisi"],
      namaPenanggungJawabRules: [
        (v) => !!v || "Nama Penanggung Jawab harus diisi",
      ],
      whatsappPenanggungJawabRules: [
        (v) => !!v || "Whatsapp Penanggung Jawab harus diisi",
      ],
      namaPicRules: [(v) => !!v || "Nama PIC harus diisi"],
      whatsappPicRules: [(v) => !!v || "Whatsapp PIC harus diisi"],
    },
    items_propinsi: [],
    items_kabupaten: [],
    loading: true,
  }),
  mounted: async function () {
    this.getListPropinsi()
  },
  methods: {
    async changePropinsi() {
      this.userData.selectedKabupaten = null
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.userData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    doBatal: function () {
      this.$router.push("/skp-kemkes/penyelenggara").catch((err) => {
        console.log(err)
      })
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0)
        return
      }

      PenyelenggaraSkpKemkesService.create(this.userData)
        .then((res) => {
          CommonService.showSuccessToast("Tambah penyelenggara berhasil")
          this.$router
            .push("/skp-kemkes/penyelenggara-edit/" + res.data.data.id)
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)
          CommonService.showSuccessToast(
            "Tambah penyelenggara gagal. Coba lagi"
          )
        })
    },
  },
}
</script>

<style scoped></style>
