<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card class="mt-4">
        <v-card-title>
          Klinik
          <v-spacer></v-spacer>

          <router-link
            to="/klinik-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="primary" dark class=""> Tambah </v-btn>
          </router-link>

          <v-dialog v-model="dialog" persistent max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Advokasi</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Nama"
                          required
                          v-model="currentData.nama"
                          :rules="currentData.namaRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="No Telepon"
                          required
                          v-model="currentData.no_telepon"
                          :rules="currentData.noTelpRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="No KTP"
                          required
                          v-model="currentData.no_ktp"
                          :rules="currentData.ktpRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <vue-editor
                          v-model="currentData.pengaduan"
                          useCustomImageHandler
                          @image-added="handleImageAdded"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Status"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedStatus"
                          :items="items_status"
                          item-text="value"
                          :rules="currentData.statusRules"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Propinsi"
              clearable
              dense
              outlined
              v-model="selectedPropinsi"
              :items="items_propinsi"
              item-text="name"
              @change="changePropinsi"
              :disabled="isCabangPengurus"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Kabupaten/Kota"
              clearable
              dense
              outlined
              v-model="selectedKabupaten"
              :items="items_kabupaten"
              item-text="name"
              @click="getListKabupaten"
              @change="changeKabupaten"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          v-model="selected"
          :single-select="true"
          show-select
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:[`item.foto_url`]="{ item }">
            <v-img
              :src="item.foto_url"
              contain
              height="100"
              width="200"
            ></v-img>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ getCurrentDate(item.created_at) }}</span>
          </template>
          <template v-slot:[`item.pengaduan`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-list-status
            </v-icon>
          </template>
          <template v-slot:[`item.data_status`]="{ item }">
            <v-chip :color="getColor(item.data_status)" dark>
              {{ getIsHide(item.data_status) }}
            </v-chip>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="getColor(item.status)" dark>
              {{ getStatus(item.status) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>

      <v-card class="mt-4">
        <AnggotaKlinikComponent
          v-if="selected != undefined && selected.length > 0"
          :id="selected[0].id"
          ref="mychild"
          :key="childKey"
        />
      </v-card>
    </v-container>

    <RumahSakitSelectComponent
      @pilihRumahSakit="pilihRumahSakit"
      ref="modalRumahSakit"
      v-model="dialogRumahSakit"
      :id="selectedPropinsiId"
    />
  </v-main>
</template>

<script>
import KlinikService from "../services/KlinikService";
import CommonService from "../services/CommonService";
import * as moment from "moment";
import { VueEditor } from "vue2-editor";
import AnggotaKlinikComponent from "./klinik/AnggotaKlinik.vue";
import EventService from "../services/EventService";
import { uuid } from "vue-uuid";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import NakesService from "../services/NakesService";
import RumahSakitSelectComponent from "../components/RumahSakitPerPropinsiSelect.vue";
import store from "../store/index";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    AnggotaKlinikComponent,
    VueEditor,
    RumahSakitSelectComponent,
  },
  data: () => ({
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    zoom: 4,
    center: [-2.482, 122.905],
    markerLatLng: [-2.482, 122.905],
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Rumah Sakit",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedPropinsi: "",
    selectedPropinsiId: "",
    selectedKabupaten: "",

    items_propinsi: [],
    items_kabupaten: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Alamat", value: "alamat", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Propinsi", value: "propinsi_nama", sortable: false },
      { text: "Kota/Kabupaten", value: "kabupaten_nama", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    list_data_koordinat: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      uuid: uuid.v4(),
      id: null,
      nama: "",
      no_telepon: "",
      pengaduan: "",
      selectedStatus: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Advokasi harus diisi"],
      statusRules: [(v) => !!v || "Status harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_status: [
      {
        text: 1,
        value: "Diajukan",
      },
      {
        text: 2,
        value: "Diproses",
      },
      {
        text: 3,
        value: "Ditindak",
      },
    ],

    jumlahAnggotaSeluruh: 0,
    jumlahAnggotaPerPropinsi: 0,
    jumlahRumahSakit: 0,
    jumlahAnggotaTeregistrasi: 0,
    rumah_sakit: "",
    rumah_sakit_id: "",
    dialogRumahSakit: false,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListPropinsi();
  },
  methods: {
    openRumahSakitModal: async function () {
      if (this.selectedPropinsi && this.selectedPropinsi != undefined) {
        this.$refs.modalRumahSakit.getDataFromApiParam(
          this.selectedPropinsi.id
        );
      } else {
        this.$refs.modalRumahSakit.getDataFromApiParam("");
      }
      this.dialogRumahSakit = true;
    },

    pilihRumahSakit(item) {
      this.dialogRumahSakit = false;
      console.log(item);
      this.rumah_sakit = item.nama;
      this.rumah_sakit_id = item.id;

      this.getAnggotaTeregistrasi();
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;

          if (this.getCabangPengurusId) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == this.getCabangPengurusId) {
                this.selectedPropinsi = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }

          this.getAnggotaTeregistrasi();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.getDataFromApi();

      this.rumah_sakit_id = "";
      this.rumah_sakit = "";

      this.getAnggotaTeregistrasi();

      if (this.selectedPropinsi && this.selectedPropinsi != undefined) {
        this.getKoordinatPropinsi();

        this.selectedPropinsiId = this.selectedPropinsi.id;
      } else {
        this.list_data_koordinat = [];
        this.selectedPropinsiId = "";
      }
    },

    clearRumahSakit() {
      this.$nextTick(() => {
        this.rumah_sakit_id = "";
        this.rumah_sakit = "";

        this.getAnggotaTeregistrasi();
      });
    },

    changeMarker(propinsiId) {
      console.log(propinsiId);

      this.items_propinsi.forEach((entry) => {
        if (entry.id == propinsiId) {
          this.selectedPropinsi = entry;
        }
      });

      this.getDataFromApi();

      if (this.selectedPropinsi && this.selectedPropinsi != undefined) {
        this.getKoordinatPropinsi();
      } else {
        this.list_data_koordinat = [];
      }
    },

    async changeKabupaten() {
      this.getDataFromApi();
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "blue";
        case 2:
          return "green";
        default:
          return "cyan";
      }
    },

    getStatus(dataStatus) {
      console.log(dataStatus);
      if (dataStatus == 1) {
        return "Diajukan";
      } else if (dataStatus == 2) {
        return "Diproses";
      } else {
        return "Ditindak";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getKoordinatPropinsi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var propinsi = "";
      var kabupaten = "";

      if (this.selectedPropinsi) {
        propinsi = this.selectedPropinsi.id;
      }

      if (this.selectedKabupaten) {
        kabupaten = this.selectedKabupaten.id;
      }

      KlinikService.rumahSakitListAllMaster(
        page,
        itemsPerPage,
        this.search,
        propinsi,
        kabupaten
      )
        .then((res) => {
          this.list_data_koordinat = res.data.list_data;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async getAnggotaTeregistrasi() {
      console.log(this.rumah_sakit_id);
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var propinsi = "";

      if (this.selectedPropinsi) {
        propinsi = this.selectedPropinsi.id;
      }

      KlinikService.nakesPropinsi(
        page,
        itemsPerPage,
        this.search,
        propinsi,
        this.rumah_sakit_id
      )
        .then((res) => {
          this.$nextTick(() => {
            this.jumlahAnggotaSeluruh = new Intl.NumberFormat("id-ID").format(
              res.data.total_seluruh
            );

            this.jumlahAnggotaPerPropinsi = new Intl.NumberFormat(
              "id-ID"
            ).format(res.data.total_propinsi);

            this.jumlahAnggotaTeregistrasi = new Intl.NumberFormat(
              "id-ID"
            ).format(res.data.total);
          });
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var propinsi = "";
      var kabupaten = "";

      if (this.selectedPropinsi) {
        propinsi = this.selectedPropinsi.id;
      }

      if (this.selectedKabupaten) {
        kabupaten = this.selectedKabupaten.id;
      }

      KlinikService.klinikListMaster(
        page,
        itemsPerPage,
        this.search,
        propinsi,
        kabupaten
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;

          this.jumlahRumahSakit = new Intl.NumberFormat("id-ID").format(
            this.totalData
          );
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.no_telepon = "";
      this.currentData.no_ktp = "";
      this.currentData.pengaduan = "";
      this.currentData.selectedStatus = "";

      console.log("do add");
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        KlinikService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update advokasi berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update advokasi gagal. Coba lagi");
          });
      } else {
        KlinikService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah advokasi berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah advokasi gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/klinik-edit/" + item.id).catch((err) => {
        console.log(err);
      });

      // this.dialog = true;
      // this.currentData.nama = this.editedItem.nama;
      // this.currentData.no_telepon = this.editedItem.no_telepon;
      // this.currentData.no_ktp = this.editedItem.no_ktp;
      // this.currentData.pengaduan = this.editedItem.pengaduan;
      // this.currentData.id = this.editedItem.id;

      // this.items_status.forEach((entry) => {
      //   if (entry.text == this.editedItem.status) {
      //     this.currentData.selectedStatus = entry;
      //   }
      // });

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      KlinikService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus advokasi berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus advokasi gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function (file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },

    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      //1 = kategori event
      EventService.uploadFotoKonten(this.currentData.uuid, file, 4)
        .then((res) => {
          console.log(res);

          Editor.insertEmbed(cursorLocation, "image", res.data.event.foto_url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Gagal menambah foto. Coba lagi");
        });
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getRolesId() {
      return store.getters.getUser.roles_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
    isCabangPengurus() {
      if (store.getters.getUser.cabang_pengurus_id) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
