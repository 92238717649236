import http from "../../http-common";

/* eslint-disable */
class InasiaAdminService {
  adminList(page, itemPerPage, keyword, inasiaId) {
    return http.get(
      "/admin-inasia/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&inasia_id=" +
        inasiaId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  adminNonList(page, itemPerPage, keyword, inasiaId) {
    return http.get(
      "/admin-inasia-non/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&inasia_id=" +
        inasiaId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("inasia_id", data.inasia_id);
    bodyFormData.append("user_id", data.id);

    return http.post("/admin-inasia", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/admin-inasia/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new InasiaAdminService();
