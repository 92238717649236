import http from "../http-common";
/* eslint-disable */
class LayananRSService {
  layananRSList(page, itemPerPage, keyword, fasyankesId) {
    return http.get(
      "/layananrs/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&fasyankes_id=" +
        fasyankesId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data.nama);

    if (data.photo != null) bodyFormData.append("photo", data.photo);

    return http.post("/layananrs", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("nama", data.nama);

    if (data.photo != null) bodyFormData.append("photo", data.photo);

    return http.put(`/layananrs/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/layananrs/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new LayananRSService();
