<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs>
          <v-tab>Keseminatan</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-title> Edit Keseminatan </v-card-title>
              <v-fab-transition>
                <v-btn
                  class="mt-8 mr-4"
                  v-if="isEditable == 0"
                  @click="changeEditable"
                  color="pink"
                  dark
                  absolute
                  top
                  right
                  fab
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4 pt-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKey"
                          :src="userData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImage"
                      accept="image/*"
                      v-model="userData.file_foto"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Nama"
                          outlined
                          dense
                          v-model="userData.nama"
                          :rules="userData.namaRules"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h5>Konten Berita</h5>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="10" md="10" class="">
                    <vue-editor
                      v-model="userData.konten"
                      useCustomImageHandler
                      @image-added="handleImageAdded"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12"> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      @click="doBatal"
                      style="margin-right: 10px"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KeseminatanService from "../../services/web_content/KeseminatanService";
import CommonService from "../../services/CommonService";
import { VueEditor } from "vue2-editor";
import EventService from "../../services/EventService";
import { uuid } from "vue-uuid";

export default {
  name: "KeseminatanEdit",
  components: { VueEditor },
  props: {
    id: String,
  },
  data: () => ({
    komunitas_key: 0,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Keseminatan",
        disabled: false,
        exact: true,
        to: { name: "Keseminatan" },
      },
      { text: "Edit Keseminatan", disabled: true },
    ],
    valid: true,
    userData: {
      uuid: uuid.v4(),
      FotoUrl: "",
      file_foto: null,
      nama: "",
      content: "",
      selectedHide: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      hideRules: [(v) => !!v || "Is Hide harus diisi"],
    },
    items_hide: [
      {
        text: 1,
        value: "Published",
      },
      {
        text: 0,
        value: "Unpublished",
      },
    ],
    result: {},
    fotoKey: 0,
    searchNakes: "",
  }),
  mounted: async function () {
    this.getKeseminatan();
  },
  computed: {
    imageSrc() {
      return this.userData.FotoUrl;
    },
  },
  watch: {},
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    getKeseminatan() {
      KeseminatanService.getKeseminatan(this.id)
        .then((res) => {
          this.userData.nama = res.data.keseminatan.nama;
          this.userData.konten = res.data.keseminatan.konten;

          this.userData.FotoUrl = res.data.keseminatan.foto_url;
          this.fotoKey++;

          this.items_hide.forEach((entry) => {
            if (entry.text == res.data.keseminatan.data_status) {
              this.userData.selectedHide = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go();
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      KeseminatanService.update(this.id, this.userData, this.userData.file_foto)
        .then((res) => {
          console.log(res);
          this.isEditable = 0;

          CommonService.showSuccessToast("Update keseminatan berhasil");
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Update keseminatan gagal. Coba lagi");
        });
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      //1 = kategori event
      EventService.uploadFotoKonten(this.userData.uuid, file, 3)
        .then((res) => {
          console.log(res);

          Editor.insertEmbed(cursorLocation, "image", res.data.event.foto_url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Gagal menambah foto. Coba lagi");
        });
    },
  },
};
</script>

<style scoped>
</style>
