<template>
  <div>
    <v-card
      ><v-tabs v-model="tab">
        <v-tab>Harga</v-tab>
        <v-tab>Peserta</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <WorkshopHargaComponent :id="id" />
        </v-tab-item>
        <v-tab-item>
          <InasiaWorkshopInvoiceComponent
            :id="id"
            :inasiaId="inasiaId"
            :harga="harga"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import WorkshopHargaComponent from "./InasiaHybridSimposiumHarga.vue"
import InasiaWorkshopInvoiceComponent from "./InasiaHybridSimposiumInvoice.vue"

export default {
  props: {
    id: Number,
    inasiaId: String,
    harga: Number,
  },
  components: {
    WorkshopHargaComponent,
    InasiaWorkshopInvoiceComponent,
  },
  data: () => ({
    tab: null,
  }),
  mounted() {},
  methods: {},
}
</script>
