<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-progress-linear
          v-if="isProgressBarShow"
          indeterminate
          color="green"
        ></v-progress-linear>
        <v-card-title>
          Event
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Judul"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <router-link
            to="/event-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="primary" dark class="mb-2"> Tambah </v-btn>
          </router-link>
          <v-btn
            color="primary"
            dark
            class="mb-2 ml-4"
            @click="onDownloadAnggota()"
          >
            Download
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan meng-unpublish data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogPublish" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan mem-publish data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogPublish = false"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.tanggal_event`]="{ item }">
            {{ getCurrentDate(item.tanggal_event) }}
          </template>
          <template v-slot:[`item.foto_url`]="{ item }">
            <v-img height="auto" width="100" :src="item.foto_url"></v-img>
          </template>
          <template v-slot:[`item.data_status`]="{ item }">
            <div v-if="item.data_status == 0">Unpublished</div>
            <div v-else>Published</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex flex-row">
              <v-icon medium class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="item.data_status == 1"
                medium
                @click="deleteItem(item)"
              >
                mdi-block-helper
              </v-icon>
              <v-icon v-else medium @click="deleteItem(item)">
                mdi-check
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import EventService from "../services/EventService";
import * as moment from "moment";
import PesertaEventService from "../services/PesertaEventService";
import CommonService from "../services/CommonService";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Event",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "judul", sortable: false },
      { text: "Tanggal Event", value: "tanggal_event", sortable: false },
      { text: "Foto", value: "foto_url", sortable: false },
      { text: "Status", value: "data_status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    dialogPublish: false,
    isProgressBarShow: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  computed: {},
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      EventService.eventList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/event-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (item.data_status == 1) this.dialogDelete = true;
      else this.dialogPublish = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      var statusSuccess = "";
      var statusGagal = "";

      if (this.editedItem.data_status == 1) {
        statusSuccess = "Berhasil meng-unpublish event";
        statusGagal = "Meng-unpublish event gagal. Coba lagi";
      } else {
        statusSuccess = "Berhasil mem-publish event";
        statusGagal = "Mem-publish event gagal. Coba lagi";
      }

      EventService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          this.$toast.success(statusSuccess, {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.dialogDelete = false;
          this.dialogPublish = false;

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error(statusGagal, {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.dialogDelete = false;
          this.dialogPublish = false;
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onDownloadAnggota() {
      this.isProgressBarShow = true;

      PesertaEventService.downloadPesertaReportAll(this.search)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download. Coba lagi");
          console.log(err);
        });
    },
  },
};
</script>
