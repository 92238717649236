<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Katalog Buku </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img :key="fotoKey" :src="userData.FotoUrl" contain></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                @change="loadImage"
                accept="image/*"
                v-model="userData.file_foto"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Judul"
                    outlined
                    dense
                    v-model="userData.judul"
                    :rules="userData.namaRules"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Penulis"
                    outlined
                    dense
                    v-model="userData.penulis"
                    :rules="userData.penulisRules"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="ISBN"
                    outlined
                    dense
                    v-model="userData.isbn"
                    :rules="userData.isbnRules"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Tahun"
                    outlined
                    dense
                    v-model="userData.tahun"
                    :rules="userData.tahunRules"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Ukuran Buku"
                    outlined
                    dense
                    v-model="userData.ukuran_buku"
                    :rules="userData.ukuranBukuRules"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Jumlah Halaman"
                    outlined
                    dense
                    v-model="userData.jumlah_halaman"
                    :rules="userData.jumlahHalamanRules"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-combobox
                    label="Status"
                    clearable
                    dense
                    outlined
                    v-model="userData.selectedHide"
                    :items="userData.items_hide"
                    item-text="value"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" class="pr-4">
                  <v-card class="pa-2"
                    ><a :href="userData.fileUrlEbook" target="_blank"
                      >Link File Ebook</a
                    ></v-card
                  >
                  <v-file-input
                    accept=".pdf"
                    v-model="userData.ebook"
                    prepend-icon="mdi-image"
                    show-size
                    label="Upload File PDF Ebook"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h5>Konten Berita</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="10" md="10" class="">
              <vue-editor
                v-model="userData.content"
                useCustomImageHandler
                @image-added="handleImageAdded"
              />
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <!-- Modal -->
    </v-container>
  </v-main>
</template>

<script>
import KatalogBukuService from "../../services/web_content/KatalogBukuService";
import EventService from "../../services/EventService";
import CommonService from "../../services/CommonService";
import { VueEditor } from "vue2-editor";
import { uuid } from "vue-uuid";

export default {
  name: "KatalogBukuAdd",
  components: { VueEditor },
  data: () => ({
    komunitas_key: 0,
    breadcrumbs: [
      {
        text: "Data Katalog Buku",
        disabled: false,
        exact: true,
        to: { name: "Katalog Buku" },
      },
      { text: "Tambah Katalog Buku", disabled: true },
    ],
    valid: true,
    userData: {
      uuid: uuid.v4(),
      content: "",
      FotoUrl: "",
      file_foto: null,
      judul: "",
      penulis: "",
      isbn: "",
      tahun: "",
      ukuran_buku: "",
      jumlah_halaman: "",
      fileUrlEbook: "",
      ebook: null,
      items_hide: [
        {
          text: 1,
          value: "Published",
        },
        {
          text: 0,
          value: "Unpublished",
        },
      ],
      selectedHide: "",
      namaRules: [(v) => !!v || "Judul harus diisi"],
      phoneRules: [(v) => !!v || "No Telepon harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      noAnggotaRules: [(v) => !!v || "Email harus diisi"],
      penulisRules: [(v) => !!v || "Penulis harus diisi"],
      isbnRules: [(v) => !!v || "ISBN harus diisi"],
      tahunRules: [(v) => !!v || "Tahun harus diisi"],
      ukuranBukuRules: [(v) => !!v || "Ukuran Buku harus diisi"],
      jumlahHalamanRules: [(v) => !!v || "Jumlah Halaman harus diisi"],
    },
    items_propinsi: [],
    items_kabupaten: [],
    result: {},
    fotoKey: 0,
    searchNakes: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Kategori", value: "category_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },
  }),
  mounted: async function () {},
  computed: {
    imageSrc() {
      return this.userData.FotoUrl;
    },
  },
  watch: {},
  methods: {
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      KatalogBukuService.create(
        this.userData,
        this.userData.file_foto,
        this.userData.ebook
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Katalog Buku berhasil");

          this.$router
            .push("/katalogbuku-edit/" + res.data.berita.id)
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Tambah Katalog Buku gagal. Coba lagi");
        });
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      //1 = kategori event
      EventService.uploadFotoKonten(this.userData.uuid, file, 3)
        .then((res) => {
          console.log(res);

          Editor.insertEmbed(cursorLocation, "image", res.data.event.foto_url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Gagal menambah foto. Coba lagi");
        });
    },
  },
};
</script>

<style scoped>
</style>
