import http from "../http-common";
/* eslint-disable */
class PengurusPusatService {
  pengurusPusatList(page, itemPerPage, keyword, propinsi_id, kabupaten_id) {
    var url =
      "/pengurus-pusat/list?page=" +
      page +
      "&item_per_page=" +
      itemPerPage +
      "&keyword=" +
      keyword;

    if (propinsi_id) {
      url += "&propinsi_id=" + propinsi_id;
    }

    if (kabupaten_id) {
      url += "&kabupaten_id=" + kabupaten_id;
    }

    return http.get(url, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  pengurusWilayahList(page, itemPerPage, keyword, propinsi_id, kabupaten_id) {
    var url =
      "/pengurus-wilayah/list?page=" +
      page +
      "&item_per_page=" +
      itemPerPage +
      "&keyword=" +
      keyword;

    if (propinsi_id) {
      url += "&propinsi_id=" + propinsi_id;
    }

    if (kabupaten_id) {
      url += "&kabupaten_id=" + kabupaten_id;
    }

    return http.get(url, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  getPengurusPusat(id) {
    return http.get(`/pengurus-pusat-wilayah/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();

    if (data.selectedUser && data.selectedUser != undefined) {
      bodyFormData.append("user_id", data.selectedUser.id);
    }

    if (data.selectedBidang && data.selectedBidang != undefined) {
      bodyFormData.append("bidang_pengurus_id", data.selectedBidang.id);
    }

    if (data.selectedDivisi && data.selectedDivisi != undefined) {
      bodyFormData.append("divisi_pengurus_id", data.selectedDivisi.id);
    }

    if (data.selectedJabatanMukisi && data.selectedJabatanMukisi != undefined) {
      bodyFormData.append("jabatan_pengurus_id", data.selectedJabatanMukisi.id);
    }

    if (data.selectedJabatan && data.selectedJabatan != undefined) {
      bodyFormData.append("jabatan_id", data.selectedJabatan.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    return http.post("/pengurus-pusat", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    if (data.selectedUser && data.selectedUser != undefined) {
      bodyFormData.append("user_id", data.selectedUser.id);
    }

    if (data.selectedBidang && data.selectedBidang != undefined) {
      bodyFormData.append("bidang_pengurus_id", data.selectedBidang.id);
    }

    if (data.selectedDivisi && data.selectedDivisi != undefined) {
      bodyFormData.append("divisi_pengurus_id", data.selectedDivisi.id);
    }

    if (data.selectedJabatanMukisi && data.selectedJabatanMukisi != undefined) {
      bodyFormData.append("jabatan_pengurus_id", data.selectedJabatanMukisi.id);
    }

    if (data.selectedJabatan && data.selectedJabatan != undefined) {
      bodyFormData.append("jabatan_id", data.selectedJabatan.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    return http.put(`/pengurus-pusat/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/pengurus-pusat/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  createWilayah(data) {
    var bodyFormData = new FormData();

    if (data.selectedUser && data.selectedUser != undefined) {
      bodyFormData.append("user_id", data.selectedUser.id);
    }

    if (data.selectedBidang && data.selectedBidang != undefined) {
      bodyFormData.append("bidang_pengurus_id", data.selectedBidang.id);
    }

    if (data.selectedDivisi && data.selectedDivisi != undefined) {
      bodyFormData.append("divisi_pengurus_id", data.selectedDivisi.id);
    }

    if (data.selectedJabatanMukisi && data.selectedJabatanMukisi != undefined) {
      bodyFormData.append("jabatan_pengurus_id", data.selectedJabatanMukisi.id);
    }

    if (data.selectedJabatan && data.selectedJabatan != undefined) {
      bodyFormData.append("jabatan_id", data.selectedJabatan.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    return http.post("/pengurus-wilayah", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new PengurusPusatService();
