import http from "../http-common";
/* eslint-disable */
class UjianService {
  ujianList(page, itemPerPage, keyword, pelatihanDetailId) {
    return http.get(
      "/ujian/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&pelatihan_detail_id=" +
        pelatihanDetailId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  ujianListCopy() {
    return http.get("/ujian/list/copy", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  createPreTest(data, config) {
    // data.jenis_ujian = 1;
    // return http.post("/ujian", data, {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });

    var bodyFormData = new FormData();

    bodyFormData.append("jenis_ujian", 1);
    if (data.pelatihan_detail_id)
      bodyFormData.append("pelatihan_detail_id", data.pelatihan_detail_id);
    if (data.pertanyaan) bodyFormData.append("pertanyaan", data.pertanyaan);
    if (data.urutan) bodyFormData.append("urutan", data.urutan);
    if (data.score) bodyFormData.append("score", data.score);

    if (data.selectedTipeKonten && data.selectedTipeKonten != undefined) {
      bodyFormData.append("tipe_konten", data.selectedTipeKonten.code);
    }

    if (data.file_foto != null)
      bodyFormData.append("file_foto", data.file_foto);

    if (data.video != null) bodyFormData.append("video", data.video);

    if (data.selectedTipeKonten && data.selectedTipeKonten.code == 2) {
      return http.post("/ujian/video", bodyFormData, config);
    } else {
      return http.post("/ujian", bodyFormData, config);
    }
  }

  createPostTest(data, config) {
    // data.jenis_ujian = 2;
    // return http.post("/ujian", data, {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });

    var bodyFormData = new FormData();

    bodyFormData.append("jenis_ujian", 2);
    if (data.pelatihan_detail_id)
      bodyFormData.append("pelatihan_detail_id", data.pelatihan_detail_id);
    if (data.pertanyaan) bodyFormData.append("pertanyaan", data.pertanyaan);
    if (data.urutan) bodyFormData.append("urutan", data.urutan);
    if (data.score) bodyFormData.append("score", data.score);

    if (data.selectedTipeKonten && data.selectedTipeKonten != undefined) {
      bodyFormData.append("tipe_konten", data.selectedTipeKonten.code);
    }

    if (data.file_foto != null)
      bodyFormData.append("file_foto", data.file_foto);

    if (data.video != null) bodyFormData.append("video", data.video);

    if (data.selectedTipeKonten && data.selectedTipeKonten.code == 2) {
      return http.post("/ujian/video", bodyFormData, config);
    } else {
      return http.post("/ujian", bodyFormData, config);
    }
  }

  copyPreTest(data) {
    data.jenis_ujian = 1;
    return http.post("/copy-ujian", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  copyPostTest(data) {
    data.jenis_ujian = 2;
    return http.post("/copy-ujian", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  update(data, config) {
    // return http.put(`/ujian/${data.id}`, data, {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });

    var bodyFormData = new FormData();

    bodyFormData.append("jenis_ujian", 1);
    if (data.pelatihan_detail_id)
      bodyFormData.append("pelatihan_detail_id", data.pelatihan_detail_id);
    if (data.pertanyaan) bodyFormData.append("pertanyaan", data.pertanyaan);
    if (data.urutan) bodyFormData.append("urutan", data.urutan);
    if (data.score) bodyFormData.append("score", data.score);

    if (data.selectedTipeKonten && data.selectedTipeKonten != undefined) {
      bodyFormData.append("tipe_konten", data.selectedTipeKonten.code);
    }

    if (data.file_foto != null)
      bodyFormData.append("file_foto", data.file_foto);

    if (data.video != null) bodyFormData.append("video", data.video);

    if (data.selectedTipeKonten.code == 2) {
      return http.put(`/ujian/video/${data.id}`, bodyFormData, config);
    } else {
      return http.put(`/ujian/${data.id}`, bodyFormData, config);
    }
  }

  delete(id) {
    return http.delete(`/ujian/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default new UjianService();
