import http from "../../http-common";

/* eslint-disable */
class InasiaFlyerService {
  flyerList(page, itemPerPage, keyword, id) {
    return http.get(
      "/inasia/flyer/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);
    if (data.nama) bodyFormData.append("nama", data.nama);

    if (data.file != null) bodyFormData.append("file", data.file);

    return http.post("/inasia/flyer", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.inasia_id) bodyFormData.append("inasia_id", data.inasia_id);
    if (data.nama) bodyFormData.append("nama", data.nama);

    if (data.file != null) bodyFormData.append("file", data.file);

    return http.put(`/inasia/flyer/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/inasia/flyer/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InasiaFlyerService();
