import http from "../../http-common";
/* eslint-disable */
class StrukturOrganisasiService {
  strukturOrganisasi(page, itemPerPage, keyword) {
    return http.get("/struktur-organisasi", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();

    if (data.poster_url != null) bodyFormData.append("photo", data.poster_url);

    return http.post("/struktur-organisasi", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.poster_url != null) bodyFormData.append("photo", data.poster_url);

    return http.put(`/struktur-organisasi/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new StrukturOrganisasiService();
