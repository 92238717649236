import http from "../http-common"
/* eslint-disable */
class PesertaPibService {
  pesertaPibList(
    page,
    itemPerPage,
    keyword,
    id,
    kepesertaan = "",
    kategori = "",
    item = "",
    statusProfesi = "",
    cabangId = ""
  ) {
    return http.get(
      "/pib-user/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id +
        "&kepesertaan=" +
        kepesertaan +
        "&kategori=" +
        kategori +
        "&item=" +
        item +
        "&jenis_anggota=" +
        statusProfesi +
        "&cabang_id=" +
        cabangId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  pesertaPib(id) {
    return http.get("/pib-user/" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  pesertaCard(id) {
    return http.get("/pib-user/card/" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  pesertaCardFull(id) {
    return http.get("/pib-user/card-full/" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  update(data) {
    var bodyFormData = new FormData()
    bodyFormData.append("id", data.id)

    if (data.nama) bodyFormData.append("nama", data.nama)
    if (data.whatsapp) bodyFormData.append("whatsapp", data.whatsapp)
    if (data.email) bodyFormData.append("email", data.email)
    if (data.institusi) bodyFormData.append("institusi", data.institusi)
    if (data.nama_sponsor)
      bodyFormData.append("nama_sponsor", data.nama_sponsor)
    if (data.alamat_sponsor)
      bodyFormData.append("alamat_sponsor", data.alamat_sponsor)
    if (data.nama_kontak_person_sponsor)
      bodyFormData.append(
        "nama_kontak_person_sponsor",
        data.nama_kontak_person_sponsor
      )
    if (data.hp_kontak_person_sponsor)
      bodyFormData.append(
        "hp_kontak_person_sponsor",
        data.hp_kontak_person_sponsor
      )
    if (data.email_sponsor)
      bodyFormData.append("email_sponsor", data.email_sponsor)

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("jenis_anggota", data.selectedKategori.code)

      if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
        bodyFormData.append("cabang_id", data.selectedPropinsi.id)
      }

      if (data.no_npa) bodyFormData.append("no_npa", data.no_npa)
    }

    if (
      data.selectedDomisiliPropinsi &&
      data.selectedDomisiliPropinsi != undefined
    ) {
      bodyFormData.append("propinsi_id", data.selectedDomisiliPropinsi.id)
    }

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto)
    if (data.file_bukti_foto != null)
      bodyFormData.append("bukti_transfer_sponsor", data.file_bukti_foto)

    return http.put(`/pib-user/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  updateCatatan(invoiceNo, catatan) {
    var bodyFormData = new FormData()

    bodyFormData.append("catatan", catatan)
    bodyFormData.append("invoice_no", invoiceNo)

    return http.post(`/pib/kehadiran/catatan`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }

  downloadPesertaReport(search, pibId, kepesertaan, kategori, item) {
    return http.get(
      "/report/peserta-pib/download?keyword=" +
        search +
        "&pib_id=" +
        pibId +
        "&kepesertaan=" +
        kepesertaan +
        "&kategori=" +
        kategori +
        "&item=" +
        item,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    )
  }

  resetPassword(id) {
    var bodyFormData = new FormData()
    bodyFormData.append("id", id)

    return http.put(`/pib-user/reset-password/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    })
  }
}

export default new PesertaPibService()
