import http from "../../http-common";

/* eslint-disable */
class InasiaHospitalityJenisOlahragaService {
  jenisOlahragaList(id) {
    return http.get("/inasia/hospitality-jenis-olahraga/list?id=" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InasiaHospitalityJenisOlahragaService();
