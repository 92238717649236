<template>
  <div>
    <v-container fluid>
      <v-card style="margin-top: 16px">
        <v-progress-linear
          v-if="isProgressBarShow"
          indeterminate
          color="green"
        ></v-progress-linear>

        <v-card class="pl-4 pr-4 pt-4 pb-4">
          <v-card-title> Download Laporan </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-card color="#1F7087" dark>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="text-h5"
                        v-text="preTestTitle"
                      ></v-card-title>

                      <v-card-subtitle
                        v-text="preTestSubtitle"
                      ></v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          class="ml-2 mt-5"
                          outlined
                          rounded
                          small
                          @click="onDownloadPreTestReport()"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card color="#952175" dark>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="text-h5"
                        v-text="postTestTitle"
                      ></v-card-title>

                      <v-card-subtitle
                        v-text="postTestSubtitle"
                      ></v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          class="ml-2 mt-5"
                          outlined
                          rounded
                          small
                          @click="onDownloadPostTestReport()"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card color="#FF461F" dark>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="text-h5"
                        v-text="evaluasiFasilitatorTitle"
                      ></v-card-title>

                      <v-card-subtitle
                        v-text="evaluasiFasilitatorSubtitle"
                      ></v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          class="ml-2 mt-5"
                          outlined
                          rounded
                          small
                          @click="onDownloadEvaluasiFasilitatorReport"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card color="#E29C45" dark>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="text-h5"
                        v-text="evaluasiPenyelenggaraanTitle"
                      ></v-card-title>

                      <v-card-subtitle
                        v-text="evaluasiPenyelenggaraanSubtitle"
                      ></v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          class="ml-2 mt-5"
                          outlined
                          rounded
                          small
                          @click="onDownloadEvaluasiPenyelenggaraanReport"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card color="#0AA344" dark>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="text-h5"
                        v-text="evaluasiPascaKegiatanTitle"
                      ></v-card-title>

                      <v-card-subtitle
                        v-text="evaluasiPascaKegiatanSubtitle"
                      ></v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          class="ml-2 mt-5"
                          outlined
                          rounded
                          small
                          @click="onDownloadEvaluasiPascaKegiatanReport"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card color="#0091F7" dark>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="text-h5"
                        v-text="evaluasiRtlTitle"
                      ></v-card-title>

                      <v-card-subtitle
                        v-text="evaluasiRtlSubtitle"
                      ></v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          class="ml-2 mt-5"
                          outlined
                          rounded
                          small
                          @click="openPesertaModal(1)"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card color="#C7ABAA" dark>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="text-h5"
                        v-text="laporanDataPesertaTitle"
                      ></v-card-title>

                      <v-card-subtitle
                        v-text="laporanDataPesertaSubtitle"
                      ></v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          class="ml-2 mt-5"
                          outlined
                          rounded
                          small
                          @click="onDownloadDataPeserta"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card color="#BAE1B6" dark>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="text-h5"
                        v-text="laporanKwitansiTitle"
                      ></v-card-title>

                      <v-card-subtitle
                        v-text="laporanKwitansiSubtitle"
                      ></v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          class="ml-2 mt-5"
                          outlined
                          rounded
                          small
                          @click="openPesertaModal(2)"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card color="#E7D09C" dark>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="text-h5"
                        v-text="laporanPendapatanTitle"
                      ></v-card-title>

                      <v-card-subtitle
                        v-text="laporanPendapatanSubtitle"
                      ></v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          class="ml-2 mt-5"
                          outlined
                          rounded
                          small
                          @click="onDownloadLaporanPendapatan"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card color="#B7AAB3" dark>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="text-h5"
                        v-text="laporanBuktiBayarTitle"
                      ></v-card-title>

                      <v-card-subtitle
                        v-text="laporanBuktiBayarSubtitle"
                      ></v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          class="ml-2 mt-5"
                          outlined
                          rounded
                          small
                          @click="openPesertaModal(3)"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-card color="#F1B003" dark>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title
                        class="text-h5"
                        v-text="laporanAbsensiTitle"
                      ></v-card-title>

                      <v-card-subtitle
                        v-text="laporanAbsensiSubtitle"
                      ></v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          class="ml-2 mt-5"
                          outlined
                          rounded
                          small
                          @click="onDownloadLaporanAbsensi"
                        >
                          Download
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-card>
      <PesertaSelectComponent
        @pilihPeserta="pilihPeserta"
        ref="modalPeserta"
        v-model="dialogPeserta"
        :id="id"
      />
    </v-container>
  </div>
</template>

<script>
import CommonService from "../../services/CommonService";
import KegiatanService from "../../services/KegiatanService";
import MasterService from "../../services/MasterService";
import * as moment from "moment";
import ReportService from "../../services/ReportService";

import PesertaSelectComponent from "../../components/PesertaSelect.vue";

export default {
  name: "KegiatanEdit",
  components: {
    PesertaSelectComponent,
  },
  props: {
    id: String,
  },
  data: () => ({
    reportType: 0,
    dialogPeserta: false,
    isProgressBarShow: false,
    tab: null,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Kegiatan",
        disabled: false,
        exact: true,
        to: { name: "Kegiatan" },
      },
      { text: "Edit Kegiatan", disabled: true },
    ],
    valid: true,
    preTestTitle: "Pre Test",
    preTestSubtitle: "Download laporan pre test",
    postTestTitle: "Post Test",
    postTestSubtitle: "Download laporan post test",
    evaluasiFasilitatorTitle: "Evaluasi Fasilitator",
    evaluasiFasilitatorSubtitle: "Download laporan evaluasi fasilitator",
    evaluasiPenyelenggaraanTitle: "Evaluasi Penyelenggaraan",
    evaluasiPenyelenggaraanSubtitle:
      "Download laporan evaluasi penyelenggaraan",
    evaluasiPascaKegiatanTitle: "Evaluasi Pasca Kegiatan",
    evaluasiPascaKegiatanSubtitle: "Download laporan evaluasi pasca kegiatan",

    evaluasiRtlTitle: "Evaluasi Rencana Tindak Lanjut",
    evaluasiRtlSubtitle: "Download laporan evaluasi rencana tindak lanjut",
    laporanDataPesertaTitle: "Laporan Data Peserta",
    laporanDataPesertaSubtitle: "Download laporan data peserta",

    laporanKwitansiTitle: "Kwitansi Peserta",
    laporanKwitansiSubtitle: "Download Kwitansi Peserta",

    laporanPendapatanTitle: "Laporan Pendapatan",
    laporanPendapatanSubtitle: "Download Laporan Pendapatan",

    laporanBuktiBayarTitle: "Laporan Bukti Bayar",
    laporanBuktiBayarSubtitle: "Download Laporan Bukti Bayar",

    laporanAbsensiTitle: "Laporan Absensi",
    laporanAbsensiSubtitle: "Download Laporan Absensi",

    currentData: {
      FotoUrl: "",
      file_foto: null,
      kegiatan_id: "",
      kegiatan_title: "",
      kegiatan_desc: "",
      informasi: "",
      prasyarat_khusus: "",
      harga: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      tanggal_evaluasi_pasca_kegiatan: "",
      jenis_kegiatan: "",
      skp_narasumber: "",
      skp_moderator: "",
      skp_panitia: "",
      skp_peserta: "",
      nomor_skp: "",
      penyelenggara: "",
      kategori: "",
      selectedKategori: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
    items_kategori: [],
    fotoKey: 0,
  }),
  mounted: async function () {
    this.getListKategoriKegiatan();
    this.getKegiatan();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    getKegiatan() {
      KegiatanService.getKegiatan(this.id)
        .then((res) => {
          console.log(res.data.kegiatan);
          this.currentData = res.data.kegiatan;
          this.currentData.tanggal_awal = moment(
            this.currentData.tanggal_awal
          ).format("YYYY-MM-DD");
          this.currentData.tanggal_akhir = moment(
            this.currentData.tanggal_akhir
          ).format("YYYY-MM-DD");

          if (this.currentData.tanggal_evaluasi_pasca_kegiatan) {
            this.currentData.tanggal_evaluasi_pasca_kegiatan = moment(
              this.currentData.tanggal_evaluasi_pasca_kegiatan
            ).format("YYYY-MM-DD");
          }

          if (res.data.kegiatan.kategori) {
            this.items_kategori.forEach((entry) => {
              if (entry.code == res.data.kegiatan.kategori) {
                this.currentData.selectedKategori = entry;
              }
            });
          }
          this.currentData.FotoUrl = res.data.kegiatan.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.isEditable = 0;
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      KegiatanService.update(
        this.id,
        this.currentData,
        this.currentData.file_foto
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Edit Kegiatan berhasil");

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Edit Kegiatan gagal. Coba lagi");
        });
    },

    onDownloadPreTestReport() {
      this.isProgressBarShow = true;
      ReportService.downloadPreTestReport(this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    onDownloadPostTestReport() {
      this.isProgressBarShow = true;
      ReportService.downloadPostTestReport(this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    onDownloadEvaluasiPenyelenggaraanReport() {
      this.isProgressBarShow = true;
      ReportService.downloadEvaluasiPenyelenggaraanReport(this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    onDownloadEvaluasiPascaKegiatanReport() {
      this.isProgressBarShow = true;
      ReportService.downloadEvaluasiPascaKegiatanReport(this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    onDownloadEvaluasiFasilitatorReport() {
      this.isProgressBarShow = true;
      ReportService.downloadEvaluasiFasilitatorReport(this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    onDownloadDataPeserta() {
      this.isProgressBarShow = true;
      ReportService.downloadDataPesertaReport(this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    onDownloadLaporanPendapatan() {
      this.isProgressBarShow = true;
      ReportService.downloadPendapatanReport(this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    onDownloadLaporanAbsensi() {
      this.isProgressBarShow = true;
      ReportService.downloadAbsensiReport(this.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    pilihPeserta(item) {
      console.log(item);
      this.dialogPeserta = false;

      switch (this.reportType) {
        case 1:
          this.isProgressBarShow = true;
          ReportService.downloadEvaluasiRtlReport(this.id, item.user_id)
            .then((res) => {
              window.open(res.data.file, "_blank");
              this.isProgressBarShow = false;
            })
            .catch((err) => {
              this.isProgressBarShow = false;
              CommonService.showErrorToast("Gagal download report. Coba lagi");
              console.log(err);
            });
          break;
        case 2:
          this.isProgressBarShow = true;
          ReportService.downloadKwitansiReport(this.id, item.user_id)
            .then((res) => {
              window.open(res.data.file, "_blank");
              this.isProgressBarShow = false;
            })
            .catch((err) => {
              this.isProgressBarShow = false;
              CommonService.showErrorToast("Gagal download report. Coba lagi");
              console.log(err);
            });
          break;
        case 3:
          ReportService.downloadBuktiBayarReport(this.id, item.user_id)
            .then((res) => {
              setTimeout(function () {
                window.open(res.data.file, "_blank");
              }, 1000);
            })
            .catch((err) => {
              CommonService.showErrorToast("Gagal download report. Coba lagi");
              console.log(err);
            });

          break;
      }
    },

    openPesertaModal: async function (type) {
      this.reportType = type;
      this.$refs.modalPeserta.getDataFromApi();
      this.dialogPeserta = true;
    },
  },
};
</script>

<style scoped>
</style>
